import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStyles } from './style';
import classNames from 'classnames';

type ButtonType = {
  children: any;
  onClick: React.MouseEventHandler<any>;
  rounded?: boolean;
  disabled?: boolean;
  className?: any;
  isShow?: boolean;
};
const Button = observer(({ children, onClick, disabled, className, isShow = true }: ButtonType) => {
  const { defaultBtnClass } = useStyles();

  return isShow ? (
    <button
      className={classNames(defaultBtnClass, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  ) : null;
});

export default Button;
