import React from 'react';
import { ArrowDownIcon, ArrowUpIcon, AudioIcon, Button, DeviceList } from '../..';
import { useStores } from '../../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import {
  existAudioDevice,
  handleShowAudio,
  handleChangeMyAudioDevice,
} from '../../../helpers/device';
// import { changeStatusAudioTrack } from '../../../helpers/stream';
// import { emitActiveMediaDevices } from '../../../services/game/emitsToGame/emits';
import useStyles from './style';
import useComponentVisible from '../../../hooks/useComponentVisible';

const AudioMenuItem = observer(({ handleUpdateLocalStream }: { handleUpdateLocalStream: any }) => {
  const {
    ref: refAudioSelect,
    isComponentVisible: isShowAudioSelect,
    setIsComponentVisible: setIsShowAudioSelect,
  } = useComponentVisible();

  const {
    allSettings: { audioDevices },
    mySetting: { isMutedAudio, isShowAudio, myAudioDeviceId },
  } = useStores();

  const handleArrowSelect = () => {
    if (!isMutedAudio) {
      setIsShowAudioSelect(!isShowAudioSelect);
    }
  };

  const { container, selectContainer, leftButtonClass, rightButtonClass } = useStyles();

  // useEffect(() => {
  //   if (isMutedAudio) {
  //     setIsShowAudioSelect(false);
  //     setIsShowAudio(false);
  //     changeStatusAudioTrack(false);
  //     emitActiveMediaDevices(false, isShowVideo);
  //   } else {
  //     setIsShowAudio(true);
  //     changeStatusAudioTrack(true);
  //     emitActiveMediaDevices(true, true);
  //   }
  // }, [isMutedAudio, isShowVideo, setIsShowAudio, setIsShowAudioSelect, isShowAudio]);

  return (
    <div className={container}>
      {isShowAudioSelect && (
        <div className={selectContainer} ref={refAudioSelect}>
          <DeviceList
            handleChange={(audioDeviceLabel: string) =>
              handleChangeMyAudioDevice(audioDeviceLabel, handleUpdateLocalStream)
            }
            options={audioDevices}
            isDisabled={!isShowAudio || !existAudioDevice()}
            activeDeviceId={myAudioDeviceId}
          />
        </div>
      )}
      <Button onClick={handleShowAudio} className={leftButtonClass}>
        <AudioIcon />
      </Button>

      <Button onClick={handleArrowSelect} className={rightButtonClass}>
        {isShowAudioSelect ? (
          <ArrowUpIcon
            color={!existAudioDevice() ? '#CECECE' : 'white'}
            background={!existAudioDevice() ? '#140E17' : 'black'}
          />
        ) : (
          <ArrowDownIcon
            color={!existAudioDevice() ? '#CECECE' : 'white'}
            background={!existAudioDevice() ? '#140E17' : 'black'}
          />
        )}
      </Button>
    </div>
  );
});

export default AudioMenuItem;
