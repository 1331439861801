import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { version } from '../../package.json';
import {
  SENTRY_CRITICAL_SEVERITY,
  SENTRY_ERROR_SEVERITY,
  SENTRY_FATAL_SEVERITY,
} from '../constants/sentry';

export const initSentry = () => {
  const { REACT_APP_SENTRY_DSN, NODE_ENV } = process.env;
  const url = new URL(window.location.href);
  const userId = url.searchParams.get('user_id');
  const eventId = url.searchParams.get('event_id');

  if (REACT_APP_SENTRY_DSN && NODE_ENV === 'production') {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      release: version,
      environment: NODE_ENV,
    });

    if (userId && eventId) {
      const sliceUserId = userId.slice(-10);
      const sliceEventId = eventId.slice(-10);

      Sentry.configureScope((scope) => {
        scope.setTag('environment', NODE_ENV);
        scope.setTag('release', version);
        scope.setTransactionName('PlayerTransaction');

        scope.setUser({
          id: sliceEventId,
          username: sliceUserId,
        });
      });
    }
  }
};

export const sendMessageToSentry = (message: string, type = SENTRY_CRITICAL_SEVERITY) => {
  const { NODE_ENV } = process.env;
  if (NODE_ENV === 'production') {
    Sentry.withScope((scope) => {
      scope.setLevel(type);

      if (
        type === SENTRY_FATAL_SEVERITY ||
        type === SENTRY_CRITICAL_SEVERITY ||
        type === SENTRY_ERROR_SEVERITY
      ) {
        scope.setTag('isBug', true);
      }
      Sentry.captureMessage(message);
    });
  }
};

export const logoutUser = () => {
  Sentry.withScope((scope) => {
    scope.setLevel(SENTRY_ERROR_SEVERITY);
    scope.setTag('isBug', true);
    Sentry.captureMessage('Юзера аварийно выкинуло из игры');
  });
};
