import React from 'react';
import useStyles from './style';
import { Modal, CloseIcon, ModeratorSelect, NumberParticipantsInSpace, ArrowDownIcon } from '../..';
import classnames from 'classnames';

const CommonModal = ({
  changeScreen,
  menu,
  children,
  isShow,
  handleClose,
  title,
  isShowNumberParticipantsInSpace = true,
  modalClass,
  modalWrapClass,
}: CommonModalType) => {
  const {
    commonModalContainer,
    commonModalTitle,
    commonModalTitleClose,
    commonModalClass,
    commonModalTitleContainer,
  } = useStyles();

  return (
    <Modal
      isOpen={isShow}
      modalClass={classnames(commonModalClass, modalClass)}
      modalWrapClass={modalWrapClass}
      handleClose={handleClose}
    >
      <div className={commonModalContainer}>
        <div>
          <div className={commonModalTitleContainer}>
            <div className={commonModalTitle}>{title}</div>
            {menu && (
              <div>
                <ModeratorSelect
                  options={menu}
                  changeValue={changeScreen}
                  icon={<ArrowDownIcon color="#A24DE8" />}
                />
              </div>
            )}
          </div>
          {isShowNumberParticipantsInSpace && (
            <div>
              <NumberParticipantsInSpace />
            </div>
          )}
        </div>
        <div className={commonModalTitleClose} onClick={handleClose}>
          <CloseIcon />
        </div>
      </div>
      {children}
    </Modal>
  );
};

export default CommonModal;
