import React from 'react';
import useStyles from './style';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { ScreenShareTargetType } from '../../models/screenSharingModule';
import { Button } from '..';
import { emitUserOpenFullScreenShare } from '../../services/game/emitsToGame/emits';
import { useStores } from '../../hooks/useStores';
import { useTranslation } from 'react-i18next';

type StaticScreenShareButtonProps = {
  screenShareTarget: ScreenShareTargetType;
  handleChangeStaticShare: any;
};

const StaticScreenShareButton = observer(
  ({ screenShareTarget, handleChangeStaticShare }: StaticScreenShareButtonProps) => {
    const {
      staticScreenShareButton,

      staticScreenShareButtonTitle,
    } = useStyles();
    const { mySetting } = useStores();
    const { t } = useTranslation();
    const { isShow, isTaken, isTakenByCurrentUser, title, screenShareTargetId } = screenShareTarget;

    const handleStartFullScreen = () => {
      mySetting.setIsFullScreen(true);
      emitUserOpenFullScreenShare(screenShareTargetId);
    };

    return (
      <Button
        className={classnames(staticScreenShareButton)}
        onClick={
          !isTaken || isTakenByCurrentUser
            ? () => handleChangeStaticShare(screenShareTarget)
            : () => handleStartFullScreen()
        }
        isShow={isShow}
      >
        {/* <div className={classnames(staticScreenShareButtonIcon)}>
          {!isTaken || isTakenByCurrentUser ? (
            isTakenByCurrentUser ? (
              <ShareIconOff
                background={palette.background.paper}
                color={palette.secondary.main}
                size="3.625rem"
              />
            ) : (
              <ShareIconOn
                background={palette.primary.dark}
                color={palette.background.default}
                size="3.5rem"
              />
            )
          ) : (
            <FullScreenOpenIcon size="3.625rem" />
          )}
        </div> */}
        <span className={classnames(staticScreenShareButtonTitle)}>
          {!isTaken || isTakenByCurrentUser ? title : `${t('Full')} ${title}`}
        </span>
      </Button>
    );
  }
);

export default StaticScreenShareButton;
