import React from 'react';

type BugIconProps = {
  onClick: () => void;
  className: any;
};
const BugIcon = ({ onClick, className }: BugIconProps) => {
  return (
    <div className={className}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        fill="darkblue"
        onClick={onClick}
      >
        <g>
          <g>
            <path
              d="M497.229,260.925h-88.615v-30.708c44.584-7.102,78.769-45.816,78.769-92.368v-19.692c0-8.156-6.613-14.769-14.769-14.769
          s-14.769,6.613-14.769,14.769v19.692c0,30.206-21.04,55.573-49.23,62.261v-13.03c0-13.573-11.042-24.615-24.615-24.615H369.23
          v-54.153c0-31.066-22.254-57.024-51.656-62.793l10.998-24.746c3.313-7.453-0.044-16.182-7.498-19.494
          c-7.455-3.314-16.182,0.044-19.494,7.498l-15.794,35.536h-59.573L210.42,8.775c-3.312-7.453-12.041-10.811-19.494-7.498
          c-7.453,3.312-10.81,12.041-7.498,19.494l10.998,24.746c-29.401,5.77-51.655,31.726-51.655,62.793v54.153h-14.769
          c-13.573,0-24.615,11.042-24.615,24.615v13.03c-28.19-6.687-49.23-32.055-49.23-62.261v-19.692
          c0-8.156-6.613-14.769-14.769-14.769s-14.769,6.613-14.769,14.769v19.692c0,46.552,34.185,85.265,78.769,92.368v30.708H14.771
          c-8.156,0-14.769,6.613-14.769,14.769s6.613,14.769,14.769,14.769h88.615v30.708c-44.584,7.102-78.769,45.816-78.769,92.368
          v19.692c0,8.156,6.613,14.769,14.769,14.769s14.769-6.613,14.769-14.769v-19.692c0-30.206,21.04-55.573,49.23-62.261v8.107
          C103.386,443.537,171.849,512,256,512s152.614-68.463,152.614-152.614v-8.107c28.19,6.688,49.23,32.055,49.23,62.261v19.692
          c0,8.156,6.613,14.769,14.769,14.769s14.769-6.613,14.769-14.769v-19.692c0-46.552-34.185-85.265-78.769-92.368v-30.708h88.615
          c8.156,0,14.769-6.613,14.769-14.769S505.385,260.925,497.229,260.925z M172.308,108.311c0-19.002,15.459-34.461,34.461-34.461
          h98.461c19.002,0,34.461,15.459,34.461,34.461v54.153H172.308V108.311z M379.076,359.386c0,67.864-55.212,123.076-123.076,123.076
          S132.924,427.25,132.924,359.386V192.003h108.307v142.768c0,8.156,6.613,14.769,14.769,14.769s14.769-6.613,14.769-14.769V192.003
          h108.307V359.386z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </div>
  );
};

export default BugIcon;
