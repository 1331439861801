import { rootStore } from '../../../models/rootStore';
import { resizePlayerStyle } from '../../mathLibraly';
import { getWebRtcPlayerObject } from '../../webRtcWrapper';
import { ReceiveChatMessageType } from '../../../types/chat';
import {
  ajv,
  responseScreenSharingSchema,
  responseOnLogMessage,
  responseUpdateNearestStandSchema,
} from '../../../helpers/validation';
import { unLockMouse, registerInputs } from '../../control';
import { logGame, logWbs } from '@pxs-infra/logger';
import { ChatType } from '../../../models/chats';
import { ChatKinds } from '../../../constants/chats';
import { Roles, ScreenAllowedForRolesMap } from '../../../constants/user';
import { closeScreenChannel } from '../../../helpers/stream';
import { ScreenStatesList } from '../../../constants/game';
import * as WbsService from '../../../services/wbs';
import { setIsChangingGameServer, setIsChangingMS } from '../../wbs/store';

export const connectToWbs = (event: any) => {
  const { msDomain: newMsDomain, userId: newLocalChannel } = event;
  const {
    wbs: { msDomain: oldMsDomain, localStreamChannel: oldLocalChannel },
  } = rootStore;

  /**
   * TODO!!!
   * СРОЧНО КАК-ТО СДЕЛАТЬ ОТКЛЮЧЕНИЕ И ПЕРЕПОДКЛЮЧЕНИЕ НОРМАЛЬНО
   */
  logWbs(
    'connectToWbs',
    JSON.stringify({ oldMsDomain, newMsDomain, oldLocalChannel, newLocalChannel })
  );

  if (oldMsDomain == newMsDomain && oldLocalChannel == newLocalChannel) {
    logWbs('connectToWbs', 'Domain and channel are the same');
    // return;
  } else if (oldLocalChannel != newLocalChannel && oldMsDomain == newMsDomain) {
    // WbsService.publishLocal();
    logWbs('connectToWbs', 'Server is the same, but channels aren`t');
  }

  setIsChangingMS(true);
  WbsService.saveData({ msDomain: newMsDomain, localChannel: newLocalChannel });
  WbsService.reconnect();
  WbsService.publishLocal();
  setIsChangingMS(false);
};

export const restreamToWbs = (event: any) => {
  console.log(`Из игры приходит событие restreamToWbs:${event}`);
};

export const onClientTravel = (event: any) => {
  const { status, travelDestination } = event;
  const { connection, user } = rootStore;

  user.setTravelDestination(travelDestination);
  console.log(`Travel destination: ${user.travelDestination}`);
  const player = connection.getRefPlayer();
  switch (status) {
    case 'StartedTravel':
      // TODO: при начале загрузки в пространство отключаем медийку
      setIsChangingGameServer(true);
      WbsService.disconnect();
      user.clearAllMessages();
      connection.setIsLoading(true);
      connection.setLoadingMessage('Waiting success command from game');
      break;
    case 'SuccessedTravel':
      // TODO: при окончании загрузки в пространство перегружаем медийку
      // TODO: из игры некорректно приходит событие connectToWbs
      // поэтому дергаем коннекшн с медийкой и публикуем канал на всякий случай
      // Нужно проверять что connectToWbs уже пришло
      // WbsService.reconnect();
      // WbsService.publishLocal();
      setIsChangingGameServer(false);
      connection.setIsLoading(false);
      connection.setIsError(false);
      if (player) {
        const webRtcPlayerObject = getWebRtcPlayerObject();
        player.appendChild(webRtcPlayerObject.video);
        registerInputs();
      }
      resizePlayerStyle();
      break;
    case 'FailedTravel':
      // TODO: при ошибке в клиенте игры отключаем медийку
      setIsChangingGameServer(true);
      WbsService.disconnect();
      user.clearAllMessages();
      connection.setIsLoading(true);
      connection.setLoadingMessage('Waiting success command from game');
      break;
  }
};

export const receiveChatMessage = (event: ReceiveChatMessageType) => {
  const { message, senderConnectUserId, senderName } = event;
  const { user } = rootStore;
  if (user.userId !== senderConnectUserId) {
    user.receiveMessage(message, senderName);
  }
};

export const screenSharing = async (event: any) => {
  const validate = ajv.compile(responseScreenSharingSchema);
  const { wbs } = rootStore;

  if (validate(event)) {
    const { streamId, action } = event;
    if (action) {
      switch (action) {
        case 'Connect':
          unLockMouse();
          wbs.setScreenChannel(streamId);
          break;
        case 'Disconnect':
          await closeScreenChannel();
          break;
      }
    }
  } else {
    console.log('requestScreenSharing validation have this errors=', validate.errors);
  }
};

export const updateMediaScreensState = (json: any) => {
  const { screenSharingModule, mySetting } = rootStore;
  screenSharingModule.removeAllScreenShareTargets();
  mySetting.setIsShowPersonalShare(false);
  onUpdateMediaScreensState(json);
};

const onUpdateMediaScreensState = async (json: OnUpdateMediaScreensStateEventSignature) => {
  const {
    screenSharingModule: {
      updateScreenShareTarget,
      setCurrentUserRole,
      selectedScreenShareTargetId,
      selectedFullScreenShareTargetId,
    },
    user: { role, userId },
    modal: { setIsOpenRequestScreenShareModal },
    mySetting: { setIsFullScreen },
  } = rootStore;
  const {
    updateMediaScreensState: { screens },
  } = json;

  screens
    .filter(
      /** sometimes game sends us the not-existent screens in this list
       @author alexander girsh */
      (screen) => screen.screenId !== -1
    )
    .forEach((screen) =>
      updateScreenShareTarget({
        screenShareTargetId: screen.screenId,
        title: screen.screenName,
        takenByUserId: screen.ownedPlayerId ? screen.ownedPlayerId : null,
        isShow: screen.bInTriggerBox,
        allowedForRoles: screen.allowedRoles.reduce(
          (sum, current) => {
            sum[current] = true;
            return sum;
          },
          {
            [Roles.SPECTATOR]: false,
            [Roles.DEFAULT_USER]: false,
            [Roles.SPEAKER]: false,
            [Roles.MODERATOR]: false,
            [Roles.GREEN_SPEAKER]: false,
          } as ScreenAllowedForRolesMap
        ),
      })
    );
  setCurrentUserRole(role);

  let isNeedCloseScreenShareModal = false;
  let isNeedCloseScreenShareChannel = false;
  let isNeedCloseFullScreenShareIcon = false;

  screens.map((screen) => {
    const { bInTriggerBox, ownedPlayerId, state, screenId } = screen;
    if (bInTriggerBox === false && selectedScreenShareTargetId === screenId) {
      if (ownedPlayerId === '' && state === ScreenStatesList.Free) {
        isNeedCloseScreenShareModal = true;
      }

      if (ownedPlayerId === userId && state === ScreenStatesList.Used) {
        isNeedCloseScreenShareChannel = true;
      }
    }

    if (
      bInTriggerBox === true &&
      state === ScreenStatesList.Free &&
      selectedFullScreenShareTargetId === screenId &&
      ownedPlayerId === ''
    ) {
      isNeedCloseFullScreenShareIcon = true;
    }
  });

  if (isNeedCloseScreenShareModal) {
    setIsOpenRequestScreenShareModal(false);
  }

  if (isNeedCloseScreenShareChannel) {
    await closeScreenChannel();
  }

  if (isNeedCloseFullScreenShareIcon) {
    setIsFullScreen(false);
  }
};

export const updateUserInterfaceState = (json: any) => {
  const bShowInterface = json.updateUserInterfaceState.bShowUI;
  rootStore.connection.setIsHideInterface(!bShowInterface);
};

export const onLogMessage = (event: any) => {
  const validate = ajv.compile(responseOnLogMessage);

  if (validate(event)) {
    const { verbosity, message } = event;
    if (verbosity === 'Log') {
      logGame(message);
    }
  } else {
    console.log('onLogMessage validation have this errors=', validate.errors);
  }
};

enum GameChatsChannelsToChatKinds {
  All = 'PUBLIC',
  Private = 'DIRECT',
}

export const onReceiveMessage = (json: { onReceiveMessage: ReceiveChatMessageType }) => {
  const { chatsModule, user } = rootStore;

  const { onReceiveMessage } = json;
  const { channel, message, senderConnectUserId } = onReceiveMessage;

  const chatMessageId = Date.now().toString();
  const authorUserId = senderConnectUserId;

  // @ts-ignore
  if ((GameChatsChannelsToChatKinds[channel as string] as ChatKinds) === ChatKinds.PUBLIC) {
    const chat = chatsModule.publicChatsList.find(
      (chat) => chat.title === 'General Channel'
    ) as ChatType;

    const chatId = chat.chatId;
    const text = message;
    const createdAt = new Date();

    chat.addMessage({
      chatId,
      chatMessageId,
      authorUserId,
      text,
      isRead: Boolean(senderConnectUserId === user.userId),
      createdAt,
    });
    // @ts-ignore
  } else if ((GameChatsChannelsToChatKinds[channel as string] as ChatKinds) === ChatKinds.DIRECT) {
    if (senderConnectUserId === user.userId) {
      /* we handle this case in the ./apiWrapper module */
      return;
    }

    let chat;

    const existingChat = chatsModule.directChatsByUserId.get(senderConnectUserId);

    if (existingChat) {
      chat = existingChat;
    } else {
      const chatId = Date.now().toString();

      chat = chatsModule.addChat({
        chatId,
        userId: senderConnectUserId,
        kind: ChatKinds.DIRECT,
        title: null,
        coverImage: null,
        messages: [],
      });
    }

    const chatMessageId = Date.now().toString();

    chat.addMessage({
      chatId: chat.chatId,
      chatMessageId,
      authorUserId: senderConnectUserId,
      text: message,
      createdAt: new Date(),
      isRead: Boolean(senderConnectUserId === user.userId),
    });
  }
};

export const updateEnvironmentConfig = (event: any) => {
  rootStore.user.setEnvironmentConfig(event);
};

export const updateCustomizationState = (event: any) => {
  rootStore.user.setCustomizableObject(event);
};

export const updateCountDownSeconds = (event: any) => {
  rootStore.user.setTimer(event);
};

export const updateLiveIndicatorVisibility = (event: any) => {
  rootStore.user.setIsLive(event.value);
};

export const requestSplashScreen = (event: any) => {
  rootStore.user.setVideoPlayerOpen(event.bRequestSplashScreen);
};

export const onWebRequestReply = (event: any) => {
  const { callbackName, status, errorList } = event;

  let message = '';
  switch (status) {
    case 'Handled':
      message = 'успешно выполнено';
      break;
    case 'Unhandled':
      message = 'не выполнено';
      break;
    case 'Error':
      message = `выполнено с ошибкой "${errorList[0]}"`;
      break;
  }

  logGame(`Событие ${callbackName} ${message}`);
};

export const onUpdateUsersInSpace = (json: any) => {
  const { participantsList } = rootStore;

  const { updateUsersInSpace } = json;

  const { inSpaceUsers, removedUsers } = updateUsersInSpace;

  rootStore.participantsList.clearList();

  const addedUsers = Object.values(inSpaceUsers) as any[];

  /**
   * updateUsersInSpace:
   inSpaceUsers:
   60f6dfa397057c001d205b9a:
   aratarUrl: "None"
   bAbleToReadMessages: false
   bAbleToSendMessages: false
   collectiveUserId: "60f6dfa397057c001d205b9a"
   infoType: "LocalUser"
   mediaSettings:
   bAudioMuted: true
   bListener: true
   bStreamedNow: false
   bVideoMuted: true
   localScreenShareId: 0
   mediaUserId: 4
   settingsType: ""
   targetVolume: 9.290608818473537e-43
   __proto__: Object
   userRole: "Moderator"
   username: "Alex Dev"
   __proto__: Object
   __proto__: Object
   */

  addedUsers.forEach((addedUser) => {
    participantsList.updateParticipant({
      userId: addedUser.collectiveUserId,
      username: addedUser.username,
      subtitle: null,
      avatar: userHasAvatar(addedUser.avatarUrl) ? addedUser.avatarUrl : null,
      role: addedUser.userRole,
      isOnline: true,
      voiceIsMuted: addedUser.mediaSettings.bAudioMuted,
      videoIsMuted: addedUser.mediaSettings.bVideoMuted,
      screenSharingIsMuted: false /* TODO: we still have no prop to mute the screen share */,
      voiceVolume: Math.trunc(addedUser.mediaSettings.targetVolume * 100),
    });
  });

  removedUsers.forEach((removedUserId: string) => {
    participantsList.updateParticipant({
      userId: removedUserId,
      isOnline: false,
    });
  });
};

const userHasAvatar = (avatarUrl: string): boolean => {
  return avatarUrl !== 'None';
};

export const onUpdateRemoteUserInfo = (json: { updateRemoteUserInfo: any }) => {
  const { updateRemoteUserInfo } = json;
  updateUserInfo(updateRemoteUserInfo);
};

const updateUserInfo = (updatedUserInfo: any) => {
  const { participantsList } = rootStore;

  const {
    aratarUrl,
    collectiveUserId,
    username,
    userRole,
    mediaSettings,
    screenShareUse,
    usedStaticScreenShare,
  } = updatedUserInfo;

  participantsList.updateParticipant({
    userId: collectiveUserId,
    username,
    subtitle: null,
    avatar: userHasAvatar(aratarUrl) ? aratarUrl : null,
    role: userRole,
    isOnline: true,
    voiceIsMuted: mediaSettings.bAudioMuted,
    videoIsMuted: mediaSettings.bVideoMuted,
    voiceIsEnabled: mediaSettings.bAudioEnabled,
    videoIsEnabled: mediaSettings.bVideoEnabled,
    screenSharingIsMuted: false /* TODO: we still have no prop to mute the screen share */,
    voiceVolume: Math.trunc(mediaSettings.targetVolume * 100),
    isSharingScreen: screenShareUse !== 'NotScrenShared',
    staticScreenShareTargetId:
      usedStaticScreenShare && usedStaticScreenShare !== -1 ? usedStaticScreenShare : null,
  });
};

export const onUpdateLocalUserInfo = (json: {
  updateLocalUserInfo: any & {
    mediaSettings: any & { bAudioMuted: boolean; bVideoMuted: boolean };
  };
}) => {
  const { updateLocalUserInfo } = json;
  rootStore.user.setRole(updateLocalUserInfo.userRole);
  updateUserInfo(updateLocalUserInfo);

  const {
    mySetting: { setIsMutedAudio, setIsMutedVideo, setIsShowAudio, setIsShowVideo },
    screenSharingModule: { setSelectedFullScreenShareTargetId },
  } = rootStore;
  const {
    mediaSettings: { bAudioMuted, bVideoMuted, bAudioEnabled, bVideoEnabled, fullScreenShareId },
  } = updateLocalUserInfo;

  if (bAudioMuted) {
    setIsMutedAudio(true);
  } else {
    setIsMutedAudio(false);
  }

  if (bVideoMuted) {
    setIsMutedVideo(true);
  } else {
    setIsMutedVideo(false);
  }

  if (bAudioEnabled) {
    setIsShowAudio(true);
  } else {
    setIsShowAudio(false);
  }

  if (bVideoEnabled) {
    setIsShowVideo(true);
  } else {
    setIsShowVideo(false);
  }

  if (fullScreenShareId !== -1) {
    setSelectedFullScreenShareTargetId(fullScreenShareId);
  }
};

export const updateBackgroundChatVisibility = (
  json: OnModeratorUpdateBackgroundChatVisibilitySignature
) => {
  const {
    updateBackgroundChatVisibility: { value },
  } = json;
  const { UI } = rootStore;

  UI.widgetsArea.items.get('chatWidget')?.setIsOpened(value);
};

export const onUserBanned = () => {
  const { modal } = rootStore;
  modal.setIsOpenBanModal(true);
};

export const updateNearestStand = (event: ResponseUpdateNearestStand) => {
  const validate = ajv.compile(responseUpdateNearestStandSchema);
  const {
    game: {
      updateNearestStand: { setCurrentProductId, setStatusBuyButton },
    },
  } = rootStore;

  if (validate(event)) {
    const {
      updateNearestStand: { status, stand_id },
    } = event;

    setStatusBuyButton(status);
    const productId = Number(stand_id);
    setCurrentProductId(productId);
  } else {
    console.log('updateNearestStand validation have this errors=', validate.errors);
  }
};
