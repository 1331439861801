import React from 'react';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { VideoOnIcon, VideoOffIcon, VideoMuteIcon } from '..';
import { existVideoDevice } from '../../helpers/device';

const VideoIcon = observer(() => {
  const {
    mySetting: { isShowVideo, isMutedVideo },
  } = useStores();

  return (
    <>
      {isMutedVideo ? (
        <VideoMuteIcon color="white" />
      ) : isShowVideo ? (
        <VideoOnIcon color={!existVideoDevice() ? '#CECECE' : 'white'} />
      ) : (
        <VideoOffIcon color={!existVideoDevice() ? '#CECECE' : 'white'} />
      )}
    </>
  );
});

export default VideoIcon;
