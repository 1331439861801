import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }: Theme) => ({
  chatsListItemContainer: {
    width: '100%',
    height: '3rem',
    marginTop: '.625rem',
    marginBottom: '.625rem',
    borderRadius: 0,
    paddingLeft: '.75rem',
    backgroundColor: 'transparent',
    transitionProperty: 'opacity background-color',
    transitionDuration: '.15s',
    transitionTimingFunction: 'ease',
    '&.selected': {
      backgroundColor: palette.primary.dark,
      '& > * > .title, & > * > .subtitle': {
        color: palette.background.default,
      },
    },
  },
  chatsListItemTitleContainer: {
    height: '2.125rem',
    maxHeight: '2.125rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& > .title': {
      transition: 'color .15s ease',
      color: palette.primary.dark,
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1rem',
    },
    '& > .subtitle': {
      transition: 'color .15s ease',
      color: palette.primary.light,
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '.8125rem',
    },
  },
  coverImageWrapperClass: {
    height: '2.125rem',
    width: '2.125rem',
    position: 'relative',
    marginRight: '.625rem',
  },
  coverImageClass: {
    height: '2.125rem',
    width: '2.125rem',
    borderRadius: '50%',
  },
  onlineBadgeClass: {
    height: '.5rem',
    width: '.5rem',
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 2,
    bottom: '0',
    right: '0',
    transition: 'background-color .25s ease',
    '&.online': {
      backgroundColor: 'green',
    },
    '&.offline': {
      backgroundColor: 'gray',
    },
  },
  unreadMessagesCounterClass: {
    height: '1.5rem',
    width: '1.5rem',
    marginLeft: 'auto' /* flexbox layout specific feature */,
    marginRight: '1rem',
    borderRadius: '50%',
    background: palette.secondary.contrastText,
    color: palette.primary.dark,
  },
}));

export default useStyles;
