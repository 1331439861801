import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {},
  checkbox: {
    '-webkit-appearance': 'none',
    appearance: 'none',
    backgroundColor: '#fff',
    margin: 0,

    width: '20px',
    height: '20px',
    borderWidth: '2px',
    borderColor: '#A2A2A2',
    borderRadius: '4px',
    borderStyle: 'solid',
    transform: 'translateY(-0.075em)',
    placeContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      width: '20px',
      height: '20px',
      transform: 'scale(0)',
      transition: '120ms transform ease-in-out',

      background: (isGroupCheckbox) =>
        isGroupCheckbox
          ? 'url(icons/common-checkbox.svg) center center no-repeat'
          : 'url(icons/checkbox.svg) center center no-repeat',
    },
    '&:checked': {
      borderColor: '#A24DE8',
    },
    '&:checked::before': {
      transform: 'scale(1)',
    },
    '&:disabled': {
      '--form-control-color': 'grey',
      color: 'grey',
      cursor: 'not-allowed',
    },
  },
}));

export default useStyles;
