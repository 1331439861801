import React, { useEffect, useState } from 'react';
import { FullScreenCloseIcon, FullScreenOpenIcon } from '../..';
import Box from '@material-ui/core/Box';
import { useStores } from '../../../hooks/useStores';
import { Roles } from '../../../constants/user';
import { toogleFullScreen, isFullScreen } from '../../../helpers/fullscreen';
import useStyles from './style';

const FullScreenMenuItem = () => {
  const {
    user: { role },
  } = useStores();
  const { fullScreenWrapper, fullScreenIcon } = useStyles();
  const [isFullScreenOn, setFullScreenOn] = useState<boolean>(false);
  const size = '48px';

  // TODO: понять где это нужно
  // const handleCloseFullScreenShare = () => {
  //   mySetting.setIsFullScreen(false);
  //   emitUserCloseFullScreenShare();
  // };
  // const size = isMobile ? '40px' : '48px';

  const handleFullScreen = () => {
    toogleFullScreen();
    setFullScreenOn(!isFullScreenOn);
  };

  useEffect(() => {
    const fullScreenListener = () => {
      if (isFullScreen(document)) {
        setFullScreenOn(true);
      } else {
        setFullScreenOn(false);
      }
    };
    document.addEventListener('fullscreenchange', fullScreenListener);
    return () => document.removeEventListener('fullscreenchange', fullScreenListener, false);
  }, []);

  return role !== Roles.SPECTATOR ? (
    <Box className={fullScreenWrapper}>
      <Box onClick={handleFullScreen} className={fullScreenIcon}>
        {isFullScreenOn ? <FullScreenCloseIcon size={size} /> : <FullScreenOpenIcon size={size} />}
      </Box>
    </Box>
  ) : null;
};

export default FullScreenMenuItem;
