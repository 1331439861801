import React, { useState, useEffect } from 'react';
import useStyles from './style';
import {
  ModeratorList,
  ModeratorTitle,
  Input,
  ParticipantItem,
  ParticipantTableHeader,
  ModeratorBanModal,
  Notification,
  SuccessIcon,
} from '../../..';
import { useStores } from '../../../../hooks/useStores';
import { ParticipantType } from '../../../../models/participants';
import { observer } from 'mobx-react-lite';

const Participants = observer(() => {
  const { container, badgeClass, participantTitle, notificationContainer } = useStyles();
  const { participantsList } = useStores();
  // search
  const [searchName, setSearchName] = useState('');
  const [searchParticipants, setSearchParticipants] = useState<ParticipantType[]>([]);

  //select
  const [isSelectAllParticipants, setIsSelectAllParticipants] = useState(false);
  const [isCheckedGroupCheckbox, setIsCheckedGroupCheckbox] = useState(false);
  const [selectParticipants, setSelectParticipants] = useState<ParticipantType[]>([]);
  const participants = participantsList.notModeratorsOnly;

  // order
  const [order, setOrder] = useState('asc');

  // ban
  const [isShowNotification, setIsShowNotification] = useState(false);
  const [isOpenBanModal, setIsOpenBanModal] = useState(false);
  const [banParticipants, setBanParticipants] = useState<ParticipantType[]>([]);

  // search
  useEffect(() => {
    const filterParticipants: ParticipantType[] = participants.filter(
      (participant) => participant.username.search(searchName) !== -1
    ) as any;
    let sortParticipants = filterParticipants.sort();

    if (order === 'asc') {
      sortParticipants = sortParticipants.sort().reverse();
    }

    setSearchParticipants(sortParticipants);
  }, [searchName, participants, order]);

  //select
  const changeSelectParticipants = (participant: ParticipantType, isChecked: boolean) => {
    if (isChecked) {
      setSelectParticipants((selectParticipants) =>
        selectParticipants.filter(
          (selectParticipant) => selectParticipant.userId !== participant.userId
        )
      );
    } else {
      setSelectParticipants((selectParticipants) => [...selectParticipants, participant]);
    }
  };
  useEffect(() => {
    setIsCheckedGroupCheckbox(selectParticipants.length !== 0);
  }, [selectParticipants]);

  const changeGroupCheckbox = () => {
    if (isSelectAllParticipants) {
      setSelectParticipants([]);
    } else {
      setSelectParticipants(searchParticipants);
    }
    setIsCheckedGroupCheckbox(!isCheckedGroupCheckbox);
    setIsSelectAllParticipants(!isSelectAllParticipants);
  };

  // order
  const changeOrderParticipants = (order: string) => {
    setOrder(order);
  };

  // ban
  const handleCloseBanModal = () => {
    setIsOpenBanModal(false);
  };

  const handleOpenBanModal = (participants: ParticipantType[]) => {
    setBanParticipants(participants);
    setIsOpenBanModal(true);
  };

  const handleApply = () => {
    banParticipants.map(async (banParticipant) => await banParticipant.toggleBanUser());
    setIsShowNotification(true);
    setIsOpenBanModal(false);
    setTimeout(() => setIsShowNotification(false), 5000);
  };

  return (
    <>
      <div className={container}>
        <div className={notificationContainer}>
          <Notification
            icon={<SuccessIcon />}
            isShow={isShowNotification}
            text={`${banParticipants.map(
              (banParticipant) => banParticipant.username
            )} has been banned`}
          />
        </div>
        <div>
          <ModeratorList />
        </div>

        <div className={participantTitle}>
          <ModeratorTitle text="Users" count={participants.length} badgeClass={badgeClass} />
        </div>
        <div>
          <Input
            value={searchName}
            setValue={setSearchName}
            placeholder="Find a participant by name"
          />
        </div>
        <div>
          <ParticipantTableHeader
            isCheckedGroupCheckbox={isCheckedGroupCheckbox}
            changeGroupCheckbox={changeGroupCheckbox}
            selectParticipants={selectParticipants}
            changeOrderParticipants={changeOrderParticipants}
            handleOpenBanModal={handleOpenBanModal}
          />
        </div>
        <div>
          {searchParticipants.map((participant: ParticipantType) => (
            <div key={participant.userId}>
              <ParticipantItem
                participant={participant}
                isSelectAllParticipants={isSelectAllParticipants}
                changeSelectParticipants={changeSelectParticipants}
                handleOpenBanModal={handleOpenBanModal}
              />
            </div>
          ))}
        </div>
        <ModeratorBanModal
          isOpen={isOpenBanModal}
          handleClose={handleCloseBanModal}
          handleApply={handleApply}
          banParticipants={banParticipants}
        />
      </div>
    </>
  );
});

export default Participants;
