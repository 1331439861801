import React, { useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
import { handleStopTestMic, handleStartTestMic } from '../../helpers/device';
import { observer } from 'mobx-react-lite';

const SliderComponent = observer(() => {
  const { startPageSlider } = useStyles();
  const {
    mySetting: { testMicVolume },
  } = useStores();

  useEffect(() => {
    handleStartTestMic();
    return () => {
      handleStopTestMic();
    };
  }, []);

  return <Slider value={testMicVolume} className={startPageSlider} />;
});

export default SliderComponent;
