import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  moderatorIconsClass: {
    display: 'flex',
    paddingLeft: '24px',
  },
  moderatorIconClass: {
    width: '40px',
    height: '40px',
    marginRight: '4px',
  },
  moderatorIconWrapperClass: {
    display: 'flex',
  },
  arrowClass: {
    '& svg:hover': {
      color: '#903CD6',
    },
  },
  moderatorTitleAndIconsClass: {
    display: 'flex',
    alignItems: 'center',
  },
  badgeClass: {
    background: '#E6E6E6',
  },
  moderatorItemContainerClass: {
    paddingTop: '16px',
    paddingBottom: '24px',
  },
  moderatorItemClass: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '16px',
  },
  moderatorItemIconClass: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
  },
  moderatorItemNameAndRoleClass: {
    paddingRight: '24px',
  },
  moderatorItemNameClass: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineРeight: '20px',
    color: '#140E17',
  },
  moderatorItemRoleClass: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineРeight: '16px',
    color: '#A2A2A2',
  },
  moderatorItemSeparateClass: {
    borderTop: '1px solid #DDDDDD',
    paddingBottom: '16px',
  },
  moderatorItemIconContainerClass: {
    display: 'flex',
  },
}));

export default useStyles;
