import React from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@material-ui/core/Button';
import { useStores } from '../../hooks/useStores';
import { emitModeratorCustomizeUpdate } from '../../services/game/emitsToGame/emits';
import useStyles from './style';

const CustomizeList = observer(() => {
  const { container, button } = useStyles();
  const { user, UI } = useStores();

  const onClickCustomize = (id: number): void => {
    emitModeratorCustomizeUpdate(id);
  };

  return UI.sideMenuArea.items.get('customizeList')?.isOpened ? (
    <div className={container}>
      {user.customizeList.objects.map((item) =>
        item?.objectName ? (
          <Button
            key={item.objectId}
            className={button}
            onClick={() => onClickCustomize(item.objectId)}
          >
            {item.objectName}
          </Button>
        ) : null
      )}
    </div>
  ) : null;
});

export default CustomizeList;
