import React, { useState } from 'react';
import {
  Button,
  TeleportMenuItem,
  MobileSettingMenuItem,
  ChatModal,
  ChatIcon,
  BuyButton,
  ShoppingCartMenuItem,
} from '../..';
import useStyles from './style';
import { isDesktop } from 'react-device-detect';
import { useStores } from '../../../hooks/useStores';

const TabbarMenuMobile = ({ handleUpdateLocalStream }: { handleUpdateLocalStream: any }) => {
  const [isShowChat, setIsShowChat] = useState(false);
  const {
    container,
    leftContainer,
    rightContainer,
    buttonClass,
    chatModalClass,
    chatModalWrapClass,
    buyContainer,
  } = useStyles();
  const {
    connection: { isLoading },
  } = useStores();

  const handleChat = () => {
    setIsShowChat(!isShowChat);
  };

  const handleClose = () => {
    setIsShowChat(false);
  };

  return (
    <>
      {!isLoading && !isDesktop && (
        <div className={container}>
          <div>
            <ChatModal
              isShow={isShowChat}
              handleClose={handleClose}
              chatModalWrapClass={chatModalWrapClass}
              chatModalClass={chatModalClass}
            />
          </div>
          <div className={buyContainer}>
            <BuyButton />
          </div>
          <div className={leftContainer}>
            <div>
              <Button onClick={handleChat} className={buttonClass}>
                <ChatIcon />
              </Button>
            </div>
            <div>
              <TeleportMenuItem buttonClass={buttonClass} />
            </div>
            <div>
              <MobileSettingMenuItem handleUpdateLocalStream={handleUpdateLocalStream} />
            </div>
          </div>
          <div className={rightContainer}>
            <div>
              <ShoppingCartMenuItem buttonClass={buttonClass} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TabbarMenuMobile;
