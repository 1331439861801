import { makeStyles, Theme } from '@material-ui/core/styles';
import { QualitySelectStyleType } from '.';

const useStyles = makeStyles(({ palette: { primary: { light }, secondary: { main } } }: Theme) => ({
  chatboxPanelMainItem: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1.5rem',
    padding: ({ padding }: QualitySelectStyleType) => `${padding}px 0`,
    color: light,
    '&:focus-within svg': {
      color: main,
    },
    '& .MuiInputBase-root': {
      maxWidth: '31.25rem',
      overflow: 'hidden',
    },
  },
  chatboxPanelText: {
    verticalAlign: 'middle',
    fontSize: 18,
    paddingRight: '1.5rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  chatboxPanelCheckbox: {
    position: 'absolute',
    right: 0,
    zIndex: -1,
    opacity: 0,
    backgroundColor: 'initial',
    appearance: 'checkbox',
    boxSizing: 'border-box',
    margin: '3px 3px 3px 4px',
    padding: 'initial',
    border: 'initial',
  },
  chatboxPanelButton: {
    fontSize: '18px !important',
    paddingLeft: '5px !important',
    fontWeight: 'bold',
    letterSpacing: '0.045em',
    '&:hover': {
      background: '#F1F1F1 !important',
      color: '#3F3F3F !important',
    },
  },
}));

export default useStyles;
