import React, { useState } from 'react';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const EnterNameInput = () => {
  const { user } = useStores();
  const { inputWrapper, inputContainer, input } = useStyles();
  const [name, setName] = useState('');
  const { t } = useTranslation();

  const onChangeName = (e: any) => {
    if (!isIOS) {
      const text = e.target!.value as string;
      if (text.length <= 200) {
        setName(text);
        user.setName(text);
      }
    }
  };
  const onKeyUpName = (e: any) => {
    if (isIOS && e.key !== 'Enter') {
      let text = name;
      if (e.key !== 'Backspace') {
        text += e.key;
      } else {
        text = text.slice(0, -1);
      }

      if (text.length <= 200) {
        setName(text);
        user.setName(text);
      }
    }
  };

  return (
    <div className={inputWrapper}>
      <label htmlFor="textInput" className={inputContainer}>
        <input
          id="textInput"
          type="text"
          className={input}
          value={name}
          onChange={onChangeName}
          onKeyUp={onKeyUpName}
          placeholder={t('Name')}
        />
      </label>
    </div>
  );
};

export default EnterNameInput;
