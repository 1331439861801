import React, { BaseSyntheticEvent, RefObject, useEffect, useRef, useState } from 'react';
import useStyles from './style';
import classnames from 'classnames';
import { ParticipantType } from '../../models/participants';
import { MIN_VOICE_VOLUME, MAX_VOICE_VOLUME } from '../../constants/eventsManagement/voice';

import { observer } from 'mobx-react-lite';

const VolumeControlBar = observer((props: { participant: ParticipantType }) => {
  const { participant }: { participant: ParticipantType } = props;

  const {
    volumeControlBarContainer,
    volumeControlBar,
    volumeControlBarDraggablePoint,
    volumeControlBarDetails,
  } = useStyles();

  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  const draggablePointRef: RefObject<HTMLSpanElement> = useRef(null);

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isInDragMode, setInDragMode] = useState(false);
  const [newDebouncedVoiceVolume, setNewDebouncedVoiceVolume] = useState(participant.voiceVolume);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setNewDebouncedVoiceVolume(participant.voiceVolume);
  }, [participant.voiceVolume]);

  const applyNewVolume = async (newVoiceVolume: number) => {
    setIsLoading(true);

    setNewDebouncedVoiceVolume(newVoiceVolume);

    await participant.setVoiceVolume(newVoiceVolume);

    setIsLoading(false);
  };

  const calcNewVoiceVolumeByCursorPositionXInContainer = (
    mousePositionXInContainer: number
  ): number => {
    /* [=0%==100%==200%=] <- calculating volume by the click X-position */

    const soundBarWidth: number = containerRef.current?.clientWidth as number;

    const mouseRelativePosition: number = Math.round(
      mousePositionXInContainer / (soundBarWidth / (MAX_VOICE_VOLUME - MIN_VOICE_VOLUME))
    );

    /* it improves UX when user's target is in edge of the scale (for e.g 0 or 200) */
    return mouseRelativePosition > MAX_VOICE_VOLUME * 0.95
      ? MAX_VOICE_VOLUME
      : mouseRelativePosition < MAX_VOICE_VOLUME * 0.05
      ? 0
      : mouseRelativePosition;
  };

  const onClick = (clickEvent: BaseSyntheticEvent) => {
    if (clickEvent.target === draggablePointRef.current) {
      /* draggable point was clicked */
      return;
    }

    // @ts-ignore
    const mousePositionXInContainer = clickEvent.nativeEvent.offsetX;

    const newVoiceVolumeCalculatedByMousePosition: number = calcNewVoiceVolumeByCursorPositionXInContainer(
      mousePositionXInContainer
    );

    applyNewVolume(newVoiceVolumeCalculatedByMousePosition);
  };

  const onMouseDown = () => {
    setInDragMode(true);
    if (containerRef.current) {
      // @ts-ignore
      containerRef.current.onmousemove = onMouseMove;
    }
  };

  const onMouseUp = async (mouseEvent: BaseSyntheticEvent) => {
    disableDragMode();

    if (newDebouncedVoiceVolume === participant.voiceVolume) {
      onClick(mouseEvent);
      return;
    } else {
      /* new volume we got in drag mode */
      await applyNewVolume(newDebouncedVoiceVolume);
    }
  };

  const onMouseMove = (mouseEvent: BaseSyntheticEvent) => {
    // @ts-ignore
    const mousePositionXInContainer = mouseEvent.layerX;

    const newVoiceVolumeCalculatedByMousePosition: number = calcNewVoiceVolumeByCursorPositionXInContainer(
      mousePositionXInContainer
    );

    setNewDebouncedVoiceVolume(newVoiceVolumeCalculatedByMousePosition);
  };

  const onMouseEnter = () => {
    setIsMouseOver(true);
  };

  const onMouseLeave = (mouseEvent: BaseSyntheticEvent) => {
    if (isInDragMode) {
      onMouseUp(mouseEvent);
      disableDragMode();
    }

    setIsMouseOver(false);
  };

  const disableDragMode = () => {
    setInDragMode(false);
    if (containerRef.current) {
      containerRef.current.onmousemove = null;
    }
  };

  return (
    <div
      ref={containerRef}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      className={classnames(volumeControlBarContainer, isLoading ? 'disabled' : '')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className={classnames(volumeControlBar)} />
      <span
        ref={draggablePointRef}
        className={volumeControlBarDraggablePoint}
        style={{
          left: `calc(${newDebouncedVoiceVolume * (100 / MAX_VOICE_VOLUME)}% - .375rem)`,
        }}
      />
      <span
        className={classnames(
          volumeControlBarDetails,
          isInDragMode || isMouseOver ? 'visible' : ''
        )}
      >
        {newDebouncedVoiceVolume}%
      </span>
    </div>
  );
});

export default VolumeControlBar;
