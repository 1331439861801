import React from 'react';
import useStyles from './style';

type CloseIconProps = {
  color?: string;
  background?: string;
};

const CloseIcon = ({ color = '#3F5CA4', background = '#EBEFF8' }: CloseIconProps) => {
  const { maskSvg } = useStyles();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill={background} />
      <mask
        id="mask0_1025_4281"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="16"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.7071 12.2929C28.0976 12.6834 28.0976 13.3166 27.7071 13.7071L21.4142 20L27.7071 26.2929C28.0976 26.6834 28.0976 27.3166 27.7071 27.7071C27.3166 28.0976 26.6834 28.0976 26.2929 27.7071L20 21.4142L13.7071 27.7071C13.3166 28.0976 12.6834 28.0976 12.2929 27.7071C11.9024 27.3166 11.9024 26.6834 12.2929 26.2929L18.5858 20L12.2929 13.7071C11.9024 13.3166 11.9024 12.6834 12.2929 12.2929C12.6834 11.9024 13.3166 11.9024 13.7071 12.2929L20 18.5858L26.2929 12.2929C26.6834 11.9024 27.3166 11.9024 27.7071 12.2929Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_1025_4281)">
        <rect x="10" y="10" width="20" height="20" fill={color} />
      </g>
    </svg>
  );
};

export default CloseIcon;
