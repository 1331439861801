import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '40px',
    paddingBottom: '20px',
  },
}));

export default useStyles;
