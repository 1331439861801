import React from 'react';
import useStyles from './style';

type CheckboxType = {
  isGroupCheckbox?: boolean;
  isChecked: boolean;
  onChange: any;
};

const Checkbox = ({ isGroupCheckbox = false, isChecked, onChange }: CheckboxType) => {
  const { container, checkbox } = useStyles(isGroupCheckbox);

  return (
    <div className={container}>
      <input
        type="checkbox"
        name="checkbox"
        className={checkbox}
        checked={isChecked}
        onChange={onChange}
      />
    </div>
  );
};

export default Checkbox;
