import Ajv, { JSONSchemaType } from 'ajv';
import addFormats from 'ajv-formats';

export const ajv = new Ajv();
addFormats(ajv);

export const responseScreenSharingSchema: JSONSchemaType<ResponseScreenSharing> = {
  type: 'object',
  properties: {
    streamId: { type: 'number' },
    action: { type: 'string' },
  },
  required: ['streamId', 'action'],
  additionalProperties: false,
};

export const responseOnLogMessage: JSONSchemaType<ResponseOnLogMessage> = {
  type: 'object',
  properties: {
    verbosity: { type: 'string' },
    message: { type: 'string' },
  },
  required: ['verbosity', 'message'],
  additionalProperties: false,
};

export const responseUpdateNearestStandSchema: JSONSchemaType<ResponseUpdateNearestStand> = {
  type: 'object',
  properties: {
    updateNearestStand: {
      type: 'object',
      properties: {
        status: { type: 'number' },
        stand_id: { type: 'string' },
      },
      required: ['status', 'stand_id'],
    },
  },
  required: ['updateNearestStand'],
  additionalProperties: false,
};
