import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  chatModalContainer: {
    position: 'relative',
    height: '100%',
  },
  chatItemName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#969FB5',
  },
  chatItemTime: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#969FB5',
    width: '100%',
    textAlign: 'right',
  },
  chatItemWrap: {
    display: 'flex',
    paddingBottom: '16px',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },
  chatItemText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#1E1E1E',
    overflowWrap: 'break-word',
    width: '100%',
  },
  chatItemAvatarTextWrap: {
    display: 'flex',
    width: '85%',
    '& > img': {
      paddingRight: '8px',
    },
  },
  chatModalControlWrap: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    border: '2px solid #EBEFF8',
    borderRadius: '15px',
    zIndex: 100,
    '&::after': {
      borderRadius: '15px',
      content: '""',
      width: '100%',
      height: '100%',
      backgroundColor: '#FFFFFF',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: -1,
    },
  },
  chatModalChooceChatWrap: {
    display: 'flex',
    padding: '20px 0',
    borderBottom: '2px solid #EBEFF8',
    margin: '0 24px',
  },
  chatModalsendMessageWrap: {
    position: 'relative',
  },
  inputClass: {
    border: 'none',
    width: '100%',
    height: '112px',
    padding: '24px',
    paddingRight: '120px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '30px',
    color: '#1E1E1E',
    resize: 'none',
    background: 'transparent',
    '&::placeholder': {
      fontFamily: 'Outfit',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '30px',
      color: '#969FB5',
    },
  },
  iconClass: {
    top: 0,
    right: '34px',
    height: '74px',
    paddingRight: '12px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    borderLeft: '2px solid #EBEFF8',
    paddingLeft: '24px',
    marginTop: '20px',
    marginBottom: '18px',
  },
  chatModalChooceChatText: {
    fontFamily: 'Outfit',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '25px',
    color: '#1E1E1E',
    paddingRight: '8px',
  },
  chatModalChooceChatSelectWrap: {
    display: 'flex',
  },
  chatModalChooceChatSelectText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    paddingRight: '4px',
  },
  chatModalChooceChatSelectIcon: {
    display: 'flex',
  },

  chatModalSelectChatInput: {
    position: 'relative',
  },
  chatModalSelectChatCountUsersWrap: {
    display: 'flex',
    paddingTop: '26px',
    paddingBottom: '22px',
    alignItems: 'center',
  },
  chatModalSelectCountFistUser: {
    marginRight: '-10.6px',
  },
  chatModalSelectChatSecondUser: {
    marginRight: '-10.6px',
  },
  chatModalSelectChatThirdUser: {},
  chatModalSelectChatText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    paddingLeft: '8px',
  },
  chatModalSelectChatUsersWrap: {},
  chatModalSelectChatUserItem: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '8px',
  },
  chatModalSelectChatUserAvatar: {
    display: 'flex',
  },
  chatModalSelectChatUserName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    paddingLeft: '8px',
  },
  chatMessagesListScrollableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 'calc(100% - 200px)',
  },
}));

export default useStyles;
