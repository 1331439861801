import React, { BaseSyntheticEvent, RefObject, useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks/useStores';
import useStyles from './style';
import classnames from 'classnames';
import { MAX_MESSAGE_LENGTH } from '../../../constants/chats';
import { isIOS } from 'react-device-detect';
import { Button } from '../..';

const ChatInput = observer(() => {
  const textInputRef: RefObject<HTMLTextAreaElement> = useRef(null);

  const { user, chatsModule } = useStores();

  const [text, setText] = useState('');

  // @ts-ignore
  const onTextInputKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      return onSendMessageButtonClick();
    } else if (e.ctrlKey && e.key === 'm') {
      // user.setVideoPlayerOpen(true);
      // user.setVideoPlayerSrc(
      //   'https://site-content.collectiveweb.com/0P0BV2Djtl65xFdkLihG2a3KISDIgqfR.m4v'
      // );
    } else {
      if (isIOS) {
        let newChatInputTextContext = text;

        if (e.key !== 'Backspace') {
          newChatInputTextContext += e.key;
        } else {
          newChatInputTextContext = newChatInputTextContext.slice(0, -1);
        }

        if (text.length <= 200) {
          user.setChatInputText(newChatInputTextContext);
        }
      }
    }
  };

  const onTextInputChange = (e: BaseSyntheticEvent) => {
    const newTextValue = e.target.value;

    adjustRowsCount(newTextValue);

    setText(newTextValue);
  };

  const adjustRowsCount = (newTextValue: string) => {
    /* adjustment of the textarea html element height from up to height of the container based
    on the text content rows count */
    const realRowsCount = newTextValue.split('\n').length;

    if (textInputRef.current) {
      textInputRef.current.rows = realRowsCount;
    }
    /* *********** */
  };

  const { chatInputContainer, textInputClass, chatInputControlButton } = useStyles();

  const onSendMessageButtonClick = async () => {
    const trimmedText = text.trim();
    if (!trimmedText.length) return;
    const result = await chatsModule.sendMessage(trimmedText);
    if (result) {
      setText('');
      adjustRowsCount('');
    }
    textInputRef.current?.focus();
  };

  const blurInput = () => textInputRef?.current?.blur();

  useEffect(() => {
    return blurInput;
  }, [textInputRef]);

  return (
    <div className={classnames(chatInputContainer)}>
      <div
        id="clickable-textarea-wrapper"
        onClick={() => {
          textInputRef.current?.focus();
        }}
        style={{
          height: '100%',
          width: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <textarea
          ref={textInputRef}
          className={textInputClass}
          placeholder={'Type here...'}
          rows={1}
          value={text}
          onChange={onTextInputChange}
          onKeyUp={onTextInputKeyUp}
        />
      </div>
      {/* <Btn transparent icon className={chatInputControlButton} onClick={() => {}}>
        <svg
          width="3rem"
          height="3rem"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 34.5C29.799 34.5 34.5 29.799 34.5 24C34.5 18.201 29.799 13.5 24 13.5C18.201 13.5 13.5 18.201 13.5 24C13.5 29.799 18.201 34.5 24 34.5ZM24 37.5C31.4558 37.5 37.5 31.4558 37.5 24C37.5 16.5442 31.4558 10.5 24 10.5C16.5442 10.5 10.5 16.5442 10.5 24C10.5 31.4558 16.5442 37.5 24 37.5Z"
            fill="#4F4F4F"
          />
          <path
            d="M22.5048 20.3058C22.5048 21.9574 21.5788 23.2963 20.4365 23.2963C19.2942 23.2963 18.3682 21.9574 18.3682 20.3058C18.3682 18.6543 19.2942 17.3154 20.4365 17.3154C21.5788 17.3154 22.5048 18.6543 22.5048 20.3058Z"
            fill="#4F4F4F"
          />
          <path
            d="M29.6317 20.3058C29.6317 21.9574 28.7057 23.2963 27.5634 23.2963C26.4211 23.2963 25.4951 21.9574 25.4951 20.3058C25.4951 18.6543 26.4211 17.3154 27.5634 17.3154C28.7057 17.3154 29.6317 18.6543 29.6317 20.3058Z"
            fill="#4F4F4F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5123 26.9547C20.1443 26.4192 21.0908 26.4974 21.6264 27.1295C22.0575 27.6383 22.8233 28.213 23.6475 28.3699C24.3335 28.5004 25.2788 28.3921 26.3848 27.1165C26.9275 26.4906 27.8748 26.4231 28.5007 26.9658C29.1267 27.5085 29.1941 28.4559 28.6514 29.0818C26.9105 31.0896 24.9173 31.6653 23.0868 31.317C21.3944 30.995 20.0656 29.9281 19.3375 29.0688C18.802 28.4368 18.8802 27.4903 19.5123 26.9547Z"
            fill="#4F4F4F"
          />
        </svg>
      </Btn> */}

      <Button
        className={classnames(chatInputControlButton)}
        disabled={
          chatsModule.messageSendingLoader ||
          !text.split(' ').join('').length ||
          text.length > MAX_MESSAGE_LENGTH
        }
        onClick={onSendMessageButtonClick}
      >
        <svg
          height="2.625rem"
          width="2.625rem"
          viewBox="0 0 42 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.51534 35.2355C3.51636 35.9529 4.25009 36.4359 4.90941 36.1532L38.1065 21.9191C38.9144 21.5727 38.9144 20.4273 38.1065 20.0809L4.90942 5.84682C4.25009 5.56412 3.51636 6.04709 3.51534 6.76447L3.50752 12.2393C3.50321 15.2498 5.73046 17.7974 8.71453 18.1953L29.75 21L8.71453 23.8047C5.73046 24.2026 3.50321 26.7502 3.50752 29.7607L3.51534 35.2355Z"
            fill="#4F4F4F"
          />
        </svg>
      </Button>
    </div>
  );
});

export default ChatInput;
