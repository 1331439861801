import React from 'react';
import { ArrowRightIcon, ScreenIcon } from '..';
import useComponentVisible from '../../hooks/useComponentVisible';
import useStyles from './style';
import classnames from 'classnames';
import { useGlobalStyles } from '../../globalStyles';
import { closeScreenChannel } from '../../helpers/stream';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';

type StopOrShowShareType = {
  refPlayerLocalScreenShare: any;
};
const StopOrShowShare = observer(({ refPlayerLocalScreenShare }: StopOrShowShareType) => {
  const {
    shareContainer,
    shareIconsWrap,
    arrowIcon,
    shareIcon,
    shareName,
    stopShare,
    shareIconName,
    shareIconBadge,
    localScreenShareVideo,
    localScreenShareVideoWrap,
  } = useStyles();
  const { visibileOn, visibileOff } = useGlobalStyles();
  const {
    isComponentVisible: isShow,
    setIsComponentVisible: setIsShow,
    ref: innerRef,
  } = useComponentVisible();
  const {
    mySetting: { isShowPersonalShare },
    screenSharingModule: { isShowStaticShare },
  } = useStores();

  return (
    <>
      {(isShowPersonalShare || isShowStaticShare) && (
        <>
          <div className={shareContainer} ref={innerRef}>
            <div className={shareIconsWrap} onClick={() => setIsShow(!isShow)}>
              <div className={shareIconBadge}>
                <div className={shareIcon}>
                  <ScreenIcon color="#FFFFFF" />
                </div>
                <div className={shareIconName}>live</div>
              </div>
              <div className={arrowIcon}>
                <ArrowRightIcon color="#7B25C1" />
              </div>
            </div>
            <div className={shareName}>Entire screen</div>
            <div className={stopShare} onClick={closeScreenChannel}>
              Stop sharing
            </div>
          </div>

          <div className={classnames(localScreenShareVideoWrap, isShow ? visibileOn : visibileOff)}>
            <video
              ref={refPlayerLocalScreenShare}
              className={localScreenShareVideo}
              muted
              autoPlay
              playsInline
              disablePictureInPicture
            />
          </div>
        </>
      )}
    </>
  );
});

export default StopOrShowShare;
