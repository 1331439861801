import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }: Theme) => ({
  contextMenuContainer: {
    position: 'relative',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },

  contextMenuItemsList: {
    zIndex: 102,
    width: 'auto',
    minWidth: '100px',
    borderRadius: '4px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 0,
    '&.leftDirected': {
      right: 0,
    },
    '&.rightDirected': {
      left: 0,
    },
  },
  contextMenuItem: {
    width: '100%',
    minWidth: '100%',
    height: '48px',
    display: 'flex',
    padding: '8px 12px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
    fontSize: '16px',
    fontWeight: 600,
    transition: 'opacity .15s ease',
    backgroundColor: palette.background.paper,
    borderTop: `1px solid ${palette.primary.light}`,
    '&:nth-child(1)': {
      borderTop: 'none',
    },
    opacity: 1,
    '&:hover': {
      opacity: 0.95,
    },
  },
  contextMenuClickableOverlay: {
    zIndex: 101,
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
  },
  contextMenuInvokerContainer: {
    opacity: 1,
    transition: 'opacity .15s ease',
    '&:hover': {
      opacity: 0.75,
    },
  },
}));

export default useStyles;
