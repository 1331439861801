import React from 'react';
import useStyles from './style';

const ShareMuteIcon = () => {
  const { maskSvg } = useStyles();
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1590_355644"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="20"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666016 4.5C0.666016 2.84314 2.00916 1.5 3.66602 1.5H16.3313C17.9882 1.5 19.3313 2.84315 19.3313 4.5V9.46362C18.7725 8.80752 18.0947 8.25585 17.3313 7.842V4.5C17.3313 3.94772 16.8836 3.5 16.3313 3.5H3.66602C3.11373 3.5 2.66602 3.94771 2.66602 4.5V11.3333C2.66602 11.8856 3.11373 12.3333 3.66602 12.3333H6.66602C6.84901 12.3333 7.02053 12.3825 7.16809 12.4683C7.05805 12.9613 7 13.4739 7 14C7 14.0919 7.00177 14.1833 7.00528 14.2743C6.89935 14.3125 6.78511 14.3333 6.66602 14.3333H3.66602C2.00916 14.3333 0.666016 12.9902 0.666016 11.3333V4.5ZM12.3172 7.20359C11.0359 7.51978 9.89366 8.1888 8.99935 9.10168V8.71228L7.28025 10.0504C6.84443 10.3896 6.21613 10.3113 5.87689 9.8755C5.53766 9.43968 5.61596 8.81137 6.05178 8.47214L9.38511 5.87754C9.74634 5.59637 10.2524 5.59637 10.6136 5.87754L12.3172 7.20359Z"
          fill="#140E17"
        />
      </mask>
      <g mask="url(#mask0_1590_355644)">
        <rect width="20" height="20" fill="#140E17" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 14C18 16.2091 16.2091 18 14 18C13.2582 18 12.5635 17.7981 11.968 17.4462L17.4462 11.968C17.7981 12.5635 18 13.2582 18 14ZM10.5538 16.032L16.032 10.5538C15.4365 10.2019 14.7418 10 14 10C11.7909 10 10 11.7909 10 14C10 14.7418 10.2019 15.4365 10.5538 16.032ZM20 14C20 17.3137 17.3137 20 14 20C10.6863 20 8 17.3137 8 14C8 10.6863 10.6863 8 14 8C17.3137 8 20 10.6863 20 14Z"
        fill="#E22929"
      />
    </svg>
  );
};

export default ShareMuteIcon;
