import React, { useState, useEffect, useRef, BaseSyntheticEvent, RefObject } from 'react';
import { Modal, SendMessageIcon, Scrollbar } from '../../..';
import useStyles from './style';
import testAvatar from '../test_avatar.png';
import { isIOS } from 'react-device-detect';
import { useStores } from '../../../../hooks/useStores';
import { ChatType } from '../../../../models/chats';
import { observer } from 'mobx-react-lite';

type ChatModalType = {
  isShow: boolean;
  handleClose: any;
  chatModalClass?: string;
  chatModalWrapClass?: string;
  changeScreen: any;
};

const SendMessage = observer(
  //changeScreen
  ({ isShow, handleClose, chatModalClass, chatModalWrapClass }: ChatModalType) => {
    const {
      chatModalContainer,
      chatMessagesListScrollableContainer,
      chatModalControlWrap,
      chatModalChooceChatWrap,
      chatModalsendMessageWrap,
      chatModalChooceChatText,
      // chatModalChooceChatSelectWrap,
      // chatModalChooceChatSelectText,
      // chatModalChooceChatSelectIcon,
      inputClass,
      iconClass,
    } = useStyles();
    const textInputRef: RefObject<HTMLTextAreaElement> = useRef(null);
    const scrollableContainerRef = useRef<any>(null);
    const [text, setText] = useState('');
    const { chatsModule, participantsList, user } = useStores();

    console.log('changeScreen');
    useEffect(() => {
      const scrollToBottom = () => {
        if (scrollableContainerRef && scrollableContainerRef.current) {
          const { scrollHeight } = scrollableContainerRef.current;

          scrollableContainerRef.current?.scrollTo({
            top: scrollHeight,
            // @ts-ignore
            behavior: 'smooth',
          });
        }
      };

      scrollToBottom();
    }, [chatsModule.selectedChat?.messagesListLength, scrollableContainerRef]);

    const messages = (chatsModule.selectedChat as ChatType).messages.map((message) => {
      const { chatMessageId: id, createdAt, text, authorUserId } = message;
      const messageAuthor = participantsList.participants.get(authorUserId);

      return {
        id,
        avatar: messageAuthor?.avatar ?? testAvatar,
        name: messageAuthor?.username ?? 'User',
        text,
        time: createdAt
          .toLocaleTimeString([], {
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
          })
          .toLowerCase(),
      };
    });

    const onSendMessageButtonClick = async () => {
      const trimmedText = text.trim();
      if (!trimmedText.length) return;
      const result = await chatsModule.sendMessage(trimmedText);
      if (result) {
        setText('');
        adjustRowsCount('');
      }
      textInputRef.current?.focus();
    };

    const adjustRowsCount = (newTextValue: string) => {
      /* adjustment of the textarea html element height from up to height of the container based
    on the text content rows count */
      const realRowsCount = newTextValue.split('\n').length;
      console.log('realRowsCount=', realRowsCount);

      if (textInputRef.current) {
        textInputRef.current.rows = realRowsCount;
      }
      /* *********** */
    };

    // @ts-ignore
    const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        return onSendMessageButtonClick();
      } else if (e.ctrlKey && e.key === 'm') {
        // user.setVideoPlayerOpen(true);
        // user.setVideoPlayerSrc(
        //   'https://site-content.collectiveweb.com/0P0BV2Djtl65xFdkLihG2a3KISDIgqfR.m4v'
        // );
      } else {
        if (isIOS) {
          let newChatInputTextContext = text;

          if (e.key !== 'Backspace') {
            newChatInputTextContext += e.key;
          } else {
            newChatInputTextContext = newChatInputTextContext.slice(0, -1);
          }

          if (text.length <= 200) {
            user.setChatInputText(newChatInputTextContext);
          }
        }
      }
    };

    const onChange = (e: BaseSyntheticEvent) => {
      const newTextValue = e.target.value;

      adjustRowsCount(newTextValue);

      setText(newTextValue);
    };

    return (
      <Modal
        isTransparent
        isOpen={isShow}
        modalWrapClass={chatModalWrapClass}
        modalClass={chatModalClass}
        handleClose={handleClose}
      >
        <div className={chatModalContainer}>
          <>
            <Scrollbar
              className={chatMessagesListScrollableContainer}
              setRef={(current) => (scrollableContainerRef.current = current)}
            >
              {messages &&
                messages.map((message) => <ChatMessageItem key={message.id} {...message} />)}
            </Scrollbar>
            {/* <ChatModalControl
              handleIsSelectChat={() => changeScreen('choose_channel')}
              text={text}
              onChange={onChange}
              onKeyUp={onKeyUp}
              textInputRef={textInputRef}
            /> */}
            <div className={chatModalControlWrap}>
              <div className={chatModalChooceChatWrap}>
                <div className={chatModalChooceChatText}>Chat</div>
                {/* <div
                  className={chatModalChooceChatSelectWrap}
                  onClick={() => changeScreen('choose_channel')}
                >
                  <div className={chatModalChooceChatSelectText}>Everybody</div>
                  <div className={chatModalChooceChatSelectIcon}>
                    <ArrowDownIcon color="#A24DE8" />
                  </div>
                </div> */}
              </div>
              <div className={chatModalsendMessageWrap}>
                <>
                  <textarea
                    ref={textInputRef}
                    placeholder={'Write a message...'}
                    id="textInput"
                    rows={1}
                    className={inputClass}
                    value={text}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                  />
                  <div className={iconClass} onClick={onSendMessageButtonClick}>
                    {<SendMessageIcon />}
                  </div>
                </>
              </div>
            </div>
          </>
        </div>
      </Modal>
    );
  }
);

type ChatMessageItemType = {
  id: string;
  avatar: string;
  name: string;
  text: string;
  time: string;
};
//avatar,
const ChatMessageItem = ({ time, name, text }: ChatMessageItemType) => {
  const {
    chatItemName,
    chatItemTime,
    chatItemWrap,
    chatItemText,
    // chatItemAvatarTextWrap,
  } = useStyles();
  return (
    <div className={chatItemWrap}>
      {/* <div className={chatItemAvatarTextWrap}> */}
      {/* <img src={avatar} alt="user" /> */}
      {/* <div> */}
      <div className={chatItemName}>{name}</div>
      <div className={chatItemText}>{text}</div>
      {/* </div> */}
      {/* </div> */}
      <div className={chatItemTime}>{time}</div>
    </div>
  );
};

export default SendMessage;
