import { MEDIA_SERVER_EVENT_SET_ICE } from '../../../../constants/wbs';
import { sendDataToServer } from '.';
import { loggerLog } from '../../logger';

export const setIce = (candidate: RTCIceCandidate, deviceId: number) => {
  const data = {
    req: MEDIA_SERVER_EVENT_SET_ICE,
    peer_id: deviceId,
    candidates: [candidate],
  };

  loggerLog(`send ${MEDIA_SERVER_EVENT_SET_ICE} to ms-server`, data);
  sendDataToServer(data);
};
