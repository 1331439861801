import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 8px',
    marginTop: '12px',
  },
  iconAndNameClass: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainerClass: {
    display: 'flex',
  },
  iconClass: {
    width: '40px',
    height: '40px',
  },
  nameClass: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#140E17',
    paddingLeft: '10px',
  },
  unbanClass: {},
  unbanButtonClass: {
    background: '#A24DE8',
    padding: '8px 12px',

    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    borderRadius: '7px',
  },
}));
