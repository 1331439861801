import React from 'react';
import useStyles from './style';

type FullScreenCloseIconProps = {
  size: string;
  color?: string;
};

const FullScreenCloseIcon = ({ size, color = 'white' }: FullScreenCloseIconProps) => {
  const { maskSvg } = useStyles();

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_666_11894"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="24"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="black"
          d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"
          transform="translate (14 14), scale (1.25 1.25)"
        />
      </mask>
      <g mask="url(#mask0_666_11894)">
        <rect x="12" y="12" width="24" height="24" fill={color} />
      </g>
    </svg>
  );
};

export default FullScreenCloseIcon;
