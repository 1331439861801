export const ControlSchemeType = {
  // Мышь может блокироваться внутри плеера WebRTC, поэтому пользователь может просто перемещать
  // мышь для управления ориентацией камеры.
  // Пользователь нажимает кнопку клавиши Escape для разблокировки мыши.
  LockedMouse: 0,

  // Мышь может навести курсор на проигрыватель WebRTC, поэтому пользователю нужно щелкнуть и
  // перетащите, чтобы контролировать ориентацию камеры.
  HoveringMouse: 1,
};

export let inputOptions = {
  controlScheme: ControlSchemeType.LockedMouse as any,
  suppressBrowserKeys: true,
};

export const MouseButton = {
  MainButton: 0,
  AuxiliaryButton: 1,
  SecondaryButton: 2,
  FourthButton: 3,
  FifthButton: 4,
};

export const MouseButtonsMask = {
  PrimaryButton: 1,
  SecondaryButton: 2,
  AuxiliaryButton: 4,
  FourthButton: 8,
  FifthButton: 16,
};

export const SpecialKeyCodes = {
  BackSpace: 8,
  Shift: 16,
  Control: 17,
  Alt: 18,
  RightShift: 253,
  RightControl: 254,
  RightAlt: 255,
};
