import { getSocket } from '../../store';

export const sendDataToServer = (data: any) => {
  try {
    const json = JSON.stringify(data);
    const socket = getSocket();
    socket?.send(json);
  } catch (e) {
    console.log(`error while sending wbs data: ${e}`);
  }
};
