import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import translationEng from '../locales/en/translation.json';
import translationRus from '../locales/ru/translation.json';

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: 'en',
    // use en if detected lng is not available
    fallbackLng: 'ru',
    // we do not use keys in form messages.welcome
    keySeparator: false,

    interpolation: {
      // react already safes from xss
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEng,
      },
      ru: {
        translations: translationRus,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18next;
