import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    '& svg:hover > rect': {
      fillOpacity: '0.4',
    },
  },
  buttonClass: {
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    background: 'rgba(255, 255, 255, 0.05)',
    border: '1px solid rgba(255, 255, 255, 0.15)',
    backdropFilter: 'blur(6.5px)',
  },
}));

export default useStyles;
