import { loggerLog, loggerError } from '../../logger';
import {
  MEDIA_SERVER_EVENT_ADD_DEVICE,
  MEDIA_SERVER_EVENT_DEVICE_INFO,
  MEDIA_SERVER_EVENT_REMOVE_DEVICE,
  MEDIA_SERVER_EVENT_SET_ICE,
  MEDIA_SERVER_EVENT_SET_SDP,
  MEDIA_SERVER_EVENT_WBS_INFO,
} from '../../../../constants/wbs';
import { addDevice } from './addDevice';
import { setSdp } from './setSdp';
import { setIce } from './setIce';
import { removeDevice } from './removeDevice';
import { wbsInfo } from './wbsInfo';
import { deviceInfo } from './deviceInfo';
import { EventCloseType, RegisterReceiveDataFromServerListenerType } from '../../../../types/wbs';
import { createPeerConnection } from '../../webrtc';

export const registerOpenSocketListener = (socket: WebSocket) => {
  socket.onopen = () => {
    loggerLog('wbs socket open');
  };
};

export const registerCloseSocketListener = (socket: WebSocket) => {
  socket.onclose = ({ code, wasClean, reason }: EventCloseType) => {
    loggerLog(
      `${
        wasClean ? 'wbs socket closed cleanly' : 'connection break'
      } with code ${code} and reason ${reason}`
    );
    // if (!getIsChangingGameServer()) {
    // setTimeout(() => {
    //   WbsService.reconnect();
    //   WbsService.publishLocal();
    // }, 1000);
    // }
  };
};

export const registerErrorSocketListener = (socket: WebSocket) => {
  socket.onerror = (error: any) => {
    loggerError(`wbs socket closed with error: ${error.message}`, error);
  };
};

export const registerReceiveDataFromServerListener = ({
  socket,
  streamType,
  stream,
  refRemoteStream,
  callback,
}: RegisterReceiveDataFromServerListenerType) => {
  socket.onmessage = (event: any) => {
    const json = JSON.parse(event.data);
    if (json) {
      loggerLog(`receive ${json.req} from ms-сервера:`, json);
      switch (json.req) {
        case MEDIA_SERVER_EVENT_ADD_DEVICE:
          if (json.device.type === 'wrtc_device') {
            let pc = new RTCPeerConnection({
              iceServers: [
                {
                  urls: [
                    'stun:stun.l.google.com:19302',
                    'turn:turn-us-west-1.oberonweb.solutions:443',
                  ],
                  username: 'turnadmin',
                  credential: 'turnpwd',
                },
              ],
            });

            createPeerConnection({
              stream,
              streamType,
              pc,
              deviceId: json.device_id,
              refRemoteStream,
            });

            addDevice(json, streamType);
          }
          break;
        case MEDIA_SERVER_EVENT_SET_SDP:
          setSdp(json, streamType);

          break;
        case MEDIA_SERVER_EVENT_SET_ICE:
          setIce(json, streamType);
          break;
        case MEDIA_SERVER_EVENT_REMOVE_DEVICE:
          removeDevice(json);
          break;
        case MEDIA_SERVER_EVENT_WBS_INFO:
          wbsInfo(json);
          break;
        case MEDIA_SERVER_EVENT_DEVICE_INFO:
          deviceInfo(json);
          break;
      }

      if (callback) {
        callback(json);
      }
    }
  };
};
