import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';
import { emitDirectConnection } from '../../services/game/emitsToGame/emits';
import useStyles from './style';
import { Button } from '..';
import { Scrollbar } from '../';

const TeleportList = observer(() => {
  const { user } = useStores();
  const { container, button } = useStyles({ isModerator: user.isModerator });

  // return UI.sideMenuArea.items.get('teleportList')?.isOpened &&
  return user?.environmentConfig?.bTeleportationAvaliable ? (
    <Scrollbar wrapClassName={container}>
      {user.environmentConfig?.serversInEnvironment.map((item) => {
        if (item.spaceName === user.travelDestination) return null;
        return (
          <Button
            key={item.spaceName}
            className={button}
            onClick={() => emitDirectConnection(item.spaceName)}
          >
            {item.displayName}
          </Button>
        );
      })}
    </Scrollbar>
  ) : null;
});

export default TeleportList;
