import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  logoutIcon: {
    background: '#F0E2FB',
    backdropFilter: 'blur(25px)',
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    '-webkit-transform': 'rotate(180deg)',
    '-moz-transform': 'rotate(180deg)',
    '-ms-transform': 'rotate(180deg)',
    '-o-transform': 'rotate(180deg)',
    transform: 'rotate(180deg)',
  },
}));

export default useStyles;
