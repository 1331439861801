import { makeStyles } from '@material-ui/core/styles';

export const useStyles = ({ isChecked }: any) =>
  makeStyles(() => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 8px',
      background: isChecked ? '#F0E2FB' : 'transparent',
      '&:hover': {
        background: '#F0E2FB',
      },
    },
    iconAndNameClass: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '30px',
    },
    iconContainerClass: {
      display: 'flex',
    },
    iconClass: {
      width: '40px',
      height: '40px',
    },
    nameClass: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#140E17',
      paddingLeft: '10px',
    },
    checkboxAndIconAndNameContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    settingsContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    volumeControlContainer: {
      paddingLeft: '12px',
    },
    audioIcon: {
      display: 'flex',
      paddingLeft: '12px',
      '&>svg:hover': {
        color: '#7B25C1',
      },
    },
    videoIcon: {
      display: 'flex',
      paddingLeft: '40px',
      '&>svg:hover': {
        color: '#7B25C1',
      },
    },
    shareIcon: {
      display: 'flex',
      paddingLeft: '40px',
      '&>svg:hover': {
        color: '#7B25C1',
      },
    },
    banClass: {
      paddingLeft: '116px',
    },
    banButtonClass: {
      background: '#A24DE8',
      padding: '8px 12px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#FFFFFF',
      borderRadius: '7px',
    },
    mockIcon: {
      width: '20px',
      height: '20px',
    },
    mockButton: {
      visibility: 'hidden',
    },
  }));
