import { MEDIA_SERVER_EVENT_DEVICE_INFO } from '../../../../constants/wbs';
import { sendDataToServer } from '.';

export const deviceInfo = (deviceId?: number) => {
  const data = {
    req: MEDIA_SERVER_EVENT_DEVICE_INFO,
    devices: [deviceId],
  };

  sendDataToServer(data);
};
