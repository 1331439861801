import { Instance, types, applySnapshot, getSnapshot } from 'mobx-state-tree';

export const AudioDeviceModel = types.model('audioDevice').props({
  deviceId: types.string,
  label: types.string,
});

export const VideoDeviceModel = types.model('videoDevice').props({
  deviceId: types.string,
  label: types.string,
});

export const AllSettingsModel = types
  .model('allSettings')
  .props({
    audioDevices: types.array(AudioDeviceModel),
    videoDevices: types.array(VideoDeviceModel),
  })
  .actions((self) => ({
    getAllAudioDevices() {
      return getSnapshot(self.audioDevices);
    },
    saveAllAudioDevices(audioDevices: AudioDeviceType[]) {
      applySnapshot(self.audioDevices, audioDevices);
    },
    getAllVideoDevices() {
      return getSnapshot(self.videoDevices);
    },
    saveAllVideoDevices(videoDevices: VideoDeviceType[]) {
      applySnapshot(self.videoDevices, videoDevices);
    },
    clearAllAudioDevices() {
      applySnapshot(self.audioDevices, []);
    },
    clearAllVideoDevices() {
      applySnapshot(self.videoDevices, []);
    },
  }));

type AllSettingsModelType = Instance<typeof AllSettingsModel>;
export interface AllSettingsType extends AllSettingsModelType {}

type AudioDeviceModelType = Instance<typeof AudioDeviceModel>;
export interface AudioDeviceType extends AudioDeviceModelType {}

type VideoDeviceModelType = Instance<typeof VideoDeviceModel>;
export interface VideoDeviceType extends VideoDeviceModelType {}
