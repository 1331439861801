import React from 'react';
import useStyles from './style';

type ArrowUpIconProps = {
  color?: string;
  background?: string;
};

const ArrowUpIcon = ({ background = 'black', color = 'white' }: ArrowUpIconProps) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      x="48"
      y="14"
    >
      <mask
        id="mask0_967_24900"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="3"
        y="6"
        width="14"
        height="7"
      >
        <path
          d="M4.64018 12.7682C4.21591 13.1218 3.58534 13.0645 3.23178 12.6402C2.87821 12.2159 2.93554 11.5853 3.35982 11.2318L4.64018 12.7682ZM10 7L9.35982 6.23178C9.73066 5.92274 10.2693 5.92274 10.6402 6.23178L10 7ZM16.6402 11.2318C17.0645 11.5853 17.1218 12.2159 16.7682 12.6402C16.4147 13.0645 15.7841 13.1218 15.3598 12.7682L16.6402 11.2318ZM3.35982 11.2318L9.35982 6.23178L10.6402 7.76822L4.64018 12.7682L3.35982 11.2318ZM10.6402 6.23178L16.6402 11.2318L15.3598 12.7682L9.35982 7.76822L10.6402 6.23178Z"
          fill={background}
        />
      </mask>
      <g mask="url(#mask0_967_24900)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default ArrowUpIcon;
