import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    bottom: '20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'end',
    padding: '0 32px',
    pointerEvents: 'none',
  },
  leftContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      paddingTop: '8px',
    },
    pointerEvents: 'auto',
    paddingBottom: '45px',
  },
  rightContainer: {
    display: 'flex',
    position: 'fixed',
    right: '32px',
    pointerEvents: 'auto',
    paddingBottom: '45px',
  },
  buttonClass: {
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    background: 'rgba(255, 255, 255, 0.05)',
    border: '1px solid rgba(255, 255, 255, 0.15)',
    backdropFilter: 'blur(6.5px)',
  },
  chatModalWrapClass: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    paddingRight: '16px',
    paddingTop: '20px',
    overflow: 'hidden',
  },
  chatModalClass: {
    width: '567px',
    background: 'transparent !important',
    backdropFilter: 'blur(10px)',
    height: '100%',
  },
  buyContainer: {
    position: 'fixed',
    left: 0,
    bottom: '40px',
  },
}));

export default useStyles;
