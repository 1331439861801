import { makeStyles } from '@material-ui/core';

export const sideMenuWidth = '5.5rem';

export const useGlobalStyles = makeStyles(() => ({
  flexDirectionColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexDirectionRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexDirectionColumnReverse: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  flexAlignCenter: {
    alignItems: 'center',
  },

  flexAlignStart: {
    alignItems: 'flex-start',
  },

  flexJustifyCenter: {
    justifyContent: 'center',
  },

  flexJustifyStart: {
    justifyContent: 'flex-start',
  },

  flexJustifyEnd: {
    justifyContent: 'flex-end',
  },

  flexJustifySpaceBetween: {
    justifyContent: 'space-between',
  },

  flexJustifySpaceAround: {
    justifyContent: 'space-around',
  },
  visibileOn: {
    opacity: 1,
  },
  visibileOff: {
    opacity: 0,
  },
}));
