import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    '& svg:hover > rect': {
      fillOpacity: '0.4',
    },
  },
}));

export default useStyles;
