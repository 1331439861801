import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  commonModalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'self-start',
  },
  commonModalTitle: {
    fontFamily: 'Outfit',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '38px',
    color: '#3F5CA4',

    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
      lineHeight: '36px',
    },
  },
  commonModalTitleClose: {
    display: 'flex',
    paddingLeft: '48px',
  },
  commonModalClass: {
    width: '540px',
    right: '1.04%',
    top: '2%',
    bottom: '2%',
    padding: '24px',
    borderRadius: '15px',
    position: 'absolute',
  },
  commonModalTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
