import React from 'react';
import useStyles from './style';
// import { useTheme } from '@material-ui/core/styles';
// import { QualityActiveIcon, QualityNonActiveIcon } from '..';
// import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
// import MenuItem from '@material-ui/core/MenuItem';
// import { isMobileOnly } from 'react-device-detect';
// import { resizePlayerStyle } from '../../services/mathLibraly';

// updateVideoStreamSize

type QualitySelectType = {
  size?: string;
  padding?: string;
  isStartPage?: boolean;
};

export type QualitySelectStyleType = {
  padding: string;
};

// const QUALITYS: number[] = [600, 720, 1080];

const QualitySelect = observer(({ padding }: QualitySelectType) => {
  // const [isActive, setActive] = useState(false);
  // const { mySetting } = useStores();
  // const theme = useTheme();
  const { chatboxPanelMainItem } = useStyles({
    padding,
  } as QualitySelectStyleType);

  // const iconColor = theme.palette.primary.main;

  // const handleChangeMyQualityDevice = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setActive(true);
  //   const quality = event.target.value as number;
  //   if (quality !== mySetting.myQuality) {
  //     mySetting.setMyQuality(+quality);
  //     resizePlayerStyle();
  //   }
  // };

  return (
    <div className={chatboxPanelMainItem}>
      {/* <div className={chatboxPanelText}>
        {isActive ? (
          <QualityActiveIcon size={size} color={isStartPage ? '#F1F1F1' : iconColor} />
        ) : (
          <QualityNonActiveIcon size={size} color={isStartPage ? '#F1F1F1' : iconColor} />
        )}
      </div> */}

      {/* <Select
        value={mySetting.myQuality}
        handleChange={handleChangeMyQualityDevice}
        isStartPage={isStartPage}
      >
        {QUALITYS.map((quality, key) => {
          if (isMobileOnly) {
            return (
              <option value={quality} key={key} className={chatboxPanelButton}>
                {`${quality}p`}
              </option>
            );
          } else {
            return (
              <MenuItem value={quality} key={key} className={chatboxPanelButton}>
                {`${quality}p`}
              </MenuItem>
            );
          }
        })}
      </Select> */}
    </div>
  );
});

export default QualitySelect;
