import { Instance, types } from 'mobx-state-tree';
import { IceServerType } from '../types/webRtcPlayer';

const CheckTurnModel = types
  .model('checkTurn')
  .props({
    isError: types.boolean,
    isSuccess: types.boolean,
    isLoading: types.boolean,
  })
  .volatile(() => ({
    iceServers: null as IceServerType[] | null,
  }));

const CheckMsModel = types.model('checkMs').props({
  isError: types.boolean,
  isSuccess: types.boolean,
  isLoading: types.boolean,
});

const CheckInstanceModel = types.model('checkInstance').props({
  isError: types.boolean,
  isSuccess: types.boolean,
  isLoading: types.boolean,
  ueClientDomain: types.string,
});

const CheckGameModel = types.model('checkGame').props({
  isError: types.boolean,
  isSuccess: types.boolean,
  isLoading: types.boolean,
});
export const DebugModel = types
  .model('debug')
  .props({
    isShowDebugPanel: types.boolean,
    checkTurn: CheckTurnModel,
    checkMs: CheckMsModel,
    checkInstance: CheckInstanceModel,
    checkGame: CheckGameModel,
  })
  .actions((self) => ({
    setIsShowDebugPanel(isShowDebugPanel: boolean) {
      self.isShowDebugPanel = isShowDebugPanel;
    },
    // checkTurn
    setIceServers(iceServers: IceServerType[]) {
      self.checkTurn.iceServers = iceServers;
    },
    setIsErrorCheckTurn(isError: boolean) {
      self.checkTurn.isError = isError;
    },
    setIsSuccessCheckTurn(isSuccess: boolean) {
      self.checkTurn.isSuccess = isSuccess;
    },
    setIsLoadingCheckTurn(isLoading: boolean) {
      self.checkTurn.isLoading = isLoading;
    },

    // checkMs
    setIsErrorCheckMs(isError: boolean) {
      self.checkMs.isError = isError;
    },
    setIsSuccessCheckMs(isSuccess: boolean) {
      self.checkMs.isSuccess = isSuccess;
    },
    setIsLoadingCheckMs(isLoading: boolean) {
      self.checkMs.isLoading = isLoading;
    },

    // checkInstance
    setUeClientDomain(ueClientDomain: string) {
      self.checkInstance.ueClientDomain = ueClientDomain;
    },
    setIsErrorCheckInstance(isError: boolean) {
      self.checkInstance.isError = isError;
    },
    setIsSuccessCheckInstance(isSuccess: boolean) {
      self.checkInstance.isSuccess = isSuccess;
    },
    setIsLoadingCheckInstance(isLoading: boolean) {
      self.checkInstance.isLoading = isLoading;
    },

    // checkGame
    setIsErrorCheckGame(isError: boolean) {
      self.checkGame.isError = isError;
    },
    setIsSuccessCheckGame(isSuccess: boolean) {
      self.checkGame.isSuccess = isSuccess;
    },
    setIsLoadingCheckGame(isLoading: boolean) {
      self.checkGame.isLoading = isLoading;
    },
  }));

type DebugModelType = Instance<typeof DebugModel>;
export interface DebugType extends DebugModelType {}
