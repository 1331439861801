import { sendInputData } from './webRtcWrapper';
import { MessageType } from './staticTypes/messageTypes';
import { logGame } from '@pxs-infra/logger';
import { generateCallbackId } from '../helpers/random';

export function emitDescriptor(messageType: any, descriptor: any) {
  let descriptorAsString = JSON.stringify(descriptor);
  let data = new DataView(new ArrayBuffer(1 + 2 + 2 * descriptorAsString.length));
  let byteIdx = 0;
  data.setUint8(byteIdx, messageType);
  byteIdx++;
  data.setUint16(byteIdx, descriptorAsString.length, true);
  byteIdx += 2;
  for (let i = 0; i < descriptorAsString.length; i++) {
    data.setUint16(byteIdx, descriptorAsString.charCodeAt(i), true);
    byteIdx += 2;
  }
  logGame('Sending descriptor:', descriptor);
  sendInputData(data.buffer);
}

export function emitUIInteraction(descriptor: any) {
  const callbackId = generateCallbackId();
  descriptor.CallbackId = callbackId;
  emitDescriptor(MessageType.UIInteraction, descriptor);
}

export function emitControlInteraction(data: any) {
  sendInputData(data);
}
