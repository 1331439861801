import React from 'react';
import classnames from 'classnames';
import useStyles from './style';
import {
  CloseIcon,
  CountDownIcon,
  ParticipantsInCircleIcon,
  OnLiveInCircleIcon,
  PinIcon,
  Switch,
} from '..';
import { useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';
import { Button } from '..';
import * as ApiWrapper from '../../services/apiWrapper';

const ModeratoSettings = observer(() => {
  const theme = useTheme();
  const {
    settings,
    settingsHeader,
    settingsHeaderTitle,
    settingsHeaderIconWrap,
    settingsListItem,
    settingsListItemLabel,
    settingsListItemLabelActive,
    settingsListItemSwitchWrap,
    settingsContainer,
    moderatoSettingsContainer,
  } = useStyles();
  const {
    UI: {
      sideMenuArea,
      isOpenedTimer,
      setIsOpenedTimer,
      isOpenedParticipants,
      setIsOpenedParticipants,
      isOpenedLiveIndicator,
      setIsOpenedLiveIndicator,
      isOpenedScreensavers,
      setIsOpenedScreensavers,
      isOpenedChatWidget,
    },
  } = useStores();

  const settingsItems = [
    {
      icon: CountDownIcon,
      label: 'Countdown',
      isOpened: isOpenedTimer,
      onToggle: async () => {
        setIsOpenedTimer(!isOpenedTimer);
        if (isOpenedChatWidget) {
          await ApiWrapper.updateChatWidgetVisibility(false);
        }
      },
    },
    {
      icon: ParticipantsInCircleIcon,
      label: 'Participants',
      isOpened: isOpenedParticipants,
      onToggle: async () => {
        setIsOpenedParticipants(!isOpenedParticipants);
        if (isOpenedChatWidget) {
          await ApiWrapper.updateChatWidgetVisibility(false);
        }
      },
    },
    {
      icon: OnLiveInCircleIcon,
      label: 'OnLive indication',
      isOpened: isOpenedLiveIndicator,
      onToggle: async () => {
        setIsOpenedLiveIndicator(!isOpenedLiveIndicator);
        if (isOpenedChatWidget) {
          await ApiWrapper.updateChatWidgetVisibility(false);
        }
      },
    },
    {
      icon: PinIcon,
      label: 'Screensavers',
      isOpened: isOpenedScreensavers,
      onToggle: async () => {
        setIsOpenedScreensavers(!isOpenedScreensavers);
        if (isOpenedChatWidget) {
          await ApiWrapper.updateChatWidgetVisibility(false);
        }
      },
    },
    {
      icon: PinIcon,
      label: 'Chat Widget',
      isOpened: isOpenedChatWidget,
      onToggle: async () => {
        setIsOpenedTimer(false);
        setIsOpenedParticipants(false);
        setIsOpenedLiveIndicator(false);
        setIsOpenedScreensavers(false);
        await ApiWrapper.updateChatWidgetVisibility(!isOpenedChatWidget);
      },
    },
  ];

  // return sideMenuArea.items.get('moderatorSettings')?.isOpened ? (
  return (
    <div className={moderatoSettingsContainer}>
      <div className={settingsContainer}>
        <div className={settings}>
          <div className={settingsHeader}>
            <p className={settingsHeaderTitle}>MOD. SETTINGS</p>
            <Button
              className={settingsHeaderIconWrap}
              onClick={() => sideMenuArea.toggleOne('moderatorSettings')}
            >
              <CloseIcon color={theme.palette.primary.dark} />
            </Button>
          </div>
          {settingsItems.map((settingItem) => (
            <div className={settingsListItem} key={settingItem.label}>
              {settingItem.icon({
                size: '1.5rem',
                activeColor: settingItem.isOpened ? '#FFFFFF' : '',
              })}
              <p
                className={classnames(
                  settingsListItemLabel,
                  settingItem.isOpened && settingsListItemLabelActive
                )}
              >
                {settingItem.label}
              </p>
              <div className={settingsListItemSwitchWrap}>
                <Switch
                  value={settingItem.isOpened}
                  onToggle={settingItem.onToggle}
                  isDisabled={false}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default ModeratoSettings;
