import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }: Theme) => ({
  chatsListSectionContainer: {
    width: '100%',
  },
  chatsListSectionTitle: {
    width: '100%',
    height: '3rem',
    paddingLeft: '1.25rem',
    paddingRight: '1.25rem',
    backgroundColor: palette.background.paper,
  },
  listContainer: {
    width: '100%',
    paddingTop: '.625rem',
    paddingBottom: '.625rem',
  },
  titleClass: {
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '1rem',
    paddingRight: '.375rem',
  },
  collapseIcon: {
    opacity: 1,
    transition: 'opacity .15s ease, transform .15s ease',
    willChange: 'transform',
    transform: 'rotate(0deg)',
    '&:hover': {
      opacity: 0.75,
    },
    '&.reversed-180': {
      transform: 'rotate(180deg)',
    },
  },
}));

export default useStyles;
