import React from 'react';
import useStyles from './style';
import classnames from 'classnames';

type NotificationType = {
  text: string;
  isShow: boolean;
  notificationClass?: string;
  icon: any;
};

const Notification = ({ text, isShow, notificationClass, icon }: NotificationType) => {
  const { container, textClass, iconClassWrap } = useStyles();

  return (
    <>
      {isShow && (
        <div className={classnames(container, notificationClass)}>
          <div className={iconClassWrap}>{icon}</div>
          <div className={textClass}>{text}</div>
        </div>
      )}
    </>
  );
};

export default Notification;
