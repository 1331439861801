import React from 'react';
import InfoIcon from '../Icons/InfoIcon';
import useStyles from './style';

const DevicesPermissionDenied = () => {
  const { container, title, link } = useStyles();
  return (
    <div className={container}>
      <div>
        <InfoIcon color="#E22929" />
      </div>
      <div className={title}>Your camera and microphone are disabled</div>
      <div className={link}>More info</div>
    </div>
  );
};

export default DevicesPermissionDenied;
