import React from 'react';
import Modal from '@material-ui/core/Modal';
import useStyles from './style';
import { Button } from '../..';
import classnames from 'classnames';
// import MenuItem from '@material-ui/core/MenuItem';
// import { useStores } from '../../../hooks/useStores';

// const resolutions = [
//   {
//     label: 'HD',
//     key: '0',
//     width: 1280,
//     height: 720,
//   },
//   {
//     label: 'Full HD',
//     key: '1',
//     width: 1920,
//     height: 1080,
//   },
// ];

const RequestScreenShareModal = ({
  open,
  handleClose,
  title,
  subTitle,
  handleAllow,
  handleDeny,
}: RequestScreenShareModalType) => {
  const {
    container,
    wrap,
    modalButtonsWrap,
    modalButtonClass,
    modal,
    modalTitle,
    redButton,
    modalSelect,
  } = useStyles();
  // const [selectResolution, setSelectResolution] = useState('0');
  // const { wbs } = useStores();

  // const handleChangeResolution = (e: any) => {
  //   const key = e.target.value;
  //   const { height, width } = resolutions[key];
  //   wbs.setScreenHeight(height);
  //   wbs.setScreenWidth(width);
  //   setSelectResolution(key);
  // };
  return (
    <Modal open={open} onClose={handleClose} className={modal}>
      <div className={container}>
        <div className={wrap}>
          <div className={modalTitle}>{title}</div>

          <div className={modalSelect}>
            {/* <Select
              value={selectResolution}
              handleChange={handleChangeResolution}
              isStartPage={true}
            >
              {resolutions.map((resolution) => {
                return (
                  <MenuItem value={resolution.key} key={resolution.key} className={modalOption}>
                    {resolution.label}
                  </MenuItem>
                );
              })}
            </Select> */}
          </div>

          <div className={modalTitle}>{subTitle}</div>

          <div className={modalButtonsWrap}>
            <Button className={modalButtonClass} onClick={handleAllow}>
              yes
            </Button>
            <Button className={classnames(modalButtonClass, redButton)} onClick={handleDeny}>
              no
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RequestScreenShareModal;
