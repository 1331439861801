import React, { useEffect, useState } from 'react';
import { Button, Modal, TrashIcon } from '../..';
import useStyles from './style';
import { useStores } from '../../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { ProductInShoppingCartModelType } from '../../../models/shop';

const ShoppingCartModal = observer(({ isShow, handleClose }: ShoppingCartModalType) => {
  const {
    modalClass,
    modalWrapClass,
    basketHeader,
    basketBody,
    basketFooter,
    basketTitle,
    basketRemoveAll,
    container,
    basketFooterLeft,
    basketClose,
    basketFooterRight,
    basketTotalWraper,
    basketButton,
    basketCountWrapper,
    basketCountTitle,
    basketCount,
    basketTotalPrice,
    basketButtonWrapper,
  } = useStyles();

  const {
    shop: { shoppingCart, setProductsToShoppingCart, totalPrice },
  } = useStores();

  useEffect(() => {
    setProductsToShoppingCart(shoppingCart);
  }, [setProductsToShoppingCart, shoppingCart]);

  const removeAllProducts = () => {
    setProductsToShoppingCart([]);
  };

  const pay = () => {
    const productsMap = shoppingCart.map(({ id, count }) => ({
      id,
      count,
    }));
    const generatedShoppingCartString = productsMap.reduce(
      (concat, { id, count }, index) =>
        (concat = concat.concat(`${id}:${count}${index === shoppingCart.length - 1 ? '' : ','}`)),
      ''
    );

    const url = `https://oberonweb.myshopify.com/cart/${generatedShoppingCartString}`;
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <Modal
      isOpen={isShow}
      modalClass={modalClass}
      modalWrapClass={modalWrapClass}
      handleClose={handleClose}
    >
      <div className={container}>
        <div className={basketHeader}>
          <div className={basketTitle}>Shopping cart</div>
          <div className={basketRemoveAll} onClick={removeAllProducts}>
            Remove all
          </div>
        </div>
        <div className={basketBody}>
          <ProductList />
        </div>
        <div className={basketFooter}>
          <div className={basketFooterLeft}>
            <div className={basketClose} onClick={handleClose}>
              Close
            </div>
          </div>
          <div className={basketFooterRight}>
            <div className={basketTotalWraper}>
              <div className={basketCountWrapper}>
                <div className={basketCountTitle}>Sub-total</div>
                <div className={basketCount}>{shoppingCart.length} items</div>
              </div>
              <div className={basketTotalPrice}>${totalPrice}</div>
            </div>
            <div className={basketButtonWrapper}>
              <Button onClick={pay} className={basketButton}>
                Checkout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

const Product = ({ image, title, price, id, count }: ProductInShoppingCartModelType) => {
  const {
    productContainer,
    productImg,
    productTitle,
    productLeft,
    productCenter,
    productRight,
    productMinus,
    productCount,
    productPlus,
    productPrice,
    productDelete,
  } = useStyles();

  const [localCount, setLocalCount] = useState<number>(count);
  const {
    shop: { increaseProductCount, decreaseProductCount, removeProduct },
  } = useStores();

  const increase = () => {
    setLocalCount((prev) => prev + 1);
    increaseProductCount(id);
  };

  const decrease = () => {
    if (localCount > 1) {
      setLocalCount((prev) => prev - 1);
      decreaseProductCount(id);
    }
  };

  const remove = (id: number) => {
    removeProduct(id);
  };

  return (
    <div className={productContainer}>
      <div className={productLeft}>
        <div className={productImg}>
          <img src={image?.src} alt={image?.alt} />
        </div>
        <div className={productTitle}>{title}</div>
      </div>
      <div className={productCenter}>
        <div className={productMinus} onClick={decrease}></div>
        <div className={productCount}>{localCount}</div>
        <div className={productPlus} onClick={increase}></div>
      </div>
      <div className={productRight}>
        <div className={productPrice}>${price * localCount}</div>
        <div className={productDelete} onClick={() => remove(id)}>
          <TrashIcon />
        </div>
      </div>
    </div>
  );
};

const ProductList = () => {
  const {
    shop: { shoppingCart },
  } = useStores();
  return (
    <>
      {shoppingCart?.map((product) => (
        <Product {...product} key={product.id} />
      ))}
    </>
  );
};

export default ShoppingCartModal;
