import { createOffer } from '../../webrtc';
import { MEDIA_SERVER_EVENT_SET_SDP } from '../../../../constants/wbs';
import { sendDataToServer } from '.';
import { loggerLog } from '../../logger';

export const setSdp = async (deviceId: number, streamType: string) => {
  const sdp = await createOffer(streamType);

  const data = {
    req: MEDIA_SERVER_EVENT_SET_SDP,
    peer_id: deviceId,
    sdp,
  };
  loggerLog(`send ${MEDIA_SERVER_EVENT_SET_SDP} to ms-server`, data);
  sendDataToServer(data);
};
