import { makeStyles } from '@material-ui/core/styles';
import { MenuSelectStyleType } from '.';

const useStyles = makeStyles(() => ({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 20px rgba(20, 14, 23, 0.08)',
    borderRadius: '8px',
    position: 'absolute',
    zIndex: 1000,
    left: ({ widthDropDown }: MenuSelectStyleType) => `-${Number(widthDropDown) / 2 - 20}px`,
    visibility: ({ isShow }: MenuSelectStyleType) => (isShow ? 'visible' : 'hidden'),
    top: '35px',
  },
  list: {
    paddingLeft: 0,
    margin: 0,
    width: '100%',
  },
  item: {
    whiteSpace: 'nowrap',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#140E17',
    padding: '8px 8px 8px 12px',
    display: 'flex',
    width: '100%',
    '&:hover': {
      background: '#E7D1F9',
      borderRadius: '8px',
    },
  },
  arrowContainer: {
    display: 'flex',
    position: 'relative',
    paddingLeft: '12px',
    '& svg': {
      pointerEvents: 'none',
    },
  },
  container: {
    position: 'relative',
  },
}));

export default useStyles;
