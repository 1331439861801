import React, { useState } from 'react';
import { useStyles } from './style';
import {
  Checkbox,
  AudioMuteIcon,
  AudioOnIcon,
  VideoMuteIcon,
  VideoOnIcon,
  ShareMuteIcon,
  ShareIcon,
  ModeratorVolumeControl,
  ModeratorSelect,
} from '..';
import sortIcon from './sortIcon.svg';
import classnames from 'classnames';
import { ParticipantType } from '../../models/participants';

type ParticipantTableHeaderType = {
  isCheckedGroupCheckbox: boolean;
  changeGroupCheckbox: any;
  selectParticipants: ParticipantType[];
  changeOrderParticipants: any;
  handleOpenBanModal: any;
};

const ParticipantTableHeader = ({
  isCheckedGroupCheckbox,
  changeGroupCheckbox,
  selectParticipants,
  changeOrderParticipants,
  handleOpenBanModal,
}: ParticipantTableHeaderType) => {
  const {
    container,
    checkboxAndNameClass,
    settingsContainerClass,
    settingTitleClass,
    nameAndSortContainerClass,
    sortIconContainerClass,
    screenshareClass,
    microphoneClass,
    groupSettingsContainer,
    accessTitle,
    groupVideo,
    groupVolumeControlContainer,
    groupVolumeControlTitle,
    groupVolumeControl,
    banSelectedTitle,
    iconContainer,
    selectClass,
  } = useStyles();

  const [volume, setVolume] = useState(0);
  const [isDisableAudio, setIsDisableAudio] = useState(false);
  const [isDisableVideo, setIsDisableVideo] = useState(false);
  const [isDisableShare, setIsDisableShare] = useState(false);

  const handleChangeGroupAudio = () => {
    setIsDisableAudio(!isDisableAudio);

    selectParticipants.map(
      async (selectParticipant) => await selectParticipant.toggleVoiceIsMuted()
    );
  };
  const handleChangeGroupVideo = () => {
    setIsDisableVideo(!isDisableVideo);

    selectParticipants.map(
      async (selectParticipant) => await selectParticipant.toggleVideoIsMuted()
    );
  };
  const handleChangeGroupShare = () => {
    setIsDisableShare(!isDisableShare);
  };

  const handleChangeVolume = (newVolume: number) => {
    setVolume(newVolume);
    selectParticipants.map(
      async (selectParticipant) => await selectParticipant.setVoiceVolume(newVolume)
    );
  };

  const orders = [
    {
      name: 'Ascending (A to Z)',
      value: 'asc',
    },
    {
      name: 'Descending (Z to A)',
      value: 'desc',
    },
  ];
  return (
    <div className={container}>
      {isCheckedGroupCheckbox ? (
        <>
          <div className={checkboxAndNameClass}>
            <div>
              <Checkbox
                isGroupCheckbox
                isChecked={isCheckedGroupCheckbox}
                onChange={changeGroupCheckbox}
              />
            </div>
            <div className={groupSettingsContainer}>
              <div className={accessTitle}>Access:</div>
              <div onClick={handleChangeGroupAudio} className={iconContainer}>
                {isDisableAudio ? <AudioMuteIcon /> : <AudioOnIcon color="#140E17" />}
              </div>
              <div
                className={classnames(groupVideo, iconContainer)}
                onClick={handleChangeGroupVideo}
              >
                {isDisableVideo ? <VideoMuteIcon /> : <VideoOnIcon color="#140E17" />}
              </div>
              <div onClick={handleChangeGroupShare} className={iconContainer}>
                {isDisableShare ? <ShareMuteIcon /> : <ShareIcon color="#140E17" />}
              </div>
            </div>
            <div className={groupVolumeControlContainer}>
              <div className={groupVolumeControlTitle}>Volume</div>
              <div className={groupVolumeControl}>
                <ModeratorVolumeControl volume={volume} setVolume={handleChangeVolume} />
              </div>
            </div>
            <div
              className={banSelectedTitle}
              onClick={() => handleOpenBanModal(selectParticipants)}
            >
              Ban selected
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={checkboxAndNameClass}>
            <div>
              <Checkbox
                isGroupCheckbox
                isChecked={isCheckedGroupCheckbox}
                onChange={changeGroupCheckbox}
              />
            </div>
            <div className={nameAndSortContainerClass}>
              <div className={settingTitleClass}>name</div>
              <ModeratorSelect
                selectClass={selectClass}
                options={orders}
                changeValue={changeOrderParticipants}
                icon={
                  <div className={sortIconContainerClass}>
                    <img src={sortIcon} alt="sortIcon" />
                  </div>
                }
              />
            </div>
          </div>
          <div className={settingsContainerClass}>
            <div className={classnames(settingTitleClass, microphoneClass)}>microphone</div>
            <div className={settingTitleClass}>video</div>
            <div className={classnames(settingTitleClass, screenshareClass)}>screenshare</div>
          </div>
        </>
      )}
    </div>
  );
};

export default ParticipantTableHeader;
