import React from 'react';
import { Modal } from '../..';
import { useStores } from '../../../hooks/useStores';
import iseStyles from './style';
import banIcon from './banIcon.svg';
import marriottLogo from './marriottLogo.svg';

const BanModal = () => {
  const { modal } = useStores();

  const {
    banModalContainer,
    banModalClass,
    banModalWrapClass,
    banModalTitleWrap,
    banModalTitleIcon,
    banModalTitleText,
    banModalTitleDesc,
    banModalTitleColumn,
    banModalDelimiter,
    banModalLogoWraper,
    banModalLogo,
    banModalLogoTitleWrap,
    banModalLogoTitle,
    banModalLogoEmail,
  } = iseStyles();
  return (
    <Modal
      isTransparent
      isOpen={modal.isOpenBanModal}
      modalClass={banModalClass}
      modalWrapClass={banModalWrapClass}
    >
      <div className={banModalContainer}>
        <div className={banModalTitleWrap}>
          <div className={banModalTitleIcon}>
            <img src={banIcon} alt="nam icon" />
          </div>
          <div className={banModalTitleColumn}>
            <div className={banModalTitleText}>You have been banned</div>
            <div className={banModalTitleDesc}>
              Your account has been banned by moderator of the event. If it’s a mistake, contact
              with organizator of the event.
            </div>
          </div>
        </div>
        <div className={banModalDelimiter}></div>
        <div className={banModalLogoWraper}>
          <div className={banModalLogo}>
            <img src={marriottLogo} alt="nam icon" />
          </div>
          <div className={banModalLogoTitleWrap}>
            <div className={banModalLogoTitle}>Marriott International</div>
            <div className={banModalLogoEmail}>contact@mariottInternational.com</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BanModal;
