import React from 'react';
import useStyles from './style';

type MagnifyingGlassIconProps = {
  color?: string;
  background?: string;
};

const MagnifyingGlassIcon = ({
  background = '#2B2D33',
  color = 'white',
}: MagnifyingGlassIconProps) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <mask
        id="mask0_1160_21473"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="16"
        height="16"
      >
        <path
          d="M9.03022 2C12.9129 2 16.0604 5.14754 16.0604 9.03022C16.0604 10.6544 15.5097 12.1499 14.5847 13.3402L17.3788 16.1357C17.7219 16.479 17.7218 17.0354 17.3786 17.3786C17.0354 17.7218 16.479 17.7219 16.1357 17.3788L13.3402 14.5847C12.1499 15.5097 10.6544 16.0604 9.03022 16.0604C5.14754 16.0604 2 12.9129 2 9.03022C2 5.14754 5.14754 2 9.03022 2ZM9.03022 3.75756C6.11821 3.75756 3.75756 6.11821 3.75756 9.03022C3.75756 11.9422 6.11821 14.3029 9.03022 14.3029C11.9422 14.3029 14.3029 11.9422 14.3029 9.03022C14.3029 6.11821 11.9422 3.75756 9.03022 3.75756Z"
          fill={background}
        />
      </mask>
      <g mask="url(#mask0_1160_21473)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default MagnifyingGlassIcon;
