import { Instance, types } from 'mobx-state-tree';
import { ScreenAllowedForRolesMap } from '../constants/user';
import { Roles } from '../constants/user';
import { rootStore } from './rootStore';

export const ScreenShareTargetModel = types
  .model('screenShareTarget')
  .props({
    screenShareTargetId: types.identifierNumber,
    title: types.string,
    takenByUserId: types.optional(types.maybeNull(types.string), null),
    allowedForRoles: types.map(types.boolean),
    isShow: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isTaken() {
      return self.takenByUserId !== null;
    },
    get isTakenByCurrentUser(): boolean {
      const { user } = rootStore;
      return this.isTaken && self.takenByUserId === user.userId;
    },
  }));

export type ScreenShareTargetModelType = Instance<typeof ScreenShareTargetModel>;
export interface ScreenShareTargetType extends ScreenShareTargetModelType {}

export const ScreenSharingModuleModel = types
  .model('screenSharingModule')
  .props({
    targets: types.optional(types.map(ScreenShareTargetModel), {}),
    selectedResolution: types.optional(types.boolean, false),
    sharedScreenWidth: types.optional(types.maybeNull(types.number), null),
    sharedScreenHeight: types.optional(types.maybeNull(types.number), null),
    selectedScreenShareTargetId: types.optional(types.maybeNull(types.number), null),
    selectedFullScreenShareTargetId: types.optional(types.maybeNull(types.number), null),
    currentUserRole: types.optional(
      types.maybeNull(types.enumeration([...Object.values(Roles)])),
      null
    ),
  })
  .volatile(() => ({
    refPlayerLocalScreenShare: null as HTMLVideoElement | null,
  }))
  .views((self) => ({
    get isShowStaticShare(): boolean {
      return this.allowedTargetsList.filter((target) => target.isTaken === true).length > 0;
    },
    get targetsList(): ScreenShareTargetModelType[] {
      return Array.from(self.targets.values());
    },

    get allowedTargetsList(): ScreenShareTargetModelType[] {
      // @ts-ignore
      return this.targetsList.filter(
        (target) =>
          self.currentUserRole && target.allowedForRoles.get(self.currentUserRole)! === true
      );
    },
  }))
  .actions((self) => ({
    setSelectedScreenShareTargetId(selectedScreenShareTargetId: number | null) {
      self.selectedScreenShareTargetId = selectedScreenShareTargetId;
    },
    setSelectedFullScreenShareTargetId(selectedFullScreenShareTargetId: number | null) {
      self.selectedFullScreenShareTargetId = selectedFullScreenShareTargetId;
    },
    setRefPlayerLocalScreenShare(newValue: any) {
      self.refPlayerLocalScreenShare = newValue;
    },
    updateScreenShareTarget(newScreenShareTargetData: {
      title: string;
      screenShareTargetId: number;
      takenByUserId: string | null;
      allowedForRoles: ScreenAllowedForRolesMap;
      isShow: boolean;
    }) {
      const { screenShareTargetId } = newScreenShareTargetData;

      const existingScreenShareTarget = self.targets.get(screenShareTargetId.toString());

      /* adding new one */
      if (!existingScreenShareTarget) {
        self.targets.set(screenShareTargetId.toString(), newScreenShareTargetData);
        return;
      }
      /* ************** */

      /* merging with existing */
      const mergedTargetData = {
        screenShareTargetId,
        title:
          newScreenShareTargetData.title !== undefined
            ? newScreenShareTargetData.title
            : existingScreenShareTarget.title,
        takenByUserId:
          newScreenShareTargetData.takenByUserId !== undefined
            ? newScreenShareTargetData.takenByUserId
            : existingScreenShareTarget.takenByUserId,
        allowedForRoles:
          newScreenShareTargetData.allowedForRoles !== undefined
            ? newScreenShareTargetData.allowedForRoles
            : existingScreenShareTarget.allowedForRoles,
      };

      // @ts-ignore
      self.targets.set(screenShareTargetId.toString(), mergedTargetData);
      /* ******************** */
    },
    removeAllScreenShareTargets() {
      self.targetsList.forEach(({ screenShareTargetId }) => {
        self.targets.delete(
          typeof screenShareTargetId === 'number'
            ? screenShareTargetId.toString()
            : (screenShareTargetId as string)
        );
      });
    },
    setCurrentUserRole(newValue: Roles) {
      self.currentUserRole = newValue;
    },
  }));

export type ScreenSharingModuleModelType = Instance<typeof ScreenSharingModuleModel>;
export interface ScreenSharingModuleType extends ScreenSharingModuleModelType {}
