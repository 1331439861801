import React, { useCallback } from 'react';
import { useStores } from '../../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import RequestScreenShareModal from '../RequestScreenShareModal_old';
import * as ApiWrapper from '../../../services/apiWrapper';
import {
  closeScreenChannel,
  onEndedStream,
  onFailStream,
  createScreenStream,
} from '../../../helpers/stream';

const RequestStaticShareModal = observer(() => {
  const {
    modal: { isOpenRequestScreenShareModal, setIsOpenRequestScreenShareModal },
    screenSharingModule: { selectedScreenShareTargetId, refPlayerLocalScreenShare: ref },
    wbs: { screenWidth: width, screenHeight: height },
  } = useStores();

  const handleDenyModalStaticShare = useCallback(async () => {
    await closeScreenChannel();
  }, []);

  const handleCloseModalStaticShare = useCallback(async () => {
    await handleDenyModalStaticShare();
  }, [handleDenyModalStaticShare]);

  const handleAllowModalStaticShare = useCallback(async () => {
    setIsOpenRequestScreenShareModal(false);
    createScreenStream({ ref, width, height, onEndedStream, onFailStream });
    const screenShareTargetId = selectedScreenShareTargetId;
    if (screenShareTargetId !== null) {
      await ApiWrapper.startSharingScreen('static', screenShareTargetId);
    }
  }, [ref, width, height, setIsOpenRequestScreenShareModal, selectedScreenShareTargetId]);

  return (
    <RequestScreenShareModal
      title="Please select resolution:"
      subTitle="Do you want to share static screen?"
      open={isOpenRequestScreenShareModal}
      handleClose={handleCloseModalStaticShare}
      handleAllow={handleAllowModalStaticShare}
      handleDeny={handleDenyModalStaticShare}
    />
  );
});

export default RequestStaticShareModal;
