import React from 'react'; // { useState }
import useStyles from './style';
import classnames from 'classnames';
import {
  ModeratorTitle,
  ModeratorChangeButton,
  // ModeratorSwitch
} from '../../..';
import { useStores } from '../../../../hooks/useStores';
import { generateAvatar } from '../../../../helpers/random';
import { observer } from 'mobx-react-lite';

type MainType = {
  changeScreen: any;
};

const Main = observer(({ changeScreen }: MainType) => {
  const {
    container,
    participantsClass,
    card,
    participantsTitleContainer,
    participantsIconsContainer,
    bannedParticipantsContainer,
    participantIconClass,
    participantIconItemClass,
    participantIconOthersClass,
    // soundSettingsClass,
    // switchContainerClass,
  } = useStyles();

  const { participantsList } = useStores();
  // const [isStandartSound, setIsStandartSound] = useState(false);
  // TODO: need refactoring with game
  const bannedParticipantsCount = 0;
  const participantsCount = participantsList.notModeratorsOnly.length;
  const showIconParticipants = 5;

  const isEmptyParticipants = participantsCount === 0;
  const isEmptyBannedParticipants = bannedParticipantsCount === 0;
  return (
    <div className={container}>
      <div className={classnames(card, participantsClass)}>
        <div className={participantsTitleContainer}>
          <div>
            <ModeratorTitle text="Participants" count={participantsCount} />
          </div>
          <div onClick={() => !isEmptyParticipants && changeScreen('participants')}>
            <ModeratorChangeButton isDisable={isEmptyParticipants} />
          </div>
        </div>
        <div className={participantsIconsContainer}>
          {participantsList.notModeratorsOnly.map(({ avatar }, index) => (
            <div key={index} className={participantIconItemClass}>
              <img src={avatar ?? generateAvatar()} className={participantIconClass} alt="icon" />
            </div>
          ))}
          {participantsCount - showIconParticipants > 0 && (
            <div className={classnames(participantIconClass, participantIconOthersClass)}>
              +{participantsCount - showIconParticipants}
            </div>
          )}
        </div>
        <div className={bannedParticipantsContainer}>
          <div>
            <ModeratorTitle text="Banned participants" count={bannedParticipantsCount} />
          </div>
          <div onClick={() => !isEmptyBannedParticipants && changeScreen('banned_participants')}>
            <ModeratorChangeButton isDisable={isEmptyBannedParticipants} />
          </div>
        </div>
      </div>
      {/* <div className={classnames(card, soundSettingsClass)}>
        <div>
          <ModeratorTitle text="Sound settings" isShowCount={false} />
        </div>
        <div className={switchContainerClass}>
          <ModeratorSwitch
            isSelect={isStandartSound}
            setIsSelect={() => setIsStandartSound(true)}
            title="Standart"
            desc="Players can hear each other regardless of the distance on the map"
            id="standart"
          />
          <ModeratorSwitch
            isSelect={!isStandartSound}
            setIsSelect={() => setIsStandartSound(false)}
            title="Spatial"
            desc="Players hear each other better or worse depending on the distance they are"
            id="spatial"
          />
        </div>
      </div> */}
    </div>
  );
});

export default Main;
