import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  screenSaver: {
    width: '18.625rem',
    height: '11.625rem',
    borderRadius: '1.5rem',
    marginTop: '1.5rem',
    backgroundColor: '#262626',
    opacity: '0.9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}));

export default useStyles;
