import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  errorModalContainer: {
    width: '100%',
    padding: '70px 150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorModalClass: {
    width: '100%',
    maxWidth: '652px',
    display: 'flex',
    borderRadius: '15px',
    position: 'relative',
  },
  errorModalWrapClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
  },
  errorModalTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '38px',
    color: '#3F5CA4',
  },
  errorModalDesc: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '30px',
    color: '#3F5CA4',
    paddingTop: '25px',
    paddingBottom: '50px',
    textAlign: 'center',
  },
  errorModalButtonWrap: {
    width: '100%',
  },
  logoutModalButton: {
    background: 'linear-gradient(89.88deg, #3060E4 0.1%, #00BAF2 51.05%, #E069A8 99.92%)',
    borderRadius: '15px',
    padding: '16px',
    width: '100%',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '25px',
    color: '#FFFFFF',
  },
}));

export default useStyles;
