import React from 'react';
import useStyles from './style';
import { ArrowRightIcon } from '..';

type ModeratorChangeButtonType = {
  isDisable?: boolean;
};

const ModeratorChangeButton = ({ isDisable = false }: ModeratorChangeButtonType) => {
  const { container, title, arrow } = useStyles(isDisable);

  return (
    <div className={container}>
      <div className={title}>Change</div>
      <div className={arrow}>
        <ArrowRightIcon color={isDisable ? '#CECECE' : '#A24DE8'} />
      </div>
    </div>
  );
};

export default ModeratorChangeButton;
