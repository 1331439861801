import React, { useState } from 'react';
import { ShoppingCartIcon, ShoppingCartModal, Button } from '../..';
import { observer } from 'mobx-react-lite';
import useStyles from './style';
import { useStores } from '../../../hooks/useStores';

type ShoppingCartMenuItemType = {
  buttonClass?: string;
};

const ShoppingCartMenuItem = observer(({ buttonClass }: ShoppingCartMenuItemType) => {
  const [isShowShoppingCartModal, setIsShowShoppingCartModal] = useState(false);

  const { container, productCount } = useStyles();

  const {
    shop: { shoppingCart },
  } = useStores();

  const handleShoppingCart = () => {
    setIsShowShoppingCartModal(!isShowShoppingCartModal);
  };

  const handleClose = () => {
    setIsShowShoppingCartModal(false);
  };

  return (
    <>
      <div className={container}>
        <Button onClick={handleShoppingCart} className={buttonClass}>
          {shoppingCart.length > 0 ? <div className={productCount}>{shoppingCart.length}</div> : ''}
          <ShoppingCartIcon />
        </Button>
      </div>
      <ShoppingCartModal isShow={isShowShoppingCartModal} handleClose={handleClose} />
    </>
  );
});

export default ShoppingCartMenuItem;
