import React from 'react';
import { ArrowDownIcon, ArrowUpIcon, VideoIcon, Button, DeviceList } from '../..';
import { useStores } from '../../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import {
  existVideoDevice,
  handleShowVideo,
  handleChangeMyVideoDevice,
} from '../../../helpers/device';
// import { changeStatusVideoTrack } from '../../../helpers/stream';
// import { emitActiveMediaDevices } from '../../../services/game/emitsToGame/emits';
import useStyles from './style';
import useComponentVisible from '../../../hooks/useComponentVisible';

const VideoMenuItem = observer(({ handleUpdateLocalStream }: { handleUpdateLocalStream: any }) => {
  const {
    ref: refVideoSelect,
    isComponentVisible: isShowVideoSelect,
    setIsComponentVisible: setIsShowVideoSelect,
  } = useComponentVisible();
  const {
    allSettings: { videoDevices },
    mySetting: { isMutedVideo, isShowVideo, myVideoDeviceId },
  } = useStores();

  const handleArrowSelect = () => {
    if (!isMutedVideo) {
      setIsShowVideoSelect(!isShowVideoSelect);
    }
  };

  const { container, selectContainer, leftButtonClass, rightButtonClass } = useStyles();

  // useEffect(() => {
  //   if (isMutedVideo) {
  //     setIsShowVideoSelect(false);
  //     setIsShowVideo(false);
  //     changeStatusVideoTrack(false);
  //     emitActiveMediaDevices(isShowAudio, false);
  //   } else {
  //     setIsShowVideo(true);
  //     changeStatusVideoTrack(true);
  //     emitActiveMediaDevices(true, true);
  //   }
  // }, [isMutedVideo, isShowAudio, setIsShowVideo, setIsShowVideoSelect]);

  return (
    <div className={container}>
      {isShowVideoSelect && (
        <div className={selectContainer} ref={refVideoSelect}>
          <DeviceList
            handleChange={(videoDeviceLabel: string) =>
              handleChangeMyVideoDevice(videoDeviceLabel, handleUpdateLocalStream)
            }
            options={videoDevices}
            isDisabled={!isShowVideo || !existVideoDevice()}
            activeDeviceId={myVideoDeviceId}
          />
        </div>
      )}
      <Button onClick={handleShowVideo} className={leftButtonClass}>
        <VideoIcon />
      </Button>

      <Button onClick={handleArrowSelect} className={rightButtonClass}>
        {isShowVideoSelect ? (
          <ArrowUpIcon
            color={!existVideoDevice() ? '#CECECE' : 'white'}
            background={!existVideoDevice() ? '#140E17' : 'black'}
          />
        ) : (
          <ArrowDownIcon
            color={!existVideoDevice() ? '#CECECE' : 'white'}
            background={!existVideoDevice() ? '#140E17' : 'black'}
          />
        )}
      </Button>
    </div>
  );
});

export default VideoMenuItem;
