import { Instance, types } from 'mobx-state-tree';
import { Roles } from '../constants/user';

import { rootStore } from './rootStore';
import * as ApiWrapper from '../services/apiWrapper';

export const ParticipantModel = types
  .model('Participant')
  .props({
    userId: types.identifier,
    username: types.string,
    subtitle: types.optional(types.maybeNull(types.string), null),
    avatar: types.optional(types.maybeNull(types.string), null),
    role: types.enumeration([...Object.values(Roles)]),
    isOnline: types.optional(types.boolean, true),
    voiceIsMuted: types.optional(types.boolean, false),
    videoIsMuted: types.optional(types.boolean, false),
    voiceIsEnabled: types.optional(types.boolean, true),
    videoIsEnabled: types.optional(types.boolean, true),
    screenSharingIsMuted: types.optional(types.boolean, false),
    voiceVolume: types.optional(types.number, 100),
    isSharingScreen: types.optional(types.boolean, false),
    staticScreenShareTargetId: types.optional(types.maybeNull(types.number), null),
  })
  .views((self) => ({
    get is() {
      return self.role === Roles.SPEAKER;
    },
    get isModerator() {
      return self.role === Roles.MODERATOR;
    },
    get isDefaultUser() {
      return self.role === Roles.DEFAULT_USER;
    },
    get isSpectator() {
      return self.role === Roles.SPECTATOR;
    },
    get hasAvatar() {
      return Boolean(self.avatar);
    },
    get hasSubtitle() {
      return Boolean(self.subtitle);
    },
  }))
  .actions((self) => ({
    async setVoiceVolume(newValue: number) {
      await ApiWrapper.setVoiceVolume(self.userId, newValue);
    },
    async toggleScreenSharingIsMuted() {
      self.screenSharingIsMuted = !self.screenSharingIsMuted;
    },
    async toggleVideoIsMuted() {
      self.videoIsMuted = !self.videoIsMuted;
      await ApiWrapper.setVideoIsMuted(self.userId, self.videoIsMuted);
    },
    async toggleVoiceIsMuted() {
      self.voiceIsMuted = !self.voiceIsMuted;
      await ApiWrapper.setVoiceIsMuted(self.userId, self.voiceIsMuted);
    },
    async toggleBanUser() {
      await ApiWrapper.banUser(self.userId);
    },
    async toggleKickUser() {
      await ApiWrapper.kickUser(self.userId);
    },
    async stopScreenShareByModerator() {
      await ApiWrapper.stopScreenShareByModerator(
        self.staticScreenShareTargetId as number,
        self.userId
      );
    },
  }));

type ParticipantModelType = Instance<typeof ParticipantModel>;
export interface ParticipantType extends ParticipantModelType {}

export const ParticipantsListModel = types
  .model('ParticipantsList')
  .props({
    participants: types.map(ParticipantModel),
  })
  .views((self) => ({
    get allParticipants() {
      return Array.from(self.participants.values());
    },
    get onlineParticipants() {
      return this.allParticipants.filter((p) => p.isOnline);
    },
    get speakersOnly(): ParticipantType[] {
      return this.allParticipants.filter((participant) => participant.is);
    },
    get moderatorsOnly(): ParticipantType[] {
      return this.allParticipants.filter((participant) => participant.isModerator);
    },
    get notModeratorsOnly(): ParticipantType[] {
      return this.allParticipants.filter((participant) => !participant.isModerator);
    },
    get defaultUsersOnly(): ParticipantType[] {
      return this.allParticipants.filter((participant) => participant.isDefaultUser);
    },
    get spectatorsOnly(): ParticipantType[] {
      return this.allParticipants.filter((participant) => participant.isDefaultUser);
    },
    get allAnotherParticipantsExceptSpectators(): ParticipantType[] {
      return this.allParticipants.filter(
        (participant) =>
          participant.userId !== (rootStore.user.userId as string) &&
          participant.role !== Roles.SPECTATOR
      );
    },
  }))
  .actions((self) => ({
    updateParticipant({
      userId,
      username,
      subtitle,
      avatar,
      role,
      isOnline,
      voiceIsMuted,
      videoIsMuted,
      voiceIsEnabled,
      videoIsEnabled,
      screenSharingIsMuted,
      voiceVolume,
      isSharingScreen,
      staticScreenShareTargetId,
    }: {
      userId: string;
      username?: string;
      subtitle?: string | null;
      avatar?: string | null;
      role?: Roles;
      isOnline?: boolean;
      voiceIsMuted?: boolean;
      videoIsMuted?: boolean;
      voiceIsEnabled?: boolean;
      videoIsEnabled?: boolean;
      screenSharingIsMuted?: boolean;
      voiceVolume?: number;
      isSharingScreen?: boolean;
      staticScreenShareTargetId?: number | null;
    }) {
      const currentParticipantState = self.participants.get(userId);

      if (!currentParticipantState) {
        /* participant does not exist in the list */

        if (username && role) {
          /* have enough data to add participant to list */
          self.participants.set(userId, {
            userId,
            username: username as string,
            subtitle,
            avatar,
            role: role as Roles,
            isOnline,
            voiceIsMuted,
            videoIsMuted,
            voiceIsEnabled,
            videoIsEnabled,
            screenSharingIsMuted,
            voiceVolume,
            isSharingScreen,
            staticScreenShareTargetId,
          });
        } else {
          /* have no enough data to add participant to list
           *  we shall do nothing
           */
          return;
        }
      } else {
        /* participant is already presented in list,
         we should merge updates to currentParticipantState */
        /* updating existing user */
        self.participants.set(userId, {
          userId,
          username: username !== undefined ? username : currentParticipantState.username,
          subtitle: subtitle !== undefined ? subtitle : currentParticipantState.subtitle,
          avatar: avatar !== undefined ? avatar : currentParticipantState.avatar,
          role: role !== undefined ? role : currentParticipantState.role,
          isOnline: isOnline !== undefined ? isOnline : currentParticipantState.isOnline,
          voiceIsMuted:
            voiceIsMuted !== undefined ? voiceIsMuted : currentParticipantState.voiceIsMuted,
          videoIsMuted:
            videoIsMuted !== undefined ? videoIsMuted : currentParticipantState.videoIsMuted,
          voiceIsEnabled:
            voiceIsEnabled !== undefined ? voiceIsEnabled : currentParticipantState.voiceIsEnabled,
          videoIsEnabled:
            videoIsEnabled !== undefined ? videoIsEnabled : currentParticipantState.videoIsEnabled,
          screenSharingIsMuted:
            screenSharingIsMuted !== undefined
              ? screenSharingIsMuted
              : currentParticipantState.screenSharingIsMuted,
          voiceVolume:
            voiceVolume !== undefined ? voiceVolume : currentParticipantState.voiceVolume,
          isSharingScreen:
            isSharingScreen !== undefined
              ? isSharingScreen
              : currentParticipantState.isSharingScreen,
          staticScreenShareTargetId:
            staticScreenShareTargetId !== undefined
              ? staticScreenShareTargetId
              : currentParticipantState.staticScreenShareTargetId,
        });
      }
    },
    getCurrentParticipant(): ParticipantType | null {
      const { user } = rootStore;
      return self.participants.get(user.userId as string) ?? null;
    },
    clearList() {
      self.participants.clear();
    },
  }));

type ParticipantsListModelType = Instance<typeof ParticipantsListModel>;
export interface ParticipantsListType extends ParticipantsListModelType {}
