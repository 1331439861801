import { observer } from 'mobx-react-lite';
import useStyles from './style';
import classnames from 'classnames';
import React, { createRef, Ref, useState } from 'react';
import { ContextMenuType } from '../../types/ui';
import { Button } from '..';

const ContextMenu = observer(({ options, invoker, direction }: ContextMenuType) => {
  const contextMenuRef: Ref<HTMLDivElement> = createRef();

  const {
    contextMenuContainer,
    contextMenuItem,
    contextMenuItemsList,
    contextMenuClickableOverlay,
    contextMenuInvokerContainer,
  } = useStyles();

  const [isOpened, setIsOpened] = useState(false);

  const toggleContextMenu = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div className={contextMenuContainer} ref={contextMenuRef}>
      {isOpened && (
        <div
          className={contextMenuClickableOverlay}
          onClick={() => {
            toggleContextMenu();
          }}
        />
      )}

      <Button
        className={classnames(contextMenuInvokerContainer)}
        onClick={() => {
          toggleContextMenu();
        }}
      >
        {invoker}
      </Button>

      {isOpened && (
        <ul
          className={classnames(
            contextMenuItemsList,
            direction === 'right' ? 'rightDirected' : 'leftDirected'
          )}
        >
          {options.length ? (
            options.map(({ title, onClick }) => {
              return (
                <span
                  key={title}
                  className={classnames(contextMenuItem)}
                  onClick={() => {
                    onClick();
                    setIsOpened(false);
                  }}
                >
                  {title}
                </span>
              );
            })
          ) : (
            <span className={classnames(contextMenuItem)}>{'...'}</span>
          )}
        </ul>
      )}
    </div>
  );
});

export default ContextMenu;
