import { validateSchema } from '../../ajv';
import { wbsInfoSchema } from '../../ajv/schema';
// import * as WbsService from '../../../../services/wbs';
// import { LOG_LEVEL, logWbs } from '@pxs-infra/logger';
import { WbsInfoType } from '../../../../types/wbs/emit';

export const wbsInfo = (json: WbsInfoType) => {
  validateSchema(wbsInfoSchema, json);
  // const { status } = json;

  // if (status !== 'success') {
  //   logWbs(LOG_LEVEL, `wbs connection status is ${status}. Reconnect to wbs`);
  //   WbsService.reconnect();
  //   WbsService.publishLocal();
  // }
};
