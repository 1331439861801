import React from 'react';
import { CommonModal } from '../..';
import useStyles from './style';
import chrome from './images/chrome.svg';
import firefox from './images/firefox.svg';
import opera from './images/opera.svg';
import safari from './images/safari.svg';

type ScreenShareDisabledModalType = {
  isShow: boolean;
  handleClose: any;
};
const ScreenShareDisabledModal = ({ isShow, handleClose }: ScreenShareDisabledModalType) => {
  const {
    screenShareDisabledModalDesc,
    screenShareDisabledModalBrowsersContainer,
    screenShareDisabledModalBrowsersRow,

    screenShareDisabledModalInfoContainer,
    screenShareDisabledModalInfoItem,
    screenShareDisabledModalInfoLink,
  } = useStyles();

  const browsers = [
    {
      id: 1,
      text1: 'Chrome guide',
      img1: chrome,
      text2: 'Safari guide',
      img2: safari,
    },
    {
      id: 2,
      text1: 'Firefox guide',
      img1: firefox,
      text2: 'Opera guide',
      img2: opera,
    },
  ];

  return (
    <CommonModal
      isShow={isShow}
      handleClose={handleClose}
      title="Screensharing is disabled in your browser"
    >
      <div className={screenShareDisabledModalDesc}>1. Follow the guide below</div>
      <div className={screenShareDisabledModalBrowsersContainer}>
        {browsers.map(({ id, text1, img1, text2, img2 }) => (
          <div className={screenShareDisabledModalBrowsersRow} key={id}>
            <ScreenShareDisabledBrowser text={text1} img={img1} />
            <ScreenShareDisabledBrowser text={text2} img={img2} />
          </div>
        ))}
      </div>
      <div className={screenShareDisabledModalDesc}>2. Restart this page</div>
      <div className={screenShareDisabledModalInfoContainer}>
        <div className={screenShareDisabledModalInfoItem}>
          Did you fail to enable access to screen?
        </div>
        <div className={screenShareDisabledModalInfoItem}>
          Write to us via<span className={screenShareDisabledModalInfoLink}> chat</span>
        </div>
      </div>
    </CommonModal>
  );
};

export default ScreenShareDisabledModal;

type ScreenShareDisabledBrowserType = {
  text: string;
  img: string;
};
const ScreenShareDisabledBrowser = ({ text, img }: ScreenShareDisabledBrowserType) => {
  const {
    screenShareDisabledModalBrowsersColumn,
    screenShareDisabledModalBrowsersImg,
    screenShareDisabledModalBrowsersText,
  } = useStyles();
  return (
    <div className={screenShareDisabledModalBrowsersColumn}>
      <div className={screenShareDisabledModalBrowsersImg}>
        <img src={img} alt={text} />
      </div>
      <div className={screenShareDisabledModalBrowsersText}>{text}</div>
    </div>
  );
};
