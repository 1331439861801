import { validateSchema } from '../../ajv';
import { removeDeviceSchema } from '../../ajv/schema';

export const removeDevice = (json: any) => {
  validateSchema(removeDeviceSchema, json);
  const { device_id } = json;

  const video = document.getElementById(`wbs_video_${device_id}`);
  if (video) {
    video.remove();
  }
};
