import { MEDIA_SERVER_EVENT_SUBSCRIBE_EVENTS } from '../../../../constants/wbs';
import { sendDataToServer } from '.';

export const subscribeEvents = () => {
  const data = {
    req: MEDIA_SERVER_EVENT_SUBSCRIBE_EVENTS,
    operation: 'subscribe_all',
  };

  sendDataToServer(data);
};
