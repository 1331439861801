import React from 'react';
import { SelectDeviceCard, LogoIcon } from '..';
import { StartPageType } from '../../types/player';
import useStyles from './style';
import { version as buildVersion } from '../../../package.json';

const StartPage = ({ refPlayer, refPlayerLocalStream, refStartPageLocalStream }: StartPageType) => {
  const {
    container,
    footer,
    iconContainer,
    footerContainer,
    footerIcon,
    footerDescContainer,
    footerTitle,
    footerEmail,
    content,
    playerContainer,
    packageVersionWrapper,
    packageVersion,
  } = useStyles(open);

  return (
    <div className={container}>
      <div className={content}>
        <div className={playerContainer}>
          <SelectDeviceCard
            refPlayer={refPlayer}
            refPlayerLocalStream={refPlayerLocalStream}
            refStartPageLocalStream={refStartPageLocalStream}
          />
        </div>
      </div>
      <div className={footer}>
        <div className={footerContainer}>
          <div className={iconContainer}>
            <div className={footerIcon}>
              <LogoIcon />
            </div>
            <div className={footerDescContainer}>
              <div className={footerTitle}>Oberonweb Player</div>
              <div className={footerEmail}>
                <a href="mailto:mail@oberonweb.com">mail@oberonweb.com</a>
              </div>
            </div>
          </div>
          <div className={packageVersionWrapper}>
            Build version: <span className={packageVersion}>{buildVersion}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartPage;
