import light from './light';
import dark from './dark';

const themes: { [key: string]: any } = {
  light,
  dark,
} as any;

export default function getThemeByName(theme: string) {
  return themes[theme];
}
