import React from 'react';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';

const NumberParticipantsInSpace = observer(() => {
  const { container, title, badge } = useStyles();
  const {
    participantsList,
    user: { spaceName },
  } = useStores();
  const participantsCount = participantsList.notModeratorsOnly.length;
  return (
    <div className={container}>
      <div className={title}>{`Number of participants in "${spaceName}`}</div>
      <div className={badge}>{participantsCount}</div>
    </div>
  );
});

export default NumberParticipantsInSpace;
