import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  progress: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    background: '#E7D1F9',
    height: '20px',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: (value) => `${value}%`,
      background: '#A24DE8',
    },
  },
  progressValue: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '52px',
    lineHeight: '60px',
    color: '#A24DE8',
    padding: '10px 40px 36px 40px',

    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
      lineHeight: '36px',
      padding: '10px 32px 12px 32px',
    },
  },
  progressWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
