import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks/useStores';
import useStyles from './style';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { ChatType } from '../../../models/chats';
import { ChatMessage } from '../../index';
import { ChatKinds } from '../../../constants/chats';
import { CloseIcon } from '../../index';
import useTheme from '@material-ui/core/styles/useTheme';
import { useGlobalStyles } from '../../../globalStyles';
import { Button } from '../..';
import { Scrollbar } from '../../';

const ChatMessagesList = observer(({ chat }: { chat: ChatType }) => {
  const scrollableContainerRef = useRef<any>(null);

  const { chatsModule } = useStores();
  const { palette } = useTheme();

  const {
    chatMessagesListContainer,
    chatMessagesListTitle,
    chatMessagesListHeader,
    chatMessagesListScrollableContainer,
  } = useStyles();

  const { flexDirectionRow, flexJustifySpaceBetween, flexAlignCenter } = useGlobalStyles();

  useEffect(() => {
    const scrollToBottom = () => {
      if (scrollableContainerRef && scrollableContainerRef.current) {
        const { scrollHeight } = scrollableContainerRef.current;

        scrollableContainerRef.current?.scrollTo({
          top: scrollHeight,
          // @ts-ignore
          behavior: 'smooth',
        });
      }
    };

    scrollToBottom();
  }, [
    chatsModule.selectedChat?.messagesListLength,
    scrollableContainerRef,
    chat.messagesListLength,
  ]);

  return (
    <div className={classnames(chatMessagesListContainer)}>
      <div
        className={classnames(
          flexDirectionRow,
          flexAlignCenter,
          flexJustifySpaceBetween,
          chatMessagesListHeader
        )}
      >
        <span className={chatMessagesListTitle}>
          {chat.kind === ChatKinds.DIRECT && 'DIRECT MESSAGES'} {chat.title}
        </span>

        <Button onClick={() => chatsModule.selectChat(null)}>
          <CloseIcon color={palette.primary.dark} />
        </Button>
      </div>
      <Scrollbar
        className={chatMessagesListScrollableContainer}
        setRef={(current) => (scrollableContainerRef.current = current)}
      >
        {chat.messages.map((message) => (
          <ChatMessage message={message} chat={chat} key={message.chatMessageId} />
        ))}
      </Scrollbar>
    </div>
  );
});

export default ChatMessagesList;
