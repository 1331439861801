/* eslint no-unused-vars: "off" */

export enum ChatKinds {
  'PUBLIC' = 'PUBLIC',
  'DIRECT' = 'DIRECT',
}

export const MAX_MESSAGE_LENGTH: number = 200;

export const GENERIC_AVATAR_COLORS: string[] = [
  '#FB8C00',
  '#00796B',
  '#7E57C2',
  '#5C6BC0',
  '#EF5350',
  '#0288D1',
  '#AB47BC',
  '#43A047',
  '#F06292',
];
