import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { useGlobalStyles } from '../../globalStyles';
import classnames from 'classnames';
import { Button } from '..';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { ProductModelType } from '../../models/shop';

const BuyButton = observer(() => {
  const { buyButtonsContainer, buyButton, buyButtonTitle } = useStyles();
  const {
    game: {
      updateNearestStand: { currentProductId, isShowBuyButton },
    },
    shop: { getProductById, setProductToShoppingCart },
  } = useStores();
  const { flexDirectionRow, flexAlignCenter, flexJustifyCenter } = useGlobalStyles();

  const handleBuy = (product: ProductModelType | null) => {
    setProductToShoppingCart(product);
  };

  const [product, setProduct] = useState<ProductModelType | null>(null);

  useEffect(() => {
    const product = getProductById(currentProductId);
    if (product) {
      setProduct(product);
    }
  }, [getProductById, currentProductId]);

  return (
    <div
      className={classnames(
        flexDirectionRow,
        flexAlignCenter,
        flexJustifyCenter,
        buyButtonsContainer
      )}
    >
      {isShowBuyButton && (
        <Button onClick={() => handleBuy(product)} className={buyButton}>
          <span className={buyButtonTitle}>{product?.title || 'buy'}</span>
        </Button>
      )}
    </div>
  );
});

export default BuyButton;
