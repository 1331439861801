import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  chatMessagesListContainer: {
    height: 'calc(100% - 6.5rem)',
    width: 'calc(100% - 1.25rem)',
    paddingRight: '0.375rem',
  },
  chatMessagesListHeader: {
    width: '100%',
    height: '1.625rem',
    paddingLeft: '1rem',
  },
  chatMessagesListTitle: {
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '1.25rem',
    overflow: 'hidden',
  },
  chatMessagesListScrollableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflowY: 'scroll',
  },
}));

export default useStyles;
