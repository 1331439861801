import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';
import useStyles from './style';
import ChatMessagesList from './ChatMessagesList';
import ChatInput from './ChatInput';
import ChatsList from './ChatsList';
import { useGlobalStyles } from '../../globalStyles';
// import { unregisterKeyboardEvents } from '../../services/control/keyboard';
// import { registerKeyboardEvents } from '../../services/control';

const ChatsModule = observer(() => {
  const { chatsModule } = useStores();

  const {
    flexDirectionRow,
    flexDirectionColumn,
    flexJustifySpaceBetween,
    flexAlignCenter,
    flexJustifyStart,
  } = useGlobalStyles();

  const { chatsModuleContainer, selectedChatContainer } = useStyles();

  // if (user.chat.isOpenChat) {
  //   console.log('chat registering keyboard events');
  //   registerKeyboardEvents();
  // } else {
  //   console.log('chat deregistering keyboard events');
  //   unregisterKeyboardEvents();
  // }

  return (
    <div
      className={classnames(
        flexDirectionRow,
        flexAlignCenter,
        flexJustifyStart,
        chatsModuleContainer
      )}
    >
      <ChatsList />
      {chatsModule.selectedChat && (
        <div
          className={classnames(
            flexDirectionColumn,
            flexAlignCenter,
            flexJustifySpaceBetween,
            selectedChatContainer
          )}
        >
          <ChatMessagesList chat={chatsModule.selectedChat} />
          <ChatInput />
        </div>
      )}
    </div>
  );
});

export default ChatsModule;
