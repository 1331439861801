import React, { ReactNode, FC, useRef, useEffect, useState } from 'react';
import cn from 'classnames';
import useStyles from './style';

type ScrollbarProps = {
  height?: number;
  children?: ReactNode;
  wrapClassName?: string;
  className?: string;
  setRef?: (ref: any) => void;
};

export type ScrollbarStyle = {
  width: number;
  height: number;
};

const Scrollbar: FC<ScrollbarProps> = ({
  height,
  children,
  wrapClassName,
  className,
  setRef,
}: ScrollbarProps) => {
  const minThumbHeight = 24;
  const scrollableContentRef = useRef<any>();
  const timerIdRef = useRef<any>(0);
  const [_height, setHeight] = useState<number>(0);
  const [sliderMax, setSliderMax] = useState<number>(0);
  const [thumbSize, setThumbSize] = useState<number>(0);
  const [sliderPosition, setSliderPosition] = useState<number>(0);
  const [isScrollbarNeed, setIsScrollbarNeed] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const s = useStyles({ width: thumbSize, height } as ScrollbarStyle);

  const hideScroll = () => {
    if (timerIdRef.current > 0) return;
    timerIdRef.current = setTimeout(() => {
      timerIdRef.current = 0;
      window.requestAnimationFrame(() => setIsScrolled(false));
    }, 3000);
  };

  const onScroll = () => {
    setIsScrolled(true);
    if (!isMouseOver) hideScroll();
    window.requestAnimationFrame(() => {
      setSliderPosition(scrollableContentRef?.current?.scrollTop);
    });
  };

  const onChangeSlider = (event: any) => {
    setSliderPosition(event.target.value);
    scrollableContentRef.current.scrollTo(0, event.target.value);
  };

  const onMouseOver = () => setIsMouseOver(true);

  const onMouseOut = () => {
    setIsMouseOver(false);
    hideScroll();
  };

  useEffect(() => {
    const scrollHeight = scrollableContentRef.current?.scrollHeight;
    const partCount = scrollHeight / _height;
    if (!height) {
      setHeight(scrollableContentRef.current?.clientHeight);
    }
    if (scrollableContentRef.current && setRef) {
      setRef(scrollableContentRef.current);
    }
    if (scrollHeight <= _height) {
      return setIsScrollbarNeed(false);
    }
    setIsScrollbarNeed(true);
    const thumbHeight = _height / partCount;
    setThumbSize(thumbHeight < minThumbHeight ? minThumbHeight : thumbHeight);
    setSliderMax(scrollHeight - _height);
  }, [
    height,
    _height,
    setRef,
    children,
    scrollableContentRef,
    scrollableContentRef.current?.scrollHeight,
  ]);

  return (
    <div className={cn(s.root, wrapClassName && wrapClassName)}>
      <div className={s.scrollableContentWrap}>
        <div
          ref={scrollableContentRef}
          className={cn(s.scrollableContent, className && className)}
          onScroll={onScroll}
        >
          {children}
        </div>
      </div>
      <div className={s.sliderWrap} style={{ height: _height }}>
        {
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <input
            type="range"
            min="0"
            className={s.slider}
            max={sliderMax}
            value={sliderPosition}
            style={{
              width: _height - 4,
              opacity: isScrollbarNeed && (isScrolled || isMouseOver) ? 1 : 0,
              transformOrigin: `${_height / 2}px ${_height / 2}px`,
            }}
            onChange={onChangeSlider}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
          />
        }
      </div>
    </div>
  );
};

export default Scrollbar;
