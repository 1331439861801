import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  timerWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '7rem',
    '& p': {
      margin: 0,
      fontSize: '1rem',
      lineHeight: '1.375rem',
      letterSpacing: '0.045em',
      fontWeight: 'bold',
    },
  },
  timerContainer: {
    boxSizing: 'content-box',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '.25rem',
    border: '.125rem solid transparent',
    '&:focus': {
      border: '.125rem solid #a8cef5ba',
      borderRadius: '.5rem',
    },
  },
  timerContainerEdit: {
    border: '.125rem solid #a8cef5ba',
    borderRadius: '.5rem',
  },
  timerSection: {
    position: 'relative',
    fontWeight: 'bolder',
    fontSize: '1.5rem',
    color: '#F1F1F1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p:first-child': {
      fontSize: '1.5rem',
      fontWeight: 800,
    },
  },
  backlight: {
    width: '1.5rem',
    height: '0.1rem',
    borderRadius: '8px',
    backgroundColor: 'transperent',
    marginTop: '0.3rem',
    marginBottom: '0.3rem',
  },
  backlightActive: {
    backgroundColor: '#FFFFFF',
  },
}));

export default useStyles;
