import React, { ChangeEvent, useEffect, useState } from 'react';
import useStyles from './style';
import { MIN_VOICE_VOLUME, MAX_VOICE_VOLUME } from '../../constants/eventsManagement/voice';

type ModeratorVolumeControlType = {
  volume: number;
  setVolume: any;
};

const ModeratorVolumeControl = ({ volume, setVolume }: ModeratorVolumeControlType) => {
  const [value, setValue] = useState(0);
  const [isShowThumb, setIsShowThumb] = useState(false);
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsShowThumb(true);
    setValue(Number(value));
    setVolume(Number(value));
  };

  useEffect(() => {
    setValue(volume);
  }, [volume]);

  const { container, volumeClass } = useStyles({ isShowThumb, value })();
  return (
    <div className={container}>
      <input
        type="range"
        className={volumeClass}
        min={MIN_VOICE_VOLUME}
        max={MAX_VOICE_VOLUME}
        defaultValue={value}
        onChange={handleChange}
      />
      <div></div>
    </div>
  );
};

export default ModeratorVolumeControl;
