import { Instance, types } from 'mobx-state-tree';

export const MediaModel = types
  .model('media')
  .props({
    channel: types.maybeNull(types.number),
    width: types.maybe(types.number),
    height: types.maybe(types.number),
  })
  .volatile(() => ({
    stream: null as MediaStream | null,
  }));

export const WbsModel = types
  .model('wbs')
  .props({
    domain: types.maybeNull(types.string),
    local: MediaModel,
    screen: MediaModel,
    re: MediaModel,
  })
  .views((self) => ({
    get msDomain() {
      return self.domain;
    },
    get localStreamChannel() {
      return self.local.channel;
    },
    get screenStreamChannel() {
      return self.screen.channel;
    },
    get screenWidth() {
      return self.screen.width;
    },
    get screenHeight() {
      return self.screen.height;
    },
    get localStream() {
      return self.local.stream;
    },
    get screenStream() {
      return self.screen.stream;
    },
  }))
  .actions((self) => ({
    getMsDomain() {
      return self.domain;
    },
    setMsDomain(msDomain: string | null) {
      self.domain = msDomain;
    },
    getLocalChannel() {
      return self.local.channel;
    },
    setLocalChannel(channel: number | null) {
      self.local.channel = channel;
    },
    setScreenChannel(channel: number | null) {
      self.screen.channel = channel;
    },
    setScreenWidth(width: number) {
      self.screen.width = width;
    },
    setScreenHeight(height: number) {
      self.screen.height = height;
    },
    setReChannel(channel: number) {
      self.re.channel = channel;
    },
    setLocalStream(stream: MediaStream | null) {
      self.local.stream = stream;
    },
    setScreenStream(stream: MediaStream | null) {
      self.screen.stream = stream;
    },
    stopTracks(stream: MediaStream) {
      stream.getTracks().forEach((track) => track.stop());
    },
    setIsEnabledVideoTrack(stream: MediaStream, isEnabled: boolean) {
      stream.getVideoTracks()[0].enabled = isEnabled;
    },
  }));

type WbsModelType = Instance<typeof WbsModel>;
export interface WbsType extends WbsModelType {}
