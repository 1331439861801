import {
  connectToWbs,
  onClientTravel,
  screenSharing,
  restreamToWbs,
  updateMediaScreensState,
  onLogMessage,
  onReceiveMessage,
  updateUserInterfaceState,
  updateEnvironmentConfig,
  updateCustomizationState,
  updateCountDownSeconds,
  updateLiveIndicatorVisibility,
  requestSplashScreen,
  onUpdateUsersInSpace,
  onUpdateRemoteUserInfo,
  onUpdateLocalUserInfo,
  updateBackgroundChatVisibility,
  onWebRequestReply,
  onUserBanned,
  updateNearestStand,
} from './events';
import { emitUserRegistrationData } from '../emitsToGame/emits';
import { resetPlayer } from '../../playerState';
import { logGame } from '@pxs-infra/logger';

export function getEventsFromGame(data: any) {
  const json = JSON.parse(data);
  logGame('Received data from game', json);
  if ('requestConnectToWbs' in json) {
    connectToWbs(json.requestConnectToWbs);
  } else if ('connectToWbs' in json) {
    connectToWbs(json.connectToWbs);
  } else if ('onClientTravel' in json) {
    onClientTravel(json.onClientTravel);
  } else if ('onReceiveMessage' in json) {
    onReceiveMessage(json);
  } else if ('requestScreenSharing' in json) {
    screenSharing(json.requestScreenSharing);
  } else if ('updateChatSettings' in json) {
    // TODO: empty
  } else if ('updateDeltaUsers' in json) {
    // TODO: empty
  } else if ('requestRegistration' in json) {
    // TODO: empty
  } else if ('onAuthAvaliability' in json) {
    emitUserRegistrationData();
  } else if ('updateUserInterfaceState' in json) {
    updateUserInterfaceState(json);
  } else if ('updateEnvironmentConfig' in json) {
    updateEnvironmentConfig(json.updateEnvironmentConfig);
  } else if ('requestRestreamToWbs' in json) {
    restreamToWbs(json.requestRestreamToWbs);
  } else if ('requestWebClientDisonnect' in json) {
    resetPlayer();
  } else if ('updateCustomizationState' in json) {
    updateCustomizationState(json.updateCustomizationState);
  } else if ('updateUsersInSpace' in json) {
    onUpdateUsersInSpace(json);
  } else if ('updateRemoteUserInfo' in json) {
    onUpdateRemoteUserInfo(json);
  } else if ('updateCountDownSeconds' in json) {
    updateCountDownSeconds(json.updateCountDownSeconds);
  } else if ('updateLocalUserInfo' in json) {
    onUpdateLocalUserInfo(json);
  } else if ('updateLiveIndicatorVisibility' in json) {
    updateLiveIndicatorVisibility(json.updateLiveIndicatorVisibility);
  } else if ('requestSplashScreen' in json) {
    requestSplashScreen(json.requestSplashScreen);
  } else if ('updateMediaScreensState' in json) {
    updateMediaScreensState(json);
  } else if ('updateBackgroundChatVisibility' in json) {
    updateBackgroundChatVisibility(json);
  } else if ('onLogMessage' in json) {
    onLogMessage(json.onLogMessage);
  } else if ('onWebRequestReply' in json) {
    onWebRequestReply(json.onWebRequestReply);
  } else if ('onUserBanned' in json) {
    onUserBanned();
  } else if ('updateNearestStand' in json) {
    updateNearestStand(json);
  }
}
