import { Instance, types } from 'mobx-state-tree';

export const ModalModel = types
  .model('modals')
  .props({
    isOpenRequestPersonalShareModal: types.boolean,
    isOpenRequestScreenShareModal: types.boolean,
    isOpenAfkModal: types.boolean,
    isOpenBanModal: types.boolean,
  })
  .actions((self) => ({
    setIsOpenRequestPersonalShareModal(isOpenRequestPersonalShareModal: boolean) {
      self.isOpenRequestPersonalShareModal = isOpenRequestPersonalShareModal;
    },
    setIsOpenRequestScreenShareModal(isOpenRequestScreenShareModal: boolean) {
      self.isOpenRequestScreenShareModal = isOpenRequestScreenShareModal;
    },
    setIsOpenAfkModal(isOpenAfkModal: boolean) {
      self.isOpenAfkModal = isOpenAfkModal;
    },
    setIsOpenBanModal(isOpenBanModal: boolean) {
      self.isOpenBanModal = isOpenBanModal;
    },
  }));

type ModalModelType = Instance<typeof ModalModel>;
export interface ModalType extends ModalModelType {}
