import React from 'react';
import useStyles from './style';

type ArrowDownIconProps = {
  color?: string;
  background?: string;
};

const ArrowDownIcon = ({ background = 'black', color = 'white' }: ArrowDownIconProps) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      x="48"
      y="14"
    >
      <mask
        id="mask0_843_81098"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="3"
        y="6"
        width="14"
        height="7"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.64018 6.23178C4.21591 5.87821 3.58534 5.93554 3.23178 6.35982C2.87821 6.78409 2.93554 7.41466 3.35982 7.76822L4.64018 6.23178ZM10 12L9.35982 12.7682C9.73066 13.0773 10.2693 13.0773 10.6402 12.7682L10 12ZM16.6402 7.76822C17.0645 7.41466 17.1218 6.78409 16.7682 6.35982C16.4147 5.93554 15.7841 5.87821 15.3598 6.23178L16.6402 7.76822ZM3.35982 7.76822L9.35982 12.7682L10.6402 11.2318L4.64018 6.23178L3.35982 7.76822ZM10.6402 12.7682L16.6402 7.76822L15.3598 6.23178L9.35982 11.2318L10.6402 12.7682Z"
          fill={background}
        />
      </mask>
      <g mask="url(#mask0_843_81098)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default ArrowDownIcon;
