import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#FFFFFF',
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  loaderText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '44px',
    textAlign: 'center',
    color: 'black',
    paddingTop: '40px',
    whiteSpace: 'pre',
    [theme.breakpoints.down('md')]: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
      paddingTop: '12px',
    },
  },
  loaderAccentText: {
    color: '#A24DE8',
  },
  loaderProgressBarContainer: {
    color: '#A24DE8',
    width: '100%',
    display: 'flex',
  },
  loaderImg: {
    display: 'flex',
    justifyContent: 'center',
  },
  loaderImgText: {
    maxWidth: '700px',
    paddingTop: '18px',
  },
}));

export default useStyles;
