import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  rotateOverlay: {
    background: 'rgba(20, 14, 23, 0.5)',
    backdropFilter: 'blur(16px)',
    position: 'fixed',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 auto',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 100,
    overflowX: 'hidden',
    pointerEvents: 'auto',
  },
  rotateIcon: {
    display: 'flex',
    paddingBottom: '16px',
  },
  rotateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '0 60px',
  },
  rotateText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
});

export default useStyles;
