import React from 'react';
import useStyles from './style';

const SuccessIcon = () => {
  const { maskSvg } = useStyles();
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1661_297740"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM14.8784 8.20241C15.2664 7.80931 15.2622 7.17616 14.8691 6.78823C14.476 6.4003 13.8428 6.40449 13.4549 6.79759L9.24498 11.0636L9.04922 10.8582L7.39058 9.1178C7.00956 8.71799 6.37658 8.70276 5.97677 9.08378C5.57697 9.4648 5.56174 10.0978 5.94276 10.4976L7.6014 12.238L8.24855 12.9171L8.43939 13.1173L8.49074 13.1712L8.50403 13.1852L8.50741 13.1887L8.50826 13.1896L8.50847 13.1898L8.50853 13.1899L9.23246 12.5L8.50854 13.1899C8.69519 13.3857 8.95321 13.4976 9.22374 13.5C9.49426 13.5023 9.7542 13.395 9.94423 13.2024L14.8784 8.20241Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_1661_297740)">
        <rect width="20" height="20" fill="#51B267" />
      </g>
    </svg>
  );
};

export default SuccessIcon;
