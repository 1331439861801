import { Instance, types } from 'mobx-state-tree';

export const MySettingModel = types
  .model('mySetting')
  .props({
    isShowAudio: types.boolean,
    isShowVideo: types.boolean,
    isMutedAudio: types.boolean,
    isMutedVideo: types.boolean,
    isShowPersonalShare: types.boolean,
    isFullScreen: types.boolean,
    myAudioDeviceLabel: types.string,
    myVideoDeviceLabel: types.string,
    myAudioDeviceId: types.maybeNull(types.string),
    myVideoDeviceId: types.maybeNull(types.string),
    myQuality: types.number,
    testMicVolume: types.number,
    isDevicesPermissionDenied: types.boolean,
  })
  .actions((self) => ({
    setTestMicVolume(volume: number) {
      self.testMicVolume = volume;
    },
    getMyAudioDeviceLabel() {
      return self.myAudioDeviceLabel;
    },
    setMyAudioDeviceLabel(myAudioDeviceLabel: string) {
      self.myAudioDeviceLabel = myAudioDeviceLabel;
    },

    getMyAudioDeviceId() {
      return self.myAudioDeviceId;
    },
    setMyAudioDeviceId(myAudioDeviceId: string) {
      self.myAudioDeviceId = myAudioDeviceId;
    },

    getMyVideoDeviceId() {
      return self.myVideoDeviceId;
    },
    setMyVideoDeviceId(myVideoDeviceId: string) {
      self.myVideoDeviceId = myVideoDeviceId;
    },

    getMyQuality() {
      return self.myQuality;
    },
    setMyQuality(myQuality: number) {
      self.myQuality = myQuality;
    },

    getMyVideoDeviceLabel() {
      return self.myVideoDeviceLabel;
    },
    setMyVideoDeviceLabel(myVideoDeviceLabel: string) {
      self.myVideoDeviceLabel = myVideoDeviceLabel;
    },

    getIsShowVideo() {
      return self.isShowVideo;
    },
    setIsShowVideo(isShowVideo: boolean) {
      self.isShowVideo = isShowVideo;
    },
    getIsShowAudio() {
      return self.isShowAudio;
    },
    setIsShowAudio(isShowAudio: boolean) {
      self.isShowAudio = isShowAudio;
    },
    setIsShowPersonalShare(isShowPersonalShare: boolean) {
      self.isShowPersonalShare = isShowPersonalShare;
    },
    setIsMutedAudio(isMutedAudio: boolean) {
      self.isMutedAudio = isMutedAudio;
    },
    setIsMutedVideo(isMutedVideo: boolean) {
      self.isMutedVideo = isMutedVideo;
    },
    setIsFullScreen(isFullScreen: boolean) {
      self.isFullScreen = isFullScreen;
    },
    setIsDevicesPermissionDenied(isDevicesPermissionDenied: boolean) {
      self.isDevicesPermissionDenied = isDevicesPermissionDenied;
    },
  }));

type MySettingModelType = Instance<typeof MySettingModel>;
// eslint-disable-next-line prettier/prettier
export interface MySettingType extends MySettingModelType {}