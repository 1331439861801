import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints: { values: { height_ipad_pro_11 } } }: Theme) => ({
  visibleHidden: {
    visibility: 'hidden',
  },
  stopOrShowShareWrap: {
    position: 'relative',
  },
  localVideoFrame: {
    width: '200px',
    height: '200px',
    top: 40,
    left: 40,
    display: 'block',
    position: 'absolute',
    background: 'transparent',
    [`@media screen and (max-width: ${height_ipad_pro_11}px)`]: {
      top: 10,
      right: 10,
    },
  },
  localVideo: {
    position: 'relative',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    pointerEvents: 'none',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  displayNone: {
    display: 'none',
  },
}));

export default useStyles;
