import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    width: '18.625rem',
    display: 'flex',
  },
  streamStatusSelector: {
    backgroundColor: '#262626',
    opacity: '0.9',
    overflow: 'hidden',
    borderRadius: '.825rem',
    width: '18.625rem',
  },
  streamStatusSelectorList: {
    '&.MuiList-padding': {
      padding: 0,
    },
  },
  streamStatusCollapse: {
    backgroundColor: '#262626',
    opacity: '0.9',
    fontSize: '1rem',
  },
  streamStatusButton: {
    marginLeft: '1.25rem',
    color: '#262626',
    backgroundColor: '#F1F1F1',
    borderRadius: '1.25rem',
    height: '3rem',
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: '#F1F1F1',
      opacity: '0.8',
    },
  },
}));

export default useStyles;
