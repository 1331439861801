import React, { useState } from 'react';
import { Modal, CloseIcon, LogoutIcon, LogoutModal } from '../..';
import useStyles from './style';
import { useStores } from '../../../hooks/useStores';
import checkboxImg from './checkbox.svg';
import { handleChangeMyVideoDevice, handleChangeMyAudioDevice } from '../../../helpers/device';
import { observer } from 'mobx-react-lite';

type MobileSettingModalType = {
  isShow: boolean;
  handleClose: any;
  handleUpdateLocalStream: any;
};

type SettingType = {
  id: number;
  name: string;
  devices: any;
  currentDeviceId: string | null;
  onClick: any;
};

const MobileSettingModal = observer(
  ({ isShow, handleClose, handleUpdateLocalStream }: MobileSettingModalType) => {
    const {
      modalClass,
      modalWrapClass,
      container,
      logoutWrapper,
      logoutIcon,
      logoutText,
      deviceIconWrapper,
      deviceWrapper,
      closeIconWrapper,
    } = useStyles();
    const {
      allSettings: { videoDevices, audioDevices },
      mySetting: { myAudioDeviceId, myVideoDeviceId },
    } = useStores();

    const [isShowLogoutModal, setIsShowLogoutModal] = useState(false);

    const handleLogoutClose = () => {
      setIsShowLogoutModal(false);
    };

    const handleLogout = () => {
      handleClose();
      setIsShowLogoutModal(!isShowLogoutModal);
    };

    const settings: SettingType[] = [
      {
        id: 1,
        name: 'camera settings',
        devices: videoDevices,
        currentDeviceId: myVideoDeviceId,
        onClick: handleChangeMyVideoDevice,
      },
      {
        id: 2,
        name: 'microphone settings',
        devices: audioDevices,
        currentDeviceId: myAudioDeviceId,
        onClick: handleChangeMyAudioDevice,
      },
    ];

    return (
      <>
        <Modal
          isOpen={isShow}
          modalClass={modalClass}
          modalWrapClass={modalWrapClass}
          handleClose={handleClose}
        >
          <div className={container}>
            <div className={deviceIconWrapper}>
              <div className={deviceWrapper}>
                {settings.map((setting) => (
                  <MobileSettingItem
                    key={setting.id}
                    setting={setting}
                    handleUpdateLocalStream={handleUpdateLocalStream}
                  />
                ))}
              </div>
              <div onClick={handleClose} className={closeIconWrapper}>
                <CloseIcon />
              </div>
            </div>
            <div className={logoutWrapper} onClick={handleLogout}>
              <div className={logoutIcon}>
                <LogoutIcon />
              </div>
              <div className={logoutText}>Back to the site</div>
            </div>
          </div>
        </Modal>

        <LogoutModal isShow={isShowLogoutModal} handleClose={handleLogoutClose} />
      </>
    );
  }
);

export default MobileSettingModal;

const MobileSettingItem = ({
  setting,
  handleUpdateLocalStream,
}: {
  setting: SettingType;
  handleUpdateLocalStream: any;
}) => {
  const { deviceItem, deviceType, deviceValueWrapper } = useStyles();

  const { name, devices, currentDeviceId, onClick } = setting;
  return (
    <div className={deviceItem}>
      <div className={deviceType}>{name}</div>
      <div className={deviceValueWrapper}>
        {devices?.map((device: any) => (
          <MobileDeviceItem
            key={device.deviceId}
            device={device}
            currentDeviceId={currentDeviceId}
            onClick={() => onClick(device.label, handleUpdateLocalStream)}
          />
        ))}
      </div>
    </div>
  );
};

const MobileDeviceItem = ({
  device,
  currentDeviceId,
  onClick,
}: {
  device: any;
  currentDeviceId: string | null;
  onClick: any;
}) => {
  const {
    deviceNameClass,
    ckeckboxWrapperClass,
    ckeckboxClass,
    deviceAndCheckboxClass,
  } = useStyles();

  const { deviceId, label } = device;
  return (
    <div className={deviceAndCheckboxClass} onClick={onClick}>
      <div className={deviceNameClass}>{label}</div>
      <div className={ckeckboxWrapperClass}>
        {currentDeviceId !== null && currentDeviceId === deviceId && (
          <img className={ckeckboxClass} src={checkboxImg} alt="checkbox" />
        )}
      </div>
    </div>
  );
};
