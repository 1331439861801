import { logWebRtcPlayer } from '@pxs-infra/logger';
import {
  onWebRtcCandidate,
  onVideoInitialised,
  onDataChannelConnected,
  onDataChannelMessage,
  onWebRtcOffer,
  webSocketObject,
} from './webRtcWrapper';

export function webRtcPlayer(parOptions: any) {
  parOptions = parOptions || {};

  let cfg = parOptions.peerConnectionOptions || {};
  cfg.sdpSemantics = 'unified-plan';
  let pcClient: any = null;
  let dcClient: any = null;
  let tnClient: any = null;
  cfg.offerExtmapAllowMixed = false;

  let sdpConstraints = {
    offerToReceiveAudio: 1,
    offerToReceiveVideo: 1,
  };

  let dataChannelOptions = { ordered: true };

  const createWebRtcVideo = () => {
    let video = document.createElement('video') as any;
    video.id = 'streamingVideo';
    video.playsInline = true;
    video.autoplay = true;
    video.controls = false;
    video.disablePictureInPicture = true;
    video.setAttribute('width', '100%');
    video.setAttribute('height', '100%');

    video.addEventListener(
      'loadedmetadata',
      () => {
        onVideoInitialised();
        setInterval(getStats, 5000);
      },
      true
    );
    return video;
  };

  let video = createWebRtcVideo();

  let onsignalingstatechange = function (state: any) {
    const { signalingState } = state.target;
    logWebRtcPlayer('signaling state change: ', signalingState);
  };

  let oniceconnectionstatechange = function (event: any) {
    const { iceConnectionState } = event.target;
    logWebRtcPlayer('ice connection state change: ', iceConnectionState);
    if (
      iceConnectionState === 'disconnected' ||
      iceConnectionState === 'failed' ||
      iceConnectionState === 'closed'
    ) {
      if (!webSocketObject) return;
      webSocketObject.close();
    }
  };

  let onicegatheringstatechange = function (state: any) {
    const { iceGatheringState } = state.target;
    logWebRtcPlayer('ice gathering state change: ', iceGatheringState);
  };

  let handleOnTrack = function (e: any) {
    if (video.srcObject !== e.streams[0]) {
      video.srcObject = e.streams[0];
    }
  };

  let setupDataChannel = function (pc: any, label: any, options: any) {
    try {
      let datachannel = pc.createDataChannel(label, options);
      logWebRtcPlayer(`Created datachannel (${label})`);

      datachannel.onopen = function () {
        console.log(`Data channel (${label}) connect`);
        if (onDataChannelConnected) {
          onDataChannelConnected();
        }
      };

      datachannel.onclose = function () {
        console.log(`Data channel (${label}) closed`);
        webSocketObject?.close();
      };

      datachannel.onmessage = function (e: any) {
        if (onDataChannelMessage) {
          if (e.data instanceof Blob) {
            e.data.arrayBuffer().then((buffer: any) => {
              onDataChannelMessage(buffer);
            });
          } else {
            onDataChannelMessage(e.data);
          }
        }
      };

      datachannel.onclose = function () {
        console.log(`Data channel (${label}) closed`);
        if (!webSocketObject) return;
        webSocketObject.close();
      };

      return datachannel;
    } catch (e: any) {
      console.warn(`Error creating datachannel ${e.message}`);
      return null;
    }
  };

  let onicecandidate = function (e: any) {
    const { candidate } = e;
    logWebRtcPlayer('ice candidate: ', candidate);
    if (candidate && candidate.candidate) {
      onWebRtcCandidate(e.candidate);
    }
  };

  const replaceLocalSdp = (offer: any) => {
    // (andriy): increase start bitrate from 300 kbps to 20 mbps and max bitrate from 2.5 mbps to 100 mbps
    // (100 mbps means we don't restrict encoder at all)
    // after we `setLocalDescription` because other browsers are not c happy to see google-specific config
    offer.sdp = offer.sdp.replace(
      /(a=fmtp:\d+ .*level-asymmetry-allowed=.*)\r\n/gm,
      '$1;x-google-start-bitrate=10000;x-google-max-bitrate=20000\r\n'
    );
    offer.sdp = offer.sdp.replace(/(a=extmap-allow-mixed)\r\n/gm, '');
    return offer;
  };

  let handleCreateOffer = function (pc: any) {
    pc.createOffer(sdpConstraints).then(
      function (offer: any) {
        pc.setLocalDescription(offer);
        if (onWebRtcOffer) {
          offer = replaceLocalSdp(offer);
          onWebRtcOffer(offer);
        }
      },
      function () {
        console.log("Couldn't create offer");
      }
    );
  };

  let setupPeerConnection = function (pc: any) {
    if (pc.SetBitrate) console.log("Hurray! there's RTCPeerConnection.SetBitrate function");

    //Setup peerConnection events
    pc.onsignalingstatechange = onsignalingstatechange;
    pc.oniceconnectionstatechange = oniceconnectionstatechange;
    pc.onicegatheringstatechange = onicegatheringstatechange;

    pc.ontrack = handleOnTrack;
    pc.onicecandidate = onicecandidate;
  };

  //This is called when revceiving new ice candidates individually instead of part of the offer
  //This is currently not used but would be called externally from this class
  const handleCandidateFromServer = function (iceCandidate: any) {
    console.log('ice candidate: ', iceCandidate);
    let candidate = new RTCIceCandidate(iceCandidate);
    pcClient.addIceCandidate(candidate).then(() => {
      console.log('ice candidate successfully added');
    });
  };

  //Called externaly to create an offer for the server
  const createOffer = function () {
    if (pcClient) {
      console.log('Closing existing PeerConnection to create new offer');
      pcClient.close();
      pcClient = null;
    }
    pcClient = new RTCPeerConnection(cfg);
    setupPeerConnection(pcClient);
    dcClient = setupDataChannel(pcClient, 'cirrus', dataChannelOptions);
    handleCreateOffer(pcClient);
  };

  //Called externaly when an answer is received from the server
  const receiveAnswer = function (answer: any) {
    console.log(`Received answer:\n`, answer);
    let answerDesc = new RTCSessionDescription(answer);
    pcClient.setRemoteDescription(answerDesc);
  };

  const close = function () {
    if (pcClient) {
      console.log('Closing existing peerClient');
      pcClient.close();
      pcClient = null;
    }
  };

  //Sends data across the datachannel
  const send = function (data: any) {
    if (dcClient && dcClient.readyState == 'open') {
      dcClient.send(data);
    }
  };

  const getStats = function () {
    if (!pcClient) {
      console.log('no pcClient present');
      return;
    }
    pcClient.getStats().then((stats: any) => {
      let statsOutput: any = [];

      stats.forEach((report: any) => {
        Object.keys(report).forEach((statName) => {
          if (statName !== 'id' && statName !== 'timestamp' && statName !== 'type') {
            statsOutput.push({ [statName]: report[statName] });
          }
        });
      });

      console.log('receive stats=', statsOutput);
    });
  };

  return {
    cfg,
    pcClient,
    dcClient,
    tnClient,
    sdpConstraints,
    video,
    dataChannelOptions,
    handleCandidateFromServer,
    createOffer,
    setupPeerConnection,
    receiveAnswer,
    close,
    send,
  };
}
