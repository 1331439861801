import { DeviceInfoType } from '../../../../types/wbs';
import { validateSchema } from '../../ajv';
import { deviceInfoSchema } from '../../ajv/schema';

export const deviceInfo = (json: DeviceInfoType) => {
  console.log('deviceInfo=', json);
  validateSchema(deviceInfoSchema, json);
  // const { status, device_states: devicesStates } = json;

  // const devices = devicesStates.map(({ state, device_id, sinks, sources }) => {

  //   if(sinks) {

  //   }

  //   return{state, device_id, sinks, sources}
  // });
};
