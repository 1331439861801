import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: '6px',
  },
  checkmark: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    paddingLeft: '10px',
  },
  input: {
    '-webkit-appearance': 'none',
    appearance: 'none',
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    border: '2px solid #A2A2A2',
    borderRadius: '50%',
    display: 'flex',
    '&:checked': {
      border: '2px solid #A24DE8',
    },
    '&:checked::before': {
      transform: 'scale(1)',
    },
    '&::before': {
      content: '""',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      transform: 'scale(0)',
      transition: '120ms transform ease-in-out',
      backgroundColor: '#140E17',
    },
  },
  titleClass: {
    color: '#140E17',
  },
  descClass: {
    color: '#A2A2A2',
    paddingTop: '2px',
  },
}));

export default useStyles;
