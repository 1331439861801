import React from 'react';
import useStyles from './style';

type ArrowLeftIconProps = {
  color?: string;
};

const ArrowLeftIcon = ({ color = 'white' }: ArrowLeftIconProps) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      color={color}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1744_83845"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="7"
        y="4"
        width="6"
        height="12"
      >
        <path
          d="M12 5L8 10L12 15"
          stroke="#140E17"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_1744_83845)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default ArrowLeftIcon;
