import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  st1: { fillRule: 'evenodd', clipRule: 'evenodd' },
  svgClass: {
    enableBackground: 'new 0 0 48 48',
  },
});

export default useStyles;
