import { observer } from 'mobx-react-lite';
import React from 'react';
import classnames from 'classnames';
import useStyles from './style';
import { GENERIC_AVATAR_COLORS } from '../../constants/chats';

const GenericAvatar = observer(({ username, className }: { username: string; className: any }) => {
  const { genericAvatarContainer } = useStyles();

  const usernameWords = username.split(' ');

  let textByUsername = '';
  for (const word of usernameWords) {
    if (textByUsername.length < 2) {
      textByUsername += word.substr(0, 1).toUpperCase();
    }
  }

  const getBackgroundColorFromPresetsByUsername = (username: string): string => {
    let charCodesSum: number = 0;

    for (let i = 0; i < username.length; i++) {
      charCodesSum += username.charCodeAt(i);
    }

    return charCodesSum === 0
      ? GENERIC_AVATAR_COLORS[0]
      : GENERIC_AVATAR_COLORS[charCodesSum % GENERIC_AVATAR_COLORS.length];
  };

  const backgroundColorByUsername = getBackgroundColorFromPresetsByUsername(username);

  return (
    <span
      className={classnames(genericAvatarContainer, className)}
      style={{ backgroundColor: backgroundColorByUsername }}
    >
      {textByUsername}
    </span>
  );
});

export default GenericAvatar;
