import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { breakpoints } from './common';

const theme = createMuiTheme({
  breakpoints,
  typography: {
    fontFamily: 'Outfit, sans-serif',
  },
  palette: {
    type: 'dark',
    primary: {
      // Цвет иконки
      main: '#7E7E7E',
      // Текст селектора
      light: '#7E7E7E',
      // Текст шапки + крест
      dark: '#F1F1F1',
    },
    secondary: {
      // Цвет выделения
      main: '#F1F1F1',
      contrastText: '#D63A3A',
    },
    background: {
      // Основной фон
      default: '#F3F3F3',
      // Фон шапки
      paper: '#4F4F4F',
    },
  },
});

export default theme;
