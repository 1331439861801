import { INFO_LEVEL, logWbs } from '@pxs-infra/logger';
import { STREAM_TYPE_LOCAL, STREAM_TYPE_SCREEN } from '../../../constants/wbs';
// import { wbsInfo } from '../socket';

let socket: WebSocket | null = null;
let localPc: RTCPeerConnection | null = null;
let screenPc: RTCPeerConnection | null = null;

let intervalIdCheckConnection: NodeJS.Timeout | null = null;
let intervalIdCheckChannel: NodeJS.Timeout | null = null;

let isChangingGameServer: boolean = false;
let isChangingMS: boolean = false;

export const setIsChangingGameServer = (bIsChangingGameServer: boolean) => {
  if (isChangingGameServer != bIsChangingGameServer) {
    isChangingGameServer = bIsChangingGameServer;
    logWbs(INFO_LEVEL, `isChangingMS: ${isChangingGameServer}`);
  }
};

export const getIsChangingGameServer = () => {
  return isChangingGameServer;
};

export const setIsChangingMS = (bIsChangingMS: boolean) => {
  if (isChangingMS != bIsChangingMS) {
    isChangingMS = bIsChangingMS;
    logWbs(INFO_LEVEL, `isChangingMS: ${isChangingMS}`);
  }
};

export const getIsChangingMS = () => {
  return isChangingMS;
};

export const getSocket = () => {
  return socket;
};

export const setSocket = (_socket: WebSocket | null) => {
  socket = _socket;
};

export const getLocalPc = () => {
  return localPc;
};

export const getScreenPc = () => {
  return screenPc;
};

export const setLocalPc = (pc: RTCPeerConnection | null) => {
  localPc = pc;
};

export const setScreenPc = (pc: RTCPeerConnection | null) => {
  screenPc = pc;
};

export const setIntervalIdCheckConnection = (handle: any, timeout = 60000) => {
  intervalIdCheckConnection = setInterval(handle, timeout);
};

export const clearIntervalIdCheckConnection = () => {
  if (intervalIdCheckConnection !== null) {
    clearInterval(intervalIdCheckConnection);
  }
};

export const setIntervalIdCheckChannel = (handle: any, timeout = 120000) => {
  intervalIdCheckChannel = setInterval(handle, timeout);
};

export const clearIntervalIdCheckChannel = () => {
  if (intervalIdCheckChannel !== null) {
    clearInterval(intervalIdCheckChannel);
  }
};

export const getPcByStreamType = (streamType: string) => {
  let pc: RTCPeerConnection | null = null;
  if (streamType === STREAM_TYPE_LOCAL) {
    pc = getLocalPc();
  } else if (streamType === STREAM_TYPE_SCREEN) {
    pc = getScreenPc();
  }
  return pc;
};

export const setPcByStreamType = (streamType: string, pc: RTCPeerConnection | null) => {
  if (streamType === STREAM_TYPE_LOCAL) {
    setLocalPc(pc);
  } else if (streamType === STREAM_TYPE_SCREEN) {
    setScreenPc(pc);
  }
};
