import React, { useCallback, useState } from 'react';
import { ShareIcon, ArrowDownIcon, MenuItem, MenuItemIcon, ScreenShareDisabledModal } from '../..';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import { existVideoDevice } from '../../../helpers/device';
import { useStores } from '../../../hooks/useStores';
import * as ApiWrapper from '../../../services/apiWrapper';
import {
  closeScreenChannel,
  setStreamToRef,
  onEndedStream,
  onFailStream,
  createScreenStream,
} from '../../../helpers/stream';

const ScreenShareMenuItem = observer(() => {
  const { t } = useTranslation();
  const [isScreenShareDisabled, setIsScreenShareDisabled] = useState(false);
  const { container } = useStyles();
  const {
    mySetting: { isShowPersonalShare, setIsShowPersonalShare },
    screenSharingModule: { refPlayerLocalScreenShare: ref },
  } = useStores();

  const handleClose = () => {
    setIsScreenShareDisabled(false);
  };

  const handleChangePersonalShare = useCallback(async () => {
    if (existVideoDevice()) {
      if (!isShowPersonalShare) {
        const width = 300;
        const height = 300;

        createScreenStream({
          ref,
          width,
          height,
          onEndedStream,
          onFailStream,
        });
      } else {
        await closeScreenChannel();
        setStreamToRef(ref, null);
        await ApiWrapper.startSharingScreen('local');
      }
      setIsShowPersonalShare(!isShowPersonalShare);
    } else {
      setIsScreenShareDisabled(true);
    }
  }, [ref, isShowPersonalShare, setIsShowPersonalShare, setIsScreenShareDisabled]);

  return (
    <div onClick={handleChangePersonalShare} className={container}>
      <MenuItem textIcon={t('Screenshare')}>
        <MenuItemIcon>
          <rect width="88" height="48" rx="24" fill="black" fillOpacity={0.2} />
          <ShareIcon />
          <ArrowDownIcon />
        </MenuItemIcon>
      </MenuItem>
      <ScreenShareDisabledModal isShow={isScreenShareDisabled} handleClose={handleClose} />
    </div>
  );
});

export default ScreenShareMenuItem;
