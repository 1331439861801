import { webRtcPlayerObject } from '../webRtcWrapper';
import { registerTouchEvents } from './pad';
import {
  registerLockedMouseEvents,
  registerHoveringMouseEvents,
  registerMouseEnterAndLeaveEvents,
} from './mouse';
import { emitControlMode } from '../game/emitsToGame/emits';
import { rootStore } from '../../models/rootStore';

export function registerInputs() {
  if (!webRtcPlayerObject) {
    return;
  }
  registerMouseEnterAndLeaveEvents();
  registerTouchEvents();
  switchInputType(!rootStore.connection.isOldControlMode);
}

export function switchInputType(isSwitchedInputType: boolean) {
  if (!webRtcPlayerObject) {
    return;
  }

  if (isSwitchedInputType) {
    registerHoveringMouseEvents();
  } else {
    registerLockedMouseEvents();
  }

  emitControlMode(isSwitchedInputType);
}

export { registerKeyboardEvents, unregisterKeyboardEvents } from './keyboard';
export { registerGamePadEvents } from './pad';
export { unLockMouse } from './mouse';
