import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  videoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'none',
    backgroundColor: '#000000',
    zIndex: 100,
  },
  closeIcon: {
    position: 'absolute',
    top: '24px',
    right: '24px',
    zIndex: 100,
  },
  close: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  video: {
    width: '100%',
    height: '100%',
  },
  open: {
    display: 'block',
  },
}));

export default useStyles;
