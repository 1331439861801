import { makeStyles } from '@material-ui/core/styles';

const useStyles = ({ isShowThumb, value }: any) =>
  makeStyles(() => ({
    container: {
      position: 'relative',
    },
    volumeClass: {
      position: 'relative',
      '-webkit-appearance': 'none',
      width: '100px',
      padding: 0,
      float: 'left',
      outline: 'none',

      // thumb
      '&::-webkit-slider-thumb': {
        position: 'absolute',
        left: `${value / 2 - 10}px`,
        '-webkit-appearance': 'none',
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        background: 'url("icons/thumb.svg")',
        margin: '-8px 0 0 0',
        backgroundPositionY: '-2px',
        backgroundPositionX: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: isShowThumb ? 1 : 0,
      },
      '&::-moz-range-thumb': {
        position: 'absolute',
        left: `${value / 2 - 10}px`,
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        background: 'url("icons/thumb.svg")',
        backgroundPositionY: '-2px',
        backgroundPositionX: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: isShowThumb ? 1 : 0,
      },
      '&::-ms-thumb': {
        position: 'absolute',
        left: `${value / 2 - 10}px`,
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        background: 'url("icons/thumb.svg")',
        backgroundPositionY: '-2px',
        backgroundPositionX: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: isShowThumb ? 1 : 0,
      },

      //track
      '&::-webkit-slider-runnable-track': {
        width: '100px',
        height: '8px',
        borderRadius: '6px',
        background: '#EEEEEE',

        backgroundImage: 'linear-gradient(#903CD6, #903CD6)',
        backgroundSize: `${value / 2}% 100%`,
        backgroundRepeat: 'no-repeat',
      },

      '&::-moz-range-track': {
        width: '100px',
        height: '8px',
        borderRadius: '6px',
        background: '#903CD6',
      },
      '&::-moz-range-progress': {
        backgroundColor: '#903CD6',
      },
      '&::-moz-focus-outer': {
        border: 0,
      },
      '&::-ms-track': {
        width: '100px',
        height: '8px',
        borderRadius: '6px',
        background: 'transparent',
        border: 'solid transparent',
        color: 'transparent',
      },
      '&::-ms-fill-lower': {
        backgroundColor: '#903CD6',
      },
      '&::-ms-fill-upper': {
        background: '#903CD6',
      },
    },
  }));

export default useStyles;
