/* eslint no-unused-vars: "off" */
// eslint parser does not see the usages of 'chat' prop in the code below
// (eslint 7.31.0, eslint-plugin-react 7.24.0, MacOS 10.13.6, Node.js 14.7.0, yarn 1.22.4)
// @alexander girsh

import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks/useStores';
import useStyles from './style';
import classnames from 'classnames';
import React from 'react';
import { ChatType } from '../../../models/chats';
import { ChatKinds } from '../../../constants/chats';
import { ParticipantType } from '../../../models/participants';
import { GenericAvatar } from '../../index';
import { useGlobalStyles } from '../../../globalStyles';
import { Button } from '../..';

const ChatsListItem = observer(
  ({
    chat,
    onClick,
    selectable,
  }: {
    chat: ChatType;
    onClick: (chat: ChatType) => any;
    selectable: boolean;
  }) => {
    const {
      chatsListItemContainer,
      chatsListItemTitleContainer,
      coverImageWrapperClass,
      coverImageClass,
      onlineBadgeClass,
      unreadMessagesCounterClass,
    } = useStyles();

    const {
      flexDirectionRow,
      flexAlignCenter,
      flexJustifyCenter,
      flexJustifyStart,
    } = useGlobalStyles();

    const { chatsModule, participantsList } = useStores();

    const collocutorParticipant: ParticipantType | null =
      chat.kind === ChatKinds.DIRECT
        ? participantsList.participants.get(chat.userId as string) || null
        : null;

    const title: string = collocutorParticipant
      ? collocutorParticipant.username
      : (chat.title as string);

    const chatSubtitle: string | null = collocutorParticipant
      ? collocutorParticipant.subtitle
      : null;

    const coverImage: string | null = collocutorParticipant
      ? collocutorParticipant.avatar
      : chat.coverImage;

    const onlineBadgeShouldBeShown: boolean = chat.kind === ChatKinds.DIRECT;
    const avatarShouldBeShown: boolean = Boolean(
      chat.kind === ChatKinds.DIRECT && collocutorParticipant
    );

    return (
      <Button
        className={classnames(
          flexDirectionRow,

          flexJustifyStart,
          flexJustifyCenter,
          chatsListItemContainer,
          chatsModule.selectedChatId === chat.chatId && 'selected',
          selectable && 'selectable'
        )}
        onClick={() => {
          onClick(chat);
        }}
      >
        <div className={classnames(coverImageWrapperClass)}>
          {(() => {
            if (
              coverImage ||
              (avatarShouldBeShown && collocutorParticipant && collocutorParticipant!.avatar)
            ) {
              return (
                <img
                  className={classnames(coverImageClass)}
                  src={coverImage || (collocutorParticipant!.avatar as string)}
                  alt={title as string}
                />
              );
            } else if (avatarShouldBeShown && collocutorParticipant) {
              return (
                <GenericAvatar
                  username={collocutorParticipant.username}
                  className={classnames(coverImageClass)}
                />
              );
            } else {
              return (
                <svg
                  className={classnames(coverImageClass)}
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="17" cy="17" r="17" fill="#F1F1F1" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.1859 12.3204C13.9657 11.25 16.2534 10.7827 18.5246 11.0954C20.7971 11.4082 22.7027 12.4504 23.8925 13.8393C25.0602 15.2025 25.4571 16.7835 25.1676 18.2599C24.8735 19.7599 23.8443 21.2648 22.073 22.3302C20.2931 23.4006 18.0054 23.8679 15.7342 23.5552C13.4618 23.2424 11.5562 22.2002 10.3664 20.8113C9.19859 19.4481 8.80173 17.8671 9.09122 16.3907C9.38533 14.8907 10.4145 13.3858 12.1859 12.3204ZM18.9337 8.1234C16.021 7.72243 13.0375 8.30745 10.6397 9.74956C8.2411 11.1921 6.62567 13.3737 6.14727 15.8135C5.66886 18.2534 6.36645 20.7533 8.08804 22.763C9.80902 24.772 12.4121 26.1262 15.3251 26.5272C18.2379 26.9282 21.2213 26.3432 23.6191 24.901C26.0177 23.4585 27.6332 21.2769 28.1116 18.8371C28.59 16.3972 27.8924 13.8973 26.1708 11.8876C24.4498 9.87861 21.8467 8.52441 18.9337 8.1234ZM12.3391 18.6782C13.0787 18.6782 13.6782 18.0787 13.6782 17.3391C13.6782 16.5996 13.0787 16 12.3391 16C11.5995 16 11 16.5996 11 17.3391C11 18.0787 11.5995 18.6782 12.3391 18.6782ZM18.3391 17.3391C18.3391 18.0787 17.7396 18.6782 17 18.6782C16.2605 18.6782 15.6609 18.0787 15.6609 17.3391C15.6609 16.5996 16.2605 16 17 16C17.7396 16 18.3391 16.5996 18.3391 17.3391ZM21.6609 18.6782C22.4005 18.6782 23 18.0787 23 17.3391C23 16.5996 22.4005 16 21.6609 16C20.9214 16 20.3218 16.5996 20.3218 17.3391C20.3218 18.0787 20.9214 18.6782 21.6609 18.6782Z"
                    fill="#2C2A23"
                  />
                </svg>
              );
            }
          })()}

          {onlineBadgeShouldBeShown && (
            <span
              className={classnames(
                onlineBadgeClass,
                collocutorParticipant?.isOnline ? 'online' : 'offline'
              )}
            />
          )}
        </div>

        <div className={classnames(chatsListItemTitleContainer)}>
          <span className={classnames('title')}>{title}</span>
          {chatSubtitle && <span className={classnames('subtitle')}>{chatSubtitle}</span>}
        </div>

        {chat.unreadMessagesCount !== 0 && (
          <span
            className={classnames(
              flexDirectionRow,
              flexAlignCenter,
              flexJustifyCenter,
              unreadMessagesCounterClass
            )}
          >
            {chat.unreadMessagesCount}
          </span>
        )}
      </Button>
    );
  }
);

export default ChatsListItem;
