import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '7.375rem',
    height: '3rem',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1rem',
    borderRadius: '1.5rem',
    backgroundColor: '#F1F1F1',
    color: '#000000',
    marginTop: '1.5rem',
  },
}));

export default useStyles;
