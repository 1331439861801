import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  modalClass: {
    width: '686px',
    display: 'flex',
    borderRadius: '5px',
    position: 'relative',
    padding: '17px 30px 21px 21px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '500px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '420px',
    },
  },
  modalWrapClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  basketHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '15px',
  },
  basketBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '10px 0',
    borderBottom: '2px solid #EBEFF8',
    borderTop: '2px solid #EBEFF8',
    height: '190px',
    overflowY: 'scroll',
  },
  basketFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '15px',
  },
  basketTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '19px',
    lineHeight: 'normal',
    color: '#3F5CA4',
    fontFamily: 'Outfit',
  },
  basketRemoveAll: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 'normal',
    color: '#5E6A88',
    fontFamily: 'Outfit',
    textDecorationLine: 'underline',
  },
  productContainer: {
    padding: '6px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  productImg: {
    '& img': {
      width: '71px',
      height: '45px',
      borderRadius: '5px',
    },
  },
  productTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: 'normal',
    color: '#3F5CA4',
    fontFamily: 'Outfit',
    paddingLeft: '15px',
  },
  productLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  productCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  productRight: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '15px',
  },
  productMinus: {
    width: '24px',
    height: '24px',
    background: '#EBEFF8',
    borderRadius: '5px',
    position: 'relative',
    '&::after': {
      content: '""',
      width: '6px',
      height: '2px',
      background: '#3F5CA4',
      display: 'flex',
      position: 'absolute',
      top: '50%',
      left: 'calc(50% - 2px)',
    },
  },
  productCount: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: 'normal',
    color: '#727272',
    fontFamily: 'Outfit',
    padding: '0 12px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
  },
  productPlus: {
    width: '24px',
    height: '24px',
    background: '#EBEFF8',
    borderRadius: '5px',
    position: 'relative',
    '&::after': {
      content: '""',
      width: '8px',
      height: '2px',
      background: '#3F5CA4',
      display: 'flex',
      position: 'absolute',
      top: '50%',
      left: 'calc(50% - 3px)',
    },
    '&::before': {
      content: '""',
      width: '2px',
      height: '8px',
      background: '#3F5CA4',
      display: 'flex',
      position: 'absolute',
      top: 'calc(50% - 3px)',
      left: '50%',
    },
  },
  productPrice: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: 'normal',
    color: '#727272',
    fontFamily: 'Outfit',
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
  },
  productDelete: {
    display: 'flex',
    paddingLeft: '5px',
  },
  basketFooterLeft: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '10px',
  },
  basketClose: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: 'normal',
    color: '#5E6A88',
    fontFamily: 'Outfit',
    textDecorationLine: 'underline',
  },
  basketFooterRight: {
    display: 'flex',
    flexDirection: 'column',
  },
  basketTotalWraper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  basketButtonWrapper: {
    display: 'flex',
    paddingTop: '15px',
  },
  basketButton: {
    padding: '5px 59px',
    borderRadius: '5px',
    background: 'linear-gradient(84deg, #3060E4 0%, #00BAF2 51.04%, #E069A8 100%)',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: 'normal',
    color: '#FFF',
    fontFamily: 'Outfit',
  },
  basketCountWrapper: {},
  basketCountTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: 'normal',
    color: '#3F5CA4',
    fontFamily: 'Outfit',
  },
  basketCount: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: 'normal',
    color: '#5E6A88',
    fontFamily: 'Outfit',
    paddingTop: '5px',
  },
  basketTotalPrice: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '25px',
    lineHeight: 'normal',
    color: '#3F5CA4',
    fontFamily: 'Outfit',
  },
}));

export default useStyles;
