import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  screenShareButton: {
    background: 'linear-gradient(90deg, #3061E5 0%, #2674E8 100%)',
    backdropFilter: 'blur(25px)',
    borderRadius: '100px',
    color: 'white',
    height: '48px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    padding: '14px 20px',
    display: 'flex',
    alignItems: 'center',
  },
  screenShareButtonLeftIcon: {
    display: 'flex',
    paddingRight: '8px',
  },
  screenShareButtonRightIcon: {
    display: 'flex',
    paddingLeft: '8px',
  },
  screenShareButtonText: {
    display: 'flex',
    alignItems: 'center',
  },
  screenShareTypeContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 20px rgba(20, 14, 23, 0.08)',
    borderRadius: '8px',
    padding: '8px',
    color: '#140E17',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    width: '240px',
    position: 'absolute',
    bottom: '65px',
  },
  screenShareItem: {
    display: 'flex',
    padding: '8px 12px',
    '&:hover': {
      background: '#E7D1F9',
      borderRadius: '8px',
      '& > div > div > svg': {
        color: '#A24DE8',
      },
    },
  },
  screenShareIcon: {
    paddingRight: '8px',
  },
  screenShareText: {
    display: 'flex',
  },
  screenShareWraper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  screenShareButtonTextWrapper: {
    display: 'flex',
  },
  screenShareButtonTextCount: {
    background: '#FFFFFF',
    borderRadius: '100px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#140E17',
    marginLeft: '8px',
    width: '26px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  screenShareArrowRight: {
    display: 'flex',
    marginLeft: '42px',
  },
  screenShareContainer: {
    width: '280px',
  },
  screenShareSecondDropDownListPosition: {
    bottom: '14px',
    left: '265px',
    zIndex: 1,
  },
}));

export default useStyles;
