import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buyButtonsContainer: {
    flexShrink: 0,
    width: '100vw',
  },
  buyButton: {
    width: '300px',
    height: '45px',
    background: 'linear-gradient(89.88deg, #3060E4 0.1%, #00BAF2 51.05%, #E069A8 99.92%)',
    borderRadius: '15px',
    fontFamily: 'Outfit',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '25px',
    textAlign: 'center',
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    border: '0px',
    padding: '0 15px',
  },
  buyButtonTitle: {
    textWrap: 'nowrap',
    alignSelf: 'flex-start',
    overflow: 'hidden',
    width: '100%',
    '& svg': {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  },
}));

export default useStyles;
