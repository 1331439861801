import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  selectContainer: {
    position: 'absolute',
    bottom: '65px',
    left: '-45px',
    width: '170px',
  },
  container: {
    position: 'relative',
    display: 'flex',
    '& svg:hover > rect': {
      fillOpacity: '0.4',
    },
  },
  tooltipClass: {
    bottom: '130%',
    marginLeft: '-60px',
    color: '#737373',
    width: '200px',
    background: '#FFFFFF',
    textAlign: 'left',
    '&::after': {
      borderColor: '#FFFFFF transparent transparent transparent',
    },
  },
  leftButtonClass: {
    height: '48px',
    width: '48px',
    background: 'linear-gradient(90deg, #3061E5 0%, #3061E5 0%)',
    'border-top-left-radius': '15px',
    'border-bottom-left-radius': '15px',
  },
  rightButtonClass: {
    height: '48px',
    width: '48px',
    background: 'linear-gradient(90deg, #3061E5 0%, #2674E8 100%)',
    'border-top-right-radius': '15px',
    'border-bottom-right-radius': '15px',
  },
}));

export default useStyles;
