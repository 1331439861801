import React from 'react';
import { Select } from './..';
import { useStores } from '../../hooks/useStores';
import { existAudioDevice, handleChangeMyAudioDevice } from '../../helpers/device';

type AudioSelectType = {
  handleUpdateLocalStream: any;
  innerRef: any;
  isShow: boolean;
  setIsShow: any;
};

const AudioSelect = ({ handleUpdateLocalStream, innerRef, isShow, setIsShow }: AudioSelectType) => {
  const {
    allSettings: { audioDevices },
    mySetting: { myAudioDeviceLabel, isShowAudio },
  } = useStores();

  return (
    <>
      <Select
        defaultValue={myAudioDeviceLabel}
        handleChange={(audioDeviceLabel: string) =>
          handleChangeMyAudioDevice(audioDeviceLabel, handleUpdateLocalStream)
        }
        innerRef={innerRef}
        isShow={isShow}
        setIsShow={setIsShow}
        options={audioDevices}
        isDisabled={!isShowAudio || !existAudioDevice()}
      />
    </>
  );
};

export default AudioSelect;
