import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks/useStores';
import useStyles from './style';
import classnames from 'classnames';
import React from 'react';
import { ChatsListSection, CloseIcon } from '../../index';
import useTheme from '@material-ui/core/styles/useTheme';
import { useGlobalStyles } from '../../../globalStyles';
import { Button } from '../..';
import { Roles } from '../../../constants/user';

const ChatsList = observer(() => {
  const { UI, user } = useStores();

  const { chatsListContainer, chatsListHeader, chatListHeaderTitle } = useStyles();

  const { flexDirectionColumn, flexAlignCenter, flexJustifyStart } = useGlobalStyles();

  const { palette } = useTheme();

  const { chatsModule, participantsList } = useStores();

  return (
    <div
      className={classnames(
        flexDirectionColumn,
        flexAlignCenter,
        flexJustifyStart,
        chatsListContainer
      )}
    >
      <div className={classnames(chatsListHeader)}>
        <span className={classnames(chatListHeaderTitle)}>CHAT</span>
        <Button onClick={() => UI.sideMenuArea.toggleOne('chatsModule')}>
          <CloseIcon color={palette.secondary.main} />
        </Button>
      </div>
      <ChatsListSection
        title={'Channels'}
        addChatContextMenuOptions={[]}
        chatsList={chatsModule.publicChatsList}
      />
      <ChatsListSection
        title={'Direct messages'}
        addChatContextMenuOptions={participantsList.onlineParticipants
          .filter(
            (participant) =>
              participant.userId !== user.userId &&
              participant.role !== Roles.SPECTATOR &&
              !chatsModule.directChatsByUserId.has(participant.userId)
          )
          .map((participant) => ({
            title: `+ ${participant.username}`,
            onClick: () => {
              chatsModule.createDirectChat(participant);
            },
          }))}
        chatsList={chatsModule.directChatsList}
      />
    </div>
  );
});

export default ChatsList;
