import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  participantsMediaControlsListContainer: {
    maxHeight: 500,
    minWidth: '18.625rem',
    backgroundColor: 'rgba(38,38,38, 0.9)',
    borderRadius: '1.5rem',
    padding: '1.25rem 1.875rem',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  participantsMediaControlsListHeader: {
    marginBottom: '1.25rem',
    textTransform: 'uppercase',
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  participantsMediaControlsList: {
    maxHeight: '400px',
    paddingRight: '1.25rem',
    borderRadius: '1.25rem',
  },
}));
