import { useState, useEffect, useRef } from 'react';

const useComponentVisible = (condition = false) => {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (isComponentVisible && ref.current && !(ref.current as any).contains(e.target)) {
        if (!condition) {
          setIsComponentVisible(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isComponentVisible, condition]);

  return { ref, isComponentVisible, setIsComponentVisible };
};

export default useComponentVisible;
