import React, { useCallback, useEffect, useRef } from 'react';
import useStyles from './style';
import classnames from 'classnames';

type ModalType = {
  isOpen: boolean;
  children: any;
  isTransparent?: boolean;
  modalClass?: string;
  modalWrapClass?: string;
  handleClose?: any;
};
const Modal = ({
  isOpen,
  children,
  isTransparent = false,
  modalClass,
  modalWrapClass,
  handleClose,
}: ModalType) => {
  const innerRef = useRef(null);
  const { modal, modalWrap } = useStyles(isTransparent);

  const handleClickOutSide = useCallback(
    (event: any) => {
      if (innerRef) {
        const paths = event.path || (event.composedPath && event.composedPath());
        if (innerRef.current && paths) {
          if (isOpen && !paths.includes(innerRef.current) && handleClose) {
            handleClose();
          }
        }
      }
    },
    [innerRef, handleClose, isOpen]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide);

    return () => document.removeEventListener('click', handleClickOutSide);
  }, [handleClickOutSide]);

  return (
    <>
      {isOpen && (
        <div className={classnames(modalWrapClass, modalWrap)}>
          <div className={classnames(modalClass, modal)} ref={innerRef}>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
