import React from 'react';

const ShoppingCartIcon = () => {
  return (
    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.21719 0.820282C7.275 0.989031 6.42188 1.44841 5.76094 2.14684C4.90782 3.03747 4.5 4.09684 4.5 5.43747V5.98591L3.92813 6.00934C3.45938 6.03278 3.29063 6.05622 3.03282 6.15466C2.29219 6.44059 1.72032 7.05934 1.51407 7.80466C1.44375 8.05309 1.37813 9.29528 1.02657 16.65C0.801565 21.3797 0.796878 21.2437 1.09688 21.8765C1.40625 22.5234 2.08594 23.0484 2.80313 23.1984C2.97657 23.2359 4.86563 23.25 9.01407 23.25C15.5813 23.25 15.2859 23.2593 15.8625 22.9547C16.275 22.739 16.6828 22.3172 16.8938 21.8859C17.2031 21.2625 17.1984 21.3984 16.9734 16.65C16.6219 9.29528 16.5563 8.05309 16.4859 7.80466C16.3828 7.42966 16.1813 7.07809 15.9 6.78747C15.375 6.23903 14.9344 6.05153 14.0813 6.01403L13.5141 5.98591L13.4859 5.25466C13.4531 4.19059 13.2516 3.52966 12.7266 2.74216C11.7609 1.28903 9.93282 0.510906 8.21719 0.820282ZM9.63282 2.32028C10.4813 2.49841 11.2688 3.10778 11.6625 3.89059C11.9109 4.38747 12 4.79528 12 5.47028V5.99997H9H6V5.47028C6 4.80466 6.08907 4.39216 6.33282 3.89059C6.92344 2.69528 8.29688 2.03903 9.63282 2.32028ZM14.5125 7.58903C14.775 7.70622 14.9859 7.97809 15.0422 8.26872C15.0703 8.39997 15.2203 11.2968 15.3844 14.7047C15.6281 19.889 15.6656 20.9297 15.6141 21.0937C15.5438 21.3375 15.2484 21.6328 15.0141 21.6984C14.7656 21.7687 3.23438 21.7687 2.98594 21.6984C2.75157 21.6328 2.45625 21.3375 2.38594 21.0937C2.33438 20.9297 2.37188 19.8984 2.61563 14.7C2.77969 11.2968 2.92969 8.39997 2.95782 8.26872C3.01407 7.97809 3.225 7.70622 3.4875 7.58903C3.67032 7.50466 3.92344 7.49997 9 7.49997C14.0766 7.49997 14.3297 7.50466 14.5125 7.58903Z"
        fill="white"
      />
    </svg>
  );
};

export default ShoppingCartIcon;
