import React, { useState, useEffect } from 'react';
import SendMessage from './SendMessage';

type ChatModalType = {
  isShow: boolean;
  handleClose: any;
  chatModalClass?: string;
  chatModalWrapClass?: string;
};
const ChatModal = ({ isShow, handleClose, chatModalClass, chatModalWrapClass }: ChatModalType) => {
  const [screen, setScreen] = useState('send_message');
  const changeScreen = (_screen: string) => {
    setScreen(_screen);
  };

  useEffect(() => {
    setScreen('send_message');
  }, [handleClose]);

  switch (screen) {
    case 'send_message':
    default:
      return (
        <SendMessage
          isShow={isShow}
          handleClose={handleClose}
          chatModalClass={chatModalClass}
          chatModalWrapClass={chatModalWrapClass}
          changeScreen={changeScreen}
        />
      );
    case 'choose_channel':
      return <></>;
  }
};

export default ChatModal;
