import React from 'react';
import useStyles from './style';
import classnames from 'classnames';
import { ParticipantsMediaControlsListItem } from '../index';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { ParticipantType } from '../../models/participants';
import { Roles } from '../../constants/user';
import { Scrollbar } from '..';

const ParticipantsMediaControlsList = observer(() => {
  const {
    participantsMediaControlsListContainer,
    participantsMediaControlsListHeader,
    participantsMediaControlsList,
  } = useStyles();

  const { participantsList } = useStores();

  const { t } = useTranslation();

  // return UI.widgetsArea.items.get('participantsMediaControlsList')?.isOpened ? (
  return (
    <Box className={classnames(participantsMediaControlsListContainer)}>
      <div className={classnames(participantsMediaControlsListHeader)}>
        <span>{t('Participants')}</span>
      </div>

      <Scrollbar className={classnames(participantsMediaControlsList)}>
        {participantsList.onlineParticipants.map((participant: ParticipantType) => {
          return participant.role === Roles.DEFAULT_USER ||
            participant.role === Roles.SPEAKER ||
            participant.role === Roles.GREEN_SPEAKER ? (
            <ParticipantsMediaControlsListItem participant={participant} key={participant.userId} />
          ) : null;
        })}
      </Scrollbar>
    </Box>
  );
});

export default ParticipantsMediaControlsList;
