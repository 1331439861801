import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  selectContainer: {
    position: 'absolute',
    bottom: '65px',
    left: '-45px',
    width: '170px',
  },
  container: {
    display: 'flex',
    position: 'relative',
    '& svg:hover > rect': {
      fillOpacity: '0.4',
    },
  },
  leftButtonClass: {
    height: '48px',
    width: '48px',
    background: 'linear-gradient(90deg, #2379E8 0%, #2379E8 0%)',
    'border-top-left-radius': '15px',
    'border-bottom-left-radius': '15px',
  },
  rightButtonClass: {
    height: '48px',
    width: '48px',
    background: 'linear-gradient(90deg, #2379E8 0%, #198DEB 100%)',
    'border-top-right-radius': '15px',
    'border-bottom-right-radius': '15px',
  },
}));

export default useStyles;
