import React from 'react';
import useStyles from './style';

type FullScreenOpenIconProps = {
  size: string;
  color?: string;
};

const FullScreenOpenIcon = ({ size, color = 'white' }: FullScreenOpenIconProps) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className={maskSvg}
        id="mask0_666_11570"
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="24"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"
          fill="black"
          transform="translate(14 14) scale(1.25 1.25)"
        />
      </mask>
      <g mask="url(#mask0_666_11570)">
        <rect x="12" y="12" width="24" height="24" fill={color} />
      </g>
    </svg>
  );
};

export default FullScreenOpenIcon;
