import React, { useState, useEffect } from 'react';
import useStyles from './style';
import { RotateIcon } from '..';
import { useMobileOrientation } from 'react-device-detect';

const RotateMobile = () => {
  const { rotateIcon, rotateText, rotateContainer, rotateOverlay } = useStyles();
  const [isPortrait, setIsPortrait] = useState(false);
  const orientation = useMobileOrientation();

  useEffect(() => {
    setIsPortrait(orientation.isPortrait);
  }, [orientation]);

  return (
    <>
      {isPortrait && (
        <div className={rotateOverlay}>
          <div className={rotateContainer}>
            <div className={rotateIcon}>
              <RotateIcon />
            </div>
            <div className={rotateText}>Please, rotate your phone to the landscape orientation</div>
          </div>
        </div>
      )}
    </>
  );
};

export default RotateMobile;
