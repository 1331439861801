import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  modalClass: {
    position: 'relative',
    width: '320px',
    borderRadius: '8px',
  },
  modalWrapClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    padding: '24px 24px 32px 12px',
  },
  logoutWrapper: {
    paddingTop: '20px',
    borderTop: '1px solid #DDDDDD',
    display: 'flex',
  },
  logoutIcon: {
    display: 'flex',
    paddingLeft: '12px',
    paddingRight: '8px',
  },
  logoutText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#140E17',
  },
  deviceIconWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  deviceWrapper: {
    paddingLeft: '12px',
    paddingBottom: '8px',
  },
  deviceItem: {},
  deviceType: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#A2A2A2',
  },
  deviceValueWrapper: {
    paddingTop: '12px',
    paddingBottom: '4px',
  },
  deviceNameClass: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#140E17',
  },
  ckeckboxWrapperClass: {
    display: 'flex',
  },
  ckeckboxClass: {
    paddingLeft: '8px',
  },
  deviceAndCheckboxClass: {
    display: 'flex',
    paddingBottom: '16px',
    width: '100%',
  },
  closeIconWrapper: {
    display: 'flex',
    height: '100%',
  },
}));

export default useStyles;
