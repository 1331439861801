import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { breakpoints } from './common';

const theme = createMuiTheme({
  breakpoints,
  typography: {
    fontFamily: 'Outfit, sans-serif',
  },
  palette: {
    type: 'light',
    primary: {
      // Цвет иконки
      main: '#457B9D',
      // Текст селектора
      light: '#457B9D',
      // Текст шапки + крест
      dark: '#1D3557',
    },
    secondary: {
      // Цвет выделения
      main: '#E63946',
    },
    background: {
      // Основной фон
      default: '#F1FAEE',
      // Фон шапки
      paper: '#A8DADC',
    },
  },
});

export default theme;
