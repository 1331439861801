import React from 'react';
import useStyles from './style';

type ProgressBarType = {
  value: number;
};

const ProgressBar = ({ value }: ProgressBarType) => {
  const { progress, progressValue, progressWrapper } = useStyles(value);

  return (
    <div className={progressWrapper}>
      <div className={progressValue}>{value}%</div>
      <div className={progress}></div>
    </div>
  );
};

export default ProgressBar;
