import React, { useEffect } from 'react';
import { existAudioDevice, existVideoDevice, handleStopTestMic } from '../../helpers/device';
import {
  Button,
  EnterNameInput,
  VideoSelect,
  AudioSelect,
  AudioOnIcon,
  VideoOnIcon,
  Switch,
  VideoOffIcon,
  DevicesPermissionDenied,
  MicTest,
  Slider,
} from '../../components';
import useStyles from './style';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../hooks/useStores';
import { getStreamAndAppend } from '../../helpers/stream';
import { load } from '../../services/app';
import { observer } from 'mobx-react-lite';
import useComponentVisible from '../../hooks/useComponentVisible';
import { StartPageType } from '../../types/player';
import { generateName } from '../../helpers/random';
import { initTestWbs } from '../../services/wbs';

const SelectDeviceCard = observer(
  ({ refPlayer, refPlayerLocalStream, refStartPageLocalStream }: StartPageType) => {
    const {
      displayNone,
      startPageContainer,
      startPageCameraWrapper,
      startPageCamera,
      startPageLocalVideo,
      startPageDeviceWrapper,
      startPageCameraOff,
      startPageButtonClass,
      audioSelectWrapper,
      videoSelectWrapper,
      enterNameInputContainer,
      startPageButtonWrapper,
      startPageDevicesPermissionDeniedWrapper,
      switchWrapper,
      switchItem,
      paddingswitchWrapper,
      switchIcon,
      switchClass,
      startPageButtonDisableClass,
      // tooltipLeftPositionClass,
      // tooltipRightPositionClass,
      testMicWrapper,
    } = useStyles();
    const { t } = useTranslation();
    const {
      mySetting: {
        isShowVideo,
        isShowAudio,
        setIsShowAudio,
        setIsShowVideo,
        isDevicesPermissionDenied,
      },
      connection: { setRefPlayer, setIsStartPage, setLoadingMessage, setIsLoading },
      user: { name, setName },
    } = useStores();

    const {
      ref: refVideoDropDownList,
      isComponentVisible: isShowVideoDropDownList,
      setIsComponentVisible: setIsShowVideoDropDownList,
    } = useComponentVisible();

    const {
      ref: refAudioDropDownList,
      isComponentVisible: isShowAudioDropDownList,
      setIsComponentVisible: setIsShowAudioDropDownList,
    } = useComponentVisible();

    const handleUpdateStartPageLocalStream = (isAudioChange: boolean) => {
      // handleRestartTestMic();
      if (!isAudioChange) {
        getStreamAndAppend(refStartPageLocalStream);
      }
    };

    const handleConnectToGame = () => {
      getStreamAndAppend(refPlayerLocalStream);
      setRefPlayer(refPlayer.current as any);
      load();
    };

    const handlePressStart = () => {
      if (name === '') {
        setName(generateName());
      }
      handleStopTestMic();
      setIsStartPage(false);
      setIsLoading(true);
      setLoadingMessage('Start loading');

      // TODO: init test
      initTestWbs();
      setTimeout(() => handleConnectToGame(), 1000);
    };

    useEffect(() => {
      getStreamAndAppend(refStartPageLocalStream);
    }, [refStartPageLocalStream]);

    return (
      <div className={startPageContainer}>
        {isDevicesPermissionDenied && (
          <div className={startPageDevicesPermissionDeniedWrapper}>
            <DevicesPermissionDenied />
          </div>
        )}
        <div className={startPageCameraWrapper}>
          <div className={startPageCamera}>
            <video
              ref={refStartPageLocalStream}
              className={classnames(
                startPageLocalVideo,
                !isShowVideo || !existVideoDevice() ? displayNone : ''
              )}
              muted
              autoPlay
              playsInline
              disablePictureInPicture
            />
            <div className={startPageCameraOff}>
              <div>
                <VideoOffIcon color="#A2A2A2" />
              </div>
              <div> {t('Camera is off')}</div>
            </div>
          </div>
        </div>
        <div className={enterNameInputContainer}>
          <EnterNameInput />
        </div>
        <div className={switchWrapper}>
          {/* <Tooltip
            isShow={!isShowAudio}
            text={'Microphone is off'}
            tooltipClass={tooltipLeftPositionClass}
          > */}
          <div className={switchItem}>
            <div className={switchIcon}>
              <AudioOnIcon color={existAudioDevice() ? '#969FB5' : '#A2A2A2'} />
            </div>
            <div className={switchClass}>
              <Switch
                value={existAudioDevice() ? isShowAudio : false}
                onToggle={() => setIsShowAudio(!isShowAudio)}
                isDisabled={!existAudioDevice()}
              />
            </div>
          </div>
          {/* </Tooltip> */}
          {isShowAudio && existAudioDevice() && (
            <div className={classnames(switchItem, paddingswitchWrapper)}>
              <div className={testMicWrapper}>
                <div className={switchIcon}>
                  <MicTest />
                </div>
                <div className={switchClass}>
                  <Slider />
                </div>
              </div>
            </div>
          )}
          {/* <Tooltip
            isShow={!isShowVideo}
            text={'Camera is off'}
            tooltipClass={tooltipRightPositionClass}
          > */}
          <div className={switchItem}>
            <div className={switchIcon}>
              <VideoOnIcon color={existVideoDevice() ? '#969FB5' : '#A2A2A2'} />
            </div>
            <div className={switchClass}>
              <Switch
                value={existVideoDevice() ? isShowVideo : false}
                onToggle={() => setIsShowVideo(!isShowVideo)}
                isDisabled={!existVideoDevice()}
              />
            </div>
          </div>
          {/* </Tooltip> */}
        </div>
        <div className={startPageDeviceWrapper}>
          <div>
            <div className={videoSelectWrapper}>
              <VideoSelect
                handleUpdateLocalStream={() => handleUpdateStartPageLocalStream(false)}
                innerRef={refVideoDropDownList}
                isShow={isShowVideoDropDownList}
                setIsShow={setIsShowVideoDropDownList}
              />
            </div>
            <div className={audioSelectWrapper}>
              <AudioSelect
                handleUpdateLocalStream={() => console.log('test')}
                innerRef={refAudioDropDownList}
                isShow={isShowAudioDropDownList}
                setIsShow={setIsShowAudioDropDownList}
              />
            </div>
          </div>
          <div className={startPageButtonWrapper}>
            <Button
              className={classnames(
                startPageButtonClass,
                !existVideoDevice() ? startPageButtonDisableClass : ''
              )}
              onClick={handlePressStart}
            >
              {t('Join')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default SelectDeviceCard;
