/* eslint no-unused-vars: "off" */

export enum Roles {
  DEFAULT_USER = 'User',
  MODERATOR = 'Moderator',
  SPEAKER = 'Speaker',
  SPECTATOR = 'Spectator',
  GREEN_SPEAKER = 'GreenSpeaker',
}

export type ScreenAllowedForRolesMap = {
  [key in Roles]: boolean;
};

export const DefaultRole = Roles.DEFAULT_USER;
