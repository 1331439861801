import React from 'react';
import { Modal, CloseIcon } from '../..';
import { useStores } from '../../../hooks/useStores';
import useStyles from './style';
import { observer } from 'mobx-react-lite';

const AfkModal = observer(() => {
  const {
    modal: { setIsOpenAfkModal, isOpenAfkModal },
    connection: { afkMinutesLeft },
  } = useStores();
  const {
    afkModalClass,
    afkModalWrapClass,
    afkModalContainer,
    afkModalLeftColumn,
    afkModalRightColumn,
    afkModalTimer,
    afkModalTitle,
    afkModalDesc,
  } = useStyles();

  const handleCloseAfkModal = () => {
    setIsOpenAfkModal(false);
  };

  return (
    <Modal isOpen={isOpenAfkModal} modalClass={afkModalClass} modalWrapClass={afkModalWrapClass}>
      <div className={afkModalContainer}>
        <div className={afkModalLeftColumn}>
          <div className={afkModalTimer}>00:{afkMinutesLeft}</div>
          <div className={afkModalTitle}>Do you want to stay?</div>
          <div className={afkModalDesc}>
            You haven’t been active for long time. Please, move your mouse. Otherwise you will be
            kicked out of the event.
          </div>
          <div className={afkModalDesc}>{"Don't worry, you can join the event again"}</div>
        </div>
        <div className={afkModalRightColumn} onClick={handleCloseAfkModal}>
          <CloseIcon />
        </div>
      </div>
    </Modal>
  );
});

export default AfkModal;
