import React from 'react';
import useStyles from './style';

type VideoMuteIconType = {
  color?: string;
};

const VideoMuteIcon = ({ color = '#140E17' }: VideoMuteIconType) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="20"
      y="14"
      color={color}
    >
      <mask id="mask0_1590_271174" className={maskSvg} maskUnits="userSpaceOnUse" x="0" y="1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.12012 10.2002C7.9242 10.2329 7.72295 10.25 7.51773 10.25C5.5157 10.25 3.89273 8.62703 3.89273 6.625C3.89273 4.62297 5.5157 3 7.51773 3C9.51976 3 11.1427 4.62297 11.1427 6.625C11.1427 6.99192 11.0882 7.3461 10.9868 7.6799C11.6492 7.36353 12.3691 7.14823 13.1266 7.05396C13.1373 6.91237 13.1427 6.76932 13.1427 6.625C13.1427 3.5184 10.6243 1 7.51773 1C4.41113 1 1.89273 3.5184 1.89273 6.625C1.89273 9.63257 4.25313 12.0888 7.2225 12.2424C7.41241 11.508 7.71855 10.8203 8.12012 10.2002ZM7.15905 12.5094C4.18108 12.6479 1.60158 14.3113 0.143344 16.7344C-0.141436 17.2076 0.0113102 17.822 0.484511 18.1068C0.957712 18.3916 1.57218 18.2388 1.85696 17.7656C2.95093 15.9478 4.84164 14.7014 7.01919 14.5223C7.00647 14.3498 7 14.1757 7 14C7 13.4885 7.05487 12.9897 7.15905 12.5094ZM7.51773 8.5C8.55326 8.5 9.39273 7.66053 9.39273 6.625C9.39273 5.58947 8.55326 4.75 7.51773 4.75C6.48219 4.75 5.64273 5.58947 5.64273 6.625C5.64273 7.66053 6.48219 8.5 7.51773 8.5Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_1590_271174)">
        <rect x="-1" y="-2" width="20" height="20" fill="currentColor" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 14C18 16.2091 16.2091 18 14 18C13.2582 18 12.5635 17.7981 11.968 17.4462L17.4462 11.968C17.7981 12.5635 18 13.2582 18 14ZM10.5538 16.032L16.032 10.5538C15.4365 10.2019 14.7418 10 14 10C11.7909 10 10 11.7909 10 14C10 14.7418 10.2019 15.4365 10.5538 16.032ZM20 14C20 17.3137 17.3137 20 14 20C10.6863 20 8 17.3137 8 14C8 10.6863 10.6863 8 14 8C17.3137 8 20 10.6863 20 14Z"
        fill="#E22929"
      />
    </svg>
  );
};

export default VideoMuteIcon;
