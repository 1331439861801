import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#F3F3F3',
    position: 'relative',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#F3F3F3',
    padding: '22px 20px',
    position: 'relative',
  },
  footerContainer: {
    border: '1px solid #E6E6E6',
    borderRadius: '8px',
    width: '100%',
    padding: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  footerIcon: {
    paddingRight: '16px',
    display: 'flex',
  },
  footerDescContainer: {},
  footerTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    color: '#140E17',
    paddingBottom: '4px',
  },
  footerEmail: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#A2A2A2',
  },
  content: {
    padding: '20px 0',
  },
  playerContainer: {
    width: '360px',
  },
  packageVersionWrapper: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    color: '#140E17',
    display: 'flex',
  },
  packageVersion: { paddingLeft: 10 },
}));

export default useStyles;
