import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    background: '#D5F0DB',
    borderRadius: '8px',
    padding: '8px 16px',
    display: 'flex',
    color: '#140E17',
    maxWidth: '420px',
  },
  textClass: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    paddingLeft: '12px',
  },
  iconClassWrap: {
    display: 'flex',
  },
}));

export default useStyles;
