import { toJS } from 'mobx';
import { Instance, types, cast } from 'mobx-state-tree';

const ProductImageModel = types.model('productImage').props({
  src: types.string,
  alt: types.string,
  height: types.number,
  width: types.number,
});

const ProductModel = types.model('product').props({
  id: types.number,
  title: types.string,
  price: types.number,
  image: types.optional(types.maybeNull(ProductImageModel), null),
});

const ProductsModel = types.array(ProductModel);

const ProductInShoppingCartModel = types.model('shoppingCart').props({
  id: types.number,
  title: types.string,
  price: types.number,
  image: types.optional(types.maybeNull(ProductImageModel), null),
  count: types.number,
});

export const ProductsInShoppingCartModel = types.array(ProductInShoppingCartModel);

export const ShopModel = types
  .model('shop')
  .props({
    products: ProductsModel,
    shoppingCart: ProductsInShoppingCartModel,
  })
  .views((self) => ({
    get totalPrice() {
      return self.shoppingCart.reduce((sum, product) => sum + product.count * product.price, 0);
    },
  }))
  .actions((self) => ({
    setProducts(products: ProductModelType[]) {
      self.products = cast(products);
    },
    getProductById(productId: number) {
      const products = toJS(self.products);
      const product = products.find((product) => product.id === productId);

      return product;
    },
    getShoppingCart() {
      return self.shoppingCart;
    },
    increaseProductCount(id: number) {
      const newProductsInShoppingCart = self.shoppingCart.map((product) => {
        if (product.id === id) {
          product.count = product.count + 1;
        }
        return product;
      });

      self.shoppingCart = cast(newProductsInShoppingCart);
    },
    decreaseProductCount(id: number) {
      const newProductsInShoppingCart = self.shoppingCart.map((product) => {
        if (product.id === id) {
          product.count = product.count - 1;
        }
        return product;
      });

      self.shoppingCart = cast(newProductsInShoppingCart);
    },
    removeProduct(id: number) {
      const newProducts = self.shoppingCart?.filter((product) => product.id !== id);
      self.shoppingCart = cast(newProducts);
    },
    setProductsToShoppingCart(shoppingCart: ProductInShoppingCartModelType[]) {
      self.shoppingCart = cast(shoppingCart);
    },
    setProductToShoppingCart(product: ProductModelType | null) {
      if (product) {
        const { id: productId } = product;
        const isExistProduct = self.shoppingCart.find(({ id }) => id === productId);

        if (!isExistProduct) {
          const cart = { ...product, count: 1 } as any;
          self.shoppingCart.push(cart);
        }
      }
    },
  }));

export interface ProductModelType extends Instance<typeof ProductModel> {}
export interface ProductInShoppingCartModelType
  extends Instance<typeof ProductInShoppingCartModel> {}
