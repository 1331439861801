import React from 'react';
import { CommonModal } from '../..';
import useStyles from './style';
import imgSrc from './img_big.png';
import { useStores } from '../../../hooks/useStores';
import { emitDirectConnection } from '../../../services/game/emitsToGame/emits';

type TeleportListModalType = {
  isShow: boolean;
  handleClose: any;
};
const TeleportListModal = ({ isShow, handleClose }: TeleportListModalType) => {
  const {
    teleportListModalDesc,
    teleportListModalContent,
    modalClass,
    modalWrapClass,
  } = useStyles();
  const { user } = useStores();

  return (
    <CommonModal
      isShow={isShow}
      handleClose={handleClose}
      title="Teleport"
      isShowNumberParticipantsInSpace={false}
      modalClass={modalClass}
      modalWrapClass={modalWrapClass}
    >
      <div className={teleportListModalDesc}>Сhoose a space you want to move to</div>
      <div className={teleportListModalContent}>
        {user?.environmentConfig?.bTeleportationAvaliable
          ? user.environmentConfig?.serversInEnvironment.map(({ spaceName, displayName }, id) => {
              const spaceImg = imgSrc;
              if (spaceName === user.travelDestination) return null;
              return (
                <TeleportListModalItem
                  key={id}
                  displayName={displayName}
                  spaceImg={spaceImg}
                  spaceName={spaceName}
                />
              );
            })
          : null}
      </div>
    </CommonModal>
  );
};

type TeleportListModalItemType = {
  spaceName: string;
  spaceImg: string;
  displayName: string;
};

const TeleportListModalItem = ({ spaceName, spaceImg, displayName }: TeleportListModalItemType) => {
  const {
    teleportListModalItem,
    teleportListModalSpaceName,
    teleportListModalSpaceImg,
  } = useStyles();

  return (
    <div className={teleportListModalItem} onClick={() => emitDirectConnection(spaceName)}>
      <div className={teleportListModalSpaceName}>{displayName}</div>
      <div className={teleportListModalSpaceImg}>
        <img src={spaceImg} alt="space" />
      </div>
    </div>
  );
};

export default TeleportListModal;
