import React, { useCallback, useImperativeHandle } from 'react';
import { useStores } from '../../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import RequestScreenShareModal from '../RequestScreenShareModal_old';
import * as ApiWrapper from '../../../services/apiWrapper';

import {
  onEndedStream,
  onFailStream,
  createScreenStream,
  closeScreenChannel,
} from '../../../helpers/stream';

const RequestPersonalShareModal = observer(
  ({ refPlayerLocalScreenShare: ref, refCloseModal }: any) => {
    const {
      modal: { isOpenRequestPersonalShareModal, setIsOpenRequestPersonalShareModal },
      wbs: { screenWidth: width, screenHeight: height },
    } = useStores();

    const handleDenyModalPersonalShare = useCallback(async () => {
      closeScreenChannel();
      setIsOpenRequestPersonalShareModal(false);
    }, [setIsOpenRequestPersonalShareModal]);

    const handleCloseModalPersonalShare = useCallback(async () => {
      await handleDenyModalPersonalShare();
    }, [handleDenyModalPersonalShare]);

    const handleAllowModalPersonalShare = useCallback(async () => {
      setIsOpenRequestPersonalShareModal(false);
      createScreenStream({
        ref,
        width,
        height,
        onEndedStream,
        onFailStream,
      });
      await ApiWrapper.startSharingScreen('local');
    }, [ref, width, height, setIsOpenRequestPersonalShareModal]);

    useImperativeHandle(refCloseModal, () => ({
      close: handleDenyModalPersonalShare,
    }));

    return (
      <RequestScreenShareModal
        title="Please select resolution:"
        subTitle="Do you want to share local screen?"
        open={isOpenRequestPersonalShareModal}
        handleClose={handleCloseModalPersonalShare}
        handleAllow={handleAllowModalPersonalShare}
        handleDeny={handleDenyModalPersonalShare}
      />
    );
  }
);

export default RequestPersonalShareModal;
