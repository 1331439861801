import { Instance, types } from 'mobx-state-tree';
import { ChatKinds } from '../constants/chats';
import { ParticipantType } from './participants';
import * as APIWrapper from '../services/apiWrapper';

export const ChatMessageModel = types
  .model('ChatMessage')
  .props({
    chatId: types.string,
    chatMessageId: types.identifier,
    authorUserId: types.string,
    text: types.string,
    createdAt: types.Date,
    isRead: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setIsRead(newValue: boolean) {
      self.isRead = newValue;
    },
  }));

export type ChatMessageModelType = Instance<typeof ChatMessageModel>;
export interface ChatMessageType extends ChatMessageModelType {}

export const ChatModel = types
  .model('Chat')
  .props({
    chatId: types.identifier,
    userId: types.maybeNull(types.string),
    kind: types.enumeration([...Object.values(ChatKinds)]),
    title: types.maybeNull(types.string),
    messages: types.optional(types.array(ChatMessageModel), []),
    coverImage: types.maybeNull(types.string),
  })
  .views((self) => ({
    get messagesList() {
      return self.messages;
    },
    get messagesListLength(): number {
      return self.messages.length;
    },
    get unreadMessagesCount(): number {
      return self.messages.reduce((sum, message) => (sum += !message.isRead ? 1 : 0), 0);
    },
  }))
  .actions((self) => ({
    addMessage({ chatId, chatMessageId, authorUserId, createdAt, text }: ChatMessageProps) {
      self.messagesList.push({
        chatId,
        chatMessageId,
        authorUserId,
        text,
        createdAt,
        isRead: false,
      } as ChatMessageType);
    },
  }));

export type ChatModelType = Instance<typeof ChatModel>;
export interface ChatType extends ChatModelType {}

export const ChatsModuleModel = types
  .model('ChatsModule')
  .props({
    chats: types.map(ChatModel),
    selectedChatId: types.maybeNull(types.string),
    messageSendingLoader: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get publicChatsList() {
        return Array.from(self.chats.values())
          .filter((chat) => chat.kind === ChatKinds.PUBLIC)
          .sort((a, b) => (a.unreadMessagesCount > b.unreadMessagesCount ? -1 : 1));
      },
      get directChatsList() {
        return Array.from(self.chats.values())
          .filter((chat) => chat.kind === ChatKinds.DIRECT)
          .sort((a, b) => (a.unreadMessagesCount > b.unreadMessagesCount ? -1 : 1));
      },
      get selectedChat(): ChatType | null {
        return self.selectedChatId ? (self.chats.get(self.selectedChatId) as ChatType) : null;
      },
      get directChatsByUserId(): Map<string, ChatType> {
        return this.directChatsList.reduce((map, chat) => {
          map.set(chat.userId, chat);
          return map;
        }, new Map());
      },
    };
  })
  .actions((self) => ({
    addChat({ chatId, userId, kind, title, messages, coverImage }: ChatProps): ChatType {
      self.chats.set(chatId, {
        chatId,
        userId,
        kind,
        title,
        messages,
        coverImage,
      } as ChatType);

      return self.chats.get(chatId) as ChatType;
    },
    selectChat(chatIdToSelect: string | null) {
      self.selectedChatId = chatIdToSelect;
    },
    async createDirectChat(participant: ParticipantType) {
      const chat = await APIWrapper.createDirectChat(participant);
      this.addChat(chat);
      this.selectChat(chat.chatId);
    },
    async sendMessage(text: string): Promise<boolean> {
      try {
        /* boolean in the method signature is a placeholder for error catching */

        if (self.messageSendingLoader) {
          return false;
        }

        if (!self.selectedChat) {
          return false;
        }

        this.updateMessageSendingLoader(true);

        const chatId = self.selectedChatId as string;

        await APIWrapper.sendChatMessage({
          chatId,
          text,
        });

        return true;
      } catch (e) {
        console.error('sendMessage() err:', e);

        return false;
      } finally {
        this.updateMessageSendingLoader(false);
      }
    },
    updateMessageSendingLoader(newValue: boolean) {
      self.messageSendingLoader = newValue;
    },
  }));

export type ChatsModuleModelType = Instance<typeof ChatsModuleModel>;
export interface ChatsModuleType extends ChatsModuleModelType {}
