import { MessageType } from '../staticTypes/messageTypes';
import { emitControlInteraction } from '../wsMessageEmitter';
import { MouseButtonsMask, MouseButton } from '../staticTypes/inputTypes';
import {
  normalizeAndQuantizeSigned,
  normalizeAndQuantizeUnsigned,
  styleHeight,
  styleWidth,
} from '../mathLibraly';
import { webRtcPlayerObject } from '../webRtcWrapper';
import { isDesktop } from 'react-device-detect';

export const unLockMouse = () => {
  if (isDesktop) {
    document.exitPointerLock = document.exitPointerLock || (document as any).mozExitPointerLock;
    document.exitPointerLock();
  }
};

/**
 * Заблокированная мышь работает, когда пользователь щелкает в проигрывателе браузера и
 * курсор исчезнет и заблокируется. Пользователь перемещает курсор и камеру
 * движется, например. Пользователь нажимает escape, чтобы освободить мышь.
 */
export const registerLockedMouseEvents = () => {
  let x = webRtcPlayerObject.video.width / 2;
  let y = webRtcPlayerObject.video.height / 2;

  webRtcPlayerObject.video.requestPointerLock =
    webRtcPlayerObject.video.requestPointerLock ||
    webRtcPlayerObject.video.mozRequestPointerLock ||
    webRtcPlayerObject.video.webkitRequestPointerLock;

  webRtcPlayerObject.video.onclick = function () {
    if (webRtcPlayerObject.video.requestPointerLock) {
      webRtcPlayerObject.video.requestPointerLock();
    }
  };

  document.addEventListener('pointerlockchange', lockStateChange, false);
  document.addEventListener('mozpointerlockchange', lockStateChange, false);
  document.addEventListener('webkitpointerlockchange', lockStateChange, false);

  function lockStateChange() {
    if (
      document.pointerLockElement === webRtcPlayerObject.video ||
      (document as any).mozPointerLockElement === webRtcPlayerObject.video ||
      (document as any).webkitPointerLockElement === webRtcPlayerObject.video
    ) {
      document.addEventListener('mousemove', updatePosition, false);
    } else {
      document.removeEventListener('mousemove', updatePosition, false);
    }
  }

  function updatePosition(e: any) {
    x += e.movementX;
    y += e.movementY;
    if (x > styleWidth - 1) {
      x = styleWidth - 1;
    }
    if (y > styleHeight - 1) {
      y = styleHeight - 1;
    }
    if (x < 1) {
      x = 1;
    }
    if (y < 1) {
      y = 0;
    }
    emitMouseMove(x, y, e.movementX, e.movementY);
  }

  webRtcPlayerObject.video.onmousedown = function ({ button }: MouseType) {
    emitMouseDown(button, x, y);
  };

  webRtcPlayerObject.video.onmouseup = function ({ button }: MouseType) {
    emitMouseUp(button, x, y);
  };

  webRtcPlayerObject.video.onmousewheel = function (e: any) {
    emitMouseWheel(e.wheelDelta, x, y);
  };

  webRtcPlayerObject.video.pressMouseButtons = function (e: any) {
    pressMouseButtons(e.buttons, x, y);
  };

  webRtcPlayerObject.video.releaseMouseButtons = function (e: any) {
    releaseMouseButtons(e.buttons, x, y);
  };
};

export const registerHoveringMouseEvents = () => {
  webRtcPlayerObject.video.onclick = function (e: any) {
    pressMouseButtons(e.buttons, e.offsetX, e.offsetY);
    // emitMouseMove(e.offsetX, e.offsetY, e.movementX, e.movementY);
  };

  webRtcPlayerObject.video.onmousemove = function (e: any) {
    emitMouseMove(e.offsetX, e.offsetY, e.movementX, e.movementY);
  };

  webRtcPlayerObject.video.onmousedown = function ({ button, offsetX, offsetY }: MouseType) {
    emitMouseDown(button, offsetX, offsetY);
  };

  webRtcPlayerObject.video.onmouseup = function ({ button, offsetX, offsetY }: MouseType) {
    emitMouseUp(button, offsetX, offsetY);
  };

  // When the context menu is shown then it is safest to release the button
  // which was pressed when the event happened. This will guarantee we will
  // get at least one mouse up corresponding to a mouse down event. Otherwise
  // the mouse can get stuck.
  // https://github.com/facebook/react/issues/5531
  webRtcPlayerObject.video.oncontextmenu = function (e: any) {
    emitMouseUp(e.button, e.offsetX, e.offsetY);
  };

  if ('onmousewheel' in webRtcPlayerObject.video) {
    webRtcPlayerObject.video.onmousewheel = function (e: any) {
      emitMouseWheel(e.wheelDelta, e.offsetX, e.offsetY);
    };
  } else {
    webRtcPlayerObject.video.addEventListener(
      'DOMMouseScroll',
      function (e: any) {
        emitMouseWheel(e.detail * -120, e.offsetX, e.offsetY);
      },
      false
    );
  }

  webRtcPlayerObject.video.pressMouseButtons = function (e: any) {
    pressMouseButtons(e.buttons, e.offsetX, e.offsetY);
  };

  webRtcPlayerObject.video.releaseMouseButtons = function (e: any) {
    releaseMouseButtons(e.buttons, e.offsetX, e.offsetY);
  };
};

export const registerMouseEnterAndLeaveEvents = () => {
  if (!webRtcPlayerObject.video) return;

  webRtcPlayerObject.video.onmouseenter = function (e: any) {
    let Data = new DataView(new ArrayBuffer(1));
    Data.setUint8(0, MessageType.MouseEnter);
    emitControlInteraction(Data.buffer);
    webRtcPlayerObject.video.pressMouseButtons(e);
  };

  webRtcPlayerObject.video.onmouseleave = function (e: any) {
    let Data = new DataView(new ArrayBuffer(1));
    Data.setUint8(0, MessageType.MouseLeave);
    emitControlInteraction(Data.buffer);
    webRtcPlayerObject.video.releaseMouseButtons(e);
  };
};

/** private functions */

const emitMouseMove = (x: any, y: any, deltaX: any, deltaY: any) => {
  let coord = normalizeAndQuantizeUnsigned(x, y);
  let delta = normalizeAndQuantizeSigned(deltaX, deltaY);
  let Data = new DataView(new ArrayBuffer(9));
  Data.setUint8(0, MessageType.MouseMove);
  Data.setUint16(1, coord.x, true);
  Data.setUint16(3, coord.y, true);
  Data.setInt16(5, delta.x, true);
  Data.setInt16(7, delta.y, true);
  emitControlInteraction(Data.buffer);
};

const emitMouseDown = (button: any, x: any, y: any) => {
  let coord = normalizeAndQuantizeUnsigned(x, y);
  let Data = new DataView(new ArrayBuffer(6));
  Data.setUint8(0, MessageType.MouseDown);
  Data.setUint8(1, button);
  Data.setUint16(2, coord.x, true);
  Data.setUint16(4, coord.y, true);
  emitControlInteraction(Data.buffer);
};

const emitMouseUp = (button: any, x: any, y: any) => {
  let coord = normalizeAndQuantizeUnsigned(x, y);
  let Data = new DataView(new ArrayBuffer(6));
  Data.setUint8(0, MessageType.MouseUp);
  Data.setUint8(1, button);
  Data.setUint16(2, coord.x, true);
  Data.setUint16(4, coord.y, true);
  emitControlInteraction(Data.buffer);
};

const emitMouseWheel = (delta: any, x: any, y: any) => {
  let coord = normalizeAndQuantizeUnsigned(x, y);
  let Data = new DataView(new ArrayBuffer(7));
  Data.setUint8(0, MessageType.MouseWheel);
  Data.setInt16(1, delta, true);
  Data.setUint16(3, coord.x, true);
  Data.setUint16(5, coord.y, true);
  emitControlInteraction(Data.buffer);
};

// Если у пользователя нажаты какие-либо кнопки мыши, отпустите их.
const releaseMouseButtons = (buttons: any, x: any, y: any) => {
  if (buttons & MouseButtonsMask.PrimaryButton) {
    emitMouseUp(MouseButton.MainButton, x, y);
  }
  if (buttons & MouseButtonsMask.SecondaryButton) {
    emitMouseUp(MouseButton.SecondaryButton, x, y);
  }
  if (buttons & MouseButtonsMask.AuxiliaryButton) {
    emitMouseUp(MouseButton.AuxiliaryButton, x, y);
  }
  if (buttons & MouseButtonsMask.FourthButton) {
    emitMouseUp(MouseButton.FourthButton, x, y);
  }
  if (buttons & MouseButtonsMask.FifthButton) {
    emitMouseUp(MouseButton.FifthButton, x, y);
  }
};

// Если у пользователя нажаты какие-либо кнопки мыши, нажмите их еще раз.
const pressMouseButtons = (buttons: any, x: any, y: any) => {
  if (buttons & MouseButtonsMask.PrimaryButton) {
    emitMouseDown(MouseButton.MainButton, x, y);
  }
  if (buttons & MouseButtonsMask.SecondaryButton) {
    emitMouseDown(MouseButton.SecondaryButton, x, y);
  }
  if (buttons & MouseButtonsMask.AuxiliaryButton) {
    emitMouseDown(MouseButton.AuxiliaryButton, x, y);
  }
  if (buttons & MouseButtonsMask.FourthButton) {
    emitMouseDown(MouseButton.FourthButton, x, y);
  }
  if (buttons & MouseButtonsMask.FifthButton) {
    emitMouseDown(MouseButton.FifthButton, x, y);
  }
};
