import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  mainModalClass: {
    width: '532px',
  },
  participantsModalClass: {
    width: '794px',
  },
  bannedParticipantsModalClass: {
    width: '794px',
  },
}));

export default useStyles;
