//onSnapshot
import { Instance, types } from 'mobx-state-tree';
import { AllSettingsModel } from './allSettings';
import { MySettingModel } from './mySetting';
import { ConnectionModel } from './connection';
import { UserModel } from './user';
import { DebugModel } from './debug';
import { DefaultRole } from '../constants/user';
import { UIModel } from './UI';
import { ChatsModuleModel } from './chats';
import { WbsModel } from './wbs';
import { ChatKinds } from '../constants/chats';
import { ParticipantsListModel } from './participants';
import { ScreenSharingModuleModel } from './screenSharingModule';
import { ModalModel } from './modals';
import { GameModel } from './game';
//ProductsInShoppingCartModel
import { ShopModel } from './shop';
import { AFK_TIMEOUT_RESET_USER_AFTER_SHOW_MODAL_IN_SECONDS } from '../constants/afk';

export const RootStoreModel = types.model({
  allSettings: AllSettingsModel,
  mySetting: MySettingModel,
  connection: ConnectionModel,
  user: UserModel,
  debug: DebugModel,
  UI: UIModel,
  chatsModule: ChatsModuleModel,
  participantsList: ParticipantsListModel,
  screenSharingModule: ScreenSharingModuleModel,
  modal: ModalModel,
  wbs: WbsModel,
  game: GameModel,
  shop: ShopModel,
});

let shop: any = {
  products: [],
  shoppingCart: [],
};

// const localStorageShoppingCart = localStorage.getItem('shopping-cart');

// if (localStorageShoppingCart) {
//   const json = JSON.parse(localStorageShoppingCart);
//   if (ProductsInShoppingCartModel.is(json)) {
//     shop.shoppingCart = json;
//   }
// }

const initialState = {
  wbs: {
    domain: null,
    local: {
      channel: null,
      width: 320,
      height: 240,
    },
    screen: {
      channel: null,
    },
    re: {
      channel: null,
    },
  },
  allSettings: {
    audioDevices: [],
    videoDevices: [],
  },
  mySetting: {
    isShowAudio: false,
    isShowVideo: false,
    isMutedAudio: false,
    isMutedVideo: false,
    isShowPersonalShare: false,
    isFullScreen: false,
    myAudioDeviceLabel: '',
    myVideoDeviceLabel: '',
    myAudioDeviceId: '',
    myVideoDeviceId: '',
    myQuality: 1200,
    testMicVolume: 0,
    isDevicesPermissionDenied: false,
  },
  connection: {
    isLoading: false,
    isError: false,
    loadingMessage: '',
    errorMessage: '',
    isHideInterface: false,
    isStartPage: true,
    isEndEvent: false,
    isOldControlMode: false,
    afkMinutesLeft: AFK_TIMEOUT_RESET_USER_AFTER_SHOW_MODAL_IN_SECONDS,
    ueClientDomain: '',
  },
  user: {
    name: '',
    userId: '',
    eventId: '',
    travelDestination: '',
    env: '',
    region: '',
    role: DefaultRole,
    chat: {
      isOpenChat: false,
      chatInputText: '',
    },
    messages: [],
    videoPlayer: {
      isOpen: false,
      poster: '',
      src: '',
      /*
       * tsc can not handle the volatile prop '.videoRef' of VideoPlayerModel here:
       * Object literal may only specify known properties, and 'videoRef' does not exist in type
       * 'ModelCreationType<ExtractCFromProps<{ isOpen: ISimpleType<boolean>; src: ISimpleType<string>; }>>'.
       * TS2322
       * @alexander girsh
       * @ts-ignore */
      videoRef: null,
    },
    customizeList: {
      lastUpdatedObject: 0,
      objects: [],
    },
    isLive: false,
    timer: {
      timestamp: 0,
      bEnable: false,
    },
  },
  eventsManagement: {
    speakers: {},
  },
  environmentConfig: {
    bTeleportationAvaliable: false,
    environmentName: '',
    lobbyServerType: '',
    serversInEnvironment: [],
  },
  UI: {
    sideMenuArea: {
      items: {
        chatsModule: {
          opened: false,
        },
        customizeList: {
          opened: false,
        },
        teleportList: {
          opened: false,
        },
        settingList: {
          opened: false,
        },
        moderatorSettings: {
          opened: false,
        },
      },
    },
    widgetsArea: {
      items: {
        fullSizeChat: {
          opened: false,
        },
        chatWidget: {
          opened: false,
        },
        participantsMediaControlsList: {
          opened: false,
        },
        streamStatusSelector: {
          opened: false,
        },
        liveIndicator: {
          opened: false,
        },
        timer: {
          opened: false,
        },
        screenSaverSelector: {
          opened: false,
        },
      },
    },
  },
  chatsModule: {
    selectedChatId: 'unique-chat-id',
    chats: {
      'unique-chat-id': {
        chatId: 'unique-chat-id',
        kind: ChatKinds.PUBLIC,
        title: 'General Channel',
        coverImage: null,
      },
    },
  },
  participantsList: {
    participants: {},
  },
  screenSharingModule: {},
  debug: {
    isShowDebugPanel: false,
    checkTurn: {
      isError: false,
      isSuccess: false,
      isLoading: false,
    },
    checkMs: {
      isError: false,
      isSuccess: false,
      isLoading: false,
    },
    checkInstance: {
      isError: false,
      isSuccess: false,
      isLoading: false,
      ueClientDomain: '',
    },
    checkGame: {
      isError: false,
      isSuccess: false,
      isLoading: false,
    },
  },
  modal: {
    isOpenRequestPersonalShareModal: false,
    isOpenRequestScreenShareModal: false,
    isOpenAfkModal: false,
    isOpenBanModal: false,
  },
  game: {
    updateNearestStand: {
      statusBuyButton: 0,
      currentProductId: 0,
    },
  },
  shop,
};

export const rootStore = RootStoreModel.create(initialState);

// onSnapshot(rootStore.shop.shoppingCart, (store) => {
//   localStorage.setItem('shopping-cart', JSON.stringify(store));
// });

type RootStoreModelType = Instance<typeof RootStoreModel>;
export interface RootStoreType extends RootStoreModelType {}
