import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  modalWrap: {
    position: 'fixed',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 auto',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 100,
    overflowX: 'hidden',
    backgroundColor: (isTransparent) => (isTransparent ? 'transparent' : 'rgba(20, 14, 23, 0.5)'),
    pointerEvents: 'auto',
  },

  modal: {
    background: '#FFFFFF',
    overflowY: 'scroll',
  },
}));

export default useStyles;
