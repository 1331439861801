import React from 'react';
import classnames from 'classnames';
import useStyles from './style';

interface ValueLabelProps {
  label: string;
  isOn: boolean;
}

const ValueLabel = ({ label, isOn = false }: ValueLabelProps) => {
  const { ValueLabelWrap, ValueLabel, ValueLabelOnIndicator } = useStyles();
  return (
    <div className={ValueLabelWrap}>
      <span className={classnames(ValueLabel, isOn && 'on')}>{label}</span>
      {isOn && <div className={ValueLabelOnIndicator}>on</div>}
    </div>
  );
};

export default ValueLabel;
