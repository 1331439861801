import React from 'react';
import useStyles from './style';
import Box from '@material-ui/core/Box';

type MenuItemType = {
  children: any;
  textIcon?: string;
  isShowTextIcon?: boolean;
};

const MenuItem = ({ children, textIcon, isShowTextIcon = true }: MenuItemType) => {
  const { menuWrapper, menuText } = useStyles();

  return (
    <Box className={menuWrapper}>
      {children}
      {isShowTextIcon && <p className={menuText}>{textIcon}</p>}
    </Box>
  );
};

export default MenuItem;
