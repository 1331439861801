import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import useStyles from './style';
import ChatMessage from '../ChatMessage';
import { useStores } from '../../../hooks/useStores';
import { ChatType } from '../../../models/chats';
import Stack from '../../Stack';

const ChatsModule = observer(() => {
  const { chatsModule } = useStores();

  const { chatWidgetContainer, chatWidgetItem } = useStyles();

  const chat = chatsModule.publicChatsList.find(
    (chat) => chat.title === 'General Channel'
  ) as ChatType;

  const lastMessages =
    chat.messagesList.length >= 5
      ? chat.messagesList.slice(chat.messagesList.length - 5)
      : chat.messagesList;

  return (
    <div className={classnames(chatWidgetContainer)}>
      <Stack keys={lastMessages.map((m) => m.chatMessageId)}>
        {lastMessages.map((message) => {
          return (
            <div key={message.chatMessageId} className={classnames(chatWidgetItem)}>
              <ChatMessage message={message} chat={chat} isChatWidget dark />
            </div>
          );
        })}
      </Stack>
    </div>
  );
});

export default ChatsModule;
