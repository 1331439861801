import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: (isDisable) => (isDisable ? '#CECECE' : '#A24DE8'),
  },
  arrow: {
    paddingLeft: '8px',
    display: 'flex',
  },
}));

export default useStyles;
