import { MEDIA_SERVER_EVENT_SUBSCRIBE_EVENTS } from '../../../../constants/wbs';
import { sendDataToServer } from '.';

export const unSubscribeEvents = () => {
  const data = {
    req: MEDIA_SERVER_EVENT_SUBSCRIBE_EVENTS,
    operation: 'unsubscribe_all',
  };

  sendDataToServer(data);
};
