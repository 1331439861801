import React from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { useStores } from '../../hooks/useStores';
import { Roles } from '../../constants/user';
import {
  LiveIndicator,
  Countdown,
  StreamStatusSelector,
  ScreenSaver,
  ParticipantsMediaControlsList,
  ChatWidget,
} from '../';
import useStyles from './style';

const SideBar = observer(() => {
  const { user, UI, connection } = useStores();
  const { sideBarWrap, sideBarItem } = useStyles();

  const isSpeakerOrModeratorRole = user.role === Roles.MODERATOR || user.role === Roles.SPEAKER;

  return (
    <div
      className={classnames(
        sideBarWrap
        // UI.isSideMenuHidden && 'left-aligned',
        // UI.isSideBarShadowed && 'shadowed'
      )}
    >
      {(UI.widgetsArea.items.get('liveIndicator')?.isOpened || user.isLive) && (
        <div className={sideBarItem}>
          <LiveIndicator />
        </div>
      )}

      {(UI.widgetsArea.items.get('timer')?.isOpened || user.timer.bEnable) && (
        <div className={sideBarItem}>
          <Countdown />
        </div>
      )}

      {isSpeakerOrModeratorRole && user.role === Roles.MODERATOR && (
        <div className={sideBarItem}>
          <ParticipantsMediaControlsList />
        </div>
      )}

      {UI.widgetsArea.items.get('screenSaverSelector')?.isOpened && (
        <div className={sideBarItem}>
          <StreamStatusSelector />
          <ScreenSaver />
        </div>
      )}

      {!connection.isStartPage &&
        !connection.isError &&
        UI.widgetsArea.items.get('chatWidget')?.isOpened &&
        !UI.sideMenuArea.items.get('chatsModule')?.isOpened && (
          <div className={classnames(sideBarItem, 'bottom-aligned', 'auto-grow')}>
            <ChatWidget />
          </div>
        )}
    </div>
  );
});

export default SideBar;
