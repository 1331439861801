import React from 'react';
import useStyles from './style';
import { MagnifyingGlassIcon } from '..';
type InputType = {
  value: string;
  setValue: any;
  placeholder: string;
};

const Input = ({ value, setValue, placeholder }: InputType) => {
  const { container, input, icon } = useStyles();

  const onChangeName = (e: any) => {
    // if (!isIOS) {
    const text = e.target!.value as string;
    setValue(text);
    // }
  };
  // const onKeyUpName = (e: any) => {
  //   // if (isIOS && e.key !== 'Enter') {
  //   let text = value;
  //   if (e.key !== 'Backspace') {
  //     text += e.key;
  //   } else {
  //     text = text.slice(0, -1);
  //   }

  //   setValue(text);
  //   // }
  // };

  return (
    <div className={container}>
      <input
        id="search"
        type="text"
        placeholder={placeholder}
        className={input}
        value={value}
        onChange={onChangeName}
        // onKeyUp={onKeyUpName}
      />
      <div className={icon}>
        <MagnifyingGlassIcon color="#140E17" />
      </div>
    </div>
  );
};

export default Input;
