import React from 'react';
import useStyles from './style';
import classnames from 'classnames';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { StaticScreenShareButton } from '../index';
import { ScreenShareTargetType } from '../../models/screenSharingModule';
import { useGlobalStyles } from '../../globalStyles';

const StaticScreenShareButtonsContainer = observer(({ handleChangeStaticShare }: any) => {
  const { staticScreenShareButtonsContainer } = useStyles();
  const { flexDirectionRow, flexAlignCenter, flexJustifyCenter } = useGlobalStyles();

  const { screenSharingModule } = useStores();

  return (
    <div
      className={classnames(
        flexDirectionRow,
        flexAlignCenter,
        flexJustifyCenter,
        staticScreenShareButtonsContainer
      )}
    >
      {screenSharingModule.allowedTargetsList.map((target: ScreenShareTargetType) => (
        <StaticScreenShareButton
          key={target.screenShareTargetId}
          screenShareTarget={target}
          handleChangeStaticShare={handleChangeStaticShare}
        />
      ))}
    </div>
  );
});

export default StaticScreenShareButtonsContainer;
