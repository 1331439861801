import { getLocalPc, getScreenPc, setLocalPc, setScreenPc } from '../store';
import { destroyPeerConnection } from '../webrtc';

import {
  registerOpenSocketListener,
  registerCloseSocketListener,
  registerErrorSocketListener,
} from './onmessage';
import { getSocket, setSocket } from './../store';
import { ChangeConnectionType, OpenConnectionType, CloseConnectionType } from '../../../types/wbs';
import { SOCKET_STATUS, SOCKET_STATUS_OPEN_CODE } from '../../../constants/wbs';
import { loggerLog } from '../logger';
import { closeChannels } from '../../../helpers/stream';
import { CloseConnectionRefType } from '../../../types/wbs/socket';

/**
 * Меняем соединение с медиа-сервером
 *
 * Если было открыто, то закрываем, иначе - открываем
 * @param param ChangeConnectionType
 */
export const changeConnection = ({ url, ref }: ChangeConnectionType) => {
  const socket = getSocket();
  if (socket) {
    closeConnection({ ref });
  } else {
    openConnection({ url });
  }
};

/**
 * Открываем соединение с медиа-сервером
 *
 * @param param OpenConnectionType
 */
export const openConnection = ({ url }: OpenConnectionType) => {
  const oldSocket = getSocket();

  if (!oldSocket) {
    const socket = new WebSocket(url);
    // @ts-ignore
    window.wss = socket;

    loggerLog(`open connection with url is ${url}`);
    setTimeout(() => {
      setSocket(socket);
      registerOpenSocketListener(socket);
      registerCloseSocketListener(socket);
      registerErrorSocketListener(socket);
    }, 2000);
  }
};

/**
 * Закрываем соединение с медиа-сервером
 *
 * Закрываем сокет
 * Уничтожаем все пир соединения
 * @param ref CloseConnectionType
 */
export const closeConnection = ({
  ref,
  code = 1000,
  reason = 'Work complete',
}: CloseConnectionType) => {
  const wbsSocket = getSocket();

  if (wbsSocket) {
    closeChannels();
    wbsSocket.close(code, reason);
    setSocket(null);
  }
  destroyPeers(ref);
};

/**
 * Переоткрываем соединение с медиа-сервером
 *
 * @param param ChangeConnectionType
 */
export const restartConnection = ({ url, ref }: ChangeConnectionType) => {
  closeConnection({ ref });
  openConnection({ url });
};

/**
 *  Есть ли соединение с медиа-сервером?
 * @returns boolean
 */
export const getIsConnection = () => {
  let isConnection = false;

  const socket = getSocket();

  if (socket) {
    const { readyState } = socket;
    const status = SOCKET_STATUS.find(({ code }) => code === readyState);

    loggerLog(`socket status is ${status?.message}`);

    if (readyState === SOCKET_STATUS_OPEN_CODE) {
      isConnection = true;
    }
  }
  loggerLog(`isConnection socket is ${isConnection}`);

  return isConnection;
};

export const destroyScreenPeer = (ref: CloseConnectionRefType) => {
  const pc = getScreenPc();
  destroyPeerConnection(pc, ref);
  setScreenPc(null);
};

export const destroyLocalPeer = (ref: CloseConnectionRefType) => {
  const pc = getLocalPc();
  destroyPeerConnection(pc, ref);
  setLocalPc(null);
};

export const destroyPeers = (ref: CloseConnectionRefType) => {
  destroyScreenPeer(ref);
  destroyLocalPeer(ref);
};
