import { Instance, types } from 'mobx-state-tree';

export const UIItemModel = types
  .model('uiItemModel')
  .props({
    opened: types.boolean,
  })
  .views((self) => ({
    get isOpened() {
      return self.opened;
    },
  }))
  .actions((self) => ({
    setIsOpened(newState: boolean) {
      self.opened = newState;
    },
    toggleIsOpened() {
      self.opened = !self.opened;
    },
  }));

export type UIItemModelType = Instance<typeof UIItemModel>;
export interface UIItemType extends UIItemModelType {}

export const UIAreaModel = types
  .model('UIArea')
  .props({
    items: types.map(UIItemModel),
  })
  .views((self) => ({
    get isSomeItemOpened() {
      return Array.from(self.items.values()).some((UIAreaItem) => UIAreaItem.isOpened);
    },
  }))
  .actions((self) => ({
    openOne(UIAreaItemTitle: string) {
      if (self.items.has(UIAreaItemTitle)) {
        this.closeAll();
        self.items.get(UIAreaItemTitle)?.setIsOpened(true);
        return;
      }

      throw new Error(`UIItem in UIArea with title "${UIAreaItemTitle}" does not exist`);
    },
    toggleOne(UIAreaItemTitle: string, closeAnotherItemsInAreaOnItemOpen: boolean = true) {
      const prevOpenedPropValue = self.items.get(UIAreaItemTitle)?.isOpened;

      if (!prevOpenedPropValue && closeAnotherItemsInAreaOnItemOpen) {
        this.closeAll();
      }

      self.items.get(UIAreaItemTitle)?.setIsOpened(!prevOpenedPropValue);
    },
    closeAll() {
      Array.from(self.items.values()).forEach((UIAreaItem) => {
        UIAreaItem.setIsOpened(false);
      });
    },
  }));

export type UIAreaModelType = Instance<typeof UIAreaModel>;
export interface UIAreaType extends UIAreaModelType {}

export const UIModel = types
  .model('UI')
  .props({
    sideMenuArea: UIAreaModel,
    widgetsArea: UIAreaModel,
    isMousePointerInSideMenuArea: types.optional(types.boolean, false),
    isSideMenuPinned: types.optional(types.boolean, false),
    // TODO: add notifications areas etc.
  })
  .views((self) => ({
    get isSideMenuHidden(): boolean {
      return (
        !self.isMousePointerInSideMenuArea &&
        !self.sideMenuArea.isSomeItemOpened &&
        !self.isSideMenuPinned
      );
    },
    get isSideBarShadowed(): boolean {
      return self.sideMenuArea.isSomeItemOpened;
    },
    get isOpenedTimer(): boolean {
      return self.widgetsArea.items.get('timer')?.isOpened as boolean;
    },
    get isOpenedParticipants(): boolean {
      return self.widgetsArea.items.get('participantsMediaControlsList')?.isOpened as boolean;
    },
    get isOpenedLiveIndicator(): boolean {
      return self.widgetsArea.items.get('liveIndicator')?.isOpened as boolean;
    },
    get isOpenedScreensavers(): boolean {
      return self.widgetsArea.items.get('screenSaverSelector')?.isOpened as boolean;
    },
    get isOpenedChatWidget(): boolean {
      return self.widgetsArea.items.get('chatWidget')?.isOpened as boolean;
    },
  }))
  .actions((self) => ({
    setIsSideMenuPinned(newValue: boolean) {
      return (self.isSideMenuPinned = newValue);
    },
    setIsOpenedTimer(isOpened: boolean) {
      self.widgetsArea.items.get('timer')?.setIsOpened(isOpened);
    },
    setIsOpenedParticipants(isOpened: boolean) {
      self.widgetsArea.items.get('participantsMediaControlsList')?.setIsOpened(isOpened);
    },
    setIsOpenedLiveIndicator(isOpened: boolean) {
      self.widgetsArea.items.get('liveIndicator')?.setIsOpened(isOpened);
    },
    setIsOpenedScreensavers(isOpened: boolean) {
      self.widgetsArea.items.get('screenSaverSelector')?.setIsOpened(isOpened);
    },
  }));

export type UIModelType = Instance<typeof UIModel>;
export interface UIInterface extends UIModelType {}
