import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  banModalContainer: {
    width: '100%',
    padding: '40px',
    paddingTop: '48px',
  },
  banModalClass: {
    width: '520px',
    // height: '313px',
    display: 'flex',
    borderRadius: '8px',
    position: 'relative',
  },
  banModalWrapClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  banModalTitleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '40px',
  },
  banModalTitleIcon: {
    display: 'flex',
    paddingRight: '12px',
    alignSelf: 'start',
  },
  banModalTitleText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '36px',
    color: '#140E17',
    paddingBottom: '8px',
  },
  banModalTitleDesc: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#9E9E9E',
  },
  banModalTitleColumn: {},
  banModalDelimiter: {
    borderTop: '1px solid #DDDDDD',
  },
  banModalLogoWraper: {
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  banModalLogo: {
    display: 'flex',
    paddingRight: '12px',
  },
  banModalLogoTitleWrap: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  banModalLogoTitle: {
    color: '#140E17',
  },
  banModalLogoEmail: {
    color: '#A2A2A2',
  },
}));

export default useStyles;
