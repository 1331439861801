import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  teleportListModalDesc: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '30px',
    color: '#3F5CA4',
    padding: '24px 0 12px 0',

    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#9E9E9E',
      padding: '10px 0 20px 0',
    },
  },
  teleportListModalContent: {},
  teleportListModalItem: {
    background: '#EBEFF8',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 16px 16px 24px',
    marginBottom: '16px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '12px',
      padding: '8px 8px 8px 16px',
    },
  },
  teleportListModalSpaceName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#3F5CA4',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  teleportListModalSpaceImg: {
    display: 'flex',
    '& img': {
      borderRadius: '15px',
      height: '100px',
      width: '150px',

      [theme.breakpoints.down('md')]: {
        height: '60px',
        width: '90px',
      },
    },
  },
  modalClass: {
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      width: '320px',
    },
  },
  modalWrapClass: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

export default useStyles;
