import React from 'react';
import useStyles from './style';

type ArrowRightIconProps = {
  color?: string;
  background?: string;
};

const ArrowRightIcon = ({ background = 'black', color = '#A2A2A2' }: ArrowRightIconProps) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      color={color}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_977_11253"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="7"
        y="3"
        width="7"
        height="14"
      >
        <path
          d="M7.23178 4.64018C6.87821 4.21591 6.93554 3.58534 7.35982 3.23178C7.78409 2.87821 8.41466 2.93554 8.76822 3.35982L7.23178 4.64018ZM13 10L13.7682 9.35982C14.0773 9.73066 14.0773 10.2693 13.7682 10.6402L13 10ZM8.76822 16.6402C8.41466 17.0645 7.78409 17.1218 7.35982 16.7682C6.93554 16.4147 6.87822 15.7841 7.23178 15.3598L8.76822 16.6402ZM8.76822 3.35982L13.7682 9.35982L12.2318 10.6402L7.23178 4.64018L8.76822 3.35982ZM13.7682 10.6402L8.76822 16.6402L7.23178 15.3598L12.2318 9.35982L13.7682 10.6402Z"
          fill={background}
        />
      </mask>
      <g mask="url(#mask0_977_11253)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default ArrowRightIcon;
