import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
import mouseSvg from './images/mouse.svg';
import teleportSvg from './images/teleport.svg';
import keyboardSvg from './images/keyboard.svg';
import joystickSvg from './images/joystick.svg';
import { ProgressBar } from '..';
import { isDesktop } from 'react-device-detect';

type LoaderType = {
  children: ReactNode;
};

type LoaderDataItem = {
  id: number;
  timer: {
    from: number;
    to: number;
  };
  text: {
    main: string;
    accent: string;
  };
  image: {
    alt: string;
    src: any;
  };
};

const Loader = observer(({ children }: LoaderType) => {
  const { loaderContainer, loaderProgressBarContainer, loaderImgText } = useStyles();
  let [progressValue, setProgressValue] = useState(0);
  const [loaderData, setLoaderData] = useState<LoaderDataItem[] | null>(null);

  const {
    connection: { isLoading },
  } = useStores();

  const timerIncrement = useCallback(() => {
    if (progressValue <= 100) {
      setProgressValue(progressValue++);
    }
  }, [progressValue]);

  useEffect(() => {
    const intervalId = setInterval(() => timerIncrement(), 100);
    return () => clearInterval(intervalId);
  }, [timerIncrement]);

  useEffect(() => {
    const loaderDataDesktop = [
      {
        id: 1,
        timer: {
          from: 0,
          to: 33,
        },
        text: {
          main: 'by holding the left \nmouse button',
          accent: 'Rotate the camera',
        },
        image: {
          alt: 'mouse',
          src: mouseSvg,
        },
      },
      {
        id: 2,
        timer: {
          from: 34,
          to: 76,
        },
        text: {
          main: 'by hovering \nthe mouse on the needed area and click on \nit',
          accent: 'Move your direction',
        },
        image: {
          alt: 'teleport',
          src: teleportSvg,
        },
      },
      {
        id: 3,
        timer: {
          from: 77,
          to: 100,
        },
        text: {
          main: 'using keys \nW, S, D, A on the keyboard',
          accent: 'Move your direction',
        },
        image: {
          alt: 'keyboard',
          src: keyboardSvg,
        },
      },
    ];

    const loaderDataMobile = [
      {
        id: 1,
        timer: {
          from: 0,
          to: 100,
        },
        text: {
          main: 'using\n the virtual joystick',
          accent: 'You can move direction',
        },
        image: {
          alt: 'joystick',
          src: joystickSvg,
        },
      },
    ];

    if (isDesktop) {
      setLoaderData(loaderDataDesktop);
    } else {
      setLoaderData(loaderDataMobile);
    }
  }, []);

  return (
    <>
      {isLoading && (
        <div className={loaderContainer}>
          <div></div>
          <div className={loaderImgText}>
            {loaderData
              ?.filter(({ timer: { from, to } }) => progressValue <= to && progressValue >= from)
              .map(({ id, image, text }) => (
                <LoaderItem image={image} text={text} key={id} />
              ))}
          </div>
          <div className={loaderProgressBarContainer}>
            <ProgressBar value={progressValue} />
          </div>
        </div>
      )}
      <div>{children}</div>
    </>
  );
});

export default Loader;

type LoaderItemType = {
  image: {
    alt: string;
    src: string;
  };
  text: {
    main: string;
    accent: string;
  };
};

const LoaderItem = ({ image: { alt, src }, text: { main, accent } }: LoaderItemType) => {
  const { loaderText, loaderImg, loaderAccentText } = useStyles();
  return (
    <>
      <div className={loaderImg}>
        <img src={src} alt={alt} />
      </div>
      <div className={loaderText}>
        <span className={loaderAccentText}>{accent}</span> {main}
      </div>
    </>
  );
};
