import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  banModalContainer: {
    width: '100%',
    padding: '40px',
  },
  banModalClass: {
    width: '520px',
    display: 'flex',
    borderRadius: '8px',
    position: 'relative',
    overflow: 'hidden',
  },
  banModalWrapClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(20, 14, 23, 0.5)',
  },
  banTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '24px',
  },
  banTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '36px',
    color: '#140E17',
  },
  banCloseIcon: {},
  banIcon: {
    width: '40px',
    height: '40px',
  },
  banUsername: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#140E17',
    paddingLeft: '10px',
  },
  banItem: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '12px',
  },
  buttonCancelClass: {
    padding: '18px 0',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#A24DE8',
    background: '#F0E2FB',
    borderRadius: '8px',
    width: '214px',
  },
  buttonApplyClass: {
    padding: '18px 0',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    background: '#A24DE8',
    borderRadius: '8px',
    width: '214px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '28px',
  },
  banIconWrapper: {
    display: 'flex',
  },
}));

export default useStyles;
