import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  settings: {
    width: '20rem',
    backgroundColor: 'rgba(31, 31, 31, 0.9)',
    padding: '1.25rem',
    borderRadius: '1.5rem',
    position: 'absolute',
    top: 0,
    left: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  settingsHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '1.25rem',
  },
  settingsHeaderTitle: {
    margin: 0,
  },
  settingsListItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  settingsListItemLabel: {
    margin: '0 0 0 1.5rem',
    fontWeight: 700,
    fontSize: '1rem',
    color: '#7E7E7E',
  },
  settingsListItemLabelActive: {
    color: '#FFFFFF',
  },
  settingsListItemSwitchWrap: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  videoSelectAdditionalClass: {
    width: '100%',
  },
  micSelectAdditionalClass: {
    width: '100%',
  },
  settingsContainer: {
    display: 'flex',
    position: 'relative',
    alignSelf: 'center',
    paddingBottom: ({ isModerator }: { isModerator: boolean }) => (isModerator ? '10rem' : '5rem'),
  },
}));

export default useStyles;
