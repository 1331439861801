import React, { useState } from 'react';
import { ChatIcon, Button, ChatModal } from '../..';
import { observer } from 'mobx-react-lite';
import useStyles from './style';
import { registerKeyboardEvents, unregisterKeyboardEvents } from '../../../services/control';

type ChatMenuItemType = {
  isShowTextIcon?: boolean;
  buttonClass?: string;
};
const ChatMenuItem = observer(({ buttonClass }: ChatMenuItemType) => {
  const [isShowChat, setIsShowChat] = useState(false);

  const { container, chatModalClass } = useStyles();

  const handleOpen = () => {
    setIsShowChat(!isShowChat);
    unregisterKeyboardEvents();
  };

  const handleClose = () => {
    setIsShowChat(false);
    registerKeyboardEvents();
  };

  const handleChat = () => {
    if (isShowChat) {
      handleClose();
    } else {
      handleOpen();
    }
  };
  return (
    <>
      <div className={container}>
        <Button onClick={handleChat} className={buttonClass}>
          <ChatIcon />
        </Button>
      </div>

      <ChatModal isShow={isShowChat} handleClose={handleClose} chatModalClass={chatModalClass} />
    </>
  );
});

export default ChatMenuItem;
