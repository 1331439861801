import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Timer from './Timer';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
import { Roles } from '../../constants/user';
import { emitModeratorStartCountDown } from '../../services/game/emitsToGame/emits';

interface CountDownInterface {
  onStart?(): void;
  onEnd?(): void;
}

const Countdown = observer(({ onStart, onEnd }: CountDownInterface) => {
  const { user } = useStores();
  const [timerValue, setTimerValue] = useState<any>({});
  const [isTimerEnable, setTimerEnable] = useState<boolean>(false);

  const { root, button } = useStyles();

  function onToogleTimer(): void {
    if (isTimerEnable) {
      onEnd && onEnd();
      emitModeratorStartCountDown(moment().utc().valueOf(), false);
      setTimerEnable(false);
      return;
    }
    const endTime = moment()
      .utc()
      .add(timerValue.h, 'h')
      .add(timerValue.m, 'm')
      .add(timerValue.s, 's')
      .valueOf();

    onStart && onStart();
    emitModeratorStartCountDown(endTime, true);
    setTimerEnable(true);
  }

  function onTimerEnd(): void {
    setTimerEnable(false);
    emitModeratorStartCountDown(moment().utc().valueOf(), false);
  }

  useEffect(() => {
    if (user.timer.bEnable) {
      setTimerEnable(true);
    }
  }, [user.timer.timestamp, user.timer.bEnable]);

  return (
    <div className={root}>
      <Timer
        value={user.timer.timestamp}
        isEnable={isTimerEnable}
        onChange={setTimerValue}
        onEnd={onTimerEnd}
      />
      {user.role === Roles.MODERATOR && (
        <Button className={button} onClick={onToogleTimer}>
          {isTimerEnable ? 'STOP' : 'START'}
        </Button>
      )}
    </div>
  );
});

export default Countdown;
