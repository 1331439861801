import React from 'react';

type AssistantIconProps = {
  color?: string;
};

const AssistantIcon = ({ color = 'white' }: AssistantIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="44"
      fill="currentColor"
      className="bi bi-headset"
      viewBox="0 0 16 16"
    >
      <path
        x="12"
        y="12"
        width="7"
        height="10"
        d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"
        fill={color}
      />
    </svg>
  );
};

export default AssistantIcon;
