import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints: { values: { height_ipad_pro_11 } } }: Theme) => ({
  root: {
    userSelect: 'none',
    height: '100vh',
    width: '100vw',
    overflow: 'visible',
  },
  body: {
    margin: 0,
    overflowY: 'hidden',
    display: 'block',
    maxHeight: '100vh',
  },
  player: {
    width: '100%',
    height: '100vh',
    position: 'relative',
    backgroundColor: '#F3F3F3',
  },
  settingsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    position: 'absolute',
    bottom: 20,
    right: 20,
    zIndex: 100,
    '& > svg:last-child': {
      marginLeft: 20,
    },
    [`@media screen and (max-width: ${height_ipad_pro_11}px)`]: {
      bottom: 10,
      right: 10,
      '& > svg': {
        width: '22px',
        height: '22px',
      },
      '& > svg:last-child': {
        marginLeft: 10,
      },
    },
  },
  displayNone: {
    display: 'none',
  },
  notificationDeviceClass: {
    position: 'absolute',
    top: '40px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  '@global': {
    body: {
      height: '100vh',
    },
    '[aria-labelledby="simple-modal-title"] > div': {
      background: 'none !important',
    },

    '.clickable:active': {
      color: '#CAC9FD',
    },
    '.MuiInputBase-input': {
      padding: 0,
    },
    '.MuiList-root': {
      padding: '16px 0',
    },
    '.MuiSelect-icon': {
      top: 'inherit',
    },
    '*': {
      // userSelect: 'none',
      // '-moz-user-select': 'none',
      // '-ms-user-select': 'none',
      '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
      outline: 'none',
      cursor: 'url(icons/pointer.png) 2 2, pointer',
    },
  },
}));

export default useStyles;
