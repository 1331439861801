import { rootStore } from '../models/rootStore';
import {
  closeChannels,
  getStreamAndAppend,
  getStreamAppendAndPushToMs,
  changeStatusVideoTrack,
  changeStatusAudioTrack,
} from '../helpers/stream';

import { emitActiveMediaDevices } from '../services/game/emitsToGame/emits';

let audioContext: AudioContext | null;

export const existAudioDevice = () => {
  const {
    allSettings: { audioDevices },
  } = rootStore;

  const audioDevicesWithPermissions = audioDevices.filter((audio: any) => audio.label !== '');
  return audioDevicesWithPermissions.length > 0;
};

export const existVideoDevice = () => {
  const {
    allSettings: { videoDevices },
  } = rootStore;

  const videoDevicesWithPermissions = videoDevices.filter((video: any) => video.label !== '');
  return videoDevicesWithPermissions.length > 0;
};

export const handleRestartTestMic = () => {
  console.log('restart');
  handleStopTestMic();
  setTimeout(() => {
    handleStartTestMic();
  }, 5000);
};

export const handleStartTestMic = async () => {
  const {
    mySetting,
    wbs: { localStream },
  } = rootStore;

  if (localStream) {
    audioContext = new AudioContext();

    try {
      await audioContext.audioWorklet.addModule('worklet/check-mic.js');
    } catch (e) {
      console.log(e);
    }

    const source = audioContext.createMediaStreamSource(localStream);
    const node = new AudioWorkletNode(audioContext, 'check-mic');

    node.port.onmessage = (event) => {
      let _volume = 0;
      let _sensibility = 1;
      if (event.data.volume) _volume = event.data.volume;

      const testMicVolume = (_volume * 100) / _sensibility;
      mySetting.setTestMicVolume(testMicVolume);
    };

    source.connect(node).connect(audioContext.destination);
  }
};

export const handleStopTestMic = () => {
  audioContext?.suspend();
};

export const updateDevices = (
  refPlayerLocalStream: React.MutableRefObject<null>,
  refStartPageLocalStream: React.MutableRefObject<null>
) => {
  console.log('update device');
  // navigator.mediaDevices.ondevicechange = function () {
  const { connection } = rootStore;
  clearAllAudioDevices();
  clearAllVideoDevices();
  closeChannels();

  if (connection.isStartPage) {
    console.log('Это стартовая страница');
    // handleRestartTestMic();
    getStreamAndAppend(refStartPageLocalStream);
  } else {
    console.log('Это страница плеера');
    getStreamAppendAndPushToMs(refPlayerLocalStream);
  }
  // };
};

export const clearAllAudioDevices = () => {
  const { allSettings, mySetting } = rootStore;
  allSettings.clearAllAudioDevices();
  mySetting.setMyAudioDeviceId('');
  mySetting.setMyAudioDeviceLabel('');
};

export const clearAllVideoDevices = () => {
  const { allSettings, mySetting } = rootStore;
  allSettings.clearAllVideoDevices();
  mySetting.setMyVideoDeviceId('');
  mySetting.setMyVideoDeviceLabel('');
};

export const handleShowAudio = () => {
  const {
    mySetting: { isShowVideo, isShowAudio, setIsShowAudio, isMutedAudio },
  } = rootStore;
  if (!isMutedAudio) {
    setIsShowAudio(!isShowAudio);
    changeStatusAudioTrack(!isShowAudio);
    emitActiveMediaDevices(!isShowAudio, isShowVideo);
  }
};

export const handleShowVideo = () => {
  const {
    mySetting: { isShowVideo, isShowAudio, setIsShowVideo, isMutedVideo },
  } = rootStore;
  if (!isMutedVideo) {
    setIsShowVideo(!isShowVideo);
    changeStatusVideoTrack(!isShowVideo);
    emitActiveMediaDevices(isShowAudio, !isShowVideo);
  }
};

export const handleChangeMyVideoDevice = (
  videoDeviceLabel: string,
  handleUpdateLocalStream: any
) => {
  const {
    allSettings: { videoDevices },
    mySetting: { setMyVideoDeviceId, setMyVideoDeviceLabel },
    wbs,
  } = rootStore;

  if (videoDevices) {
    const videoDeviceId = videoDevices.find((videoDevice) => videoDevice.label === videoDeviceLabel)
      ?.deviceId;
    const beforeStream = wbs.localStream;
    if (beforeStream) {
      wbs.setIsEnabledVideoTrack(beforeStream, false);
      wbs.stopTracks(beforeStream);
    }

    setMyVideoDeviceId(videoDeviceId as string);
    setMyVideoDeviceLabel(videoDeviceLabel);

    handleUpdateLocalStream();

    const afterStream = wbs.localStream;
    if (afterStream) {
      wbs.setIsEnabledVideoTrack(afterStream, true);
    }
  }
};

export const handleChangeMyAudioDevice = (
  audioDeviceLabel: string,
  handleUpdateLocalStream: any
) => {
  const {
    allSettings: { audioDevices },
    mySetting: { setMyAudioDeviceId, setMyAudioDeviceLabel },
  } = rootStore;
  if (audioDevices) {
    const audioDeviceId = audioDevices.find((audioDevice) => audioDevice.label === audioDeviceLabel)
      ?.deviceId;
    setMyAudioDeviceId(audioDeviceId as string);
    setMyAudioDeviceLabel(audioDeviceLabel);
    handleUpdateLocalStream();
  }
};
