import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    color: '#140E17',
  },
  badge: {
    marginLeft: '12px',
    background: '#FFFFFF',
    padding: '2px 9px',
    borderRadius: '100px',

    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#140E17',
  },
}));

export default useStyles;
