import React from 'react';

interface PinInterface {
  size: string;
  activeColor?: string;
}

const PinIcon = ({ size, activeColor = '' }: PinInterface) => {
  const color: string = activeColor || '#7E7E7E';
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4875 17.2919C2.4875 25.4377 9.14585 32.0961 17.2917 32.0961C25.4375 32.0961 32.0959 25.4377 32.0959 17.2919C32.0959 9.14607 25.4375 2.48774 17.2917 2.48774C9.14585 2.48774 2.4875 9.14607 2.4875 17.2919ZM17.2917 1.00024C8.29585 1.00024 1 8.29607 1 17.2919C1 26.2877 8.29585 33.5836 17.2917 33.5836C26.2875 33.5836 33.5834 26.2877 33.5834 17.2919C33.5834 8.29607 26.2875 1.00024 17.2917 1.00024Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9689 15.012L18.6029 10.6461L16.7318 12.5172L21.0978 16.8831L22.9689 15.012ZM20.4741 8.77505C19.4407 7.74165 17.7652 7.74165 16.7318 8.77505L14.8607 10.6461L12.9896 12.5172L14.8607 14.3883L19.2266 18.7543L21.0978 20.6253L22.9689 18.7543L24.8399 16.8831C25.8733 15.8498 25.8733 14.1743 24.8399 13.1409L20.4741 8.77505ZM16.6862 23.6982L18.6029 25.6149L20.0583 23.328L22.9689 18.7543L14.8607 10.6461L10.2869 13.5567L8 15.012L9.91677 16.9288L16.6862 23.6982ZM18.1415 21.4113L19.5968 19.1244L14.4905 14.0182L12.2037 15.4735L18.1415 21.4113Z"
        fill={color}
      />
      <path
        d="M14.8609 10.6455L16.732 12.5166L14.4954 14.0222L12.9897 12.5166L14.8609 10.6455Z"
        fill={color}
      />
      <path
        d="M22.9698 18.7549L21.0987 16.8838L19.593 19.1203L21.0987 20.626L22.9698 18.7549Z"
        fill={color}
      />
      <path
        d="M12.366 19.3774L14.2371 21.2485L9.24747 24.9907L8.62378 24.367L12.366 19.3774Z"
        fill={color}
      />
    </svg>
  );
};

export default PinIcon;
