import React, { useEffect, useState } from 'react';
import { useStyles } from './style';
import {
  Button,
  Checkbox,
  ModeratorVolumeControl,
  AudioOnIcon,
  AudioMuteIcon,
  VideoOnIcon,
  VideoMuteIcon,
  ShareIcon,
  ShareMuteIcon,
} from '..';
import { ParticipantType } from '../../models/participants';
import { generateAvatar } from '../../helpers/random';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';

type ParticipantItemType = {
  isSelectAllParticipants: boolean;
  participant: ParticipantType;
  changeSelectParticipants: any;
  handleOpenBanModal: any;
};
const ParticipantItem = observer(
  ({
    participant,
    isSelectAllParticipants,
    changeSelectParticipants,
    handleOpenBanModal,
  }: ParticipantItemType) => {
    const {
      avatar,
      username,
      voiceVolume,
      toggleVoiceIsMuted,
      toggleVideoIsMuted,
      toggleScreenSharingIsMuted,
      voiceIsMuted,
      videoIsMuted,
      screenSharingIsMuted,
      setVoiceVolume,
    } = participant;

    const [isChecked, setIsChecked] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [isDisableAudio, setIsDisableAudio] = useState(false);
    const [isDisableVideo, setIsDisableVideo] = useState(false);
    const [isDisableShare, setIsDisableShare] = useState(false);

    const {
      container,
      iconAndNameClass,
      iconContainerClass,
      iconClass,
      nameClass,
      banClass,
      banButtonClass,
      checkboxAndIconAndNameContainer,
      settingsContainer,
      volumeControlContainer,
      audioIcon,
      videoIcon,
      shareIcon,
      mockIcon,
      mockButton,
    } = useStyles({ isChecked })();

    useEffect(() => {
      setIsDisableAudio(voiceIsMuted);
    }, [voiceIsMuted]);

    useEffect(() => {
      setIsDisableVideo(videoIsMuted);
    }, [videoIsMuted]);

    useEffect(() => {
      setIsDisableShare(screenSharingIsMuted);
    }, [screenSharingIsMuted]);

    useEffect(() => {
      setIsChecked(isSelectAllParticipants);
    }, [isSelectAllParticipants]);

    const handleChangeAudio = async () => {
      setIsDisableAudio(!isDisableAudio);
      await toggleVoiceIsMuted();
    };

    const handleChangeVideo = async () => {
      setIsDisableVideo(!isDisableVideo);
      await toggleVideoIsMuted();
    };

    const handleChangeShare = async () => {
      setIsDisableShare(!isDisableShare);
      await toggleScreenSharingIsMuted();
    };

    const onChange = () => {
      changeSelectParticipants(participant, isChecked);
      setIsChecked(!isChecked);
    };

    const handleMouseOver = () => {
      setIsHovering(true);
    };

    const handleMouseOut = () => {
      setIsHovering(false);
    };

    return (
      <div className={container} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <div className={checkboxAndIconAndNameContainer}>
          <div>
            <Checkbox isChecked={isChecked} onChange={onChange} />
          </div>
          <div className={iconAndNameClass}>
            <div className={iconContainerClass}>
              <img className={iconClass} src={avatar ?? generateAvatar()} alt="icon" />
            </div>
            <div className={nameClass}>{username}</div>
          </div>
        </div>
        <div className={settingsContainer}>
          <div className={volumeControlContainer}>
            <ModeratorVolumeControl volume={voiceVolume} setVolume={setVoiceVolume} />
          </div>
          <div className={audioIcon} onClick={handleChangeAudio}>
            {isDisableAudio ? (
              <AudioMuteIcon />
            ) : isChecked || isHovering ? (
              <AudioOnIcon color="#140E17" />
            ) : (
              <div className={mockIcon}></div>
            )}
          </div>
          <div className={videoIcon} onClick={handleChangeVideo}>
            {isDisableVideo ? (
              <VideoMuteIcon />
            ) : isChecked || isHovering ? (
              <VideoOnIcon color="#140E17" />
            ) : (
              <div className={mockIcon}></div>
            )}
          </div>
          <div className={shareIcon} onClick={handleChangeShare}>
            {isDisableShare ? (
              <ShareMuteIcon />
            ) : isChecked || isHovering ? (
              <ShareIcon color="#140E17" />
            ) : (
              <div className={mockIcon}></div>
            )}
          </div>
          <div className={banClass}>
            <Button
              onClick={() => handleOpenBanModal([participant])}
              className={classnames(banButtonClass, !isChecked && !isHovering && mockButton)}
            >
              ban
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default ParticipantItem;
