import { MessageType } from '../staticTypes/messageTypes';
import { emitControlInteraction } from '../wsMessageEmitter';
import { normalizeAndQuantizeUnsigned } from '../mathLibraly';
import { logApi, LOG_LEVEL } from '@pxs-infra/logger';
import { webRtcPlayerObject } from '../webRtcWrapper';

export const registerGamePadEvents = () => {
  window.addEventListener('gamepadconnected', function (e: any) {
    logApi(LOG_LEVEL, 'Gamepad connected:', e.gamepad);
  });
  window.addEventListener('gamepaddisconnected', function (e: any) {
    logApi(LOG_LEVEL, 'Gamepad disconnected:', e.gamepad);
  });

  if ('getGamepads' in navigator) {
    logApi(LOG_LEVEL, 'Browser support Gamepad Api');
    const gamepad = navigator.getGamepads()[0];

    if (gamepad) {
      setInterval(() => {
        const [axes0, axes1, axes2, axes3] = gamepad.axes;

        const leftStickHorizontal = applyDeadzone(axes0, 0.25);
        const leftStickVertical = applyDeadzone(axes1, 0.25);
        const rightStickHorizontal = applyDeadzone(axes2, 0.25);
        const rightStickVertical = applyDeadzone(axes3, 0.25);
        console.log(`Left stick at (${leftStickHorizontal}, ${leftStickVertical})`);
        console.log(`Right stick at (${rightStickHorizontal}, ${rightStickVertical})`);
      }, 100);
    }
  } else {
    logApi(LOG_LEVEL, 'Browser not support Gamepad Api');
  }
};

export const registerTouchEvents = () => {
  console.log('Touch events registration!');
  // Нам нужно присвоить каждому пальцу уникальный идентификатор.
  // Мы делаем это, сопоставляя каждый объект Touch с идентификатором.
  let fingers = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
  let fingerIds = {} as any;

  function rememberTouch(touch: any) {
    let finger = fingers.pop();
    console.log('Current fingers', fingers);
    if (finger === undefined) {
      console.log('exhausted touch indentifiers');
    }
    fingerIds[touch.identifier] = finger;
    console.log('onRemember', fingers, fingerIds);
  }

  function forgetTouch(touch: any) {
    fingers.push(fingerIds[touch.identifier]);
    delete fingerIds[touch.identifier];
    fingers.sort((a, b) => b - a);
    console.log('Deleting touch identifier', touch.identifier);
    console.log('onForget', fingers, fingerIds);
  }

  function emitTouchData(type: any, touches: any) {
    let data = new DataView(new ArrayBuffer(2 + 6 * touches.length));
    data.setUint8(0, type);
    data.setUint8(1, touches.length);
    let byte = 2;
    for (let t = 0; t < touches.length; t++) {
      let touch = touches[t];
      let x = touch.clientX - webRtcPlayerObject.video.offsetLeft;
      let y = touch.clientY - webRtcPlayerObject.video.offsetTop;
      let coord = normalizeAndQuantizeUnsigned(x, y);
      data.setUint16(byte, coord.x, true);
      byte += 2;
      data.setUint16(byte, coord.y, true);
      byte += 2;
      data.setUint8(byte, fingerIds[touch.identifier]);
      byte += 1;
      data.setUint8(byte, 255 * touch.force);
      byte += 1;
    }
    emitControlInteraction(data.buffer);
  }

  webRtcPlayerObject.video.ontouchstart = function (e: any) {
    // Присваиваем уникальный идентификатор каждому касанию.
    for (let t = 0; t < e.changedTouches.length; t++) {
      rememberTouch(e.changedTouches[t]);
    }
    emitTouchData(MessageType.TouchStart, e.changedTouches);
    e.preventDefault();
  };

  webRtcPlayerObject.video.ontouchend = function (e: any) {
    emitTouchData(MessageType.TouchEnd, e.changedTouches);

    // Повторно меняем циклы уникальных идентификаторов, ранее назначенных каждому касанию.
    for (let t = 0; t < e.changedTouches.length; t++) {
      forgetTouch(e.changedTouches[t]);
    }
    e.preventDefault();
  };

  webRtcPlayerObject.video.ontouchmove = function (e: any) {
    emitTouchData(MessageType.TouchMove, e.touches);
    e.preventDefault();
  };
};

/**
 * «Мертвая зона» - это порог, используемый для предотвращения использования значений
 * ниже определенного значения для управления игрой.
 * Следующая функция применяет мертвую зону.
 * Порог также вычитается из любого значения, превышающего порог, так что значение порога равно 0, а не, например, внезапно 0,25.
 * var joystickX = applyDeadzone(gamepad.axes[0], 0.25);
 */
const applyDeadzone = (number: number, threshold: number) => {
  let percentage = (Math.abs(number) - threshold) / (1 - threshold);

  if (percentage < 0) percentage = 0;

  return percentage * (number > 0 ? 1 : -1);
};
