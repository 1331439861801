import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  startPageSlider: {
    display: 'flex !important',
    height: '100% !important',
    width: '100% !important',
    alignItems: 'center',

    padding: '0 !important',
    '& .MuiSlider-thumb': {
      display: 'none',
      height: 0,
      width: 0,
    },
    '& .MuiSlider-track': {
      display: 'flex',
      height: '8px', //26px
      borderRadius: '8px',
      left: '2px !important',
      position: 'absolute',
      background: 'linear-gradient(90deg, #3D65B0 0%, #00BAF2 121.43%)',
    },
    '& .MuiSlider-rail': {
      height: '8px',
      borderRadius: '8px',
      backgroundColor: 'white',
      border: '1px solid white',
      opacity: 1,
      position: 'relative',
      width: '37px',
    },
  },
}));

export default useStyles;
