import {
  PEER_CONNECTION_STATUS_CLOSED,
  PEER_CONNECTION_STATUS_CONNECTED,
  PEER_CONNECTION_STATUS_DISCONNECTED,
  PEER_CONNECTION_STATUS_FAILED,
} from '../../constants/wbs';
import { setIce } from './socket/send/setIce';
import { loggerError, loggerLog } from './logger';
import { RefObject } from 'react';
import { getIsChangingGameServer, getPcByStreamType, getSocket, setPcByStreamType } from './store';
import { CreatePeerConnectionType } from '../../types/wbs';
import { closeConnection } from './socket';
import { publishLocal, reconnect } from '.';

export const destroyPeerConnection = (
  pc: RTCPeerConnection | null,
  refRemoteStream?: RefObject<HTMLDivElement> | void
) => {
  if (pc) {
    loggerLog('destroy peerConnection');

    pc.close();
    pc.onicecandidate = null;
    pc.ontrack = null;
    pc.onnegotiationneeded = null;
    pc.oniceconnectionstatechange = null;
    pc.onicegatheringstatechange = null;
    pc.onsignalingstatechange = null;
    pc.onconnectionstatechange = null;

    // pc = null

    if (refRemoteStream) {
      const div = refRemoteStream.current;

      if (div) {
        while (div.firstChild) {
          div.removeChild(div.firstChild);
        }
      }
    }
  }
};

export const createPeerConnection = ({
  stream,
  streamType,
  pc,
  deviceId,
  refRemoteStream,
}: CreatePeerConnectionType) => {
  loggerLog('create peerConnection');

  pc.onicegatheringstatechange = handleICEGatheringStateChangeEvent;
  pc.onicecandidate = (e: any) => handleICECandidateEvent(e, deviceId);
  pc.oniceconnectionstatechange = handleICEConnectionStateChangeEvent;
  pc.onconnectionstatechange = (e: any) => handleConnectionStateChange(e, pc);
  pc.onnegotiationneeded = handleNegotiationNeededEvent;
  pc.onsignalingstatechange = (e: any) => handleSignalingStateChangeEvent(e, pc);
  (pc as any).onicecandidateerror = handleIceCandidateError;
  pc.ontrack = (e: any) => handleTrackEvent(e, refRemoteStream);

  stream.getTracks().forEach((track) => pc.addTrack(track, stream));
  setPcByStreamType(streamType, pc);
};

/**
 * Происходит всякий раз, когда изменяется агрегированное состояние соединения.
 *  Агрегатное состояние - это комбинация состояний всех отдельных сетевых транспортов, используемых соединением.
 */
const handleConnectionStateChange = (e: any, pc: RTCPeerConnection) => {
  loggerLog('connection state:', e.target.connectionState);
  switch (e.target.connectionState) {
    case PEER_CONNECTION_STATUS_CONNECTED:
      break;
    case PEER_CONNECTION_STATUS_DISCONNECTED:
    case PEER_CONNECTION_STATUS_CLOSED:
    case PEER_CONNECTION_STATUS_FAILED:
      destroyPeerConnection(pc);
      break;
  }
};

/**
 * Получаем iceCandidate из локального уровня ICE и
 * передаем их в медиасервер
 */
const handleICECandidateEvent = async (e: any, deviceId: number) => {
  try {
    if (e.candidate && e.candidate.candidate) {
      loggerLog('local candidate:', e.candidate);
      setIce(e.candidate, deviceId);
    }
  } catch (event) {
    loggerError('error ice candidate', event);
  }
};

const handleIceCandidateError = ({
  url,
  errorText,
  errorCode,
  hostCandidate,
}: RTCPeerConnectionIceErrorEvent & { hostCandidate: string }) => {
  loggerError('on ice candidate error', {
    url,
    errorText,
    errorCode,
    hostCandidate,
  });
};

/**
 * Этот обработчик trackсобытия вызывается локальным уровнем WebRTC при добавлении дорожки к соединению.
 * Это позволяет вам подключать входящие мультимедийные данные к элементу, например, для его отображения.
 */
const handleTrackEvent = (e: any, refRemoteStream?: RefObject<HTMLDivElement>) => {
  if (e.track.kind === 'video' && refRemoteStream) {
    loggerLog('on track');
    const groupsDiv = refRemoteStream.current;

    const video = document.createElement('video');
    video.srcObject = e.streams[0];
    video.autoplay = true;
    video.controls = true;
    video.muted = true;
    video.id = `wbs_video`;

    groupsDiv?.appendChild(video);
  }
};

/**
 * После того, как вызывающий абонент создал его  RTCPeerConnection,
 * создал медиапоток и добавил свои треки в соединение, браузер доставит negotiationneededсобытие в объект,
 * чтобы RTCPeerConnectionуказать, что он готов начать переговоры с другим одноранговым узлом.
 * Эта функция вызывается всякий раз, когда инфраструктуре WebRTC требуется, чтобы вы заново начали процесс согласования сеанса.
 * Его работа - создать и отправить предложение вызываемому с просьбой связаться с нами.
 */
const handleNegotiationNeededEvent = () => {
  loggerLog('on negotiation needed');
};

/**
 * Срабатывает, когда iceconnectionstatechange события отправляются на уровень RTCPeerConnectionICE
 * при изменении состояния соединения (например, когда вызов завершается с другого конца).
 * Это может помочь вам узнать, когда соединение не удалось или было потеряно.
 */
const handleICEConnectionStateChangeEvent = (e: any) => {
  const { iceConnectionState } = e.target;
  loggerLog('ice connection state:', iceConnectionState);
  const wbsSocket = getSocket();
  if (
    iceConnectionState === 'disconnected' ||
    iceConnectionState === 'failed' ||
    iceConnectionState === 'closed'
  ) {
    if (wbsSocket) {
      closeConnection({ reason: `ice connection state ${iceConnectionState}` });
    }
    if (!getIsChangingGameServer()) {
      setTimeout(() => {
        reconnect();
        publishLocal();
      }, 1000);
    }
  }
};

/**
 * Срабатывает, когда icegatheringstatechange события используются,
 * чтобы сообщить вам об изменении состояния процесса сбора кандидатов ICE(например, начало сбора кандидатов или завершение переговоров).
 * может быть полезно наблюдать за этими событиями в целях отладки, а также для определения того, когда завершился сбор кандидатов.
 */
const handleICEGatheringStateChangeEvent = (e: any) => {
  loggerLog('ice gathering state:', e.target.iceGatheringState);
};

/**
 * Срабатывает, если состояние сигнализации меняется на closed, мы аналогичным образом закрываем вызов.
 */
const handleSignalingStateChangeEvent = (e: any, pc: RTCPeerConnection) => {
  loggerLog('on signaling state change:', e.target.signalingState);
  loggerLog('peer signaling state:', pc.signalingState);
  switch (pc.signalingState) {
    case PEER_CONNECTION_STATUS_CLOSED:
      destroyPeerConnection(pc);
      break;
  }
};

const replaceLocalSdp = (offer: any) => {
  offer.sdp = offer.sdp.replace(/(m=video.*\r\n)/g, `$1b=AS:15000000\r\n`);
  return offer;
};

export const createOffer = async (streamType: string): Promise<void | string> => {
  const defaultSdpOptions = {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
    iceRestart: true,
  };

  try {
    const pc = getPcByStreamType(streamType);

    if (pc) {
      const offer = replaceLocalSdp(await pc.createOffer(defaultSdpOptions));
      await pc.setLocalDescription(offer);

      return offer.sdp;
    }
  } catch (e) {
    loggerError('error create local sdp:', e);
  }
};
