import React from 'react';
import {
  VideoMenuItem,
  AudioMenuItem,
  ChatMenuItem,
  Recorder,
  ScreenShareButton,
  // ModeratorSettingsMenuItem,
  FullScreenMenuItem,
  TeleportMenuItem,
  BuyButton,
  ShoppingCartMenuItem,
  // ScreenShareMenuItem,
  // IntercomHelpchatIcon,
  // Button,
  // LogoutMenuItem,
} from '../..';
import useStyles from './style';
import { useStores } from '../../../hooks/useStores';
// import { Roles } from '../../../constants/user';
import { isDesktop } from 'react-device-detect';
import classnames from 'classnames';

const TabbarMenuDesktop = ({ handleUpdateLocalStream }: { handleUpdateLocalStream: any }) => {
  const {
    container,
    leftContainer,
    centerContainer,
    // mockButton,
    mockIcon,
    buttonClass,
    chatBackgroundClass,
    teleportBackgroundClass,
    recorderBackroundClass,
    buyContainer,
    shoppingCartBackgroundClass,
  } = useStyles();
  const {
    // user: { role },
    connection: { isLoading },
  } = useStores();

  // const handleIntercomHelpchat = () => {
  //   console.log('IntercomHelpchatIcon');
  // };

  return (
    <>
      {!isLoading && isDesktop && (
        <div className={container}>
          <div className={leftContainer}>
            {/* {role === Roles.MODERATOR && (
              <div>
                <ModeratorSettingsMenuItem />
              </div>
            )} */}
            {/* <div>
              <FullScreenMenuItem />
            </div>
            <div>
              <Button onClick={handleIntercomHelpchat} className={intercomHelpchatIcon}>
                <IntercomHelpchatIcon />
              </Button>
            </div>
            <div>
              <LogoutMenuItem />
            </div> */}
          </div>
          <div className={buyContainer}>
            <ScreenShareButton />
            <BuyButton />
          </div>
          <div className={centerContainer}>
            <AudioMenuItem handleUpdateLocalStream={handleUpdateLocalStream} />
            <VideoMenuItem handleUpdateLocalStream={handleUpdateLocalStream} />
            {/**
             * !!! PERSONAL SCREENSHARE
             */}
            {/* <ScreenShareMenuItem /> */}

            <TeleportMenuItem buttonClass={classnames(buttonClass, teleportBackgroundClass)} />
            <ChatMenuItem buttonClass={classnames(buttonClass, chatBackgroundClass)} />
            <Recorder buttonClass={classnames(buttonClass, recorderBackroundClass)} />
            <FullScreenMenuItem />
            <ShoppingCartMenuItem
              buttonClass={classnames(buttonClass, shoppingCartBackgroundClass)}
            />
          </div>
          {/* <div className={mockButton}></div> */}
          <div className={mockIcon}></div>
        </div>
      )}
    </>
  );
};

export default TabbarMenuDesktop;
