import React, { useState } from 'react';
import { TeleportIcon, Button, TeleportListModal } from '../..';
import useStyles from './style';

type TeleportMenuItemType = {
  buttonClass?: string;
};
const TeleportMenuItem = ({ buttonClass }: TeleportMenuItemType) => {
  const [isShowTeleportListModal, setIsShowTeleportListModal] = useState(false);
  const { container } = useStyles();

  const handleTeleport = () => {
    setIsShowTeleportListModal(!isShowTeleportListModal);
  };

  const handleClose = () => {
    setIsShowTeleportListModal(false);
  };

  return (
    <>
      <div className={container}>
        <Button onClick={handleTeleport} className={buttonClass}>
          <TeleportIcon />
        </Button>
      </div>
      <TeleportListModal isShow={isShowTeleportListModal} handleClose={handleClose} />
    </>
  );
};

export default TeleportMenuItem;
