import { makeStyles } from '@material-ui/core/styles';
import { ScrollbarStyle } from '.';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '15px',
    backgroundColor: '#FFFFFF',
  },
  scrollableContentWrap: {
    width: 'calc(100% - 24px)',
    overflow: 'hidden',
  },
  scrollableContent: {
    paddingRight: 24,
    marginRight: -24,
    paddingLeft: 24,
    marginTop: 24,
    border: '0 none',
    overflowX: 'hidden',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  sliderWrap: {
    padding: 0,
    width: 24,
    display: 'inline-block',
    transform: 'scaleY(0.989)',
  },
  slider: {
    height: 24,
    opacity: 0,
    outline: 'none',
    direction: 'rtl',
    background: 'transparent',
    transform: 'rotate(-90deg)',
    transition: 'opacity .2s',
    '-webkit-appearance': 'none',
    ' -webkit-transition': '.2s',
    '&::-webkit-slider-thumb': {
      appearance: 'none',
      border: 'none',
      '-webkit-appearance': 'none',
      background: 'rgba(0, 0, 0, 0.3)',
      height: 8,
      borderRadius: 16,
      width: ({ width }: ScrollbarStyle) => width,
    },
    '&::-moz-range-thumb': {
      border: 'none',
      background: 'rgba(0, 0, 0, 0.3)',
      height: 8,
      borderRadius: 16,
    },
    '&:hover': {
      opacity: 1,
    },
  },
}));

export default useStyles;
