import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '40px 20px 20px 20px',
  },
  card: {
    background: '#F3F3F3',
    borderRadius: '8px',
    padding: '20px',
  },
  participantsClass: {
    marginBottom: '24px',
  },
  soundSettingsClass: {},
  participantsTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  participantsIconsContainer: {
    display: 'flex',
    paddingTop: '12px',
    paddingBottom: '40px',
  },
  bannedParticipantsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #DDDDDD',
    paddingTop: '22px',
    paddingBottom: '6px',
  },
  participantIconClass: {
    width: '40px',
    height: '40px',
    borderRadius: '1000px',
  },
  participantIconItemClass: {
    paddingRight: '4px',
  },
  participantIconOthersClass: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    color: '#A2A2A2',
    background: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  switchContainerClass: {
    paddingTop: '12px',
  },
}));

export default useStyles;
