import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  logoutModalContainer: {
    width: '100%',
    padding: '24px',
  },
  logoutModalClass: {
    width: '520px',
    display: 'flex',
    borderRadius: '8px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '320px',
    },
  },
  logoutModalWrapClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoutModalTitleWrap: {
    display: 'flex',
    paddingBottom: '40px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  logoutModalTitleText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '44px',
    color: '#140E17',
    [theme.breakpoints.down('md')]: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
  logoutModalTitleIcon: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '60px',
    },
  },
  logoutModalButton: {
    background: '#A24DE8',
    borderRadius: '8px',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: ' #FFFFFF',
    width: '100%',
    padding: '18px 0',
    [theme.breakpoints.down('md')]: {
      padding: '16px 0',
    },
  },
}));

export default useStyles;
