import { ParticipantType } from '../models/participants';
import { ChatKinds } from '../constants/chats';
import { ChatType } from '../models/chats';
import * as EventsAPI from './game/emitsToGame/emits';
import { rootStore } from '../models/rootStore';
import { MUTING_TARGETS } from '../constants/eventsManagement';

export const createDirectChat = async (participant: ParticipantType): Promise<ChatProps> => {
  return {
    chatId: Date.now().toString(),
    kind: ChatKinds.DIRECT,
    userId: participant.userId,
    title: null,
    messages: [],
    coverImage: null,
  };
};

export const sendChatMessage = async ({ chatId, text }: ChatMessageProps): Promise<void> => {
  const { user, chatsModule } = rootStore;

  const chat = chatsModule.chats.get(chatId) as ChatType;

  if (chat.kind === ChatKinds.PUBLIC) {
    EventsAPI.sendChatMessage({
      channel: 'All',
      message: text,
    });
  } else if (chat.kind === ChatKinds.DIRECT) {
    EventsAPI.sendChatMessage({
      channel: 'Private',
      message: text,
      userId: chat.userId?.toString() as string,
    });

    /* we should put the new message to the chat independently
     *   because parsing the event from server we'll can not recognize the chat (channel) properly
     * */

    const chatMessageId = Date.now().toString();

    chat.addMessage({
      chatId: chat.chatId,
      chatMessageId,
      authorUserId: user.userId,
      createdAt: new Date(),
      isRead: true,
      text,
    });

    /* ******************************************************* */
  } else {
    throw new Error(`Not implemented yet!`);
  }
};

export const setVoiceVolume = async (userId: string, newValue: number) => {
  EventsAPI.emitChangeVolumeOtherUser(userId, +(newValue / 100).toFixed(2));
};

export const setVideoIsMuted = async (userId: string, newValue: boolean) => {
  EventsAPI.emitMuteOtherUser(userId, newValue, MUTING_TARGETS.Video);
};

export const setVoiceIsMuted = async (userId: string, newValue: boolean) => {
  EventsAPI.emitMuteOtherUser(userId, newValue, MUTING_TARGETS.Audio);
};

export const startSharingScreen = async (type: string, screenShareTargetId?: string | number) => {
  EventsAPI.emitUserScreenShareStart(type, screenShareTargetId);
};

export const stopSharingScreen = async () => {
  EventsAPI.emitUserScreenShareCancel();
};

export const stopScreenShareByModerator = async (screenShareTargetId: number, userId: string) => {
  EventsAPI.emitModeratorScreenShareCancel(screenShareTargetId, userId);
};

export const updateChatWidgetVisibility = async (newValue: boolean) => {
  EventsAPI.emitUpdateBackgroundChatVisibility(newValue);
};

export const banUser = async (userId: string) => {
  EventsAPI.emitModeratorBanUser(userId);
};

export const kickUser = async (userId: string) => {
  EventsAPI.emitModeratorKickUser(userId);
};
