import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import getThemeByName from '../themes';

export const CustomThemeContext = React.createContext(getThemeByName('dark'));

const CustomThemeProvider = ({ children }: any) => {
  const [themeName, setThemeName] = useState('dark');
  const theme = getThemeByName(themeName);

  return (
    <CustomThemeContext.Provider value={setThemeName}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
