import icon1 from './icons/1.png';
import icon2 from './icons/2.png';
import icon3 from './icons/3.png';
import icon4 from './icons/4.png';
import icon5 from './icons/5.png';
import { names } from '../constants/user';

export const generateCallbackId = (length = 25) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const generate = (items: any) => {
  return items[Math.floor(Math.random() * items.length)];
};
export const generateAvatar = () => {
  const icons = [icon1, icon2, icon3, icon4, icon5];

  return generate(icons);
};

export const generateName = () => {
  return generate(names);
};
