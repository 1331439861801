import { INFO_LEVEL, logWbs } from '@pxs-infra/logger';
import {
  closeConnection,
  openConnection,
  restartConnection,
  deviceInfo,
  subscribeEvents,
  unSubscribeEvents,
  wbsInfo,
} from './socket';
import { rootStore } from '../../models/rootStore';
import { publishLocalChannel } from '../../helpers/stream';

/*
 * Событие срабатывает при
 * закрытии websocket канала
 * или закрытии datachannel канала
 * или получении события StartedTravel
 */
export const disconnect = () => {
  logWbs(INFO_LEVEL, 'Disconnect wbs');
  closeConnection({});
};

/*
 * Событие срабатывает при
 * открытом канале websocket-а
 * и открытом канале datachannel-а
 * и получении события SuccessedTravel
 * и получения события ConnectToWbs если данные были нулевые
 */
export const connect = () => {
  logWbs(INFO_LEVEL, 'Сonnect to wbs');
  const {
    wbs: { msDomain },
  } = rootStore;

  if (msDomain) {
    const url = `wss://${msDomain}`;
    openConnection({ url });
  }
};

/*
 * Событие срабатывает при
 * закрытии wbs socket канала
 * или получения события ConnectToWbs если данные не нулевые
 */
export const reconnect = () => {
  logWbs(INFO_LEVEL, 'Reconnect wbs');
  const {
    wbs: { msDomain },
  } = rootStore;

  if (msDomain) {
    const url = `wss://${msDomain}`;
    restartConnection({ url });
  }
};

export const publishLocal = () => {
  const {
    wbs: { localStream },
  } = rootStore;

  if (localStream) {
    logWbs(INFO_LEVEL, 'publish local channel when receive event from game "connectToWbs"');
    setTimeout(() => publishLocalChannel(localStream), 5000);
  }
};

type WbsData = {
  msDomain: string | null;
  localChannel: number | null;
};

export const saveData = ({ msDomain, localChannel }: WbsData) => {
  const {
    wbs: { setLocalChannel, setMsDomain },
  } = rootStore;
  setMsDomain(msDomain);
  setLocalChannel(localChannel);
};

export const initTestWbs = () => {
  const {
    wbs: { getMsDomain, setMsDomain, getLocalChannel, setLocalChannel },
  } = rootStore;
  (window as any).wbs = {
    getMsDomain,
    setMsDomain,
    getLocalChannel,
    setLocalChannel,
    disconnect,
    connect,
    reconnect,
    publishLocal,
    deviceInfo,
    subscribeEvents,
    unSubscribeEvents,
    wbsInfo,
  };
};
