import React from 'react';
import { Switch as SSwitch } from '@material-ui/core/';
import useStyles from './style';

export type SwitchType = {
  onToggle?(isChecked: boolean): void;
  value: boolean;
  isDisabled: boolean;
};

const Switch = ({ onToggle, value, isDisabled, ...props }: SwitchType) => {
  const { root, focusVisible, switchBase, thumb, track, checked } = useStyles({
    isDisabled,
    value,
  });

  return (
    <SSwitch
      checked={value}
      disableRipple
      focusVisibleClassName={focusVisible}
      classes={{
        root: root,
        switchBase: switchBase,
        thumb: thumb,
        track: track,
        checked: checked,
      }}
      onChange={() => {
        onToggle && onToggle(!value);
      }}
      {...props}
    />
  );
};

export default Switch;
