import React from 'react';
import useStyles from './style';

type ShareIconProps = {
  background?: string;
  color?: string;
};

const ScreenIcon = ({ background = 'black', color = 'white' }: ShareIconProps) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      x="20"
      y="15"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <mask
        id="mask0_909_17475"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="20"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.66699 1.5C2.01014 1.5 0.666992 2.84314 0.666992 4.5V11.3333C0.666992 12.9902 2.01014 14.3333 3.66699 14.3333H6.66699C7.21928 14.3333 7.66699 13.8856 7.66699 13.3333C7.66699 12.781 7.21928 12.3333 6.66699 12.3333H3.66699C3.11471 12.3333 2.66699 11.8856 2.66699 11.3333V4.5C2.66699 3.94771 3.11471 3.5 3.66699 3.5H16.3323C16.8846 3.5 17.3323 3.94772 17.3323 4.5V11.3333C17.3323 11.8856 16.8846 12.3333 16.3323 12.3333H13.3337C12.7814 12.3333 12.3337 12.781 12.3337 13.3333C12.3337 13.8856 12.7814 14.3333 13.3337 14.3333H16.3323C17.9892 14.3333 19.3323 12.9902 19.3323 11.3333V4.5C19.3323 2.84315 17.9892 1.5 16.3323 1.5H3.66699ZM10.6146 5.87754C10.2533 5.59637 9.74732 5.59637 9.38609 5.87754L6.05276 8.47214C5.61694 8.81137 5.53864 9.43968 5.87787 9.8755C6.2171 10.3113 6.84541 10.3896 7.28123 10.0504L9.00033 8.71228V17.5C9.00033 18.0523 9.44804 18.5 10.0003 18.5C10.5526 18.5 11.0003 18.0523 11.0003 17.5V8.71228L12.7194 10.0504C13.1552 10.3896 13.7835 10.3113 14.1228 9.8755C14.462 9.43968 14.3837 8.81137 13.9479 8.47214L10.6146 5.87754Z"
          fill={background}
        />
      </mask>
      <g mask="url(#mask0_909_17475)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default ScreenIcon;
