import React from 'react';

interface ParticipantsInCircleIconInterface {
  size: string;
  activeColor?: string;
}

const ParticipantsInCircleIcon = ({
  size,
  activeColor = '',
}: ParticipantsInCircleIconInterface) => {
  const color: string = activeColor || '#7E7E7E';
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4875 17.2917C2.4875 25.4375 9.14583 32.0958 17.2917 32.0958C25.4375 32.0958 32.0958 25.4375 32.0958 17.2917C32.0958 9.14583 25.4375 2.4875 17.2917 2.4875C9.14583 2.4875 2.4875 9.14583 2.4875 17.2917ZM17.2917 1C8.29583 1 1 8.29583 1 17.2917C1 26.2875 8.29583 33.5833 17.2917 33.5833C26.2875 33.5833 33.5833 26.2875 33.5833 17.2917C33.5833 8.29583 26.2875 1 17.2917 1Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 18.3867C19.4159 18.3867 21.3982 16.3956 21.3982 13.9689C21.3982 11.5422 19.4159 9.55111 17 9.55111C14.5841 9.55111 12.6018 11.5422 12.6018 13.9689C12.6018 16.3956 14.5841 18.3867 17 18.3867ZM17 21C20.8407 21 24 17.8889 24 13.9689C24 10.0489 20.8407 7 17 7C13.1593 7 10 10.1111 10 14.0311C10 17.9511 13.1593 21 17 21Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1523 24.2128C13.6815 24.2128 11.344 26.267 10.6357 27.1878C10.1398 27.8253 9.21899 27.967 8.58149 27.4711C7.94399 26.9753 7.80233 26.0545 8.29816 25.417C9.36066 23.9295 12.5482 21.2378 17.1523 21.2378C20.0565 21.2378 22.3232 22.2295 23.8815 23.292C24.6607 23.7878 25.2982 24.2836 25.7232 24.7086C25.9357 24.9211 26.1482 25.0628 26.219 25.2045C26.219 25.2045 26.219 25.2045 26.2898 25.2753C26.3607 25.3461 26.3607 25.3461 26.3607 25.3461C26.9273 25.842 27.069 26.692 26.644 27.3295C26.1482 27.967 25.2273 28.1795 24.5898 27.6836C24.4482 27.6128 24.3773 27.4711 24.3065 27.4711C24.3065 27.4711 24.2357 27.4003 24.1648 27.3295C24.0232 27.1878 23.8815 27.117 23.7398 26.9045C23.3857 26.5503 22.8898 26.1961 22.3232 25.7711C21.0482 24.9211 19.3482 24.2128 17.1523 24.2128Z"
        fill={color}
      />
    </svg>
  );
};

export default ParticipantsInCircleIcon;
