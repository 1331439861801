import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    top: 0,
    left: '6.5rem',
    paddingTop: ({ isModerator }: { isModerator: boolean }) => (isModerator ? 0 : '2rem'),
    paddingBottom: ({ isModerator }: { isModerator: boolean }) => (isModerator ? '4rem' : 0),
    zIndex: 100,
    position: 'absolute',
  },
  button: {
    width: '15rem',
    height: '3rem',
    border: '.125rem solid #F1F1F1',
    borderRadius: '1.5rem',
    marginTop: '1.25rem',
    backgroundColor: '#262626',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '1rem',
    textAlign: 'center',
    letterSpacing: '0.045em',
    color: '#F1F1F1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
