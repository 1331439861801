import React from 'react';

const LogoIcon = () => {
  return (
    // <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M23.6764 0.585938C10.6105 0.585938 0 11.1652 0 24.1925C0 37.2198 10.6105 47.7991 23.6764 47.7991C36.7423 47.7991 47.3528 37.2198 47.3528 24.1925C47.3528 11.1652 36.7423 0.585938 23.6764 0.585938ZM24.0272 9.62055C27.9148 9.62055 31.0424 12.7681 31.0424 16.6151C31.0424 20.4912 27.8856 23.6096 24.0272 23.6096C20.1396 23.6096 17.0119 20.4621 17.0119 16.6151C16.9827 12.7681 20.1396 9.62055 24.0272 9.62055ZM16.6612 35.6169C12.7736 35.6169 9.64595 32.4694 9.64595 28.6224C9.64595 24.7462 12.8028 21.6278 16.6612 21.6278C20.5488 21.6278 23.6764 24.7754 23.6764 28.6224C23.6764 32.4985 20.5488 35.6169 16.6612 35.6169ZM30.7501 35.9666C26.8625 35.9666 23.7349 32.8191 23.7349 28.9721C23.7349 25.096 26.8917 21.9776 30.7501 21.9776C34.6377 21.9776 37.7654 25.1251 37.7654 28.9721C37.7654 32.8191 34.6377 35.9666 30.7501 35.9666Z"
    //     fill="#A24DE8"
    //   />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 300 300"
    >
      <circle cx="150" cy="150" r="150" fill="#fff"></circle>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M239.918 200.953c43.887-25.077 39.205-68.411-10.455-96.789-49.661-28.377-125.495-31.052-169.381-5.975-43.887 25.078-39.205 68.412 10.455 96.79 49.661 28.377 125.495 31.052 169.381 5.974zm-8.364-4.779c30.027-17.159 17.856-51.933-27.185-77.671-45.041-25.738-105.896-32.692-135.923-15.534s-17.856 51.933 27.184 77.67c45.042 25.738 105.896 32.693 135.924 15.535z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default LogoIcon;
