import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints: { values: { height_ipad_pro_11 } } }: Theme) => ({
  container: {
    position: 'absolute',
    background: '#4F4F4F',
    borderRadius: '10px',
    width: '258px',
    bottom: '80px',
    right: 160,
    color: 'white',
    fontStyle: 'normal',
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 0',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  iconWrapper: {
    width: 40,
    height: 40,
    marginRight: 20,
  },
  button: {
    fontSize: 12,
    display: 'flex',
    padding: '10 0',
    background: 'darkblue',
    color: 'white',
    '&:active': {
      background: 'darkblue',
    },
    '&:hover': {
      color: 'darkblue',
      background: 'white',
    },
  },
  spinnerWrapper: {
    display: 'flex',
    width: '30%',
    alignItems: 'center',
    '& > div': {
      color: 'darkblue',
      width: '20px !important',
      height: '20px !important',
    },
  },
  buttonWrapper: {
    display: 'flex',
    padding: '5px',
    justifyContent: 'space-between',
  },
  resultText: {
    fontWeight: 'bold',
    fontSize: 15,
    display: 'flex',
    width: '30%',
    alignSelf: 'center',
  },
  successColor: {
    color: 'green',
  },
  errorColor: {
    color: 'red',
  },
  [`@media screen and (max-width: ${height_ipad_pro_11}px)`]: {
    container: {
      right: 70,
    },
    iconWrapper: {
      width: 20,
      height: 20,
      marginRight: 10,
    },
  },
}));

export default useStyles;
