import Ajs from 'ajv';
import { logWbs, ERROR_LEVEL } from '@pxs-infra/logger';

const ajv = new Ajs();

export const validateSchema = (schema: any, data: any) => {
  const validate = ajv.compile(schema);
  const valid = validate(data);
  if (!valid) logWbs(ERROR_LEVEL, 'Ошибка валидации:', validate.errors);
};
