import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks/useStores';
import useStyles from './style';
import classnames from 'classnames';
import React, { useState } from 'react';
import { ChatsListSectionType } from '../../../types/chat';
import { ChatsListItem, ContextMenu } from '../../index';
import { Button } from '../..';
import { useGlobalStyles } from '../../../globalStyles';

const ChatsListSection = observer(
  ({ title, chatsList, addChatContextMenuOptions }: ChatsListSectionType) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const {
      chatsListSectionContainer,
      chatsListSectionTitle,
      listContainer,
      titleClass,
      collapseIcon,
    } = useStyles();

    const {
      flexDirectionRow,
      flexDirectionColumn,
      flexAlignCenter,
      flexJustifySpaceBetween,
    } = useGlobalStyles();

    const { chatsModule } = useStores();

    return (
      <div className={classnames(flexDirectionColumn, chatsListSectionContainer)}>
        <div
          className={classnames(
            flexDirectionRow,
            flexAlignCenter,
            flexJustifySpaceBetween,
            chatsListSectionTitle
          )}
        >
          <span className={classnames(flexDirectionRow, flexAlignCenter)}>
            <span className={classnames(titleClass)}>{title}</span>
            <Button
              className={classnames(collapseIcon, isCollapsed && 'reversed-180')}
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
            >
              <svg
                width=".8125rem"
                height=".5rem"
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.5 8L0.870834 0.5L12.1292 0.5L6.5 8Z" fill="#F1F1F1" />
              </svg>
            </Button>
          </span>

          {addChatContextMenuOptions.length ? (
            <ContextMenu
              invoker={
                <span>
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="17.5"
                      y1="9.5"
                      x2="1.5"
                      y2="9.5"
                      stroke="#F1F1F1"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                    <line
                      x1="9.5"
                      y1="17.5"
                      x2="9.5"
                      y2="1.5"
                      stroke="#F1F1F1"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
              }
              direction={'right'}
              options={addChatContextMenuOptions}
            />
          ) : null}
        </div>
        {!isCollapsed && (
          <div className={classnames(flexDirectionColumn, listContainer)}>
            {chatsList.map((chat) => {
              return (
                <ChatsListItem
                  selectable={true}
                  chat={chat}
                  key={chat.chatId}
                  onClick={(clickedChat) => {
                    chatsModule.selectChat(clickedChat.chatId);
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

export default ChatsListSection;
