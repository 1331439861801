import { registerResizeEvents, deregisterResizeEvents } from './mathLibraly';
import { registerKeyboardEvents, registerGamePadEvents } from './control';
import { getEventsFromGame } from './game/eventsFromGame';
import { unregisterKeyboardEvents } from './control/keyboard';
export let responseEventListeners = new Map() as any;

function addResponseEventListener(name: string, listener: any) {
  responseEventListeners.set(name, listener);
}

export function registerMainEvents() {
  console.log('registering main events');
  addResponseEventListener('getEventsFromGame', getEventsFromGame);
  registerResizeEvents();
  registerKeyboardEvents();
  registerGamePadEvents();
}

export function unRegisterMainEvents() {
  responseEventListeners.clear();
  deregisterResizeEvents();
  unregisterKeyboardEvents();
}

export function disableEventListener(eventSignature: string) {
  console.log(eventSignature);
}

export function enableEventListener(eventSignature: string) {
  console.log(eventSignature);
}
