import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  participantsMediaControlListItemContainer: {
    width: '100%',
    marginBottom: '1.25rem',
    ['&:last-child']: {
      marginBottom: '3.75rem',
    },
    '& > *': {
      marginRight: '.75rem',
    },
    '& > *:last-child': {
      marginRight: '0',
    },
  },
  username: {
    width: '8rem',
    maxWidth: '8rem',
    minWidth: '8rem',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '1.125rem',
    textOverflow: 'ellipsis', //
    whiteSpace: 'nowrap', // this combination generates css-only '...' on the end of the long line
    overflow: 'hidden', //
  },
  turnOffStaticScreenShareButton: {
    borderRadius: '1rem',
  },
}));
