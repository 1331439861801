import { observer } from 'mobx-react-lite';
import React, { useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
import { Roles } from '../../constants/user';
import { isDesktop } from 'react-device-detect';
import { ArrowRightIcon, ArrowLeftIcon, Button, VideoIcon, AudioIcon } from '..';
import { handleShowAudio, handleShowVideo, existVideoDevice } from '../../helpers/device';

type LocalStreamMobileType = {
  refPlayerLocalStream: any;
};
const LocalStreamMobile = observer(({ refPlayerLocalStream }: LocalStreamMobileType) => {
  const {
    mySetting: { isShowVideo },
    connection: { isHideInterface, isLoading },
    user: { role },
    screenSharingModule: { setRefPlayerLocalScreenShare },
  } = useStores();
  const {
    localVideoWrapper,
    localVideoContainer,
    localVideo,
    displayNone,
    arrowIconSubWrapper,
    arrowIconWrapper,
    deviceControlWrapper,
    buttonClass,
    wrapper,
  } = useStyles();
  const refPlayerLocalScreenShare = useRef(null);
  const [isShowStream, setIsShowStream] = useState(true);

  useEffect(() => {
    setRefPlayerLocalScreenShare(refPlayerLocalScreenShare);
  }, [setRefPlayerLocalScreenShare]);

  return (
    <>
      {role !== Roles.SPECTATOR && !isDesktop && (
        <div
          className={classnames(
            localVideoContainer,
            isLoading || isHideInterface ? displayNone : ''
          )}
        >
          <div className={wrapper}>
            <div className={deviceControlWrapper}>
              <div>
                <Button onClick={handleShowVideo} className={buttonClass}>
                  <VideoIcon />
                </Button>
              </div>
              <div>
                <Button onClick={handleShowAudio} className={buttonClass}>
                  <AudioIcon />
                </Button>
              </div>
            </div>

            <div
              className={classnames(
                localVideoWrapper,
                !isShowVideo || !isShowStream || !existVideoDevice() ? displayNone : ''
              )}
            >
              <video
                ref={refPlayerLocalStream}
                className={localVideo}
                muted
                autoPlay
                playsInline
                disablePictureInPicture
              />
            </div>

            {existVideoDevice() && (
              <div className={arrowIconWrapper} onClick={() => setIsShowStream(!isShowStream)}>
                <div className={arrowIconSubWrapper}>
                  {isShowStream ? (
                    <ArrowRightIcon color="white" />
                  ) : (
                    <ArrowLeftIcon color="white" />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
});

export default LocalStreamMobile;
