import React from 'react';
import useStyles from './style';
import classnames from 'classnames';

type ModeratorTitleType = {
  text: string;
  count?: number;
  isShowCount?: boolean;
  badgeClass?: string;
};

const ModeratorTitle = ({ text, count, badgeClass, isShowCount = true }: ModeratorTitleType) => {
  const { container, title, badge } = useStyles();

  return (
    <div className={container}>
      <div className={title}>{text}</div>
      {isShowCount && <div className={classnames(badge, badgeClass)}>{count}</div>}
    </div>
  );
};

export default ModeratorTitle;
