import { makeStyles, Theme } from '@material-ui/core/styles';

const containerMedia = {
  mobile: {
    background: 'rgba(38, 38, 38, 0.9)',
    borderRadius: '10px',
    padding: '22.5px 15.5px',
    maxWidth: '300px',
  },
  tablet: {
    background: 'rgba(38, 38, 38, 0.9)',
    borderRadius: '20px',
    padding: '55px 46px',
  },
};

const modalTitleMedia = {
  mobile: {
    fontSize: '10px',
    lineHeight: '13.5px',
    textAlign: 'center',
    letterSpacing: '0.0125em',
  },
  tablet: {
    fontSize: '20px',
    lineHeight: '27px',
    textAlign: 'center',
    letterSpacing: '0.025em',
  },
};

const modalButtonClassMedia = {
  mobile: {
    width: '100%',
    height: '22px',
    borderRadius: '25px',
    fontSize: '10px',
    lineHeight: '13.5px',
  },
  tablet: {
    width: '131px',
    height: '48px',
    borderRadius: '50px',
    fontSize: '20px',
    lineHeight: '27px',
  },
};

const useStyles = makeStyles(
  ({
    breakpoints: {
      values: { width_iphone_6s, width_ipad_pro_11, height_iphone_6s, height_ipad_pro_11 },
    },
    palette: {
      primary: { main },
    },
  }: Theme) => ({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > div:first-child': {
        background: 'none !important',
      },
    },
    container: {
      background: 'rgba(38, 38, 38, 0.8) !important',
      borderRadius: '25px',
      color: main,
      fontStyle: 'normal',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '96.25px 78.125px 78.125px 78.125px',
      [`@media screen and (max-width: ${width_iphone_6s}px)`]: {
        ...containerMedia.mobile,
      },
      [`@media screen and (min-width: ${width_iphone_6s}px) and (max-width: ${width_ipad_pro_11}px)`]: {
        ...containerMedia.tablet,
      },
      [`@media screen and (max-width: ${height_iphone_6s}px) and (orientation: landscape)`]: {
        ...containerMedia.mobile,
      },
      [`@media screen and (min-width: ${height_iphone_6s}px) and (max-width: ${height_ipad_pro_11}px) and (orientation: landscape)`]: {
        ...containerMedia.tablet,
      },
    },
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '-16px',
      '& > div': {
        marginTop: '16px',
      },
    },
    modalTitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '35px',
      lineHeight: '47.5px',
      textAlign: 'center',
      letterSpacing: '0.025em',
      color: '#F1F1F1',
      [`@media screen and (max-width: ${width_iphone_6s}px)`]: {
        ...modalTitleMedia.mobile,
      },
      [`@media screen and (min-width: ${width_iphone_6s}px) and (max-width: ${width_ipad_pro_11}px)`]: {
        ...modalTitleMedia.tablet,
      },
      [`@media screen and (max-width: ${height_iphone_6s}px) and (orientation: landscape)`]: {
        ...modalTitleMedia.mobile,
      },
      [`@media screen and (min-width: ${height_iphone_6s}px) and (max-width: ${height_ipad_pro_11}px) and (orientation: landscape)`]: {
        ...modalTitleMedia.tablet,
      },
    },
    modalButtonsWrap: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginLeft: '-45px',
      marginTop: -8,
      width: '100%',
      [`@media screen and (max-width: ${width_iphone_6s}px)`]: {
        marginLeft: '-22.5px',
      },
      [`@media screen and (min-width: ${width_iphone_6s}px) and (max-width: ${width_ipad_pro_11}px)`]: {
        marginLeft: '-55px',
      },
      [`@media screen and (max-width: ${height_iphone_6s}px) and (orientation: landscape)`]: {
        marginLeft: '-22.5px',
      },
      [`@media screen and (min-width: ${height_iphone_6s}px) and (max-width: ${height_ipad_pro_11}px) and (orientation: landscape)`]: {
        marginLeft: '-55px',
      },
    },
    modalButtonClass: {
      minWidth: '200px',
      maxWidth: '321.7875px',
      height: '71.7625px',

      background: '#F1F1F1',
      borderRadius: '50px',
      fontStyle: 'normal',
      fontWeight: 'bold',

      fontSize: '30px',
      lineHeight: '41.25px',
      textTransform: 'uppercase',
      color: '#000000',
      border: 'none',
      marginTop: 8,

      marginLeft: '45px',
      [`@media screen and (max-width: ${width_iphone_6s}px)`]: {
        ...modalButtonClassMedia.mobile,
        marginLeft: '22.5px',
      },
      [`@media screen and (min-width: ${width_iphone_6s}px) and (max-width: ${width_ipad_pro_11}px)`]: {
        ...modalButtonClassMedia.tablet,
        marginLeft: '55px',
      },
      [`@media screen and (max-width: ${height_iphone_6s}px) and (orientation: landscape)`]: {
        ...modalButtonClassMedia.mobile,
        marginLeft: '22.5px',
      },
      [`@media screen and (min-width: ${height_iphone_6s}px) and (max-width: ${height_ipad_pro_11}px) and (orientation: landscape)`]: {
        ...modalButtonClassMedia.tablet,
        marginLeft: '55px',
      },
    },
    redButton: {
      background: '#D63A3A',
      color: '#F1F1F1',
    },
    modalOption: {
      fontSize: '18px !important',
      paddingLeft: '5px !important',
      fontWeight: 'bold',
      letterSpacing: '0.045em',
      '&:hover': {
        background: '#F1F1F1 !important',
        color: '#3F3F3F !important',
      },
    },
    modalSelect: {
      // maxWidth: '200px',
      // '& .MuiSelect-root': {
      //   fontStyle: 'normal',
      //   fontWeight: 'bold',
      //   fontSize: '30px',
      //   lineHeight: '44px',
      //   letterSpacing: '0.045em',
      //   color: 'white',
      //   overflow: 'visible',
      //   [`@media screen and (max-width: ${width_iphone_6s}px)`]: {
      //     fontSize: '10px',
      //   },
      //   [`@media screen and (min-width: ${width_iphone_6s}px) and (max-width: ${width_ipad_pro_11}px)`]: {
      //     fontSize: '20px',
      //   },
      //   [`@media screen and (max-width: ${height_iphone_6s}px) and (orientation: landscape)`]: {
      //     fontSize: '10px',
      //   },
      //   [`@media screen and (min-width: ${height_iphone_6s}px) and (max-width: ${height_ipad_pro_11}px) and (orientation: landscape)`]: {
      //     fontSize: '20px',
      //   },
      // },
      // '& .MuiSelect-icon > svg': {
      //   width: '32px',
      //   height: '32px',
      //   [`@media screen and (max-width: ${width_iphone_6s}px)`]: {
      //     width: '14px',
      //   },
      //   [`@media screen and (min-width: ${width_iphone_6s}px) and (max-width: ${width_ipad_pro_11}px)`]: {
      //     width: '24px',
      //   },
      //   [`@media screen and (max-width: ${height_iphone_6s}px) and (orientation: landscape)`]: {
      //     width: '14px',
      //   },
      //   [`@media screen and (min-width: ${height_iphone_6s}px) and (max-width: ${height_ipad_pro_11}px) and (orientation: landscape)`]: {
      //     width: '24px',
      //   },
      // },
    },
  })
);

export default useStyles;
