import { EmitChatMessageType } from '../../../types/chat';
import { rootStore } from '../../../models/rootStore';
import { emitUIInteraction } from '../../wsMessageEmitter';
import { MUTING_TARGETS } from '../../../constants/eventsManagement';
import { INFO_LEVEL, logWbs } from '@pxs-infra/logger';

export function sendChatMessage(chatMessage: EmitChatMessageType) {
  const { channel, message, userId } = chatMessage;
  const descriptor = {
    CallbackName: 'onSendMessage',
    Args: {
      Channel: channel,
      Message: message,
      Recipient: userId,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitUserControlScheme() {
  let hasDeviceOrientation = typeof window.orientation !== 'undefined';
  let hasTouchscreen = 'ontouchstart' in window;
  let isMobile = hasTouchscreen || hasDeviceOrientation || (hasDeviceOrientation && hasTouchscreen);

  const descriptor = {
    CallbackName: 'onUserControlsScheme',
    Args: {
      bUseMobileControls: isMobile,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitUserRegistrationData() {
  let gatewayEnv;
  const { userId, name, eventId, env, region } = rootStore.user;
  if (eventId) {
    if (env) {
      gatewayEnv = 'dev';
    } else {
      gatewayEnv = 'prod';
    }
  } else {
    gatewayEnv = 'test';
  }
  const descriptor = {
    CallbackName: 'onRegisterUser',
    Args: {
      GUID: userId,
      UserName: name,
      eventId: eventId,
      environment: gatewayEnv,
      region,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitViewportResolution(resolution: any) {
  const descriptor = {
    CallbackName: 'onChangeResolution',
    Args: {
      height: resolution.height,
      width: resolution.width,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitCurrentLocale(locale: string) {
  // Locales - ru_RU/en_US
  console.log(locale);
  const descriptor = {
    CallbackName: 'onLocalizationChange',
    Args: {
      locale: 'en_US',
    },
  };
  emitUIInteraction(descriptor);
}

export function emitActiveMediaDevices(audio: boolean, video: boolean) {
  const descriptor = {
    CallbackName: 'onUserMediaStatusUpdate',
    Args: {
      bHasAudioStream: audio,
      bHasVideoStream: video,
    },
  };
  logWbs(INFO_LEVEL, `Emitting active devices: Audio ${audio}, Video ${video}`);
  emitUIInteraction(descriptor);
}

export function emitUserAfkMode() {
  const descriptor = {
    CallbackName: 'onUserAfkMode',
    Args: {},
  };
  emitUIInteraction(descriptor);
}

/**
 * Обновление статуса стрима скриншары
 * Активна или не активна скриншара
 */
export function emitPlayerScrenShareUpdated(screenShareId: string, status: boolean) {
  const descriptor = {
    CallbackName: 'onPlayerScrenShareUpdated',
    Args: {
      screenShareId,
      status,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitRestreamConfigRequest(status: boolean) {
  const descriptor = {
    CallbackName: 'onRestreamConfigRequest',
    Args: {
      bIsStreamActivated: status,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitModeratorCustomizeUpdate(id: number) {
  const descriptor = {
    CallbackName: 'onModerator_CustomizeUpdate',
    Args: {
      customizeObjectId: id,
      requiredVariant: id,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitModeratorStartCountDown(timestamp: number, bEnable: boolean) {
  let descriptor = {
    CallbackName: 'onModerator_StartCountDown',
    Args: {
      timestamp,
      bEnable,
    },
  };
  emitUIInteraction(descriptor);
}
export function emitChangeVolumeOtherUser(userId: string, targetVolume: number) {
  const descriptor = {
    CallbackName: 'onUser_ChangeVolumeOtherUser',
    Args: {
      userId,
      targetVolume,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitMuteOtherUser(
  userId: string,
  bShouldBeMutted: boolean,
  target: MUTING_TARGETS
) {
  const descriptor = {
    CallbackName: 'onUser_MuteOtherUser',
    Args: {
      userId,
      bShouldBeMutted,
      target,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitModeratorForceLiveMode(isLive: boolean) {
  const descriptor = {
    CallbackName: 'onModerator_ForceLiveMode',
    Args: {
      Value: isLive,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitDirectConnection(destination: string) {
  let descriptor = {
    CallbackName: 'onDirectConnection',
    Args: {
      Destination: destination,
    },
  };
  rootStore.UI.sideMenuArea.closeAll();
  rootStore.UI.widgetsArea.closeAll();
  rootStore.user.setIsLive(false);
  rootStore.screenSharingModule.removeAllScreenShareTargets();
  emitUIInteraction(descriptor);
}

export function emitRequestSplashScreen({
  bRequestSplashScreen,
  splashScreenName,
}: {
  bRequestSplashScreen: boolean;
  splashScreenName: string;
}) {
  let descriptor = {
    CallbackName: 'onModerator_RequestSplashScreen',
    Args: {
      bRequestSplashScreen,
      splashScreenName,
    },
  };
  emitUIInteraction(descriptor);
}
export function emitUserScreenShareStart(type: string, screenShareId?: string | number) {
  const descriptor = {
    CallbackName: 'onUser_ScreenShareStart',
    Args: { type },
  } as any;

  if (screenShareId !== undefined) {
    descriptor.Args.screenShareId = screenShareId;
  }

  emitUIInteraction(descriptor);
}

export function emitUserScreenShareCancel() {
  const descriptor = {
    CallbackName: 'onUser_ScreenShareCancel',
    Args: {},
  };
  emitUIInteraction(descriptor);
}

export function emitModeratorScreenShareCancel(screenShareTargetId: number, userId: string) {
  const descriptor = {
    CallbackName: 'onModerator_ScreenShareCancel',
    Args: {
      screenShareId: screenShareTargetId,
      userId: userId,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitUpdateBackgroundChatVisibility(newValue: boolean) {
  const descriptor = {
    CallbackName: 'onModerator_BackgroundChatVisibility',

    Args: {
      value: newValue,
    },
  };
  emitUIInteraction(descriptor);
}

/**
 * Запуск скриншары на полный экран
 */
export function emitUserOpenFullScreenShare(screenShareId: number) {
  const descriptor = {
    CallbackName: 'onUser_OpenFullScreenShare',

    Args: {
      type: 'static',
      screenShareId,
    },
  };
  emitUIInteraction(descriptor);
}

/**
 * Свернуть текущую полноэкранную скриншару
 */
export function emitUserCloseFullScreenShare() {
  const descriptor = {
    CallbackName: 'onUser_CloseFullScreenShare',
    Args: {},
  };
  emitUIInteraction(descriptor);
}

export function emitControlMode(controlMode: any) {
  const descriptor = {
    CallbackName: 'onUserControllModeChange',
    Args: {
      userControlMode: controlMode,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitModeratorBanUser(userId: string) {
  const descriptor = {
    CallbackName: 'onModerator_BanOtherUser',
    Args: {
      userId: userId,
    },
  };
  emitUIInteraction(descriptor);
}

export function emitModeratorKickUser(userId: string) {
  const descriptor = {
    CallbackName: 'onModerator_KickOtherUser',
    Args: {
      userId: userId,
    },
  };

  emitUIInteraction(descriptor);
}

export function emitUserRotateRemoteStream() {
  console.log('I hope it will work');
}
