export const names = [
  'Player',
  'Warrior',
  'Stranger',
  'Wayfarer',
  'Explorer',
  'Alchemist',
  'Rebel',
  'Cardinal',
  'Divergent',
  'Magician',
  'Daywalker',
  'Tor',
  'Iceflower',
  'Amdros',
  'Hakjun',
  'Veton',
  'Dateus',
  'Damon',
  'Thonthor',
  'Mentotos',
  'Unicorn',
  'Akteon',
  'Oskax',
  'Janos',
  'Protonus',
];
