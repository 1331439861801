import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  liveIndicator: {
    backgroundColor: '#C4C4C4',
    color: '#F1F1F1',
    width: '5.875rem',
    height: '1.5rem',
    borderRadius: '1.5rem',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: '0 .375rem',
  },
  liveIndicatorOn: {
    backgroundColor: '#D63A3A',
  },
  liveIndicatorDot: {
    width: '.75rem',
    height: '.75rem',
    borderRadius: '1.25rem',
    backgroundColor: '#F1F1F1',
  },
}));

export default useStyles;
