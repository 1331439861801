import React from 'react';
import { Modal, CloseIcon, Button } from '../..';
import useStyles from './style';

type LogoutModalType = {
  isShow: boolean;
  handleClose: any;
};
const LogoutModal = ({ isShow, handleClose }: LogoutModalType) => {
  const {
    logoutModalContainer,
    logoutModalClass,
    logoutModalWrapClass,
    logoutModalTitleWrap,
    logoutModalTitleText,
    logoutModalTitleIcon,
    logoutModalButton,
  } = useStyles();

  return (
    <Modal isOpen={isShow} modalClass={logoutModalClass} modalWrapClass={logoutModalWrapClass}>
      <div className={logoutModalContainer}>
        <div className={logoutModalTitleWrap}>
          <div className={logoutModalTitleText}>Are you want to quit space?</div>
          <div className={logoutModalTitleIcon} onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        <div>
          <Button onClick={handleClose} className={logoutModalButton}>
            Quit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
