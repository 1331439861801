import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  staticScreenShareButtonsContainer: {
    flexShrink: 0,
    width: '100vw',
    position: 'fixed',
    left: '0',
    bottom: '1.25rem',
    zIndex: 2,
    '&:empty': {
      display: 'none',
    },
  },
}));

export default useStyles;
