import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  displayNone: {
    display: 'none',
  },

  enterNameInputContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: '38px',
    paddingBottom: '50px',
  },
  startPageContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#FAFBFF',
    padding: '50px 15px 43px 15px',
    borderRadius: '15px',
    height: '100%',
  },
  audioSelectWrapper: {
    paddingBottom: '233px',
    position: 'relative',
  },
  videoSelectWrapper: {
    position: 'relative',
    paddingBottom: '22px',
  },
  startPageCameraWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: 'linear-gradient(to right bottom, #2E64E5 0%, #00BAF2 54.17%, #E069A8 100%)',
    padding: '3px',
    borderRadius: '50%',
  },
  startPageCamera: {
    borderRadius: '50%',
    '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
    overflow: 'hidden',
    position: 'relative',
    display: 'block',
    width: '110px',
    height: '110px',
  },
  startPageLocalVideo: {
    position: 'relative',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  startPageDeviceWrapper: {
    width: '100%',
    maxWidth: '460px',
    position: 'relative',
  },
  startPageCameraOff: {
    display: 'flex',
    flexDirection: 'column',
    background: '#EEEEEE',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',

    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#A2A2A2',
  },

  startPageButtonClass: {
    width: '100%',
    height: '45px',
    background: 'linear-gradient(89.88deg, #3060E4 0.1%, #00BAF2 51.05%, #E069A8 99.92%)',
    borderRadius: '15px',
    fontFamily: 'Outfit',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '25px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  startPageButtonWrapper: {},
  paddingswitchWrapper: {
    padding: '0 14px',
  },
  switchWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '22px',
    width: '100%',
  },
  switchItem: {
    background: '#EBEFF8',
    borderRadius: '15px',
    padding: '14px',
    alignItems: 'center',
    display: 'flex',
    height: '50px',
  },
  switchClass: {
    display: 'flex',
    alignItems: 'center',
  },
  switchIcon: {
    display: 'flex',
    paddingRight: '10px',
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
  startPageButtonDisableClass: {
    background: '#CECECE',
  },
  startPageDevicesPermissionDeniedWrapper: {
    width: '100%',
    maxWidth: '460px',
    position: 'relative',
    paddingBottom: '24px',
  },
  tooltipLeftPositionClass: {
    bottom: '180%',
    left: '0%',
    marginLeft: '-25px',
    color: '#A24DE8',
    height: '60px',
    background: '#F0E2FB',
    '&::after': {
      borderColor: '#F0E2FB transparent transparent transparent',
    },
  },
  tooltipRightPositionClass: {
    bottom: '180%',
    left: '-100%',
    marginLeft: '-25px',
    color: '#A24DE8',
    height: '60px',
    background: '#F0E2FB',
    '&::after': {
      borderColor: '#F0E2FB transparent transparent transparent',
    },
  },
  testMicWrapper: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
  },
}));

export default useStyles;
