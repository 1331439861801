import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  stackContainer: {
    height: '100%',
    width: '100%',
    '& > .stack-item': {
      width: '100%',
    },
    '& > .stack-item-enter': {
      opacity: 0,
    },
    '& > .stack-item-enter-active': {
      opacity: 1,
      transition: 'opacity 250ms ease-in',
    },
    '& > .stack-item-exit': {
      opacity: 1,
    },
    '& > .stack-item-exit-active': {
      opacity: 0,
      transition: 'opacity 250ms ease-in',
    },
  },
}));

export default useStyles;
