import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  emitRequestSplashScreen,
  emitModeratorForceLiveMode,
} from '../../services/game/emitsToGame/emits';
import ValueLabel from './ValueLabel';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
import { Button } from '..';
import classNames from 'classnames';
import { useGlobalStyles } from '../../globalStyles';

// TODO: plz i18n
const STREAM_STATUSES: string[] = [
  'Stream will be continued soon',
  'Stream will be start soon',
  'Stream has ended',
];

const StreamStatusSelector = observer(() => {
  const { user } = useStores();
  const [value, setValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [isOn, setOn] = useState<boolean>(false);
  const {
    container,
    streamStatusSelector,
    streamStatusSelectorList,
    streamStatusCollapse,
    streamStatusButton,
  } = useStyles();

  const { flexDirectionColumn, flexJustifySpaceAround } = useGlobalStyles();

  const onPressOpen = () => setOpen(!open);
  const onPressItem = (option: string) => {
    setValue(option);
    setOpen(false);
  };

  const onSend = () => {
    if (value === '' || isOn) {
      setValue('');
      setOn(false);
      emitRequestSplashScreen({
        bRequestSplashScreen: false,
        splashScreenName: '',
      });
      emitModeratorForceLiveMode(true);
      return;
    }
    if (user.isLive) {
      emitModeratorForceLiveMode(false);
    }
    setOn(true);
    emitRequestSplashScreen({
      bRequestSplashScreen: true,
      splashScreenName: value,
    });
  };

  return (
    <div className={container}>
      <div
        className={classNames(streamStatusSelector, flexDirectionColumn, flexJustifySpaceAround)}
      >
        <List component="div" className={streamStatusSelectorList}>
          <ListItem button onClick={onPressOpen}>
            <ListItemText primary={<ValueLabel label={value} isOn={isOn} />} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse unmountOnExit timeout="auto" in={open} className={streamStatusCollapse}>
            <List component="div" disablePadding>
              {STREAM_STATUSES.map((status) => (
                <ListItem button key={status} onClick={() => onPressItem(status)}>
                  <ListItemText primary={status} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </div>
      <Button className={streamStatusButton} onClick={onSend}>
        {!isOn ? 'send' : 'off'}
      </Button>
    </div>
  );
});

export default StreamStatusSelector;
