import React from 'react';

const SendMessageIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6025 23.25C14.3238 23.2526 14.0498 23.1776 13.8113 23.0331C13.5729 22.8887 13.3794 22.6807 13.2525 22.4325L10.2525 16.755C10.1655 16.5795 10.1506 16.377 10.211 16.1906C10.2714 16.0043 10.4022 15.849 10.5756 15.7579C10.749 15.6668 10.9512 15.6471 11.1389 15.7031C11.3265 15.7591 11.4849 15.8863 11.58 16.0575L14.58 21.735L21.75 2.295L2.25003 9.3675L9.60003 13.3125L15.84 7.0725C15.9813 6.93127 16.1728 6.85193 16.3725 6.85193C16.5723 6.85193 16.7638 6.93127 16.905 7.0725C17.0463 7.21373 17.1256 7.40527 17.1256 7.605C17.1256 7.80472 17.0463 7.99627 16.905 8.1375L10.2675 14.7675C10.1547 14.8805 10.0087 14.9545 9.8509 14.9788C9.69308 15.0031 9.53162 14.9763 9.39003 14.9025L1.56753 10.7475C1.29372 10.6194 1.06503 10.4115 0.911423 10.1511C0.757818 9.8907 0.686475 9.58998 0.706746 9.28835C0.727017 8.98672 0.837956 8.69825 1.02502 8.46076C1.21208 8.22328 1.46654 8.04786 1.75503 7.9575L21.1875 0.847498C21.4624 0.731579 21.7656 0.700248 22.0583 0.757508C22.3511 0.814768 22.6201 0.95802 22.8311 1.16895C23.042 1.37989 23.1853 1.64893 23.2425 1.94169C23.2998 2.23445 23.2685 2.53763 23.1525 2.8125L16.0425 22.245C15.9448 22.526 15.7657 22.7717 15.5279 22.9506C15.2902 23.1296 15.0046 23.2338 14.7075 23.25H14.6025Z"
        fill="#3F5CA4"
      />
    </svg>
  );
};

export default SendMessageIcon;
