import React, { useState, useEffect } from 'react';
import useComponentVisible from '../../hooks/useComponentVisible';
import useStyles from './style';
import classnames from 'classnames';

type ModeratorSelectType = {
  changeValue: any;
  icon: any;
  selectClass?: string;
  options: { name: string; value: string }[];
};

export type MenuSelectStyleType = {
  widthDropDown: number;
  isShow: boolean;
};

const ModeratorSelect = ({ changeValue, options, icon, selectClass }: ModeratorSelectType) => {
  const {
    isComponentVisible: isShow,
    setIsComponentVisible: setIsShow,
    ref: listRef,
  } = useComponentVisible();
  const [widthDropDown, setWidthDropDown] = useState(0);

  const handleOpen = () => {
    setIsShow(!isShow);
  };

  useEffect(() => {
    if (isShow && listRef.current) {
      const width = (listRef.current as any)?.clientWidth;
      setWidthDropDown(width);
    }
  }, [listRef, isShow]);

  const { container, listContainer, list, item, arrowContainer } = useStyles({
    widthDropDown,
    isShow,
  } as MenuSelectStyleType);

  const onOptionClicked = (value: any) => {
    setIsShow(false);
    changeValue(value);
  };

  return (
    <div className={container}>
      <div className={arrowContainer} onClick={handleOpen}>
        {icon}
      </div>

      <div className={classnames(listContainer, selectClass)} ref={listRef}>
        <div className={list}>
          {options.map(({ name, value }) => (
            <div className={item} onClick={() => onOptionClicked(value)} key={value}>
              {name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModeratorSelect;
