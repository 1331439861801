import React from 'react';

type MobileSettingIconType = {
  color?: string;
};

const MobileSettingIcon = ({ color = 'white' }: MobileSettingIconType) => {
  return (
    <svg
      width="20"
      height="5"
      viewBox="0 0 20 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M10 4C10.8284 4 11.5 3.32843 11.5 2.5C11.5 1.67157 10.8284 1 10 1C9.17157 1 8.5 1.67157 8.5 2.5C8.5 3.32843 9.17157 4 10 4Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 4C3.32843 4 4 3.32843 4 2.5C4 1.67157 3.32843 1 2.5 1C1.67157 1 1 1.67157 1 2.5C1 3.32843 1.67157 4 2.5 4Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 4C18.3284 4 19 3.32843 19 2.5C19 1.67157 18.3284 1 17.5 1C16.6716 1 16 1.67157 16 2.5C16 3.32843 16.6716 4 17.5 4Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MobileSettingIcon;
