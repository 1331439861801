import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  inputWrapper: { width: '100%' },
  inputContainer: {
    fontSize: '14px',
    position: 'relative',
  },
  input: {
    appearance: 'none',
    background: '#EBEFF8',
    width: '100%',
    padding: '13.5px 15px 13.5px 15px',
    borderRadius: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '160%',
    color: '#3F5CA4',
    border: '1px solid #EBEFF8',
    '&::placeholder': {
      color: '#3F5CA4',
    },
    '&:focus': {
      border: '1px solid #969FB5',
    },
  },
}));

export default useStyles;
