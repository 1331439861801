import { EmojiesType, EmojiType } from '../../types/emoji';

const convertToUnicode = (codeString: string) => {
  const codeArray = codeString.split('-');

  if (codeArray) {
    let uniСode = '';
    for (let code of codeArray) {
      const charcode = parseInt(code, 16);
      uniСode = `${uniСode}${String.fromCodePoint(charcode)}`;
    }

    return uniСode;
  }
};

export const convertEmojies = (emojies: any) => {
  const emojiArray: EmojiType[] = emojies.map((emoji: any) => {
    return {
      category: emoji.category,
      name: emoji.name,
      uniСode: convertToUnicode(emoji.unified),
      searchName: emoji.short_name,
    };
  });

  const reduceEmoji = emojiArray.reduce((r: any, a: any) => {
    r[a.category] = r[a.category] || [];
    r[a.category].push(a);

    return r;
  }, Object.create(null));

  const convertEmojies: EmojiesType[] = [];
  Object.keys(reduceEmoji).forEach((category) => {
    convertEmojies.push({
      categoryName: category,
      emojies: reduceEmoji[category],
    });
  });

  return convertEmojies;
};
