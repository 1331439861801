import React from 'react';
import useStyles from './style';
import classnames from 'classnames';

type DeviceListType = {
  options: any;
  handleChange: any;
  isDisabled: boolean;
  activeDeviceId: string | null;
};

const DeviceList = ({ handleChange, isDisabled, options, activeDeviceId }: DeviceListType) => {
  const { listContainer, list, item, accentColor } = useStyles({
    isDisabled,
  } as {
    isDisabled: boolean;
  });

  const onOptionClicked = (value: any) => {
    handleChange(value);
  };

  return (
    <div className={listContainer}>
      <div className={list}>
        {options.map(({ deviceId, label }: any) => (
          <div
            className={classnames(item, activeDeviceId === deviceId ? accentColor : '')}
            onClick={() => onOptionClicked(label)}
            key={deviceId}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeviceList;
