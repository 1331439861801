import React from 'react';
import { useState, useCallback } from 'react';
import { AssistantIcon } from '../..';
import { observer } from 'mobx-react-lite';
import useStyles from './style';
import { useLongPress } from 'use-long-press';
// import { isSafari } from 'react-device-detect';
import { useStores } from '../../../hooks/useStores';
// import { getSupportedAudioTypes } from '../../../helpers/mimetypes';

const MicRecorder = require('mic-recorder-to-mp3');

// import { rootStore } from '../../../models/rootStore';

type RecorderMenuItemType = {
  isShowTextIcon?: boolean;
  buttonClass?: string;
};

const Recorder = observer(({ buttonClass }: RecorderMenuItemType) => {
  // const {
  //   wbs: { localStream },
  // } = rootStore;

  /* const recording_stream = new MediaStream();
  for (const track of gum_stream.getAudioTracks()) {
    recording_stream.addTrack(track);
  }
  const recorder = new MediaRecorder(recording_stream); */

  const { connection, user } = useStores();
  const { container } = useStyles();
  const [enabled] = useState(true);

  //status, clearBlobUrl, mediaBlobUrl
  // let currentChunks: any = [];
  // let currentFormat: string;

  let recorder: any;

  recorder = new MicRecorder({
    bitRate: 128,
  });

  const startRecording = () => {
    recorder
      .start()
      .then(() => {})
      .catch((e: any) => console.log(e));
  };

  const stopRecording = () => {
    recorder
      .stop()
      .getMp3()
      // @ts-ignore
      .then(([buffer, blob]) => {
        console.log(buffer);
        sendPost(blob);
      })
      .catch((e: any) => console.log(e));
  };

  // const validateBlobSize = (blobThing: any) => {
  //   return blobThing && blobThing.size > 50000 ? true : false;
  // };

  const sendPost = (blobThing: any) => {
    const { REACT_APP_SMART_ASSISTANT_URL } = process.env;

    const formData = new FormData();

    const suffix = 'mp3';

    let file = new File([blobThing], `music.${suffix}`);

    formData.append(`file`, file, `music.${suffix}`);
    formData.append('userId', user.userId || 'test');
    formData.append('eventId', user.eventId || 'test');
    formData.append('ueClientDomain', `https://${connection.ueClientDomain}` || 'test');

    const res = fetch(`${REACT_APP_SMART_ASSISTANT_URL}/site/upload`, {
      method: 'post',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          console.log('Request to assistant failed');
        } else {
          console.log('Request to assistant passed', res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callback = useCallback((event) => {
    console.log(event);
  }, []);

  const bind = useLongPress(enabled ? callback : null, {
    onStart: (event) => {
      console.log('Press started ', event);
      try {
        startRecording();
      } catch (error) {
        console.log('Recording is not possible, reason: ', error);
      }
    },
    onFinish: (event) => {
      console.log('Long press finished ', event);
      try {
        stopRecording();
      } catch (error) {
        console.log('Stop recording is not possible, reason: ', error);
      }
    },
    onCancel: (event) => {
      console.log('Press cancelled ', event);
      try {
        stopRecording();
      } catch (error) {
        console.log('Stop recording is not possible, reason: ', error);
      }
    },
    // onMove: (event) => console.log('Detected mouse or touch movement ', event),
    filterEvents: () => true, // All events can potentially trigger long press (same as 'undefined')
    threshold: 500, // In milliseconds
    captureEvent: true, // Event won't get cleared after React finish processing it
    cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
    cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressingк
    // detect: 'pointer', // Default option
  });

  return (
    <>
      <div className={container}>
        <button {...bind()} className={buttonClass}>
          <AssistantIcon />
        </button>
        {/* <audio src={mediaBlobUrl} controls autoPlay loop /> */}
      </div>
    </>
  );
});

export default Recorder;
