// import { initEruda } from './eruda';
import { logApi, INFO_LEVEL } from '@pxs-infra/logger';

export async function isExistBlockExtension(): Promise<string> {
  try {
    const testUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

    await fetch(testUrl, {
      method: 'HEAD',
      mode: 'no-cors',
    });
    return 'is not blocked';
  } catch (e: any) {
    if (e.message === 'Failed to fetch') {
      logApi(INFO_LEVEL, 'Ad blockers are enabled so eruda and sentry are disabled');
    }
    return 'is blocked';
  }
}

export const checkIsExistBlockExtension = () => {
  isExistBlockExtension().then((isBlockSite) => {
    const { NODE_ENV } = process.env;
    if (isBlockSite === 'is not blocked') {
      if (NODE_ENV === 'development') {
        // initEruda();
      }
    }
  });
};
