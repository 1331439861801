import { MEDIA_SERVER_EVENT_WBS_INFO } from '../../../../constants/wbs';
import { sendDataToServer } from '.';

export const wbsInfo = () => {
  const data = {
    req: MEDIA_SERVER_EVENT_WBS_INFO,
  };

  sendDataToServer(data);
};
