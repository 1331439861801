import { Instance, types } from 'mobx-state-tree';

export const UpdateNearestStandModel = types
  .model('updateNearestStand')
  .props({
    statusBuyButton: types.number,
    currentProductId: types.number,
  })
  .views((self) => ({
    get isShowBuyButton() {
      return self.statusBuyButton === 1;
    },
  }))
  .actions((self) => ({
    setStatusBuyButton(statusBuyButton: number) {
      self.statusBuyButton = statusBuyButton;
    },
    setCurrentProductId(currentProductId: number) {
      self.currentProductId = currentProductId;
    },
  }));

export const GameModel = types.model('game').props({
  updateNearestStand: UpdateNearestStandModel,
});

type GameModelType = Instance<typeof GameModel>;
export interface GameType extends GameModelType {}
