import React from 'react';

type MenuItemIconType = {
  children: any;
  isSingleIcon?: boolean;
};

const MenuItemIcon = ({ children, isSingleIcon = false }: MenuItemIconType) => {
  return (
    <svg
      width={isSingleIcon ? 48 : 88}
      height="48"
      viewBox={`0 0 ${isSingleIcon ? 48 : 88} 48`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};
export default MenuItemIcon;
