import React from 'react';
import { Modal, CloseIcon, Button } from '../..';
import { generateAvatar } from '../../../helpers/random';
import { ParticipantType } from '../../../models/participants';
import iseStyles from './style';

type ModeratorBanModalType = {
  isOpen: boolean;
  handleClose: any;
  handleApply: any;
  banParticipants: ParticipantType[];
};
const ModeratorBanModal = ({
  handleClose,
  handleApply,
  isOpen,
  banParticipants,
}: ModeratorBanModalType) => {
  const {
    banModalContainer,
    banModalClass,
    banModalWrapClass,
    banTitleContainer,
    banTitle,
    banCloseIcon,
    banIcon,
    banUsername,
    banItem,
    buttonCancelClass,
    buttonApplyClass,
    buttonWrapper,
    banIconWrapper,
  } = iseStyles();

  const handleCancel = () => {
    handleClose();
  };

  const isSinglebanParticipant = banParticipants.length === 1;
  return (
    <Modal
      isTransparent
      isOpen={isOpen}
      handleClose={handleClose}
      modalClass={banModalClass}
      modalWrapClass={banModalWrapClass}
    >
      <div className={banModalContainer}>
        <div className={banTitleContainer}>
          <div className={banTitle}>
            Ban {isSinglebanParticipant ? 'this' : 'these'} participant?
          </div>
          <div className={banCloseIcon} onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        {banParticipants.map(({ avatar, username, userId }) => (
          <div key={userId} className={banItem}>
            <div className={banIconWrapper}>
              <img className={banIcon} src={avatar ?? generateAvatar()} alt="logo" />
            </div>
            <div className={banUsername}>{username}</div>
          </div>
        ))}
        <div className={buttonWrapper}>
          <Button className={buttonCancelClass} onClick={handleCancel}>
            Cancel
          </Button>

          <Button className={buttonApplyClass} onClick={handleApply}>
            Yes, ban {isSinglebanParticipant ? 'he/she' : 'them'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModeratorBanModal;
