import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import classname from 'classnames';
import { useStores } from '../../../hooks/useStores';
import { Roles } from '../../../constants/user';
import useStyles from './style';

const DEFAULT_TIMER = {
  h: '00',
  m: '00',
  s: '00',
};

const getDuration = (endTime: moment.Moment) => {
  const now = moment().utc();
  const diff = endTime.diff(now);
  const duration = moment.duration(diff);
  return {
    h: duration.hours(),
    m: duration.minutes(),
    s: duration.seconds(),
  };
};

const addZero = (partOfTime: number): string => {
  return partOfTime > 9 ? String(partOfTime) : `0${partOfTime}`;
};

interface TimeInterface {
  h: string;
  m: string;
  s: string;
}

interface TimerInterface {
  value: number;
  isEnable: boolean;
  onChange(value: TimeInterface): void;
  onEnd?(): void;
}

const Timer = observer(({ value, isEnable = false, onChange, onEnd }: TimerInterface) => {
  const { user } = useStores();
  const timerConteinerRef = useRef<any>(null);
  const intervalIdRef = useRef<any>(0);
  const [timer, setTimer] = useState<TimeInterface>(DEFAULT_TIMER);
  const [editStep, setEditStep] = useState<number>(0);
  const { timerWrap, timerContainer, timerSection, backlight, backlightActive } = useStyles();

  function onClick(): void {
    if (user.role !== Roles.MODERATOR) {
      return;
    }
    setEditStep(1);
    setTimer(DEFAULT_TIMER);
  }

  function onKeyPress(event: any): void {
    if (user.role !== Roles.MODERATOR) {
      return;
    }
    if (/\d/.test(event.key)) {
      setEditStep((prev) => prev + 1);
      if (editStep < 5 && Number(`${timer.m}${event.key}`) > 59) {
        setTimer((prev) => {
          return { ...prev, m: '59' };
        });
        return;
      }
      if (editStep < 7 && Number(`${timer.s}${event.key}`) > 59) {
        setTimer((prev) => {
          return { ...prev, s: '59' };
        });
        return;
      }

      (editStep === 1 || editStep === 2) &&
        setTimer((prev) => {
          return { ...prev, h: prev.h === '00' ? event.key : `${prev.h}${event.key}` };
        });
      (editStep === 3 || editStep === 4) &&
        setTimer((prev) => {
          return { ...prev, m: prev.m === '00' ? event.key : `${prev.m}${event.key}` };
        });
      (editStep === 5 || editStep === 6) &&
        setTimer((prev) => {
          return { ...prev, s: prev.s === '00' ? event.key : `${prev.s}${event.key}` };
        });
    }
  }

  function onBlur(): void {
    if (user.role !== Roles.MODERATOR) {
      return;
    }
    onChange(timer);
    setEditStep(0);
  }

  useEffect(() => {
    if (isEnable) {
      intervalIdRef.current = setInterval(() => {
        const time = getDuration(moment(value));

        if (time.h <= 0 && time.m <= 0 && time.s <= 0) {
          clearInterval(intervalIdRef.current);
          setTimer(DEFAULT_TIMER);
          onEnd && onEnd();
          return;
        }

        setTimer({
          h: addZero(time.h),
          m: addZero(time.m),
          s: addZero(time.s),
        });
      }, 1000);
    } else {
      clearInterval(intervalIdRef.current);
    }

    return () => clearInterval(intervalIdRef.current);
  }, [value, isEnable, onEnd]);

  return (
    <div className={timerWrap}>
      <p>COUNTDOWN</p>
      <div
        tabIndex={-1}
        className={classname(timerContainer)}
        ref={timerConteinerRef}
        onClick={onClick}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
      >
        <div className={timerSection}>
          <p>{timer.h}</p>
          <div
            className={classname(backlight, (editStep === 1 || editStep === 2) && backlightActive)}
          />
          <p>HH</p>
        </div>
        <div className={timerSection}>
          <p>{timer.m}</p>
          <div
            className={classname(backlight, (editStep === 3 || editStep === 4) && backlightActive)}
          />
          <p>MM</p>
        </div>
        <div className={timerSection}>
          <p>{timer.s}</p>
          <div
            className={classname(backlight, (editStep === 5 || editStep === 6) && backlightActive)}
          />
          <p>SS</p>
        </div>
      </div>
    </div>
  );
});

export default Timer;
