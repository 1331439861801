import { validateSchema } from '../../ajv';
import { setIceSchema } from '../../ajv/schema';
import { getPcByStreamType } from '../../store';

export const setIce = async (json: any, streamType: string) => {
  validateSchema(setIceSchema, json);
  const { candidates } = json;
  const candidate = candidates[0];

  if (candidate) {
    const pc = getPcByStreamType(streamType);
    if (pc) {
      await pc.addIceCandidate(candidate);
    }
  }
};
