import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    position: 'relative',
  },
  input: {
    width: '100%',
    border: '1px solid #DDDDDD',
    borderRadius: '8px',
    padding: '18px 12px',
    paddingRight: '40px',
    '&::placeholder': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#A2A2A2',
    },
  },
  icon: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '12px',
  },
}));

export default useStyles;
