import React from 'react';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { AudioMuteIcon, AudioOnIcon, AudioOffIcon } from '..';
import { existAudioDevice } from '../../helpers/device';

const AudioIcon = observer(() => {
  const {
    mySetting: { isShowAudio, isMutedAudio },
  } = useStores();

  return (
    <>
      {isMutedAudio ? (
        <AudioMuteIcon color="white" />
      ) : isShowAudio ? (
        <AudioOnIcon color={!existAudioDevice() ? '#CECECE' : 'white'} />
      ) : (
        <AudioOffIcon color={!existAudioDevice() ? '#CECECE' : 'white'} />
      )}
    </>
  );
});

export default AudioIcon;
