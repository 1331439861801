import React from 'react';

interface PinInterface {
  size: string;
  activeColor?: string;
}

const CountDownIcon = ({ size, activeColor = '' }: PinInterface) => {
  const color: string = activeColor || '#7E7E7E';
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4875 17.2917C2.4875 25.4375 9.14583 32.0958 17.2917 32.0958C25.4375 32.0958 32.0958 25.4375 32.0958 17.2917C32.0958 9.14583 25.4375 2.4875 17.2917 2.4875C9.14583 2.4875 2.4875 9.14583 2.4875 17.2917ZM17.2917 1C8.29583 1 1 8.29583 1 17.2917C1 26.2875 8.29583 33.5833 17.2917 33.5833C26.2875 33.5833 33.5833 26.2875 33.5833 17.2917C33.5833 8.29583 26.2875 1 17.2917 1Z"
        fill={color}
      />
      <path
        d="M15.7915 8.40747C15.7915 7.57904 16.4631 6.90747 17.2915 6.90747C18.1199 6.90747 18.7915 7.57904 18.7915 8.40747V17.3188C18.7915 18.1472 18.1199 18.8188 17.2915 18.8188C16.4631 18.8188 15.7915 18.1472 15.7915 17.3188V8.40747Z"
        fill={color}
      />
      <path
        d="M17.2915 18.8191C16.4631 18.8191 15.7915 18.1475 15.7915 17.3191C15.7915 16.4907 16.4631 15.8191 17.2915 15.8191H26.2028C27.0312 15.8191 27.7028 16.4907 27.7028 17.3191C27.7028 18.1475 27.0312 18.8191 26.2028 18.8191H17.2915Z"
        fill={color}
      />
    </svg>
  );
};

export default CountDownIcon;
