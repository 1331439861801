export const WBS_API_SOCKET_CONNECTION = 'socket_connection';

export const MEDIA_SERVER_EVENT_ADD_DEVICE = 'add_device';
export const MEDIA_SERVER_EVENT_REMOVE_DEVICE = 'remove_device';
export const MEDIA_SERVER_EVENT_SET_SDP = 'set_sdp';
export const MEDIA_SERVER_EVENT_SET_ICE = 'set_ice';
export const MEDIA_SERVER_EVENT_DEVICE_INFO = 'device_info';
export const MEDIA_SERVER_EVENT_QUEUE_INFO = 'queue_info';
export const MEDIA_SERVER_EVENT_SET_DEVICE_STREAMS = 'set_device_streams';
export const MEDIA_SERVER_EVENT_REMOVE_DEVICE_STREAMS = 'remove_device_streams';
export const MEDIA_SERVER_EVENT_SUBSCRIBE_EVENTS = 'subscribe_events';
export const MEDIA_SERVER_EVENT_ON_EVENT = 'on_event';
export const MEDIA_SERVER_EVENT_WBS_INFO = 'wbs_info';

export const MEDIA_SERVER_STATUS_SUCCESS = 'success';
export const MEDIA_SERVER_STATUS_NOT_FOUND = 'not_found';
export const MEDIA_SERVER_STATUS_NO_RESOURCES = 'no_resources';
export const MEDIA_SERVER_STATUS_BUSY = 'busy';
export const MEDIA_SERVER_STATUS_BAD_MESSAGE = 'bad_message';
export const MEDIA_SERVER_STATUS_NOT_SUPPORTED = 'not_supported';
export const MEDIA_SERVER_STATUS_FAILURE = 'failure';

/*
 * Socket status
 */
export const SOCKET_STATUS_CONNECTING_CODE = 0;
export const SOCKET_STATUS_OPEN_CODE = 1;
export const SOCKET_STATUS_CLOSING_CODE = 2;
export const SOCKET_STATUS_CLOSED_CODE = 3;

export const SOCKET_STATUS_CONNECTING_MESSAGE = 'connecting';
export const SOCKET_STATUS_OPEN_MESSAGE = 'open';
export const SOCKET_STATUS_CLOSING_MESSAGE = 'closing';
export const SOCKET_STATUS_CLOSED_MESSAGE = 'closed';

export const SOCKET_STATUS = [
  {
    code: SOCKET_STATUS_CONNECTING_CODE,
    message: SOCKET_STATUS_CONNECTING_MESSAGE,
  },
  {
    code: SOCKET_STATUS_OPEN_CODE,
    message: SOCKET_STATUS_OPEN_MESSAGE,
  },
  {
    code: SOCKET_STATUS_CLOSING_CODE,
    message: SOCKET_STATUS_CLOSING_MESSAGE,
  },
  {
    code: SOCKET_STATUS_CLOSED_CODE,
    message: SOCKET_STATUS_CLOSED_MESSAGE,
  },
];
