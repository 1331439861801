import { MutableRefObject } from 'react';
import { emitActiveMediaDevices } from '../services/game/emitsToGame/emits';
import { rootStore } from '../models/rootStore';
import { clearAllAudioDevices, clearAllVideoDevices } from './device';
import { addDevice, destroyLocalPeer, destroyScreenPeer } from '../services/wbs/socket';
import * as ApiWrapper from '../services/apiWrapper';
import { ERROR_LEVEL, INFO_LEVEL, LOG_LEVEL, logApi, logWbs } from '@pxs-infra/logger';
import { Roles } from '../constants/user';
import { LOBBY_WOW } from '../constants/lobby';
import { DEVICE_TYPE_WRTC_DEVICE, STREAM_TYPE_LOCAL, STREAM_TYPE_SCREEN } from '../constants/wbs';
import { isSafari, isChrome, isDesktop } from 'react-device-detect';
import { getSocket } from '../services/wbs/store';
import { publishLocal, reconnect } from '../services/wbs';
// import { getSocket } from '../services/wbs/store';

export async function stopVideoAndAudioTracks(stream: MediaStream | null) {
  if (stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }
}

export const changeStatusVideoTrack = (status: boolean) => {
  const {
    wbs: { localStream, setLocalStream },
  } = rootStore;

  if (localStream) {
    if (localStream.getVideoTracks()[0]) {
      localStream.getVideoTracks()[0].enabled = status;
      logApi(LOG_LEVEL, `Set local stream. Setting video track status as ${status}`);
      setLocalStream(localStream);
    }
  }
};

export const changeStatusAudioTrack = (status: boolean) => {
  const {
    wbs: { localStream, setLocalStream },
  } = rootStore;

  if (localStream) {
    if (localStream.getAudioTracks()[0]) {
      localStream.getAudioTracks()[0].enabled = status;
      logApi(LOG_LEVEL, `Set local stream. Setting audio track status as ${status}`);
      setLocalStream(localStream);
    }
  }
};

const getIsExistAudio = (audioDevices: DeviceType[]): boolean => {
  return audioDevices.length > 0;
};

const getIsExistVideo = (videoDevices: DeviceType[]): boolean => {
  return videoDevices.length > 0;
};

async function checkExistOrBlockDevices() {
  let { audioDevices, videoDevices } = await getDevices();

  const isExistAudio = getIsExistAudio(audioDevices);
  const isExistVideo = getIsExistVideo(videoDevices);

  return {
    isExistAudio,
    isExistVideo,
  };
}

export const createScreenStream = ({ ref, width, height, onEndedStream, onFailStream }: any) => {
  console.log(width, height);
  return getScreenStream()
    .then((stream: MediaStream) => {
      (stream as MediaStream & { stop: any }).stop = function () {
        this.getAudioTracks().forEach((track) => track.stop());
        this.getVideoTracks().forEach((track) => track.stop());
      };
      rootStore.wbs.setScreenStream(stream);
      setStreamToRef(ref, stream);
      publishScreenChannel(stream);

      stream.getVideoTracks()[0].onended = onEndedStream;
      return stream;
    })
    .catch(onFailStream);
};

/**
 * TODO: return settings for screen share quality and redo this function with this settings
 */
const getScreenStream = () => {
  const mediaScreenShareParams = {
    audio: isChrome && isDesktop ? true : false,
    video: {
      width: isSafari ? 1920 : { ideal: 1600, max: 1920 },
      height: isSafari ? 1200 : { max: 1200 },
    },
  };
  return navigator.mediaDevices.getDisplayMedia(mediaScreenShareParams);
};

export async function getLocalStream() {
  try {
    const {
      allSettings,
      mySetting,
      wbs: { localStream, setLocalStream },
    } = rootStore;
    if (
      navigator &&
      navigator.mediaDevices &&
      typeof navigator.mediaDevices.enumerateDevices === 'function'
    ) {
      logApi(LOG_LEVEL, 'Get local stream');
      const { isExistAudio, isExistVideo } = await checkExistOrBlockDevices();

      await stopVideoAndAudioTracks(localStream);

      if (!isExistAudio) {
        console.log('dont exist/block audio device or private mode');
        clearAllAudioDevices();
      }

      if (!isExistVideo) {
        console.log('dont exist/block video device or private mode');
        clearAllVideoDevices();
      }

      if (isExistAudio || isExistVideo) {
        const audioDeviceId = mySetting.getMyAudioDeviceId();
        const videoDeviceId = mySetting.getMyVideoDeviceId();

        const stream = await getUserMediaStream(
          isExistAudio,
          isExistVideo,
          audioDeviceId,
          videoDeviceId
        );

        if (stream) {
          logApi(LOG_LEVEL, 'Set local stream.');
          setLocalStream(stream);

          const isActiveVideo = mySetting.getIsShowVideo();
          const isActiveAudio = mySetting.getIsShowAudio();

          // TODO: может что-то поломать.
          // Вероятно или не нужно т.к. лишний раз сохраняет в mobx
          // Нужно потестить и потом удалить.
          // На момент июль 2023 работает
          // changeStatusVideoTrack(isActiveVideo);
          // changeStatusAudioTrack(isActiveAudio);
          emitActiveMediaDevices(isActiveAudio && isExistAudio, isActiveVideo && isExistVideo);

          let { audioDevices, videoDevices } = await getDevices();

          if (getIsExistAudio(audioDevices)) {
            allSettings.saveAllAudioDevices(audioDevices);
          }

          if (getIsExistVideo(videoDevices)) {
            allSettings.saveAllVideoDevices(videoDevices);
          }

          const selectAudioDeviceLabel = mySetting.getMyAudioDeviceLabel();
          const selectVideoDeviceLabel = mySetting.getMyVideoDeviceLabel();
          let defaultAudioDevice = null;
          let defaultVideoDevice = null;

          if (allSettings.audioDevices.length > 0) {
            defaultAudioDevice = allSettings.audioDevices[0];
          }

          if (allSettings.videoDevices.length > 0) {
            defaultVideoDevice = allSettings.videoDevices[0];
          }

          if (!selectAudioDeviceLabel && defaultAudioDevice) {
            mySetting.setMyAudioDeviceLabel(defaultAudioDevice.label);
            mySetting.setMyAudioDeviceId(defaultAudioDevice.deviceId);
          }

          if (!selectVideoDeviceLabel && defaultVideoDevice) {
            mySetting.setMyVideoDeviceLabel(defaultVideoDevice.label);
            mySetting.setMyVideoDeviceId(defaultVideoDevice.deviceId);
          }

          return stream;
        }
      }
    }
  } catch (e) {
    console.log('e=', e);
  }
}

export const appendStreamToPage = async (ref: MutableRefObject<null>, stream: MediaStream) => {
  let video = ref.current as HTMLVideoElement | null;

  if (video) {
    video.srcObject = stream;
    video.style.transform = 'scale(-1, 1)';
  }
};

export const getStreamAndAppend = async (ref: MutableRefObject<null>) => {
  const stream = await getLocalStream();
  if (stream) {
    await appendStreamToPage(ref, stream);
  }
};

export const getStreamAppendAndPushToMs = async (ref: MutableRefObject<null>) => {
  const stream = await getLocalStream();
  if (stream) {
    logWbs(INFO_LEVEL, 'stream append and push to ms', JSON.stringify({ stream }));
    await appendStreamToPage(ref, stream);
    setTimeout(() => {
      publishLocalChannel(stream);
    }, 2000);
  }
};

type DeviceType = {
  label: string;
  deviceId: string;
} | null;

async function getDevices() {
  try {
    let devices: MediaDeviceInfo[] | [] = [];
    let audioDevices: DeviceType[] | [] = [];
    let videoDevices: DeviceType[] | [] = [];

    if (navigator.mediaDevices) {
      devices = await navigator.mediaDevices.enumerateDevices();
    }

    if (devices.length === 0) {
      throw new Error('not media devices found');
    }
    audioDevices = devices
      .filter((device) => device.kind === 'audioinput')
      .map((device) => {
        let { deviceId, label } = device;
        if (label && deviceId) {
          if (label.length > 30) {
            label = label.substring(0, 30);
          }
        }

        return { deviceId, label };
      });

    videoDevices = devices
      .filter((device) => device.kind === 'videoinput')
      .map((device) => {
        let { deviceId, label } = device;
        if (label && deviceId) {
          if (label.length > 30) {
            label = label.substring(0, 30);
          }
        }

        return { deviceId, label };
      });

    return { audioDevices, videoDevices };
  } catch (e: any) {
    return e;
  }
}

async function getUserMediaStream(
  isShowAudio: boolean,
  isShowVideo: boolean,
  audioDeviceId: string | null,
  videoDeviceId: string | null
) {
  try {
    if (navigator.mediaDevices.getUserMedia) {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: isShowAudio ? (audioDeviceId ? { deviceId: audioDeviceId } : true) : false,
        video: isShowVideo
          ? videoDeviceId
            ? { deviceId: videoDeviceId }
            : {
                width: isSafari ? { max: 640 } : { ideal: 352 },
                height: isSafari ? { max: 360 } : { ideal: 240 },
                frameRate: isSafari ? { max: 30 } : { ideal: 24 },
              }
          : false,
      });

      return mediaStream;
    }

    return null;
  } catch (e: any) {
    console.log(`Error requesting userMedia!: ${e}`);
    if (e.name === 'NotAllowedError') {
      rootStore.mySetting.setIsDevicesPermissionDenied(true);
    }
  }
}

export const setStreamToRef = (ref: any, stream: MediaStream | null) => {
  if (ref) {
    const video = ref.current as HTMLVideoElement | null;
    if (video) {
      video.srcObject = stream;
    }
  }
};

// PUBLISH
export const publishLocalChannel = (stream: MediaStream) => {
  const { localStreamChannel } = rootStore.wbs;
  try {
    /**
     * REWORK
     * Made websocket check on channel publish
     * If WS connection doesn't exist:
     * Do a full reconnect cycle
     */
    if (!getSocket()?.OPEN) {
      reconnect();
      publishLocal();
      // return;
    }
    if (localStreamChannel !== null) {
      logWbs(INFO_LEVEL, 'publish local channel', JSON.stringify({ localStreamChannel }));
      addDevice({
        sinkQueues: [{ name: localStreamChannel }],
        deviceType: { type: DEVICE_TYPE_WRTC_DEVICE },
        stream,
        streamType: STREAM_TYPE_LOCAL,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const publishScreenChannel = (stream: MediaStream) => {
  const { screenStreamChannel } = rootStore.wbs;
  if (screenStreamChannel !== null) {
    addDevice({
      sinkQueues: [{ name: screenStreamChannel }],
      deviceType: { type: DEVICE_TYPE_WRTC_DEVICE },
      stream,
      streamType: STREAM_TYPE_SCREEN,
    });
  }
};

export const closeLocalChannel = () => {
  destroyLocalPeer();
};

export const closeChannels = () => {
  closeScreenChannel();
  closeLocalChannel();
};

export const closeScreenChannel = async () => {
  const {
    mySetting: { setIsShowPersonalShare },
    modal: { setIsOpenRequestScreenShareModal },
    screenSharingModule: { refPlayerLocalScreenShare: ref },
    wbs: { screenStream, setScreenStream },
  } = rootStore;

  if (screenStream) {
    screenStream.stop();
    setScreenStream(null);
  }
  destroyScreenPeer();
  setIsShowPersonalShare(false);
  setStreamToRef(ref, null);
  setIsOpenRequestScreenShareModal(false);
  await ApiWrapper.stopSharingScreen();
};

export const onEndedStream = async () => {
  logWbs(INFO_LEVEL, 'share close');
  await closeScreenChannel();
};

export const onFailStream = async (e: Error) => {
  logWbs(ERROR_LEVEL, `share error ${e.message}`);
  await closeScreenChannel();
};

export const isShowPersonalShare = (): boolean => {
  const {
    user: { role, travelDestination },
  } = rootStore;

  return role !== Roles.MODERATOR && isDesktop && travelDestination !== LOBBY_WOW;
};
