import React from 'react';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';

const ScreenSaver = () => {
  const { UI } = useStores();

  const { screenSaver } = useStyles();
  return UI.widgetsArea.items.get('screenSaverSelector')?.isOpened ? (
    <div className={screenSaver}>SCREENSAVER</div>
  ) : null;
};

export default ScreenSaver;
