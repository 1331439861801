export const breakpoints = {
  values: {
    xs: 375,
    sm: 600,
    md: 1080,
    lg: 1280,
    xl: 1920,
    width_iphone_6s: 380,
    height_iphone_6s: 670,
    width_ipad_pro_11: 834,
    height_ipad_pro_11: 1200,
  },
};
