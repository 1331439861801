import React from 'react';
import { Select } from './..';
import { useStores } from '../../hooks/useStores';
import { existVideoDevice, handleChangeMyVideoDevice } from '../../helpers/device';

type VideoSelectType = {
  handleUpdateLocalStream: any;
  innerRef: any;
  isShow: boolean;
  setIsShow: any;
};

const VideoSelect = ({ handleUpdateLocalStream, innerRef, isShow, setIsShow }: VideoSelectType) => {
  const {
    allSettings: { videoDevices },
    mySetting: { myVideoDeviceLabel, isShowVideo },
  } = useStores();

  return (
    <Select
      defaultValue={myVideoDeviceLabel}
      handleChange={(videoDeviceLabel: string) =>
        handleChangeMyVideoDevice(videoDeviceLabel, handleUpdateLocalStream)
      }
      innerRef={innerRef}
      isShow={isShow}
      setIsShow={setIsShow}
      options={videoDevices}
      isDisabled={!isShowVideo || !existVideoDevice()}
    />
  );
};
export default VideoSelect;
