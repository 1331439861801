import { validateSchema } from '../../ajv';
import { setSdpSchema } from '../../ajv/schema';
import { getPcByStreamType } from '../../store';

export const setSdp = async (json: any, streamType: string) => {
  validateSchema(setSdpSchema, json);
  let { sdp: sdpString } = json;

  if (sdpString) {
    sdpString = replaceRemoteSdp(sdpString);
    let sdp = new RTCSessionDescription({
      type: 'answer',
      sdp: sdpString,
    });
    const pc = getPcByStreamType(streamType);
    if (pc) {
      await pc.setRemoteDescription(sdp);
    }
  }
};

const replaceRemoteSdp = (sdpString: string) => {
  sdpString = sdpString.replace(/a=extmap.* urn:3gpp:video-orientation\r\n/, '');
  // sdpString = sdpString.replace(
  //   'a=extmap:13 urn:3gpp:video-orientation\r\n',
  //   ''
  // )
  return sdpString;
};
