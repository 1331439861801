import { rootStore } from '../models/rootStore';
import { emitViewportResolution } from './game/emitsToGame/emits';
import { webRtcPlayerObject } from './webRtcWrapper';
export let styleWidth: any;
export let styleHeight: any;
export let normalizeAndQuantizeUnsigned: any;
export let normalizeAndQuantizeSigned: any;
export let playerElementClientRect: any;

let lastTimeResized = new Date().getTime();
let resizeTimeout: any;
let styleTop;
let styleLeft;
let styleAdditional: any;

export function registerResizeEvents() {
  window.addEventListener('orientationchange', onOrientationChange);
  window.addEventListener('resize', resizePlayerStyle, true);
}

export function deregisterResizeEvents() {
  window.removeEventListener('orientationchange', onOrientationChange);
  window.removeEventListener('resize', resizePlayerStyle, true);
}

function onOrientationChange() {
  setTimeout(() => {
    resizePlayerStyle();
  }, 500);
}

export function resizePlayerStyle() {
  let playerElement = rootStore.connection.getRefPlayer();
  if (!playerElement) return;

  updateVideoStreamSize();

  // Расчет и нормализация позиций зависит от ширины и высоты player
  playerElementClientRect = playerElement.getBoundingClientRect();
  setupNormalizeAndQuantize();

  resizePlayerStyleToFillWindow(playerElement);
}

function updateVideoStreamSize() {
  let now = new Date().getTime();
  if (now - lastTimeResized > 1500) {
    let playerElement = rootStore.connection.getRefPlayer();
    if (!playerElement || !webRtcPlayerObject) return;

    let currentVideoHeight = webRtcPlayerObject?.video.videoHeight;
    let currentVideoWidth = webRtcPlayerObject?.video.videoWidth;

    let resolution = calculateOptimalStreamingResolution();
    if (!resolution?.height || !resolution.width) return;

    let width = resolution.width;
    let height = resolution.height;

    if (currentVideoHeight + currentVideoWidth !== width + height) {
      emitViewportResolution(resolution);
    }

    lastTimeResized = new Date().getTime();
  } else {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(updateVideoStreamSize, 1500);
  }
}

export function calculateOptimalStreamingResolution() {
  // let aspectRatio = Math.floor(window.innerWidth / window.innerHeight);
  /**
   * TODO: add device pixel ratio
   */
  let pixelRatio = window.devicePixelRatio;

  let innerHeight = window.innerHeight * pixelRatio;
  let innerWidth = window.innerWidth * pixelRatio;

  let maxHeight = rootStore.mySetting.myQuality;
  let maxWidth = 2560;

  let finalWidth;
  let finalHeight;

  if (innerHeight < maxHeight) {
    finalHeight = innerHeight;
    if (innerWidth > maxWidth) {
      finalWidth = maxWidth;
    } else if (innerWidth <= maxWidth) {
      finalWidth = innerWidth;
    }
  } else if (innerHeight > maxHeight) {
    finalHeight = maxHeight;
    let aspectRatio = innerWidth / innerHeight;
    let tempWidth = Math.floor(finalHeight * aspectRatio);
    if (tempWidth > maxWidth) {
      finalWidth = maxWidth;
    } else if (tempWidth <= maxWidth) {
      finalWidth = tempWidth;
    }
  }
  console.log(`current resolution: ${finalWidth} x ${finalHeight}`);
  return { height: finalHeight, width: finalWidth };
}

function resizePlayerStyleToFillWindow(playerElement: any) {
  let videoElement = playerElement.getElementsByTagName('VIDEO');

  // Заполняем экран плеера в окне, сохраняя соотношение сторон изображения.
  let windowAspectRatio = window.innerHeight / window.innerWidth;
  let playerAspectRatio = playerElement.clientHeight / playerElement.clientWidth;
  // Мы хотим, чтобы соотношение сторон видео было правильным для видеопотока
  let videoAspectRatio = videoElement.videoHeight / videoElement.videoWidth;
  if (isNaN(videoAspectRatio)) {
    // Видео еще не инициализировано, поэтому установите playerElement на размер окна
    styleWidth = window.innerWidth;
    styleHeight = window.innerHeight;
    styleTop = 0;
    styleLeft = 0;
  } else if (windowAspectRatio < playerAspectRatio) {
    // Высота окна является ограничивающим фактором, чтобы сохранить ширину изменения соотношения сторон соответствующим образом
    styleWidth = Math.floor(window.innerHeight / videoAspectRatio);
    styleHeight = window.innerHeight;
    styleTop = 0;
    styleLeft = Math.floor((window.innerWidth - styleWidth) * 0.5);
  } else {
    // Ширина окна является ограничивающим фактором, поэтому, чтобы сохранить соотношение сторон, измените высоту соответствующим образом
    styleWidth = window.innerWidth;
    styleHeight = Math.floor(window.innerWidth * videoAspectRatio);
    styleTop = Math.floor((window.innerHeight - styleHeight) * 0.5);
    styleLeft = 0;
  }

  // Видео теперь на 100% состоит из playerElement, поэтому установите стиль playerElement
  playerElement.style = `top: ${styleTop}px; left: ${styleLeft}px; width: ${styleWidth}px; height: ${styleHeight}px; ${styleAdditional}`;
}

function setupNormalizeAndQuantize() {
  let playerElement = rootStore.connection.getRefPlayer() as any;
  let videoElement = playerElement.getElementsByTagName('video');

  if (playerElement && videoElement.length > 0) {
    let playerAspectRatio = playerElement.clientHeight / playerElement.clientWidth;
    let videoAspectRatio = videoElement[0].videoHeight / videoElement[0].videoWidth;

    // Позиции XY без знака - это соотношение (0,0..1,0) вдоль оси окна просмотра,
    // квантуется в uint16 (0..65536).
    // Подписанные дельты XY - это отношение (-1.0..1.0) вдоль оси окна просмотра,
    // квантуется в int16 (-32767..32767).
    // Это позволяет области просмотра браузера и окна просмотра клиента иметь разные
    // размер.
    // Хак: в настоящее время мы устанавливаем крайнее положение вне допустимого диапазона (65535)
    // поскольку мы еще не можем точно определить события входа и выхода мыши
    // именно внутри видео с соотношением сторон, которое вызывает матирование.
    if (playerAspectRatio > videoAspectRatio) {
      let ratio = playerAspectRatio / videoAspectRatio;
      normalizeAndQuantizeUnsigned = (x: any, y: any) => {
        let normalizedX = x / playerElement.clientWidth;
        let normalizedY = ratio * (y / playerElement.clientHeight - 0.5) + 0.5;
        if (normalizedX < 0.0 || normalizedX > 1.0 || normalizedY < 0.0 || normalizedY > 1.0) {
          return {
            inRange: false,
            x: 65535,
            y: 65535,
          };
        } else {
          return {
            inRange: true,
            x: normalizedX * 65536,
            y: normalizedY * 65536,
          };
        }
      };
      normalizeAndQuantizeSigned = (x: any, y: any) => {
        let normalizedX = x / (0.5 * playerElement.clientWidth);
        let normalizedY = (ratio * y) / (0.5 * playerElement.clientHeight);
        return {
          x: normalizedX * 32767,
          y: normalizedY * 32767,
        };
      };
    } else {
      let ratio = videoAspectRatio / playerAspectRatio;
      normalizeAndQuantizeUnsigned = (x: any, y: any) => {
        let normalizedX = ratio * (x / playerElement.clientWidth - 0.5) + 0.5;
        let normalizedY = y / playerElement.clientHeight;
        if (normalizedX < 0.0 || normalizedX > 1.0 || normalizedY < 0.0 || normalizedY > 1.0) {
          return {
            inRange: false,
            x: 65535,
            y: 65535,
          };
        } else {
          return {
            inRange: true,
            x: normalizedX * 65536,
            y: normalizedY * 65536,
          };
        }
      };
      normalizeAndQuantizeSigned = (x: any, y: any) => {
        let normalizedX = (ratio * x) / (0.5 * playerElement.clientWidth);
        let normalizedY = y / (0.5 * playerElement.clientHeight);
        return {
          x: normalizedX * 32767,
          y: normalizedY * 32767,
        };
      };
    }
  }
}
