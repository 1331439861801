import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    '& svg:hover > rect': {
      fillOpacity: '0.4',
    },
  },
  productCount: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: 'normal',
    color: '#FFF',
    fontFamily: 'Outfit',
    width: '18px',
    height: '18px',
    background: 'linear-gradient(84deg, #3060E4 0%, #00BAF2 51.04%, #E069A8 100%)',
    borderRadius: '50%',
    position: 'absolute',
    top: '2px',
    right: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
