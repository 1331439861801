import React, { useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import {
  DebugPanel,
  // SideBar,
  StartPage,
  VideoPlayer,
  Loader,
  AfkModal,
  BanModal,
  TabbarMenuDesktop,
  TabbarMenuMobile,
  // ErrorModal,
  NotificationDevice,
  RotateMobile,
  LocalStreamDesktop,
  LocalStreamMobile,
  // TeleportList,
  // CustomizeList,
  // Settings,
  // ModeratoSettings,
} from '../../components';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { updateDevices } from '../../helpers/device';
import { PlayerProps } from '../../types/player';
import { DefaultRole, Roles } from '../../constants/user';
import { closeChannels, getStreamAppendAndPushToMs } from '../../helpers/stream';
import * as ShopService from '../../services/shop';
import { LOG_LEVEL, logApi } from '@pxs-infra/logger';

const Player = observer(
  ({
    eventId: eventIdProp,
    userId: userIdProp,
    env: envProp,
    region: regionProp,
    role: roleProp,
  }: PlayerProps) => {
    //player
    const { body, settingsWrapper, displayNone, notificationDeviceClass } = useStyles();

    const refPlayerLocalStream = useRef(null);
    const refStartPageLocalStream = useRef(null);
    const refPlayer = useRef(null);

    document.getElementsByTagName('html')[0].style.fontSize = 'calc(max(1vh, 1vw))';

    const {
      mySetting,
      connection,
      user,
      debug,
      shop: { setProducts },
      game,
    } = useStores();

    const { isHideInterface } = connection;

    // TODO: show modal if exist event connection.isEndEvent === true
    // const handleCloseModalEndEvent = () => {
    //   connection.setIsEndEvent(false);
    //   connection.setIsStartPage(true);
    // };

    // const handleChangeStaticShare = async (screenShareTarget: ScreenShareTargetType) => {
    //   if (existVideoDevice()) {
    //     const { isTaken, isTakenByCurrentUser, screenShareTargetId } = screenShareTarget;
    //     await closeScreenChannel();
    //     if (!isTaken && !isTakenByCurrentUser) {
    //       setIsOpenRequestScreenShareModal(true);
    //       screenSharingModule.setSelectedScreenShareTargetId(screenShareTargetId);
    //     }
    //     setStreamToRef(refPlayerLocalScreenShare, null);
    //   } else {
    //     setIsOpenPlayerPermissionModal(true);
    //   }
    // };

    const url = new URL(window.location.href);

    // get all products from shopify
    useEffect(() => {
      const fetchAllProducts = async () => {
        const products = await ShopService.getProducts();
        logApi(LOG_LEVEL, 'get all products from shoppify', products);
        if (products) {
          setProducts(products);
        }
      };
      fetchAllProducts().catch(console.error);
    }, [setProducts]);

    useEffect(() => {
      const saveGetParamsOrProps = () => {
        let userId = '',
          eventId = '',
          env = 'prod',
          region = 'california',
          role = DefaultRole,
          ueClientDomain = '';

        if (userIdProp && eventIdProp) {
          userId = userIdProp;
          eventId = eventIdProp;

          if (envProp) {
            env = envProp;
          }

          if (regionProp) {
            region = regionProp;
          }

          if (roleProp) {
            //@ts-ignore
            role = Roles[(roleProp as string).toUpperCase()];
          }
        } else {
          const userIdGetParams = url.searchParams.get('user_id');
          const eventIdGetParams = url.searchParams.get('event_id');
          const envGetParams = url.searchParams.get('env');
          const regionGetParams = url.searchParams.get('region');
          const roleGetParams = url.searchParams.get('role');
          const ueClientDomainGetParams = url.searchParams.get('ss_url');

          if (userIdGetParams) {
            userId = userIdGetParams;
          }

          if (eventIdGetParams) {
            eventId = eventIdGetParams;
          }

          if (envGetParams) {
            env = envGetParams;
          }

          if (regionGetParams) {
            region = regionGetParams;
          }

          if (roleGetParams) {
            //@ts-ignore
            role = Roles[(roleGetParams as string).toUpperCase()];
          }

          if (ueClientDomainGetParams) {
            ueClientDomain = ueClientDomainGetParams;
          }
        }

        user.setUserId(userId);
        user.setEventId(eventId);
        user.setEnv(env);
        user.setRegion(region);
        connection.setUeClientDomain(ueClientDomain);
        debug.setUeClientDomain(ueClientDomain);
        game.updateNearestStand.setStatusBuyButton(0);
        /* init with a default role or a custom role
        if it was passed in a GET param 'role'  or a roleProp arg */
        user.setRole(role);
      };

      saveGetParamsOrProps();
      connection.setIsHideInterface(false);

      const { REACT_APP_IS_HIDE_INTERFACE } = process.env;

      if (REACT_APP_IS_HIDE_INTERFACE) {
        if (REACT_APP_IS_HIDE_INTERFACE === 'true') {
          connection.setIsHideInterface(true);
        }
      }

      const skipStartPage: boolean = url.searchParams.get('skip_start_page') === 'true';
      const isOldControlMode: boolean = url.searchParams.get('is_old_control_mode') === 'true';
      connection.setIsError(false);
      connection.setIsLoading(false);
      connection.setLoadingMessage('');
      connection.setErrorMessage('');
      connection.setIsStartPage(!skipStartPage);
      connection.setIsOldControlMode(isOldControlMode);
      mySetting.setIsShowAudio(true);
      mySetting.setIsShowVideo(true);
      mySetting.setIsShowPersonalShare(false);
      user.clearAllMessages();
      connection.setIsEndEvent(false);
      updateDevices(refPlayerLocalStream, refStartPageLocalStream);
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [mySetting, connection, user, userIdProp, eventIdProp, envProp, roleProp]);

    // TODO: need delete
    // connection.setIsStartPage(false);

    const handleUpdateLocalStream = (isAudioChange: boolean) => {
      if (!isAudioChange) {
        closeChannels();
        getStreamAppendAndPushToMs(refPlayerLocalStream);
      }
    };

    // if (connection.isError) {
    //   return (
    //     <div className={player}>
    //       <ErrorModal isShow={connection.isError} handleClose={() => window.location.reload()} />
    //     </div>
    //   );
    // }

    if (connection.isStartPage) {
      return (
        <StartPage
          refPlayerLocalStream={refPlayerLocalStream}
          refStartPageLocalStream={refStartPageLocalStream}
          refPlayer={refPlayer}
        />
      );
    } else {
      return (
        <div
          className={body}
          // onMouseMove={() => resetAfkWarningTimer()}
          // onKeyDown={() => resetAfkWarningTimer()}
          // onMouseDown={() => resetAfkWarningTimer()}
          tabIndex={0}
        >
          <Loader>
            <RotateMobile />
            <div ref={refPlayer} className={connection.isLoading ? displayNone : ''}></div>
            <div className={notificationDeviceClass}>
              <NotificationDevice />
            </div>
            {/* TODO:delete start */}
            {/* <>
              <Box
                className={
                  classnames('')
                  // connection.isLoading || isHideInterface ? displayNone : ''
                  // UI.isSideMenuHidden && 'hidden'
                }
              >

                <CustomizeList />
                <Settings />
                <ModeratoSettings />
                <ChatsModule />
              </Box>
              {!UI.isSideMenuHidden && (
                <div
                  onClick={(e: any & { target: any & { id: any } }) => {
                    if (e.target.id === 'opened-side-menu-item-overlay') {
                      UI.sideMenuArea.closeAll();
                    }
                  }}
                />
              )}
            </> */}

            {user.role !== Roles.SPECTATOR && (
              <Box
                className={classnames(
                  settingsWrapper,
                  connection.isLoading || isHideInterface ? displayNone : ''
                )}
              >
                {debug.isShowDebugPanel && <DebugPanel />}
              </Box>
            )}
            {user.role !== Roles.SPECTATOR && (
              <>
                {/* Desktop */}
                <LocalStreamDesktop refPlayerLocalStream={refPlayerLocalStream} />
                <TabbarMenuDesktop handleUpdateLocalStream={handleUpdateLocalStream} />

                {/* Mobile */}
                <LocalStreamMobile refPlayerLocalStream={refPlayerLocalStream} />
                <TabbarMenuMobile handleUpdateLocalStream={handleUpdateLocalStream} />
              </>
            )}

            {/* Modals */}
            {user.role === Roles.DEFAULT_USER && <VideoPlayer />}
            {/* <RequestPersonalShareModal
              refPlayerLocalScreenShare={refPlayerLocalScreenShare}
              refCloseModal={refCloseModalPersonalShare}
            /> */}
            {/* <RequestStaticShareModal /> */}
            <AfkModal />
            <BanModal />
            {/* <SideBar /> */}
          </Loader>
        </div>
      );
    }
  }
);

export default Player;
