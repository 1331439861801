import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#A2A2A2',
  },
  badge: {
    marginLeft: '8px',
    background: '#A24DE8',
    padding: '2px 9px',
    borderRadius: '100px',

    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#FFFFFF',
  },
}));

export default useStyles;
