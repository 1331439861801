import { logApi, logGame, logWbs, ERROR_LEVEL, LOG_LEVEL } from '@pxs-infra/logger';
import { rootStore } from '../../models/rootStore';
import { emitUserControlScheme } from '../../services/game/emitsToGame/emits';
import { IceServerType } from '../../types/webRtcPlayer';

export function checkAvailabilityStun(iceServers: IceServerType[]) {
  if (!iceServers[0].urls.length) {
    throw new Error("Don't exist urls for default stun server");
  }

  const pc = new RTCPeerConnection({
    iceCandidatePoolSize: 0,
    iceServers,
  });

  pc.createDataChannel('', { reliable: false } as any);

  pc.onicecandidateerror = function (e: any) {
    const { debug } = rootStore;
    logApi(ERROR_LEVEL, 'ICE error when trying to check communication with STUN', e.errorText);
    debug.setIsErrorCheckTurn(true);
    debug.setIsLoadingCheckTurn(false);
  };

  pc.onicecandidate = (e: any) => {
    if (!e.candidate) return;

    if (e.candidate.type == 'srflx') {
      logApi(LOG_LEVEL, 'The STUN server is reachable', e.candidate);
    }

    if (e.candidate.type == 'relay') {
      const { debug } = rootStore;
      logApi(LOG_LEVEL, 'The TURN server is reachable', e.candidate);
      debug.setIsSuccessCheckTurn(true);
      debug.setIsLoadingCheckTurn(false);
    }
  };

  pc.createOffer().then((offerDesc) => {
    pc.setLocalDescription(offerDesc);
  });
}

export const checkAvailabilityMs = () => {
  const { wbs, debug } = rootStore;
  try {
    const { msDomain } = wbs;
    const websocket = new WebSocket(`wss://${msDomain}`);
    logWbs(LOG_LEVEL, 'ms exist', websocket);
    debug.setIsSuccessCheckMs(true);
  } catch (e: any) {
    logWbs(ERROR_LEVEL, 'ms error:', e);
    debug.setIsErrorCheckMs(true);
  }
  debug.setIsLoadingCheckMs(false);
};

export const checkAvailabilityInstance = () => {
  const { debug } = rootStore;
  try {
    const { ueClientDomain } = debug.checkInstance;
    const websocket = new WebSocket(`wss://${ueClientDomain}`);
    logApi(LOG_LEVEL, 'instance exist', websocket);
    debug.setIsSuccessCheckInstance(true);
  } catch (e: any) {
    logApi(ERROR_LEVEL, 'instance error:', e);
    debug.setIsErrorCheckInstance(true);
  }
  debug.setIsLoadingCheckInstance(false);
};

export const checkAvailabilityGame = () => {
  const { debug } = rootStore;
  try {
    emitUserControlScheme();
    logGame(LOG_LEVEL, 'success emit to game');
    debug.setIsSuccessCheckGame(true);
  } catch (e: any) {
    logGame(ERROR_LEVEL, ' emit to game error');
    debug.setIsSuccessCheckGame(true);
  }
  debug.setIsLoadingCheckGame(false);
};
