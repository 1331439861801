import React from 'react';
import { Player } from './pages';
import CssBaseline from '@material-ui/core/CssBaseline';
import CustomThemeProvider from './context/theme';
import { StoresContext } from './context/mobx';
import { rootStore } from './models/rootStore';
import 'webrtc-adapter';
import { I18nextProvider } from 'react-i18next';
import i18n from './helpers/i18next';
import { PlayerProps } from './types/player';

const App = ({ eventId, userId, env, region, role }: PlayerProps) => {
  return (
    <StoresContext.Provider value={rootStore}>
      <React.StrictMode>
        <CustomThemeProvider>
          <CssBaseline />
          <I18nextProvider i18n={i18n}>
            <Player eventId={eventId} userId={userId} env={env} role={role} region={region} />
          </I18nextProvider>
        </CustomThemeProvider>
      </React.StrictMode>
    </StoresContext.Provider>
  );
};

export default App;
