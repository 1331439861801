import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  chatsListContainer: {
    height: '100%',
    width: '18.875rem',
    backgroundColor: 'rgba(31,31,31, .9)',
  },
  chatsListHeader: {
    width: '100%',
    height: '4.25rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '1.25rem',
    paddingRight: '.75rem',
  },
  chatListHeaderTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
}));

export default useStyles;
