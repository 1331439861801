import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';
import { emitModeratorForceLiveMode } from '../../services/game/emitsToGame/emits';
import useStyles from './style';

const LiveIndicator = observer(() => {
  const { user } = useStores();
  const { liveIndicator, liveIndicatorOn, liveIndicatorDot } = useStyles();

  return (
    <div
      className={classnames(liveIndicator, { [liveIndicatorOn]: user.isLive })}
      onClick={() => emitModeratorForceLiveMode(!user.isLive)}
    >
      {user.isLive && <div className={liveIndicatorDot} />}
      <span>On live</span>
    </div>
  );
});

export default LiveIndicator;
