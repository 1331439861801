import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { AudioMuteIcon, VideoMuteIcon, AudioOffIcon, VideoOffIcon, Notification } from '..';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const NotificationDevice = observer(() => {
  const { t } = useTranslation();
  const { container, notificationClass } = useStyles();
  const [isShowAudioNotification, setIsShowAudioNotification] = useState(false);
  const [isShowVideoNotification, setIsShowVideoNotification] = useState(false);
  const [audioNotificationData, setAudioNotificationData] = useState<{ icon: any; text: string }>({
    icon: null,
    text: '',
  });
  const [videoNotificationData, setVideoNotificationData] = useState<{ icon: any; text: string }>({
    icon: null,
    text: '',
  });
  const {
    mySetting: { isMutedAudio, isMutedVideo, isShowAudio, isShowVideo },
  } = useStores();

  useEffect(() => {
    if (isMutedAudio) {
      setAudioNotificationData({
        icon: <AudioMuteIcon color="white" />,
        text: t('Mute_audio'),
      });
      setIsShowAudioNotification(true);
      setTimeout(() => setIsShowAudioNotification(false), 5000);
    }

    if (!isMutedAudio && !isShowAudio) {
      setAudioNotificationData({
        icon: <AudioOffIcon color="white" />,
        text: t('Off_audio'),
      });
      setIsShowAudioNotification(true);
      setTimeout(() => setIsShowAudioNotification(false), 5000);
    }

    if (isMutedVideo) {
      setVideoNotificationData({
        icon: <VideoMuteIcon color="white" />,
        text: t('Mute_video'),
      });
      setIsShowVideoNotification(true);
      setTimeout(() => setIsShowVideoNotification(false), 5000);
    }

    if (!isMutedVideo && !isShowVideo) {
      setVideoNotificationData({
        icon: <VideoOffIcon color="white" />,
        text: t('Off_video'),
      });
      setIsShowVideoNotification(true);
      setTimeout(() => setIsShowVideoNotification(false), 5000);
    }
  }, [isMutedAudio, isMutedVideo, isShowAudio, isShowVideo, t]);

  return (
    <div className={container}>
      <div>
        <Notification
          notificationClass={notificationClass}
          icon={audioNotificationData.icon}
          isShow={isShowAudioNotification}
          text={audioNotificationData.text}
        />
      </div>
      <div>
        <Notification
          notificationClass={notificationClass}
          icon={videoNotificationData.icon}
          isShow={isShowVideoNotification}
          text={videoNotificationData.text}
        />
      </div>
    </div>
  );
});

export default NotificationDevice;
