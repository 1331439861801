import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: '20px',
  },
  menuText: {
    margin: 0,
    paddingTop: 4,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
}));

export default useStyles;
