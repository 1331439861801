import React from 'react';
import classnames from 'classnames';
import useStyles from './style';
import { QualitySelect, CloseIcon, PinIcon, Switch } from './..';
import { useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';
// import { closeChannels, getStreamAppendAndPushToMs } from '../../helpers/stream';
import { Button } from '..';
import { existVideoDevice } from '../../helpers/device';
// import { switchInputType } from '../../services/control';
// import { useTranslation } from 'react-i18next';

// TODO: этот компонент будет удаляться
const Settings = observer(() => {
  // const [isFullScreenOn, setFullScreenOn] = useState<boolean>(false);
  // const [isSwitchedInputType, setIsSwitchedInputType] = useState<boolean>(true);
  const theme = useTheme();
  const {
    user: { isModerator },
    UI,
  } = useStores();
  const {
    settings,
    settingsHeader,
    settingsHeaderTitle,
    settingsListItem,
    settingsListItemLabel,
    settingsListItemLabelActive,
    settingsListItemSwitchWrap,
    settingsContainer,
  } = useStyles({ isModerator });
  // const { t } = useTranslation();

  // useEffect(() => {
  //   const fullScreenListener = () => {
  //     if (isFullScreen(document)) {
  //       setFullScreenOn(true);
  //     } else {
  //       setFullScreenOn(false);
  //     }
  //   };
  //   document.addEventListener('fullscreenchange', fullScreenListener);
  //   return () => document.removeEventListener('fullscreenchange', fullScreenListener, false);
  // }, []);

  // const handleFullScreen = () => {
  //   toogleFullScreen();
  //   setFullScreenOn(!isFullScreenOn);
  // };

  // const handleSwitchInputType = () => {
  //   switchInputType(!isSwitchedInputType);
  //   setIsSwitchedInputType(!isSwitchedInputType);
  // };

  return UI.sideMenuArea.items.get('settingList')?.isOpened ? (
    <div className={settingsContainer}>
      <div className={settings}>
        <div className={settingsHeader}>
          <p className={settingsHeaderTitle}>SETTINGS</p>
          <Button onClick={() => UI.sideMenuArea.toggleOne('settingList')}>
            <CloseIcon color={theme.palette.primary.dark} />
          </Button>
        </div>
        <div>
          {/* {existAudioDevice() && (
            <div className={settingsListItem}>
              <MicSelect
                handleUpdateLocalStream={() => handleUpdateLocalStream(true)}
                className={micSelectAdditionalClass}
              />
            </div>
          )} */}
          {existVideoDevice() && (
            <div className={settingsListItem}>
              {/* <VideoSelect
                className={videoSelectAdditionalClass}
                handleUpdateLocalStream={() => handleUpdateLocalStream(false)}
              /> */}
            </div>
          )}
          <QualitySelect size="1.5rem" padding=".375rem" />
          {/* {role !== Roles.SPECTATOR && (
            <div className={settingsListItem}>
              <p
                className={classnames(settingsListItemLabel, {
                  [settingsListItemLabelActive]: isFullScreenOn,
                })}
              >
                Switch to fullsrceen
              </p>
              <div className={settingsListItemSwitchWrap}>
                <Switch onToggle={handleFullScreen} value={isFullScreenOn} />
              </div>
            </div>
          )} */}
          <div className={settingsListItem}>
            <PinIcon size="1.5rem" activeColor={UI.isSideMenuPinned ? '#FFFFFF' : ''} />
            <p
              className={classnames(settingsListItemLabel, {
                [settingsListItemLabelActive]: UI.isSideMenuPinned,
              })}
            >
              Pin the Panel
            </p>
            <div className={settingsListItemSwitchWrap}>
              <Switch
                isDisabled={false}
                value={UI.isSideMenuPinned}
                onToggle={() => {
                  UI.setIsSideMenuPinned(!UI.isSideMenuPinned);
                }}
              />
            </div>
          </div>

          {/* <div className={settingsListItem}>
            <p
              className={classnames(settingsListItemLabel, {
                [settingsListItemLabelActive]: isFullScreenOn,
              })}
            >
              {t('Enable new control mode')}
            </p>
            <div className={settingsListItemSwitchWrap}>
              <Switch onToggle={handleSwitchInputType} value={isSwitchedInputType} />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  ) : null;
});

export default Settings;
