import { makeStyles, Theme } from '@material-ui/core/styles';
import { sideMenuWidth } from '../../globalStyles';

// eslint-disable-next-line no-empty-pattern
const useStyles = makeStyles(({}: Theme) => ({
  chatsModuleContainer: {
    height: '100%',
    position: 'absolute',
    left: sideMenuWidth,
    top: 0,
    zIndex: 3,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all',
    },
  },
  selectedChatContainer: {
    height: 'calc(100% - 12rem)',
    width: '39.43rem',
    marginLeft: '1.25rem',
    backgroundColor: 'rgba(31,31,31, .9)',
    paddingTop: '1.5rem',
    borderRadius: '1.5rem',
    overflow: 'hidden',
  },
}));

export default useStyles;
