import { MEDIA_SERVER_EVENT_ADD_DEVICE } from '../../../../constants/wbs';
import { getSocket } from '../../store';
import { sendDataToServer } from '.';
import { AddDeviceType } from '../../../../types/wbs';
import { registerReceiveDataFromServerListener } from '../onmessage';
import { STREAM_TYPE_LOCAL } from '../../../../constants/wbs';
import { loggerLog } from '../../logger';

export const addDevice = ({
  deviceType,
  stream,
  ref,
  sourceQueues,
  sinkQueues,
  streamType = STREAM_TYPE_LOCAL,
}: AddDeviceType) => {
  if (deviceType.type === 'wrtc_device') {
    const socket = getSocket();
    if (socket && stream) {
      registerReceiveDataFromServerListener({
        socket,
        streamType,
        stream,
        refRemoteStream: ref,
      });
    }
  }

  const data = {
    req: MEDIA_SERVER_EVENT_ADD_DEVICE,
    device: deviceType,
    sinks: sinkQueues,
    sources: sourceQueues,
  };
  loggerLog(`send ${MEDIA_SERVER_EVENT_ADD_DEVICE} to ms-server`, data);
  sendDataToServer(data);
};
