import React from 'react';
import useStyles from './style';

type AudioOffIconProps = {
  color?: string;
};

const AudioOffIcon = ({ color = '#A24DE8' }: AudioOffIconProps) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      color={color}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_933_5254"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="20"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.66602 1.5C2.00916 1.5 0.666016 2.84315 0.666016 4.5V13C0.666016 14.6569 2.00916 16 3.66602 16H13.3336H16.3327C17.9895 16 19.3327 14.6569 19.3327 13V4.5C19.3327 2.84315 17.9895 1.5 16.3327 1.5H3.66602ZM2.66602 4.5C2.66602 3.94772 3.11373 3.5 3.66602 3.5H16.3327C16.885 3.5 17.3327 3.94771 17.3327 4.5V13C17.3327 13.5523 16.885 14 16.3327 14H13.3336H3.66602C3.11373 14 2.66602 13.5523 2.66602 13V4.5ZM1.66602 16.5C1.11373 16.5 0.666016 16.9477 0.666016 17.5C0.666016 18.0523 1.11373 18.5 1.66602 18.5H18.3327C18.885 18.5 19.3327 18.0523 19.3327 17.5C19.3327 16.9477 18.885 16.5 18.3327 16.5H1.66602Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_933_5254)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default AudioOffIcon;
