import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  afkModalClass: {
    width: '480px',
    display: 'flex',
    borderRadius: '8px',
    position: 'relative',
  },
  afkModalWrapClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  afkModalContainer: {
    width: '100%',
    padding: '24px 24px 40px 40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  afkModalLeftColumn: {},
  afkModalRightColumn: {},
  afkModalTimer: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: '#FFFFFF',
    background: '#A24DE8',
    width: '80px',
    height: ' 80px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  afkModalTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '44px',
    color: '#140E17',
    paddingTop: '8px',
  },
  afkModalDesc: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#140E17',
    paddingTop: '24px',
  },
}));

export default useStyles;
