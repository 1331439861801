import { JSONSchemaType } from 'ajv/dist/core';
import { DeviceInfoType } from '../../../types/wbs';
import { WbsInfoType } from '../../../types/wbs/emit';

export const addDeviceSchema = {
  type: 'object',
  properties: {
    sources: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: { type: 'string' },
        },
        required: ['name'],
      },
    },
    sinks: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: { type: 'string' },
        },
        required: ['name'],
      },
    },
    device: {
      type: 'object',
      properties: {
        type: { type: 'string' },
      },
    },
    status: { type: 'string' },
    req: { type: 'string', const: 'add_device' },
    device_id: { type: 'number' },
    ice_servers: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          urls: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
        },
        required: ['urls'],
      },
    },
  },
  allOf: [
    {
      required: ['req', 'device', 'device_id', 'status'],
    },
    {
      anyOf: [
        {
          required: ['sinks'],
        },
        {
          required: ['sources'],
        },
        {
          required: ['sources', 'sinks'],
        },
      ],
    },
  ],
  additionalProperties: true,
};

export const setSdpSchema = {
  type: 'object',
  properties: {
    status: { type: 'string' },
    req: { type: 'string', const: 'set_sdp' },
    device_id: { type: 'number' },
    sdp: { type: 'string' },
  },
  required: ['device_id', 'req', 'sdp'],
  additionalProperties: true,
};

export const setIceSchema = {
  type: 'object',
  properties: {
    status: { type: 'string' },
    req: { type: 'string', const: 'set_ice' },
    device_id: { type: 'number' },
  },
  required: ['device_id', 'req'],
  additionalProperties: true,
};

export const removeDeviceSchema = {
  type: 'object',
  properties: {
    status: { type: 'string' },
    req: { type: 'string', const: 'remove_device' },
    device_id: { type: 'number' },
  },
  required: ['device_id', 'status', 'req'],
  additionalProperties: true,
};

export const wbsInfoSchema: JSONSchemaType<WbsInfoType> = {
  type: 'object',
  properties: {
    build_time: { type: 'string' },
    command_pending: { type: 'number' },
    command_workers: { type: 'number' },
    cpu_used: { type: 'number' },
    handles: { type: 'number' },
    id: { type: 'number' },
    memory_used: { type: 'number' },
    pid: { type: 'number' },
    req: { type: 'string', const: 'wbs_info' },
    start_time: { type: 'string' },
    status: { type: 'string' },
    transcode_pending: { type: 'number' },
    transcode_workers: { type: 'number' },
    version: { type: 'string' },
  },
  required: [
    'build_time',
    'command_pending',
    'command_workers',
    'cpu_used',
    'handles',
    'id',
    'memory_used',
    'pid',
    'req',
    'start_time',
    'status',
    'transcode_pending',
    'transcode_workers',
    'version',
  ],
  additionalProperties: true,
};

export const deviceInfoSchema: JSONSchemaType<DeviceInfoType> = {
  type: 'object',
  properties: {
    id: { type: 'number' },
    req: { type: 'string', const: 'device_info' },
    status: { type: 'string' },
    device_states: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          device: {
            type: 'object',
            properties: {
              type: { type: 'string' },
              url: { type: 'string' },
            },
            required: ['type', 'url'],
            additionalProperties: true,
          },
          sinks: {
            type: 'array',
            nullable: true,
            items: {
              type: 'object',
              properties: {
                audio_format: { type: 'string' },
                name: { type: 'string' },
                transcode_always: { type: 'boolean' },
                video_format: { type: 'string' },
              },
              required: ['audio_format', 'name', 'transcode_always', 'video_format'],
            },
          },
          sources: {
            type: 'array',
            nullable: true,
            items: {
              type: 'object',
              properties: {
                audio_format: { type: 'string' },
                name: { type: 'string' },
                transcode_always: { type: 'boolean' },
                video_format: { type: 'string' },
              },
              required: ['audio_format', 'name', 'transcode_always', 'video_format'],
            },
          },
          device_id: { type: 'number' },
          state: { type: 'string' },
        },
        required: ['device', 'device_id', 'state'],
        additionalProperties: true,
      },
    },
  },
  required: ['id', 'req', 'status', 'device_states'],
  additionalProperties: true,
};
