import React from 'react';
import useStyles from './style';

type InfoIconProps = {
  color?: string;
};

const InfoIcon = ({ color = '#3183F3' }: InfoIconProps) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <g clipPath="url(#clip0_1047_19542)">
        <mask
          id="mask0_1047_19542"
          className={maskSvg}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM11.25 14.5833C11.25 15.2737 10.6903 15.8333 9.99998 15.8333C9.30962 15.8333 8.74998 15.2737 8.74998 14.5833C8.74998 13.893 9.30962 13.3333 9.99998 13.3333C10.6903 13.3333 11.25 13.893 11.25 14.5833ZM11 5C11 4.44772 10.5523 4 10 4C9.44771 4 9 4.44772 9 5V11C9 11.5523 9.44771 12 10 12C10.5523 12 11 11.5523 11 11V5Z"
            fill="#140E17"
          />
        </mask>
        <g mask="url(#mask0_1047_19542)">
          <path d="M0 0H20V20H0V0Z" fill="currentColor" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1047_19542">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIcon;
