import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  chatWidgetContainer: {
    position: 'relative',
    marginTop: '1.5rem',
    overflow: 'hidden',
    pointerEvents: 'none',
    height: '100%',
    width: '100%',
    minWidth: '18.625rem',
    maskImage: 'gradient(linear, left bottom, left top, from(rgba(0,0,0,.8)), to(rgba(0,0,0,.3)))',
    marginBottom: '1.25rem',
  },
  chatWidgetItem: {
    width: '100%',
    display: 'flex',
  },
}));

export default useStyles;
