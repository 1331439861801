import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }: Theme) => ({
  chatMessageContainer: {
    maxWidth: '90%',
    minWidth: '50%',
    marginTop: '.625rem',
    marginBottom: '.625rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexShrink: 0,
    flexGrow: 0,
    '&.right-aligned': {
      alignSelf: 'flex-end',
      flexDirection: 'row-reverse',
    },
  },
  chatMessageAvatar: {
    flexShrink: 0,
    flexGrow: 0,
    objectFit: 'cover',
    height: '2.625rem',
    width: '2.625rem',
    borderRadius: '50%',
    marginRight: '1.875rem',
    marginLeft: '.75rem',
  },
  chatMessageTextContentContainer: {
    height: '100%',
    minWidth: '166px',
    maxWidth: '342px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '.875rem',
    borderStyle: 'solid',
    borderWidth: '.125rem',
    borderColor: palette.primary.dark,
    color: palette.primary.dark,
    padding: '.625rem',
    letterSpacing: '0.045em',
    '&.highlighted': {
      backgroundColor: palette.primary.dark,
      color: palette.background.default,
    },
    '&.dark': {
      backgroundColor: '#262626',
      opacity: 0.9,
      color: '#ffffff',
      border: 'none',
    },
    '& > .username': {
      marginBottom: '.625rem',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '.75rem',
      letterSpacing: '0.045em',
    },
    '& > .text': {
      marginBottom: '.625rem',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '.75rem',
      wordBreak: 'break-all',
    },
    '& > .datetime': {
      fontWeight: 700,
      fontSize: '.625rem',
      alignSelf: 'flex-end',
    },
  },
  chatMessageContextMenuInvoker: {
    alignSelf: 'flex-start',
    height: '1.25rem',
    width: '.75rem',
    marginLeft: '.3125rem',
    marginRight: '.3125rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span': {
      backgroundColor: palette.primary.dark,
      height: '.25rem',
      width: '.25rem',
      borderRadius: '50%',
    },
  },
}));

export default useStyles;
