import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line no-empty-pattern
export const useStyles = makeStyles(() => ({
  defaultBtnClass: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: 'none',
    borderStyle: 'none',
    '&:hover': {
      opacity: 0.75,
    },
    '&:disabled': {
      opacity: 0.6,
      cursor: 'not-allowed',
    },
    justifyContent: 'space-around',
  },
}));
