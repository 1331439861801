import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '15px',
    zIndex: 1,
  },
  list: {
    padding: '12px 0',
    margin: 0,
    width: '100%',
  },
  item: {
    fontFamily: 'Outfit',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '22px',
    color: '#969FB5',
    padding: '4px 15px',
    display: 'flex',
    width: '100%',
    borderRadius: '15px',
    '&:hover': {
      color: '#3F5CA4',
      fontWeight: 600,
    },
  },
  accentColor: {
    color: '#3F5CA4',
    fontWeight: 600,
  },
}));

export default useStyles;
