import 'audioworklet-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { checkIsExistBlockExtension } from './helpers/browser';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initSentry } from './helpers/sentry';

initSentry();
ReactDOM.render(<App />, document.getElementById('root'));
checkIsExistBlockExtension();

serviceWorkerRegistration.register();
reportWebVitals();
