import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    bottom: 40,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between;',
    alignItems: 'end',
    padding: '0 40px',
  },
  leftContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    paddingBottom: '20px',
    '& > div': {
      paddingTop: '17px',
    },
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'start',
    width: '500px',
    justifyContent: 'space-between',
  },
  intercomHelpchatIcon: {
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(25px)',
    borderRadius: '50%',
    width: '48px',
    height: '48px',
  },
  mockButton: {
    width: '190px',
    height: '48px',
  },
  mockIcon: {
    width: '48px',
    height: '48px',
  },
  buttonClass: {
    borderRadius: '50%',
    width: '48px',
    height: '48px',
  },
  teleportBackgroundClass: { background: 'linear-gradient(90deg, #248DE7 0%, #5584D7 100%)' },
  recorderBackroundClass: { background: 'linear-gradient(90deg, #6B80D0 0%, #9B77C0 100%)' },
  chatBackgroundClass: { background: 'linear-gradient(90deg, #6B80D0 0%, #9B77C0 100%)' },
  shoppingCartBackgroundClass: { background: 'linear-gradient(90deg, #B172B8 0%, #E06AA9 100%)' },
  buyContainer: {
    position: 'fixed',
    left: 0,
    bottom: '120px',
  },
}));

export default useStyles;
