import React from 'react';

interface OnLiveInCircleIconInterface {
  size: string;
  activeColor?: string;
}

const OnLiveInCircleIcon = ({ size, activeColor = '' }: OnLiveInCircleIconInterface) => {
  const color: string = activeColor || '#7E7E7E';
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4875 17.2917C2.4875 25.4375 9.14583 32.0958 17.2917 32.0958C25.4375 32.0958 32.0958 25.4375 32.0958 17.2917C32.0958 9.14583 25.4375 2.4875 17.2917 2.4875C9.14583 2.4875 2.4875 9.14583 2.4875 17.2917ZM17.2917 1C8.29583 1 1 8.29583 1 17.2917C1 26.2875 8.29583 33.5833 17.2917 33.5833C26.2875 33.5833 33.5833 26.2875 33.5833 17.2917C33.5833 8.29583 26.2875 1 17.2917 1Z"
        fill={color}
      />
      <path
        d="M24.9336 9.29195C24.5575 8.90268 23.9603 8.90268 23.5843 9.29195C23.2082 9.68122 23.2082 10.2995 23.5843 10.6887C26.9465 14.1692 26.9465 19.825 23.5843 23.3055C23.2082 23.6948 23.2082 24.3131 23.5843 24.7023C23.7612 24.8855 24.0046 25 24.2479 25C24.4912 25 24.7345 24.9084 24.9115 24.7023C29.0258 20.4433 29.0257 13.5281 24.9336 9.29195Z"
        fill={color}
      />
      <path
        d="M21.7705 12.5434C21.3945 12.1541 20.7972 12.1541 20.4212 12.5434C20.0452 12.9327 20.0452 13.5509 20.4212 13.9402C22.0359 15.6118 22.0581 18.3595 20.4212 20.0311C20.0452 20.4203 20.0452 21.0386 20.4212 21.4278C20.5981 21.611 20.8415 21.7255 21.0848 21.7255C21.3281 21.7255 21.5714 21.6339 21.7484 21.4278C24.1594 18.9778 24.1373 14.9935 21.7705 12.5434Z"
        fill={color}
      />
      <path
        d="M11.4184 10.6887C11.7945 10.2995 11.7945 9.68122 11.4184 9.29195C11.0424 8.90268 10.4452 8.90268 10.0691 9.29195C5.97696 13.5281 5.97696 20.4433 10.0691 24.6794C10.2461 24.8626 10.4894 24.9771 10.7327 24.9771C10.976 24.9771 11.2194 24.8855 11.3963 24.6794C11.7723 24.2902 11.7723 23.6719 11.3963 23.2826C8.05622 19.825 8.05622 14.1692 11.4184 10.6887Z"
        fill={color}
      />
      <path
        d="M14.5595 13.9402C14.9355 13.5509 14.9355 12.9327 14.5595 12.5434C14.1834 12.1541 13.5862 12.1541 13.2102 12.5434C10.8434 14.9935 10.8434 18.9778 13.2102 21.4278C13.3871 21.611 13.6304 21.7255 13.8738 21.7255C14.1171 21.7255 14.3604 21.6339 14.5374 21.4278C14.9134 21.0386 14.9134 20.4203 14.5374 20.0311C12.9447 18.3595 12.9447 15.6118 14.5595 13.9402Z"
        fill={color}
      />
      <path
        d="M17.5015 18.6573C18.3933 18.6573 19.1162 17.9089 19.1162 16.9858C19.1162 16.0626 18.3933 15.3142 17.5015 15.3142C16.6097 15.3142 15.8867 16.0626 15.8867 16.9858C15.8867 17.9089 16.6097 18.6573 17.5015 18.6573Z"
        fill={color}
      />
    </svg>
  );
};

export default OnLiveInCircleIcon;
