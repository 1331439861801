import { DocumentElementWithFullscreen, DocumentWithFullscreen } from '../types/fullscreen';
import { isIOS } from 'react-device-detect';

export function isFullScreen(doc: DocumentWithFullscreen): boolean {
  return !!(
    doc.fullscreenElement ||
    doc.mozFullScreenElement ||
    doc.webkitFullScreenElement ||
    doc.msFullScreenElement ||
    doc.webkitCurrentFullScreenElement
  );
}

function requestFullScreen(elem: any) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.msRequestFullScreen) {
    elem.msRequestFullScreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullScreen) {
    elem.webkitRequestFullScreen();
  } else if (elem.webkitEnterFullScreen) {
    // elem.webkitEnterFullScreen();
    // window.addEventListener('beforeinstallprompt', function (event: any) {
    // https://www.youtube.com/watch?v=LWRdBywm4Zo
    //   event.preventDefault()
    //   (navigator as any).setAppBadge();
    //   console.log('e=', event);
    //   event.userChoice.then(function (result: any) {
    //     if (result.outcome == 'dismissed') {
    //       console.log('true');
    //     } else {
    //       console.log('false');
    //     }
    //   });
    // });
  }
}

function exitFullScreen(doc: DocumentWithFullscreen) {
  if (doc.exitFullscreen) {
    doc.exitFullscreen();
  } else if (doc.msExitFullScreen) {
    doc.msExitFullScreen();
  } else if (doc.webkitExitFullScreen) {
    doc.webkitExitFullScreen();
  } else if (doc.webkitExitFullscreen) {
    doc.webkitExitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen();
  }
}

/**
 * Для IOS, IPad делаем fullScreen для тега video т.к. fullscreen для IOS, IPad не работает на div
 * для остальных браузеров (проверить что работает на MacOS) для document-а
 */
function detectElement() {
  let doc: DocumentWithFullscreen = document;
  let elem: DocumentElementWithFullscreen = document.documentElement;

  if (isIOS) {
    elem = document.getElementById('streamingVideo')!;
    doc = elem as any;
  }

  return { doc, elem };
}

export function toogleFullScreen(): void {
  if (document) {
    const { doc, elem } = detectElement();

    if (isFullScreen(doc)) {
      exitFullScreen(doc);
    } else {
      requestFullScreen(elem);
    }
  }
}
