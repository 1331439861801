import { observer } from 'mobx-react-lite';
import { ChatMessageType, ChatType } from '../../../models/chats';
import React from 'react';
import useStyles from './style';
import classnames from 'classnames';
import { useStores } from '../../../hooks/useStores';
import { ContextMenu, GenericAvatar } from '../../';
import { ContextMenuOptionType } from '../../../types/ui';
import { ChatKinds } from '../../../constants/chats';

const ChatMessage = observer(
  ({
    message,
    chat,
    className,
    isChatWidget = false,
    dark = false,
  }: {
    message: ChatMessageType;
    chat: ChatType;
    className?: any;
    isChatWidget?: boolean;
    dark?: boolean;
  }) => {
    const isDirection = chat.kind === ChatKinds.DIRECT;
    const {
      chatMessageContainer,
      chatMessageTextContentContainer,
      chatMessageAvatar,
      chatMessageContextMenuInvoker,
    } = useStyles();

    const { user, participantsList } = useStores();

    const contextMenuOptions: ContextMenuOptionType[] = [];

    // /* rm msg option for context menu */
    // if (user.role === Roles.MODERATOR) {
    //   contextMenuOptions.push({
    //     title: 'remove message',
    //     onClick: () => {},
    //   });
    // }
    // /* ****************************** */
    //
    // /* muting option for context menu */
    // contextMenuOptions.push({
    //   title: user.role === Roles.MODERATOR ? 'mute everywhere' : 'mute for me',
    //   onClick:
    //     user.role === Roles.MODERATOR
    //       ? () => {
    //           console.log('user will be muted everywhere');
    //         }
    //       : () => {
    //           console.log('user will be muted for this user locally');
    //         },
    // });
    // /* ***************************** */

    const messageAuthor = participantsList.participants.get(message.authorUserId);

    message.setIsRead(true);

    return (
      <div
        className={classnames(
          chatMessageContainer,
          !isChatWidget && message.authorUserId === user.userId && 'right-aligned',
          className
        )}
      >
        {(!(isDirection || message.authorUserId === user.userId) || isChatWidget) &&
          messageAuthor &&
          (messageAuthor.avatar ? (
            <img
              className={classnames(chatMessageAvatar)}
              src={(messageAuthor && messageAuthor.avatar) || undefined}
              alt={(messageAuthor && messageAuthor.username) || undefined}
            />
          ) : (
            <GenericAvatar
              username={messageAuthor.username}
              className={classnames(chatMessageAvatar)}
            />
          ))}
        <div
          className={classnames(
            chatMessageTextContentContainer,
            (message.authorUserId === user.userId || !message.isRead) && 'highlighted',
            { dark: dark }
          )}
        >
          <span className={classnames('username')}>{messageAuthor && messageAuthor.username}</span>

          <span className={classnames('text')}>{message.text}</span>

          <span className={classnames('datetime')}>{message.createdAt.toLocaleTimeString()}</span>
        </div>
        {contextMenuOptions.length ? (
          <ContextMenu
            direction={'left'}
            options={contextMenuOptions}
            invoker={
              <div className={classnames(chatMessageContextMenuInvoker)}>
                <span />
                <span />
                <span />
              </div>
            }
          />
        ) : null}
      </div>
    );
  }
);

export default ChatMessage;
