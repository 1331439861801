import { rootStore } from '../models/rootStore';
import { unRegisterMainEvents } from './eventRegistrationManager';
import { closeWebRtcPlayer } from './webRtcWrapper';
// import { emitUserAfkMode } from './game/emitsToGame/emits';
import { closeChannels } from '../helpers/stream';
// import {
//   AFK_TIMEOUT_SHOW_MODAL_IN_MILISECONDS,
//   AFK_TIMEOUT_RESET_USER_AFTER_SHOW_MODAL_IN_SECONDS,
// } from '../constants/afk';
// import { unLockMouse } from './control';

// let timeoutIdStartAfkModalShow: NodeJS.Timeout | null = null;
// let timeoutIdStopAfkModalShow: NodeJS.Timeout | null = null;
// let afkShowModalSecond = 0;

// const isNotModerator = () => {
//   const {
//     user: { isModerator },
//   } = rootStore;
//   return !isModerator;
// };

// export const startAfkWarningTimer = () => {
//   if (timeoutIdStartAfkModalShow === null && isNotModerator()) {
//     timeoutIdStartAfkModalShow = setInterval(
//       () => timerIncrement(),
//       AFK_TIMEOUT_SHOW_MODAL_IN_MILISECONDS * 1000
//     );
//   }
// };

// const stopAfkWarningTimer = () => {
//   if (isNotModerator()) {
//     if (timeoutIdStartAfkModalShow !== null) {
//       clearInterval(timeoutIdStartAfkModalShow);
//       timeoutIdStartAfkModalShow = null;
//     }

//     if (timeoutIdStopAfkModalShow !== null) {
//       clearInterval(timeoutIdStopAfkModalShow);
//       timeoutIdStopAfkModalShow = null;
//     }
//   }
// };

// const timerIncrement = () => {
//   if (timeoutIdStartAfkModalShow !== null) {
//     const { connection, modal } = rootStore;
//     afkShowModalSecond = 0;
//     timeoutIdStopAfkModalShow = setTimeout(function run() {
//       if (timeoutIdStartAfkModalShow !== null) {
//         clearInterval(timeoutIdStartAfkModalShow);
//         timeoutIdStartAfkModalShow = null;
//       }
//       setTimeout(() => unLockMouse(), 2000);
//       afkShowModalSecond++;
//       connection.setAfkMinutesLeft(
//         AFK_TIMEOUT_RESET_USER_AFTER_SHOW_MODAL_IN_SECONDS - afkShowModalSecond
//       );

//       if (afkShowModalSecond === AFK_TIMEOUT_RESET_USER_AFTER_SHOW_MODAL_IN_SECONDS) {
//         console.log('disconnect');
//         // stopAfkWarningTimer();
//         emitUserAfkMode();
//         resetPlayer();
//       }

//       timeoutIdStopAfkModalShow = setTimeout(run, 1000);
//     }, 1000);

//     if (!modal.isOpenAfkModal) {
//       modal.setIsOpenAfkModal(true);
//       connection.setAfkMinutesLeft(AFK_TIMEOUT_RESET_USER_AFTER_SHOW_MODAL_IN_SECONDS);
//     }
//   }
// };

export const resetPlayer = () => {
  const { modal } = rootStore;
  unRegisterMainEvents();
  // stopAfkWarningTimer();
  modal.setIsOpenAfkModal(false);
  closeWebRtcPlayer();
  closeChannels();
  rootStore.connection.setIsStartPage(true);
  rootStore.UI.sideMenuArea.closeAll();
  rootStore.UI.widgetsArea.closeAll();
  rootStore.user.setIsLive(false);
  rootStore.screenSharingModule.removeAllScreenShareTargets();
};

// export const resetAfkWarningTimer = () => {
//   const { modal } = rootStore;
//   if (isNotModerator()) {
//     modal.setIsOpenAfkModal(false);

//     if (timeoutIdStartAfkModalShow === null) {
//       startAfkWarningTimer();
//     } else {
//       stopAfkWarningTimer();
//       resetAfkWarningTimer();
//     }
//   }
// };

export const onPxsDisconnected = () => {
  closeChannels();
};

/**
 * MAIN STATES DEPENDENCY
 * If any of those params are changed
 * it needs to update current player state
 * and reinit WebRTC
 */
// let WS_UE_CLIENT_STATE = 0;
// let WS_WBS_CLIENT_STATE = 0;
// let RTC_PEER_CLIENT_CONNECTION_STATE = 0;
// let RTC_PEER_SERVER_CONNECTION_STATE = 0;
