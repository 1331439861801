import React, { useState } from 'react';
import { ModeratorTitle, ArrowDownIcon, ArrowUpIcon, ModeratorVolumeControl } from '..';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { generateAvatar } from '../../helpers/random';

const ModeratorList = observer(() => {
  const [isOpenModeratorList, setIsOpenModeratorList] = useState(false);
  const {
    participantsList: { moderatorsOnly },
    user: { userId: currentUserId },
  } = useStores();

  const {
    container,
    moderatorIconsClass,
    moderatorIconClass,
    moderatorIconWrapperClass,
    moderatorTitleAndIconsClass,
    badgeClass,
    arrowClass,
    moderatorItemContainerClass,
  } = useStyles();

  const handleChangeModeratorList = () => {
    setIsOpenModeratorList(!isOpenModeratorList);
  };

  return (
    <>
      <div className={container}>
        <div className={moderatorTitleAndIconsClass}>
          <div>
            <ModeratorTitle
              text="Moderators"
              isShowCount={isOpenModeratorList}
              count={moderatorsOnly.length}
              badgeClass={badgeClass}
            />
          </div>
          {isOpenModeratorList ? null : (
            <div className={moderatorIconsClass}>
              {moderatorsOnly.map(({ avatar }, id) => (
                <div key={id} className={moderatorIconWrapperClass}>
                  <img src={avatar ?? generateAvatar()} className={moderatorIconClass} alt="icon" />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={arrowClass} onClick={handleChangeModeratorList}>
          {isOpenModeratorList ? (
            <ArrowUpIcon color="#140E17" />
          ) : (
            <ArrowDownIcon color="#140E17" />
          )}
        </div>
      </div>
      {isOpenModeratorList && (
        <div className={moderatorItemContainerClass}>
          {moderatorsOnly.map(({ avatar, userId, username, voiceVolume, setVoiceVolume }, id) => (
            <ModeratorItem
              key={id}
              avatar={avatar}
              userId={userId}
              username={username}
              volume={voiceVolume}
              setVolume={setVoiceVolume}
              currentUserId={currentUserId}
            />
          ))}
        </div>
      )}
    </>
  );
});

export default ModeratorList;

type ModeratorItemType = {
  avatar: string | null;
  userId: string;
  username: string;
  currentUserId: string;
  volume: number;
  setVolume: any;
};
const ModeratorItem = ({
  avatar,
  userId,
  currentUserId,
  username,
  volume,
  setVolume,
}: ModeratorItemType) => {
  const {
    moderatorItemClass,
    moderatorItemIconClass,
    moderatorItemNameAndRoleClass,
    moderatorItemNameClass,
    moderatorItemRoleClass,
    moderatorItemSeparateClass,
    moderatorItemIconContainerClass,
  } = useStyles();
  return (
    <>
      <div className={moderatorItemClass}>
        <div className={moderatorItemIconContainerClass}>
          <img src={avatar ?? generateAvatar()} className={moderatorItemIconClass} alt="icon" />
        </div>
        <div className={moderatorItemNameAndRoleClass}>
          <div className={moderatorItemNameClass}>{username}</div>
          <div className={moderatorItemRoleClass}>
            Moderator {userId === currentUserId && ' (You)'}
          </div>
        </div>
        {userId === currentUserId && (
          <ModeratorVolumeControl volume={volume} setVolume={setVolume} />
        )}
      </div>
      {userId === currentUserId && <div className={moderatorItemSeparateClass}></div>}
    </>
  );
};
