import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    height: '48px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '13.5px 15px',
    gap: '10px',
    background: ({ isDisabled }: any) => (isDisabled ? '#EEEEEE' : '#EBEFF8'),
    borderRadius: '15px',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: '34.5px',
    background: '#EBEFF8',
    // boxShadow: '0px 4px 20px rgba(20, 14, 23, 0.08)',
    'border-bottom-left-radius': '15px',
    'border-bottom-right-radius': '15px',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,
  },
  list: {
    padding: '6.75px 0',
    margin: 0,
    width: '100%',
  },
  item: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '160%',
    color: '#3F5CA4',
    padding: '6.75px 15px',
    display: 'flex',
    width: '100%',
    borderRadius: '15px',
    '&:hover': {
      color: '#198DEB',
      fontWeight: 600,
    },
  },
  headerValue: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '160%',
    color: ({ isDisabled }: any) => (isDisabled ? '#737373' : '#3F5CA4'),
  },
  arrowContainer: {
    display: 'flex',
    alignSelf: 'end',
    '& svg': {
      pointerEvents: 'none',
    },
  },
}));

export default useStyles;
