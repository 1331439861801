import React, { useRef, Ref } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
// import CloseIcon from '../Icons/CloseIcon';

const VideoPlayer = observer(() => {
  const { user } = useStores();
  const { videoContainer, video, open } = useStyles();
  const videoElement: Ref<HTMLVideoElement> | null = useRef(null);

  user.setVideoRef(videoElement.current);

  // const onPressClose = () => {
  //   user.setVideoPlayerOpen(false);
  // };

  return (
    <div className={classnames(videoContainer, user.videoPlayer.isOpen && open)}>
      {/* <div className={closeIcon} onClick={onPressClose}>
        <CloseIcon color={theme.palette.primary.dark} />
      </div> */}
      <video
        controls
        preload="auto"
        ref={videoElement}
        poster={user.videoPlayer.poster}
        src={user.videoPlayer.src}
        className={video}
        disablePictureInPicture
      />
    </div>
  );
});

export default VideoPlayer;
