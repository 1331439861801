import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  notificationClass: {
    padding: '8px 20px 8px 16px',
    background: 'rgba(0, 0, 0, 0.3)',
    backdropFilter: 'blur(25px)',
    borderRadius: '8px',
    color: '#FFFFFF',
    marginBottom: '20px',
  },
}));

export default useStyles;
