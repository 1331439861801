import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  ValueLabelWrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  ValueLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '16px',
    '&.on': {
      width: 'calc(100% - 24px)',
    },
  },
  ValueLabelOnIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F1F1F1',
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    textAlign: 'center',
    color: '#262626',
    fontWeight: 500,
  },
}));

export default useStyles;
