import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  shareContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '8px',
    marginTop: '12px',
  },
  shareIconsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrowIcon: {},
  shareIcon: {
    display: 'flex',
  },
  shareIconBadge: {
    display: 'flex',
    background: '#E22929',
    borderRadius: '100px',
    padding: '4px 8px',
    alignItems: 'center',
  },
  shareName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#A2A2A2',
    paddingTop: '4px',
    paddingBottom: '16px',
  },
  stopShare: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#A24DE8',
  },
  shareIconName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#FFFFFF',
    paddingLeft: '2px',
  },
  localScreenShareVideo: {
    border: '1px solid #7B25C1',
    borderRadius: '8px',

    position: 'relative',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    pointerEvents: 'none',
    overflow: 'hidden',
  },
  localScreenShareVideoWrap: {
    width: '354px',
    height: '200px',
    position: 'absolute',
    top: 0,
    right: 0,
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '8px',
    marginRight: '-362px',
  },
}));

export default useStyles;
