import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  screenShareDisabledModalDesc: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#9E9E9E',
    padding: '12px 0 12px 0',
  },
  screenShareDisabledModalBrowsersContainer: {
    '& > :nth-child(1)': {
      marginBottom: '8px',
    },
  },
  screenShareDisabledModalBrowsersRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > :nth-child(1)': {
      marginRight: '8px',
    },
  },
  screenShareDisabledModalBrowsersColumn: {
    display: 'flex',
    width: '100%',
    height: '96px',
    background: '#EEEEEE',
    borderRadius: '8px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  screenShareDisabledModalBrowsersImg: {
    display: 'flex',
  },
  screenShareDisabledModalBrowsersText: {
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#737373',
    paddingTop: '8px',
  },
  screenShareDisabledModalInfoContainer: {
    paddingTop: '28px',
  },
  screenShareDisabledModalInfoItem: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#9E9E9E',
  },
  screenShareDisabledModalInfoLink: {
    color: 'rgba(123, 37, 193, 0.64)',
  },
}));

export default useStyles;
