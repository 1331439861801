import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }: Theme) => ({
  chatInputContainer: {
    width: '100%',
    height: '4.875rem',
    padding: '.625rem',
    paddingLeft: '1.25rem',
    paddingRight: 0,
    borderTop: '.125rem solid',
    borderColor: palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 0,
  },
  textInputClass: {
    width: '100%',
    maxHeight: '100%',
    resize: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.375rem',
    letterSpacing: '0.045em',
    caretColor: palette.primary.dark,
    color: palette.primary.dark,
    backgroundColor: 'transparent',
    border: 'none',
    overflow: 'hidden',
    '&:placeholder': {
      color: palette.background.paper,
    },
    WebkitUserSelect: 'auto',
    userSelect: 'auto',
  },
  chatInputControlButton: {
    height: '100%',
    width: '3.875rem',
    padding: '.625rem',
    marginRight: '0.375rem',
    '&:nth-child(2)': {
      margin: 0,
    },
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
    transition: 'opacity .15s ease',
    '&:hover': {
      opacity: 0.75,
      color: palette.primary.dark,
    },
    '&:disabled': {
      opacity: 0.5,
      color: palette.primary.dark,
      cursor: 'none',
    },
  },
}));

export default useStyles;
