import { MessageType } from '../staticTypes/messageTypes';
import { emitControlInteraction } from '../wsMessageEmitter';
import { inputOptions, SpecialKeyCodes } from '../staticTypes/inputTypes';
import { rootStore } from '../../models/rootStore';

let pressedKeyboardKeys = new Set();

export const registerKeyboardEvents = () => {
  console.log('Registering keyboard events');
  document.addEventListener('keydown', keydownHandler);
  document.addEventListener('keyup', keyupHandler);
  document.addEventListener('keypress', keypressHandler);
};

export const unregisterKeyboardEvents = () => {
  console.log('Deregistering keyboard events');
  document.removeEventListener('keydown', keydownHandler);
  document.removeEventListener('keyup', keyupHandler);
  document.removeEventListener('keypress', keypressHandler);
};

const keydownHandler = (e: any) => {
  emitControlInteraction(new Uint8Array([MessageType.KeyDown, getKeyCode(e), e.repeat]).buffer);
  pressedKeyboardKeys.add(e.keyCode);
  if (inputOptions.suppressBrowserKeys && isKeyCodeBrowserKey(e.keyCode)) {
    e.preventDefault();
  }
  pressedKeyboardSpecialKeyCodes();
};

const keyupHandler = (e: any) => {
  emitControlInteraction(new Uint8Array([MessageType.KeyUp, getKeyCode(e)]).buffer);
  if (inputOptions.suppressBrowserKeys && isKeyCodeBrowserKey(e.keyCode)) {
    e.preventDefault();
  }
  pressedKeyboardKeys.delete(e.keyCode);
};

const keypressHandler = (e: any) => {
  let data = new DataView(new ArrayBuffer(3));
  data.setUint8(0, MessageType.KeyPress);
  data.setUint16(1, e.charCode, true);
  emitControlInteraction(data.buffer);
};

/** private functions*/
const pressedKeyboardSpecialKeyCodes = () => {
  const { connection, debug } = rootStore;
  if (
    pressedKeyboardKeys.has(72) &&
    pressedKeyboardKeys.has(SpecialKeyCodes.Control) &&
    pressedKeyboardKeys.has(SpecialKeyCodes.Shift)
  ) {
    // hide interface CTRL+SHIFT+H
    const { isHideInterface } = connection;
    connection.setIsHideInterface(!isHideInterface);
  } else if (
    pressedKeyboardKeys.has(76) &&
    pressedKeyboardKeys.has(SpecialKeyCodes.Control) &&
    pressedKeyboardKeys.has(SpecialKeyCodes.Shift)
  ) {
    // show test button for test restreaming CTRL+SHIFT+L
    const { isShowDebugPanel } = debug;
    debug.setIsShowDebugPanel(!isShowDebugPanel);
  }
};

// У ключей браузера нет charCode, поэтому нам нужно только проверить keyCode.
const isKeyCodeBrowserKey = (keyCode: number) => {
  return (keyCode >= 112 && keyCode <= 123) || keyCode === 9;
};

const getKeyCode = (e: any) => {
  if (e.keyCode === SpecialKeyCodes.Shift && e.code === 'ShiftRight')
    return SpecialKeyCodes.RightShift;
  else if (e.keyCode === SpecialKeyCodes.Control && e.code === 'ControlRight')
    return SpecialKeyCodes.RightControl;
  else if (e.keyCode === SpecialKeyCodes.Alt && e.code === 'AltRight')
    return SpecialKeyCodes.RightAlt;
  else return e.keyCode;
};
