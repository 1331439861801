import React from 'react';

type BanUserIconProps = {
  color: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  size: string;
};

const BanUserIcon = ({ color, onClick, size }: BanUserIconProps) => {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      width={size}
      height={size}
      onClick={onClick}
    >
      <path
        fill="currentColor"
        d="M425.706,86.294A240,240,0,0,0,86.294,425.705,240,240,0,0,0,425.706,86.294ZM256,48A207.1,207.1,0,0,1,391.528,98.345L98.345,391.528A207.1,207.1,0,0,1,48,256C48,141.309,141.309,48,256,48Zm0,416a207.084,207.084,0,0,1-134.986-49.887l293.1-293.1A207.084,207.084,0,0,1,464,256C464,370.691,370.691,464,256,464Z"
        className="ci-primary"
      />
    </svg>
  );
};

export default BanUserIcon;
