import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  visibleHidden: {
    visibility: 'hidden',
  },
  localVideoContainer: {
    top: '50px',
    right: 0,
    position: 'absolute',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
  },
  localVideoWrapper: {
    width: '92px',
    height: '92px',
    display: 'block',
    background: 'transparent',
    marginRight: '12px',
  },
  localVideo: {
    position: 'relative',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    pointerEvents: 'none',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  displayNone: {
    display: 'none',
  },
  arrowIconSubWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  arrowIconWrapper: {
    width: '36px',
    height: '109px',
    borderRadius: '8px 0px 0px 8px',
    alignItems: 'center',
    display: 'flex',
    background: 'rgba(255, 255, 255, 0.05)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(6.5px)',
  },
  deviceControlWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingRight: '12px',
  },
  buttonClass: {
    width: '45px',
    height: '45px',
    borderRadius: '100px',
    padding: '10px',
    background: 'rgba(255, 255, 255, 0.03)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(6.5px)',
  },
  wrapper: { display: 'flex' },
}));

export default useStyles;
