import React, { useCallback } from 'react';
import {
  Button,
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowRightIcon,
  ShareIcon,
  // ScreenIcon,
  // WindowIcon,
  // TabIcon,
} from '../..';
import useComponentVisible from '../../../hooks/useComponentVisible';
import useStyles from './style';
import classnames from 'classnames';
import { useStores } from '../../../hooks/useStores';
import { ScreenShareTargetType } from '../../../models/screenSharingModule';
import { existVideoDevice } from '../../../helpers/device';
import {
  closeScreenChannel,
  createScreenStream,
  setStreamToRef,
  onEndedStream,
  onFailStream,
} from '../../../helpers/stream';
import { observer } from 'mobx-react-lite';
import * as ApiWrapper from '../../../services/apiWrapper';
import { useGlobalStyles } from '../../../globalStyles';

// const ScreenShareTypes = () => {
//   const { screenShareItem, screenShareIcon, screenShareText } = useStyles();

//   return (
//     <>
//       <div className={screenShareItem}>
//         <div className={screenShareIcon}>
//           <ScreenIcon />
//         </div>
//         <div className={screenShareText}>Your entire screen</div>
//       </div>
//       <div className={screenShareItem}>
//         <div className={screenShareIcon}>
//           <WindowIcon />
//         </div>
//         <div className={screenShareText}>A window</div>
//       </div>
//       <div className={screenShareItem}>
//         <div className={screenShareIcon}>
//           <TabIcon />
//         </div>
//         <div className={screenShareText}>A tab of the browser</div>
//       </div>
//     </>
//   );
// };
const StaticScreenShareContainer = observer(() => {
  const {
    screenShareTypeContainer,
    screenShareItem,
    screenShareText,
    screenShareWraper,
    screenShareArrowRight,
    screenShareContainer,
    // screenShareSecondDropDownListPosition,
  } = useStyles();

  const {
    screenSharingModule: {
      allowedTargetsList,
      setSelectedScreenShareTargetId,
      refPlayerLocalScreenShare,
    },
  } = useStores();

  // const {
  //   ref: refDropDownList,
  //   isComponentVisible: isShowDropDownList,
  //   setIsComponentVisible: setIsShowDropDownList,
  // } = useComponentVisible();

  // const {
  //   ref,
  //   isComponentVisible: isScreenShareTypeVisible,
  //   setIsComponentVisible: setIsScreenShareTypeVisible,
  // } = useComponentVisible(isShowDropDownList);

  const {
    ref: refScreenShareTypeVisible,
    isComponentVisible: isScreenShareTypeVisible,
    setIsComponentVisible: setIsScreenShareTypeVisible,
  } = useComponentVisible();

  const handleScreenShareFirstDropDownList = () => {
    setIsScreenShareTypeVisible(!isScreenShareTypeVisible);
  };

  // const handleScreenShareSecondDropDownList = () => {
  //   setIsShowDropDownList(!isShowDropDownList);
  // };

  const handleChangeStaticShare = useCallback(
    async (screenShareTarget: ScreenShareTargetType) => {
      if (existVideoDevice()) {
        const width = 300;
        const height = 300;
        const { isTaken, isTakenByCurrentUser, screenShareTargetId } = screenShareTarget;
        await closeScreenChannel();
        if (!isTaken && !isTakenByCurrentUser) {
          setSelectedScreenShareTargetId(screenShareTargetId);
        }
        setStreamToRef(refPlayerLocalScreenShare, null);

        createScreenStream({
          ref: refPlayerLocalScreenShare,
          width,
          height,
          onEndedStream,
          onFailStream,
        });
        if (screenShareTargetId !== null) {
          await ApiWrapper.startSharingScreen('static', screenShareTargetId);
        }
      } else {
        console.log('screenshare error');
      }
    },
    [refPlayerLocalScreenShare, setSelectedScreenShareTargetId]
  );

  const screenShareCount = allowedTargetsList.length;
  // const { isShow, isTaken, isTakenByCurrentUser, title, screenShareTargetId } = screenShareTarget;
  return (
    <div ref={refScreenShareTypeVisible} className={screenShareWraper}>
      {isScreenShareTypeVisible &&
        (screenShareCount === 1 ? (
          <>
            {/* <div className={screenShareTypeContainer}>
                  <ScreenShareTypes />
                </div> */}
          </>
        ) : (
          <>
            <div
              className={classnames(screenShareTypeContainer, screenShareContainer)}
              // ref={refDropDownList}
            >
              {allowedTargetsList.map((target: ScreenShareTargetType) => (
                <div
                  className={screenShareItem}
                  onClick={() => handleChangeStaticShare(target)}
                  key={target.screenShareTargetId}
                >
                  <div className={screenShareText}>
                    <div>{target.title}</div>
                    <div className={screenShareArrowRight}>
                      <ArrowRightIcon />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* {isShowDropDownList && (
                  <div
                    className={classnames(
                      screenShareTypeContainer,
                      screenShareSecondDropDownListPosition
                    )}
                  >
                    <ScreenShareTypes />
                  </div>
                )} */}
          </>
        ))}
      <StaticScreenShareButton
        isScreenShareTypeVisible={isScreenShareTypeVisible}
        handleScreenShareFirstDropDownList={handleScreenShareFirstDropDownList}
        handleChangeStaticShare={handleChangeStaticShare}
        targets={allowedTargetsList.filter((target) => target.isShow === true) || []}
      />
    </div>
  );
});

export default StaticScreenShareContainer;

type StaticScreenShareButtonType = {
  isScreenShareTypeVisible: boolean;
  handleScreenShareFirstDropDownList: any;
  handleChangeStaticShare: any;
  targets: any;
};
const StaticScreenShareButton = observer(
  ({
    isScreenShareTypeVisible,
    handleScreenShareFirstDropDownList,
    handleChangeStaticShare,
    targets,
  }: StaticScreenShareButtonType) => {
    const {
      screenShareButton,
      screenShareButtonLeftIcon,
      screenShareButtonRightIcon,
      screenShareButtonText,
      screenShareButtonTextWrapper,
      screenShareButtonTextCount,
    } = useStyles();
    const { visibileOn, visibileOff } = useGlobalStyles();

    const screenShareCount = targets.length;
    return (
      <div className={screenShareCount !== 0 ? visibileOn : visibileOff}>
        <Button
          onClick={
            screenShareCount === 1
              ? () => handleChangeStaticShare(targets[0])
              : handleScreenShareFirstDropDownList
          }
          className={classnames(screenShareButton)}
        >
          <div className={screenShareButtonLeftIcon}>
            <ShareIcon />
          </div>
          <div className={screenShareButtonText}>
            <div className={screenShareButtonTextWrapper}>
              <div> {screenShareCount === 1 ? targets[0]?.title : 'Space actions'}</div>
              {screenShareCount !== 1 && screenShareCount !== 0 && (
                <div className={screenShareButtonTextCount}>{screenShareCount}</div>
              )}
            </div>
          </div>
          <div className={screenShareButtonRightIcon}>
            {isScreenShareTypeVisible ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
        </Button>
      </div>
    );
  }
);

// type StaticScreenShareButtonProps = {
//   screenShareTarget: ScreenShareTargetType;
//   handleChangeStaticShare: any;
// };

// const StaticScreenShareButton = observer(
//   ({ screenShareTarget, handleChangeStaticShare }: StaticScreenShareButtonProps) => {
//     const {
//       staticScreenShareButton,

//       staticScreenShareButtonTitle,
//     } = useStyles();
//     const { mySetting } = useStores();
//     const { t } = useTranslation();
//     const { isShow, isTaken, isTakenByCurrentUser, title, screenShareTargetId } = screenShareTarget;

//     const handleStartFullScreen = () => {
//       mySetting.setIsFullScreen(true);
//       emitUserOpenFullScreenShare(screenShareTargetId);
//     };

//     return (
//       <Button
//         className={classnames(staticScreenShareButton)}
//         onClick={
//           !isTaken || isTakenByCurrentUser
//             ? () => handleChangeStaticShare(screenShareTarget)
//             : () => handleStartFullScreen()
//         }
//         isShow={isShow}
//       >
//         {/* <div className={classnames(staticScreenShareButtonIcon)}>
//           {!isTaken || isTakenByCurrentUser ? (
//             isTakenByCurrentUser ? (
//               <ShareIconOff
//                 background={palette.background.paper}
//                 color={palette.secondary.main}
//                 size="3.625rem"
//               />
//             ) : (
//               <ShareIconOn
//                 background={palette.primary.dark}
//                 color={palette.background.default}
//                 size="3.5rem"
//               />
//             )
//           ) : (
//             <FullScreenOpenIcon size="3.625rem" />
//           )}
//         </div> */}
//         <span className={classnames(staticScreenShareButtonTitle)}>
//           {!isTaken || isTakenByCurrentUser ? title : `${t('Full')} ${title}`}
//         </span>
//       </Button>
//     );
//   }
// );
