import { observer } from 'mobx-react-lite';
import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import useStyles from './style';
import { useStores } from '../../hooks/useStores';
import { StopOrShowShare } from '..';
import { Roles } from '../../constants/user';
import { isDesktop } from 'react-device-detect';

type LocalStreamDesktopType = {
  refPlayerLocalStream: any;
};
const LocalStreamDesktop = observer(({ refPlayerLocalStream }: LocalStreamDesktopType) => {
  const {
    mySetting: { isShowVideo },
    connection: { isHideInterface, isLoading },
    user: { role },
    screenSharingModule: { setRefPlayerLocalScreenShare },
  } = useStores();
  const {
    stopOrShowShareWrap,
    localVideoFrame,
    localVideo,
    visibleHidden,
    displayNone,
  } = useStyles();
  const refPlayerLocalScreenShare = useRef(null);

  useEffect(() => {
    setRefPlayerLocalScreenShare(refPlayerLocalScreenShare);
  }, [setRefPlayerLocalScreenShare]);

  // const handleChangePersonalShare = async () => {
  //   if (existVideoDevice()) {
  //     const { isShowPersonalShare, setIsShowPersonalShare } = mySetting;
  //     if (refCloseModalPersonalShare) {
  //       await (refCloseModalPersonalShare as any).current.close();
  //     }

  //     if (!isShowPersonalShare) {
  //       setIsOpenRequestPersonalShareModal(true);
  //     }

  //     setIsShowPersonalShare(!isShowPersonalShare);
  //     setStreamToRef(refPlayerLocalScreenShare, null);
  //   } else {
  //     setIsOpenPlayerPermissionModal(true);
  //   }
  // };

  return (
    <>
      {role !== Roles.SPECTATOR && isDesktop && (
        <div
          className={classnames(localVideoFrame, isLoading || isHideInterface ? displayNone : '')}
        >
          <video
            ref={refPlayerLocalStream}
            className={classnames(localVideo, !isShowVideo ? visibleHidden : '')}
            muted
            autoPlay
            playsInline
            disablePictureInPicture
          />
          <div className={stopOrShowShareWrap}>
            <StopOrShowShare refPlayerLocalScreenShare={refPlayerLocalScreenShare} />
          </div>
          {/* {!mySetting.isFullScreen ? (
          <div>
            <Video handleChangeVideo={handleChangeVideo} />
            <Mic handleChangeAudio={handleChangeAudio} />
            {isShowPersonalShare() ? (
              <Share
                isShareActive={mySetting.isShowPersonalShare}
                handleChangeShare={handleChangePersonalShare}
              />
            ) : (
              <EmptyIcon size={'3rem'} />
            )}
          </div>
        ) : (
          <CloseFullScreenShare />
        )} */}
        </div>
      )}
    </>
  );
});

export default LocalStreamDesktop;
