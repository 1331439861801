import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    '& svg:hover > rect': {
      fillOpacity: '0.4',
    },
  },
  chatModalClass: {
    width: '567px',
    right: '40px',
    top: '40px',
    bottom: '40px',
    position: 'absolute',
    background: 'transparent !important',
    overflow: 'hidden',
  },
}));

export default useStyles;
