import { INFO_LEVEL, logApi } from '@pxs-infra/logger';

export const getProducts = async () => {
  const { REACT_APP_SMART_ASSISTANT_URL } = process.env;
  let products = null;
  try {
    const responce = await fetch(`${REACT_APP_SMART_ASSISTANT_URL}/site/get-products`);
    const data = await responce.json();
    products = data.products;
  } catch (e: any) {
    logApi(INFO_LEVEL, `error send message ${e.message}`);
  }

  return products;
};
