import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  fullScreenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'linear-gradient(90deg, #B172B8 0%, #E06AA9 100%)',
    borderRadius: '50%',
    width: '48px',
    height: '48px',
  },
  fullScreenIcon: {
    display: 'flex',
    '& svg:hover > rect': {
      fillOpacity: '0.4',
    },
  },
}));

export default useStyles;
