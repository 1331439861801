import React from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import useStyles from './style';
import { useGlobalStyles } from '../../globalStyles';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Stack = observer(({ children, keys }: { children: any[]; keys: string[] | number[] }) => {
  const { flexDirectionColumn, flexAlignStart, flexJustifyEnd } = useGlobalStyles();
  const { stackContainer } = useStyles();

  return (
    <TransitionGroup
      className={classnames(stackContainer, flexDirectionColumn, flexAlignStart, flexJustifyEnd)}
    >
      {children.map((child, index) => {
        return (
          <CSSTransition key={keys[index]} timeout={500} classNames="stack-item">
            {child}
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
});

export default Stack;
