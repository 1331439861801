import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 8px',
    // marginTop: '12px',
    borderBottom: '1px solid #E6E6E6',
  },
  checkboxAndNameClass: {
    display: 'flex',
    alignItems: 'center',
  },
  settingsContainerClass: {
    display: 'flex',
  },
  settingTitleClass: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: '#A2A2A2',
  },
  nameAndSortContainerClass: {
    display: 'flex',
    paddingLeft: '30px',
    position: 'relative',
    alignItems: 'center',
  },
  sortIconContainerClass: {
    display: 'flex',
    paddingLeft: '10px',
  },
  screenshareClass: {
    paddingRight: '91px',
    paddingLeft: '18px',
  },
  microphoneClass: {
    paddingRight: '88px',
  },
  groupSettingsContainer: {
    display: 'flex',
    paddingLeft: '30px',
    borderRight: '1px solid #E6E6E6',
    paddingRight: '24px',
    '&>div>svg:hover': {
      color: '#7B25C1',
    },
  },
  accessTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#A2A2A2',
    paddingRight: '20px',
  },
  groupVideo: {
    padding: '0 12px',
  },
  groupVolumeControlContainer: {
    padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
  },
  groupVolumeControlTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#A2A2A2',
    paddingRight: '20px',
  },
  groupVolumeControl: {},
  banSelectedTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#A24DE8',
    paddingLeft: '24px',
    borderLeft: '1px solid #E6E6E6',
  },
  iconContainer: {
    display: 'flex',
  },
  selectClass: {
    position: 'absolute',
    left: '-90px',
    top: '25px',
  },
}));
