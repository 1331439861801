import { makeStyles, Theme } from '@material-ui/core/styles';
import { SwitchType } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '44px',
    height: '22px',
    margin: 0,
    padding: 0,
    border: ({ isDisabled, value }: SwitchType) =>
      isDisabled ? '2px solid #CECECE' : value ? '2px solid #E069A8' : '2px solid #A2A2A2',

    background: ({ isDisabled }: SwitchType) => (isDisabled ? '#DDDDDD' : '#FFFFFF'),
    borderRadius: '100px',
  },
  switchBase: {
    padding: 0,
    left: '2px',
    top: '0px',
    height: '100%',
    '&$checked': {
      left: '2px',
      top: '0px',
      height: '100%',
      '& + $track': {
        backgroundColor: '#E069A8',
        border: 'none',
      },
    },
  },
  thumb: {
    width: '15px',
    height: '15px',
    background: ({ isDisabled }: SwitchType) => (isDisabled ? '#CECECE' : '#140E17'),
  },
  track: {
    borderRadius: '100px',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));

export default useStyles;
