import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
  ({
    palette: {
      primary: { dark, main },
      background: { default: defaultBackground, paper },
    },
  }: Theme) => ({
    emojiModal: {
      position: 'absolute',
      background: defaultBackground,
      borderRadius: '10px',
      width: '258px',
      height: '231px',
      bottom: '90px',
      left: '542px',
      color: main,
      fontStyle: 'normal',
      display: 'flex',
      flexDirection: 'column',
    },
    emojiModalHeader: {
      width: '100%',
      background: paper,
      color: dark,
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      fontWeight: 'bold',
      lineHeight: '16px',
      letterSpacing: '0.045em',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '6px 7px 4px 11px',
    },
    emojiModalHeaderTitle: {
      fontSize: '13px',
      margin: '0px',
    },
    emojiModalClose: {
      display: 'flex',
    },
    emojiModalBody: {},
    emojiSearchWrapper: {
      padding: '15px 44px 11px 11px',
    },
    emojiSearch: {
      background: '#4F4F4F',
      borderRadius: '50px',
      height: '24px',
      paddingLeft: '12px',
      '& input::placeholder': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'justify',
        letterSpacing: '0.045em',
        color: '#F1F1F1',
        opacity: 1,
      },
      '& input:-ms-input-placeholder': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'justify',
        letterSpacing: '0.045em',
        color: '#F1F1F1',
        opacity: 1,
      },
      '& input::-ms-input-placeholder': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'justify',
        letterSpacing: '0.045em',
        color: '#F1F1F1',
        opacity: 1,
      },
      '& fieldset': {
        border: 'none !important',
      },
      '& .MuiInputBase-root': {
        height: '100%',
      },
    },
    emojiChoose: {
      paddingLeft: '12px',
      overflowX: 'hidden',
      overflowY: 'auto',
      height: '147px',
      scrollbarWidth: 'none',
      scrollBehavior: 'smooth',
      '-webkit-overflow-scrolling': 'touch',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    emojiCategoryTitle: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '10px',
      lineHeight: '14px',
      textAlign: 'justify',
      letterSpacing: '0.045em',
      textTransform: 'capitalize',
      color: '#FFFFFF',
    },
    emojiCategoryContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      paddingBottom: '16px',
    },
  })
);

export default useStyles;
