import React from 'react';
import useStyles from './style';
import classnames from 'classnames';
import { ParticipantType } from '../../models/participants';
import {
  VolumeControlBar,
  // KickUserIcon,
  BanUserIcon,
} from '..';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';
import { Button } from '..';
import { useGlobalStyles } from '../../globalStyles';
import { Roles } from '../../constants/user';

const ParticipantsMediaControlsListItem = observer((props: { participant: ParticipantType }) => {
  const {
    participantsMediaControlListItemContainer,
    username,
    turnOffStaticScreenShareButton,
  } = useStyles();
  const { flexDirectionRow, flexAlignCenter, flexJustifyStart } = useGlobalStyles();

  const { participant }: { participant: ParticipantType } = props;

  const { screenSharingModule, user } = useStores();

  const handleBanUser = () => {
    participant.toggleBanUser();
  };

  return (
    <div
      className={classnames(
        participantsMediaControlListItemContainer,
        flexDirectionRow,
        flexAlignCenter,
        flexJustifyStart
      )}
    >
      <span className={classnames(username)}>{participant.username}</span>

      <Button
        disabled={user.role !== Roles.MODERATOR}
        onClick={() => {
          participant.toggleVoiceIsMuted();
        }}
      >
        {/* {participant.voiceIsMuted ? (
          <MicOffIcon color="#F1F1F1" background="#D63A3A" size="3rem" />
        ) : !participant.voiceIsEnabled ? (
          <MicOffIcon color="#F1F1F1" background="transparent" size="3rem" />
        ) : (
          <MicOnIcon color="#262626" background="#F1F1F1" size="3rem" />
        )} */}
      </Button>

      <VolumeControlBar participant={participant} />

      <Button
        onClick={async () => {
          await participant.toggleVideoIsMuted();
        }}
        disabled={user.role !== Roles.MODERATOR}
      >
        {/* {participant.videoIsMuted
          ? <VideoOffIcon color="#F1F1F1" size="3rem" />
            VideoOffIcon
          : !participant.videoIsEnabled
          ?
          VideoOffIcon
          : VideoOnIcon} */}
      </Button>

      {(() => {
        if (participant.isSharingScreen && !participant.staticScreenShareTargetId) {
          return (
            <Button
              disabled={user.role !== Roles.MODERATOR}
              onClick={async () => {
                await participant.stopScreenShareByModerator();
              }}
            >
              ShareIconOff
            </Button>
          );
        } else if (participant.isSharingScreen && participant.staticScreenShareTargetId) {
          return (
            <Button
              disabled={user.role !== Roles.MODERATOR}
              onClick={async () => {
                await participant.stopScreenShareByModerator();
              }}
              className={classnames(
                flexDirectionRow,
                flexAlignCenter,
                turnOffStaticScreenShareButton
              )}
            >
              ShareIconOff
              {screenSharingModule.targets.get(participant.staticScreenShareTargetId.toString())
                ?.title && (
                <span style={{ marginLeft: '.375rem', marginRight: '.375rem' }}>
                  {
                    screenSharingModule.targets.get(
                      participant.staticScreenShareTargetId.toString()
                    )?.title
                  }
                </span>
              )}
            </Button>
          );
        } else {
          return (
            <Button disabled onClick={() => {}}>
              ShareIconOn
            </Button>
          );
        }
      })()}
      {/* <Btn
        icon
        transparent
        disabled={user.role !== Roles.MODERATOR}
        onClick={() => {
          participant.toggleKickUser();
        }}
      >
        <KickUserIcon color="#F1F1F1" size="3rem" />
      </Btn> */}
      <Button disabled={user.role !== Roles.MODERATOR} onClick={handleBanUser}>
        <BanUserIcon color="#F1F1F1" size="3rem" />
      </Button>
    </div>
  );
});

export default ParticipantsMediaControlsListItem;
