import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles(({ palette }: Theme) => ({
  volumeControlBarContainer: {
    height: '2.5rem',
    width: '4.75rem',
    minWidth: '4.75rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '&.disabled': {
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },
  volumeControlBarDraggablePoint: {
    height: '.75rem',
    width: '.75rem',
    borderRadius: '50%',
    background: palette.secondary.contrastText,
    position: 'absolute',
    pointerEvents: 'none',
  },
  volumeControlBar: {
    height: '.25rem',
    width: '100%',
    backgroundColor: palette.secondary.main,
    borderRadius: '.375rem',
    position: 'relative',
  },
  volumeControlBarDetails: {
    position: 'absolute',
    top: '-.25rem',
    visibility: 'hidden',
    '&.visible': {
      visibility: 'visible',
    },
  },
}));
