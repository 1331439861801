import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import useStyles from './style';
import { emitRestreamConfigRequest } from '../../services/game/emitsToGame/emits';
import Modal from '@material-ui/core/Modal';
import { BugIcon } from './..';
import { useStores } from '../../hooks/useStores';
import {
  checkAvailabilityStun,
  checkAvailabilityInstance,
  checkAvailabilityMs,
  checkAvailabilityGame,
} from '../../helpers/debug';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@material-ui/core/CircularProgress';

type DebugItemProps = {
  handleCheck: () => any;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  buttonTitle: string;
};

const DebugItem = ({ handleCheck, isLoading, isSuccess, isError, buttonTitle }: DebugItemProps) => {
  const {
    button,
    buttonWrapper,
    resultText,
    successColor,
    errorColor,
    spinnerWrapper,
  } = useStyles();

  return (
    <div className={buttonWrapper}>
      <Button variant="contained" className={button} onClick={handleCheck} disabled={isLoading}>
        {buttonTitle}
      </Button>
      {isSuccess ? (
        <div className={classnames(resultText, successColor)}>Success</div>
      ) : isError ? (
        <div className={classnames(resultText, errorColor)}>Error</div>
      ) : isLoading ? (
        <div className={spinnerWrapper}>
          <CircularProgress />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const DebugPanel = observer(() => {
  const { debug } = useStores();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    debug.setIsErrorCheckTurn(false);
    debug.setIsSuccessCheckTurn(false);
    debug.setIsLoadingCheckTurn(false);
    debug.setIsLoadingCheckMs(false);
    debug.setIsErrorCheckMs(false);
    debug.setIsSuccessCheckMs(false);
    debug.setIsLoadingCheckInstance(false);
    debug.setIsErrorCheckInstance(false);
    debug.setIsSuccessCheckInstance(false);
    debug.setIsLoadingCheckGame(false);
    debug.setIsErrorCheckGame(false);
    debug.setIsSuccessCheckGame(false);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const { container, text, button, iconWrapper, buttonWrapper, resultText } = useStyles();
  const {
    checkTurn: {
      isError: isErrorCheckTurn,
      isSuccess: isSuccessCheckTurn,
      isLoading: isLoadingCheckTurn,
      iceServers,
    },
    checkMs: { isError: isErrorCheckMs, isSuccess: isSuccessCheckMs, isLoading: isLoadingCheckMs },
    checkInstance: {
      isError: isErrorCheckInstance,
      isSuccess: isSuccessCheckInstance,
      isLoading: isLoadingCheckInstance,
    },
    checkGame: {
      isError: isErrorCheckGame,
      isSuccess: isSuccessCheckGame,
      isLoading: isLoadingCheckGame,
    },
  } = debug;

  const handleRestreamButton = () => {
    emitRestreamConfigRequest(true);
  };

  const handleCheckStun = () => {
    debug.setIsLoadingCheckTurn(true);
    debug.setIsErrorCheckTurn(false);
    debug.setIsSuccessCheckTurn(false);
    if (iceServers) {
      checkAvailabilityStun(iceServers);
    }
  };

  const handleCheckMs = () => {
    debug.setIsLoadingCheckMs(true);
    debug.setIsErrorCheckMs(false);
    debug.setIsSuccessCheckMs(false);

    checkAvailabilityMs();
  };

  const handleCheckInstance = () => {
    debug.setIsLoadingCheckInstance(true);
    debug.setIsErrorCheckInstance(false);
    debug.setIsSuccessCheckInstance(false);

    checkAvailabilityInstance();
  };

  const handleCheckGame = () => {
    debug.setIsLoadingCheckGame(true);
    debug.setIsErrorCheckGame(false);
    debug.setIsSuccessCheckGame(false);

    checkAvailabilityGame();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <div className={container}>
            <header className={text}>Debug panel</header>
            <main>
              <DebugItem
                handleCheck={handleCheckStun}
                isLoading={isLoadingCheckTurn}
                isError={isErrorCheckTurn}
                isSuccess={isSuccessCheckTurn}
                buttonTitle="check stun"
              />
              <DebugItem
                handleCheck={handleCheckMs}
                isLoading={isLoadingCheckMs}
                isError={isErrorCheckMs}
                isSuccess={isSuccessCheckMs}
                buttonTitle="check ms"
              />
              <DebugItem
                handleCheck={handleCheckInstance}
                isLoading={isLoadingCheckInstance}
                isError={isErrorCheckInstance}
                isSuccess={isSuccessCheckInstance}
                buttonTitle="check instance"
              />
              <DebugItem
                handleCheck={handleCheckGame}
                isLoading={isLoadingCheckGame}
                isError={isErrorCheckGame}
                isSuccess={isSuccessCheckGame}
                buttonTitle="check game"
              />

              <div className={buttonWrapper}>
                <Button variant="contained" className={button} onClick={handleRestreamButton}>
                  check restream
                </Button>
                <div className={resultText}></div>
              </div>
            </main>
          </div>
        </div>
      </Modal>
      <BugIcon onClick={handleOpen} className={iconWrapper} />
    </>
  );
});

export default DebugPanel;
