/*eslint no-prototype-builtins: "off"*/
import { responseEventListeners } from './eventRegistrationManager';
import { webRtcPlayer } from './webRtcPlayer';
import { handleError } from '../helpers/error';
import { rootStore } from '../models/rootStore';
import { ToClientMessageType } from './staticTypes/messageTypes';
import { emitUserControlScheme, emitCurrentLocale } from './game/emitsToGame/emits';
import { logWebRtcPlayer, logApi, LOG_LEVEL } from '@pxs-infra/logger';

export let webRtcPlayerObject: any;
export let webSocketObject: any;

export function getWebRtcPlayerObject() {
  return webRtcPlayerObject;
}

async function connect() {
  try {
    window.WebSocket = window.WebSocket || window.MozWebSocket;
    if (!window.WebSocket) {
      throw Error('Браузер не поддерживает WebSocket');
    }

    const { userId } = rootStore.user;
    const { ueClientDomain } = rootStore.connection;
    logApi(LOG_LEVEL, `ueClientDomain bind from site = ${ueClientDomain}`);
    if (!userId) {
      throw Error('Должен быть указан идентификатор пользователя');
    }
    if (!ueClientDomain) {
      throw Error('Please pass query to bind instance');
    }

    webSocketObject = new WebSocket(`wss://${ueClientDomain}:443`);

    webSocketObject.onmessage = (event: any) => {
      let msg = JSON.parse(event.data);
      logWebRtcPlayer('<- SS:', msg);
      if (msg.type === 'config') {
        setupWebRtcPlayer(msg);
      } else if (msg.type === 'answer') {
        onWebRtcAnswer(msg);
      } else if (msg.type === 'iceCandidate') {
        onWebRtcIce(msg.candidate);
      } else {
        console.log(`invalid SS message type: ${msg.type}`);
      }
    };

    webSocketObject.onerror = function (event: any) {
      console.log(`WS error. ${event.type}`);
    };

    webSocketObject.onclose = function (event: any) {
      console.log(`WS closed: ${JSON.stringify(event.code)} - ${event.reason}`);
      let playerDiv = rootStore.connection.getRefPlayer() as any;

      if (webRtcPlayerObject && playerDiv) {
        if (playerDiv.contains(webRtcPlayerObject.video)) {
          playerDiv.removeChild(webRtcPlayerObject.video);
        }
        webRtcPlayerObject.close();
        webRtcPlayerObject = undefined;
      }
      setTimeout(() => {
        start();
      }, 1000);
    };
  } catch (e: any) {
    handleError(e.message);
  }
}

function setupWebRtcPlayer(config: any) {
  try {
    const { peerConnectionOptions } = config;
    const { iceServers } = peerConnectionOptions;
    if (!iceServers.length) {
      throw new Error("Don't exist any stun server");
    }

    rootStore.debug.setIceServers(iceServers);

    webRtcPlayerObject = webRtcPlayer({
      peerConnectionOptions,
    });

    createWebRtcOffer();
  } catch (e: any) {
    console.log(`webRtcPlayer setup failed, reason: ${e.message}`);
  }
}

export function start() {
  connect();
}

export function closeWebRtcPlayer() {
  if (!webRtcPlayerObject) {
    console.log(`Cannot close webRtcPlayer, webRtcObject is undefined`);
  }
  console.log('Close webRtc player');
  webRtcPlayerObject.close();
}

export function playWebRtcPlayer() {
  if (!webRtcPlayerObject) {
    console.log(`Cannot play webRtcPlayer, webRtcObject is undefined`);
  }
  console.log('Play webRtc player');
  webRtcPlayerObject.video.play();
}

function onWebRtcAnswer(webRTCData: any) {
  webRtcPlayerObject.receiveAnswer(webRTCData);
}

function onWebRtcIce(iceCandidate: any) {
  if (!webRtcPlayerObject) {
    console.log(`Icing failed, webRtcObject is undefined`);
  }
  webRtcPlayerObject.handleCandidateFromServer(iceCandidate);
}

function createWebRtcOffer() {
  if (!webRtcPlayerObject) {
    console.log('Creating offer failed, webRtcPlayerObject is undefined');
    return;
  }
  const { connection } = rootStore;
  connection.setIsLoading(true);
  connection.setLoadingMessage('Starting connection to server, please wait');
  webRtcPlayerObject.createOffer();
}

export function sendInputData(data: any) {
  if (!webRtcPlayerObject) {
    console.log('Sending input failed, webRtcPlayerObject is undefined');
    return;
  }
  webRtcPlayerObject.send(data);
}

export const onVideoInitialised = function () {
  if (!webSocketObject && webSocketObject.readyState !== WS_OPEN_STATE) {
    console.log(
      `Cannot initialize video:
      webSocketObject state: ${Boolean(webSocketObject)},
      webSocketObject readystate :${webSocketObject.readyState}`
    );
  }
  emitUserControlScheme();
  emitCurrentLocale('en_US');
  console.log(`Screen orientation is: ${(screen.orientation || {}).type}`);
  if (webRtcPlayerObject) webRtcPlayerObject.video.play();
};

export const onWebRtcCandidate = function (candidate: any) {
  if (!webSocketObject && webSocketObject.readyState !== WS_OPEN_STATE) {
    console.log(
      `Cannot handle WebRtcCandidate:
      webSocketObject state: ${Boolean(webSocketObject)},
      webSocketObject readystate :${webSocketObject.readyState}`
    );
  }
  logWebRtcPlayer('-> SS: iceCandidate', candidate);
  webSocketObject.send(JSON.stringify({ type: 'iceCandidate', candidate: candidate }));
};

export const onDataChannelConnected = function () {
  if (!webSocketObject && webSocketObject.readyState !== WS_OPEN_STATE) {
    console.log(
      `Cannot initialize video:
      webSocketObject state: ${Boolean(webSocketObject)},
      webSocketObject readystate :${webSocketObject.readyState}`
    );
  }
  const { connection } = rootStore;
  connection.setLoadingMessage('WebRTC connected, waiting for video');
};

export const onWebRtcOffer = function (offer: any) {
  if (!webSocketObject && webSocketObject.readyState !== WS_OPEN_STATE) {
    console.log(
      `Cannot handle WebRtcOffer:
      webSocketObject state: ${Boolean(webSocketObject)},
      webSocketObject readystate :${webSocketObject.readyState}`
    );
  }
  let offerStr = JSON.stringify(offer);
  logWebRtcPlayer(`-> SS: offer: ${offerStr}`);
  webSocketObject.send(offerStr);
};

export const onDataChannelMessage = function (data: any) {
  let view = new Uint8Array(data);
  if (view[0] === ToClientMessageType.QualityControlOwnership) {
    let ownership = view[1] === 0 ? false : true;
    console.log(ownership);
  } else if (view[0] === ToClientMessageType.Response) {
    let response = new TextDecoder('utf-16').decode(data.slice(1));
    for (let listener of responseEventListeners.values()) {
      listener(response);
    }
  } else if (view[0] === ToClientMessageType.Command) {
    let commandAsString = new TextDecoder('utf-16').decode(data.slice(1));
    console.log(commandAsString);
    // let command = JSON.parse(commandAsString);
  } else {
    console.error(`unrecognised data received, packet ID ${view[0]}`);
  }
};

export const WS_OPEN_STATE = 1;
