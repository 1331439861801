import { start } from './webRtcWrapper';
import { registerMainEvents } from './eventRegistrationManager';
import { testScreenResolutionCalculator } from './tests/testWrapper';
// import { startAfkWarningTimer } from './playerState';

export function load() {
  testScreenResolutionCalculator();
  registerMainEvents();
  start();
}
