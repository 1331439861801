import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tooltip: {
    position: 'relative',
    display: 'inline-block',
    '&:hover span': {
      visibility: 'visible',
    },
  },
  tooltiptext: {
    visibility: 'hidden',
    textAlign: 'center',
    position: 'absolute',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    mixBlendMode: 'normal',
    padding: '20px',
    gap: '10px',
    borderRadius: '8px',
    zIndex: 1,
    '&::after': {
      content: '" "',
      marginLeft: '93px',
      borderWidth: '10px',
      borderStyle: 'solid',
      position: 'absolute',
      top: '100%',
      left: '0%',
    },
  },
}));

export default useStyles;
