import React from 'react';
import useStyles from './style';

const LogoutIcon = () => {
  const { maskSvg } = useStyles();
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_894_12152"
        className={maskSvg}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="18"
        height="19"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 1C2.84315 1 1.5 2.34314 1.5 4V16.003C1.5 17.6599 2.84315 19.003 4.5 19.003H10.5C12.1569 19.003 13.5 17.6599 13.5 16.003V14.0023C13.5 13.45 13.0523 13.0023 12.5 13.0023C11.9477 13.0023 11.5 13.45 11.5 14.0023V16.003C11.5 16.5553 11.0523 17.003 10.5 17.003H4.5C3.94772 17.003 3.5 16.5553 3.5 16.003V4C3.5 3.44771 3.94771 3 4.5 3H10.5C11.0523 3 11.5 3.44772 11.5 4V6.00151C11.5 6.55379 11.9477 7.00151 12.5 7.00151C13.0523 7.00151 13.5 6.55379 13.5 6.00151V4C13.5 2.34315 12.1569 1 10.5 1H4.5ZM18.283 10.6254C18.5744 10.2605 18.5744 9.74253 18.283 9.3776L15.888 6.3776C15.5434 5.94599 14.9142 5.87543 14.4826 6.22C14.051 6.56458 13.9804 7.1938 14.325 7.62541L15.4236 9.00151H7.50153C6.94924 9.00151 6.50153 9.44922 6.50153 10.0015C6.50153 10.5538 6.94924 11.0015 7.50153 11.0015H15.4236L14.325 12.3776C13.9804 12.8092 14.051 13.4384 14.4826 13.783C14.9142 14.1276 15.5434 14.057 15.888 13.6254L18.283 10.6254Z"
          fill="#F0E2FB"
        />
      </mask>
      <g mask="url(#mask0_894_12152)">
        <rect width="20" height="20" fill="#A24DE8" />
      </g>
    </svg>
  );
};

export default LogoutIcon;
