import React, { useRef, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '..';
import useStyles from './style';

type SelectType = {
  options: any;
  handleChange: any;
  innerRef: any;
  isShow: boolean;
  setIsShow: any;
  defaultValue: string;
  isDisabled: boolean;
};

const Select = ({
  defaultValue,
  options,
  handleChange,
  innerRef,
  isShow,
  setIsShow,

  isDisabled,
}: SelectType) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const listRef = useRef(null);

  const { container, header, listContainer, list, item, headerValue, arrowContainer } = useStyles({
    isDisabled,
  } as {
    isDisabled: boolean;
  });

  const handleOpen = () => {
    if (!isDisabled) {
      setIsShow(!isShow);
    }
  };

  const onOptionClicked = (value: any) => {
    setSelectedOption(value);
    handleChange(value);
  };
  const arrowColor = isDisabled ? '#737373' : '#3F5CA4';
  return (
    <>
      <div className={container} ref={innerRef}>
        <div className={header} onClick={handleOpen}>
          <div>
            <div className={headerValue}>{selectedOption || defaultValue}</div>
          </div>
          <div className={arrowContainer}>
            {isShow ? <ArrowUpIcon color={arrowColor} /> : <ArrowDownIcon color={arrowColor} />}
          </div>
        </div>
      </div>
      {isShow && (
        <div className={listContainer} ref={listRef}>
          <div className={list}>
            {options.map(({ deviceId, label }: any) => (
              <div className={item} onClick={() => onOptionClicked(label)} key={deviceId}>
                {label}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Select;
