import { makeStyles } from '@material-ui/core/styles';
import { sideMenuWidth } from '../../globalStyles';

const useStyles = makeStyles(() => ({
  sideBarWrap: {
    minWidth: 400,
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: `calc(${sideMenuWidth} + 1rem)`,
    height: '100%',
    maxHeight: '100vh',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-2rem',
    flexDirection: 'column',
    paddingLeft: '.5rem',
    transition: 'left .25s ease, opacity .25s ease',
    overflow: 'hidden',
    '&.left-aligned': {
      left: '1.25rem',
    },
    '&.shadowed': {
      opacity: '.5',
    },
    '& > *': {
      pointerEvents: 'auto',
    },
  },
  sideBarItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '1.5rem',
    marginLeft: '2rem',
    '&.auto-grow': {
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden',
    },
    '&.bottom-aligned': {
      marginTop: 'auto',
    },
  },
}));

export default useStyles;
