import { Instance, types } from 'mobx-state-tree';
import moment from 'moment';
import { Roles } from '../constants/user';

export const ChatMessageModel = types.model('chatMessage').props({
  text: types.string,
  name: types.string,
  time: types.string,
  isSenderThisUser: types.boolean,
});

export const ServersInEnvironmentModel = types.model('serversInEnvironment').props({
  displayName: types.string,
  spaceName: types.string,
});

export const EnvironmentConfigModel = types.model('environmentConfigModel').props({
  bTeleportationAvaliable: types.boolean,
  environmentName: types.string,
  lobbyServerType: types.string,
  serversInEnvironment: types.optional(types.array(ServersInEnvironmentModel), []),
});

export const ChatModel = types
  .model('chat')
  .props({
    isOpenChat: types.boolean,
    chatInputText: types.string,
  })
  .volatile(() => ({
    chatInputRef: null as HTMLInputElement | null,
  }));

const videoPlayerModel = types
  .model('videoPlayer')
  .props({
    isOpen: types.boolean,
    poster: types.string,
    src: types.string,
  })
  .volatile(() => ({
    videoRef: null as HTMLVideoElement | null,
  }));

const timerModel = types.model('timerModel').props({
  timestamp: types.number,
  bEnable: types.boolean,
});

const CustomizableObject = types.model('CustomizableObject').props({
  objectName: types.string,
  objectId: types.number,
  selectedVariant: types.number,
  numberOfVariants: types.number,
});

const CustomizeListModel = types.model('customizeList').props({
  lastUpdatedObject: types.number,
  objects: types.array(CustomizableObject),
});

export const UserModel = types
  .model('user')
  .props({
    name: types.string,
    userId: types.string,
    eventId: types.string,
    env: types.string,
    region: types.string,
    role: types.enumeration([...Object.values(Roles)]),
    messages: types.optional(types.array(ChatMessageModel), []),
    chat: ChatModel,
    videoPlayer: videoPlayerModel,
    customizeList: CustomizeListModel,
    isLive: types.boolean,
    timer: timerModel,
    environmentConfig: types.maybe(EnvironmentConfigModel),
    travelDestination: types.string,
  })
  .views((self) => ({
    get isModerator() {
      return self.role === Roles.MODERATOR;
    },
    get spaceName() {
      return self.environmentConfig?.lobbyServerType;
    },
  }))
  .actions((self) => ({
    setName(name: string) {
      self.name = name;
    },
    setUserId(userId: string) {
      self.userId = userId;
    },
    setEventId(eventId: string) {
      self.eventId = eventId;
    },
    setEnv(env: string) {
      self.env = env;
    },
    setRegion(region: string) {
      self.region = region;
    },
    setRole(newRole: Roles) {
      self.role = newRole;
    },
    sendMessage(text: string) {
      let time = moment().format('hh:mm A');
      self.messages.push({
        text,
        name: self.name,
        time,
        isSenderThisUser: true,
      });
    },
    receiveMessage(text: string, name: string) {
      let time = moment().format('hh:mm A');
      self.messages.push({
        text,
        name,
        time,
        isSenderThisUser: false,
      });
    },
    getAllMessages() {
      return self.messages;
    },
    clearAllMessages() {
      self.messages.clear();
    },
    setIsOpenChat(isOpenChat: boolean) {
      self.chat.isOpenChat = isOpenChat;
    },
    setChatInputText(chatInputText: string) {
      self.chat.chatInputText = chatInputText;
    },
    setChatInputRef(chatInputRef: HTMLInputElement) {
      self.chat.chatInputRef = chatInputRef;
    },
    setVideoPlayerOpen(isOpen: boolean) {
      self.videoPlayer.isOpen = isOpen || false;
    },
    setVideoPlayerSrc(src: string) {
      self.videoPlayer.src = src;
    },
    setVideoRef(videoRef: HTMLVideoElement | null) {
      self.videoPlayer.videoRef = videoRef;
    },
    setCustomizableObject(updateCustomizationState?: CustomizeListType) {
      if (!updateCustomizationState || !updateCustomizationState.objects) {
        return;
      }
      self.customizeList.lastUpdatedObject = updateCustomizationState.lastUpdatedObject;
      self.customizeList.objects = updateCustomizationState.objects;
    },
    setIsLive(isLive: boolean): void {
      self.isLive = isLive;
    },
    setTimer(timer: { timestamp: number; bEnable: boolean }) {
      self.timer = timer;
    },
    setEnvironmentConfig(environmentConfig?: EnvironmentConfigType) {
      if (!environmentConfig || !environmentConfig.serversInEnvironment) {
        return;
      }
      self.environmentConfig = environmentConfig;
      self.environmentConfig.serversInEnvironment = environmentConfig.serversInEnvironment;
    },
    setTravelDestination(travelDestination: string) {
      self.travelDestination = travelDestination;
    },
  }));

type ChatMessageModelType = Instance<typeof ChatMessageModel>;
export interface ChatMessageType extends ChatMessageModelType {}

type UserModelType = Instance<typeof UserModel>;
export interface UserType extends UserModelType {}

type CustomizeListModelType = Instance<typeof CustomizeListModel>;
export interface CustomizeListType extends CustomizeListModelType {}

type EnvironmentConfigModelType = Instance<typeof EnvironmentConfigModel>;
export interface EnvironmentConfigType extends EnvironmentConfigModelType {}
