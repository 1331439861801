import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }: Theme) => ({
  staticScreenShareButton: {
    padding: '0',
    position: 'relative',
    width: '17rem',
    height: '3.5rem',
    margin: '.375rem',
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    fontWeight: 700,
    fontStyle: 'normal',
    letterSpacing: '4.5%',
    whiteSpace: 'nowrap',
  },
  staticScreenShareButtonTitle: {
    width: '100%',
    height: '3rem',
    borderRadius: '3.125rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.background.default,
    color: palette.primary.dark,
    paddingLeft: '3rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  staticScreenShareButtonIcon: {
    height: '3.5rem',
    width: '3.5rem',
    position: 'absolute',
    left: '-0.125rem',
  },
}));

export default useStyles;
