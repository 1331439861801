import React, { useState } from 'react';
import { MobileSettingIcon, MenuItem, Button, MobileSettingModal } from '../..';
import useStyles from './style';

const MobileSettingMenuItem = ({ handleUpdateLocalStream }: { handleUpdateLocalStream: any }) => {
  const [isShowMobileSettingModal, setIsShowMobileSettingModal] = useState(false);
  const { container, buttonClass } = useStyles();

  const handleMobileSetting = () => {
    setIsShowMobileSettingModal(!isShowMobileSettingModal);
  };

  const handleClose = () => {
    setIsShowMobileSettingModal(false);
  };

  return (
    <>
      <div className={container}>
        <MenuItem isShowTextIcon={false}>
          <Button onClick={handleMobileSetting} className={buttonClass}>
            <MobileSettingIcon />
          </Button>
        </MenuItem>
      </div>
      <MobileSettingModal
        handleUpdateLocalStream={handleUpdateLocalStream}
        isShow={isShowMobileSettingModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default MobileSettingMenuItem;
