import React from 'react';
import useStyles from './style';

type AudioMuteIconType = {
  color?: string;
};

const AudioMuteIcon = ({ color = '#140E17' }: AudioMuteIconType) => {
  const { maskSvg } = useStyles();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      x="20"
      y="14"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <mask id="mask0_1590_315139" className={maskSvg} maskUnits="userSpaceOnUse" x="0" y="1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.75 9.36981V5C8.75 4.0335 7.9665 3.25 7 3.25C6.0335 3.25 5.25 4.0335 5.25 5V9.375C5.25 10.3415 6.0335 11.125 7 11.125C7.24086 11.125 7.47036 11.0763 7.6792 10.9883C7.36349 11.6497 7.14854 12.3684 7.05421 13.1246C7.03617 13.1249 7.0181 13.125 7 13.125C4.92893 13.125 3.25 11.4461 3.25 9.375V5C3.25 2.92893 4.92893 1.25 7 1.25C9.07107 1.25 10.75 2.92893 10.75 5V7.7986C9.99033 8.19754 9.31295 8.73199 8.75 9.36981ZM7.00304 14.2083C7.03932 15.4495 7.3987 16.6097 8 17.6076V18.125C8 18.6773 7.55228 19.125 7 19.125C6.44772 19.125 6 18.6773 6 18.125V16.1314C2.79156 15.6325 0.375 12.7697 0.375 9.375C0.375 8.82272 0.822715 8.375 1.375 8.375C1.92728 8.375 2.375 8.82272 2.375 9.375C2.375 12.079 4.47965 14.2083 7 14.2083C7.00101 14.2083 7.00203 14.2083 7.00304 14.2083Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_1590_315139)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 14C18 16.2091 16.2091 18 14 18C13.2582 18 12.5635 17.7981 11.968 17.4462L17.4462 11.968C17.7981 12.5635 18 13.2582 18 14ZM10.5538 16.032L16.032 10.5538C15.4365 10.2019 14.7418 10 14 10C11.7909 10 10 11.7909 10 14C10 14.7418 10.2019 15.4365 10.5538 16.032ZM20 14C20 17.3137 17.3137 20 14 20C10.6863 20 8 17.3137 8 14C8 10.6863 10.6863 8 14 8C17.3137 8 20 10.6863 20 14Z"
        fill="#E22929"
      />
    </svg>
  );
};

export default AudioMuteIcon;
