import { Instance, types } from 'mobx-state-tree';

export const ConnectionModel = types
  .model('connection')
  .props({
    isLoading: types.boolean,
    loadingMessage: types.string,
    isError: types.boolean,
    errorMessage: types.string,
    isHideInterface: types.boolean,
    isStartPage: types.boolean,
    isEndEvent: types.boolean,
    isOldControlMode: types.boolean,
    afkMinutesLeft: types.number,
    ueClientDomain: types.string,
  })
  .volatile(() => ({
    refPlayer: null as HTMLDivElement | null,
  }))
  .actions((self) => ({
    getRefPlayer(): HTMLDivElement | null {
      return self.refPlayer;
    },
    setIsEndEvent(isEndEvent: boolean) {
      self.isEndEvent = isEndEvent;
    },
    setRefPlayer(refPlayer: HTMLDivElement) {
      self.refPlayer = refPlayer;
    },
    setIsLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setLoadingMessage(loadingMessage: string) {
      self.loadingMessage = loadingMessage;
    },
    setIsError(isError: boolean) {
      self.isError = isError;
    },
    setErrorMessage(errorMessage: string) {
      self.errorMessage = errorMessage;
    },
    setIsHideInterface(isHideInterface: boolean) {
      self.isHideInterface = isHideInterface;
    },
    setIsStartPage(isStartPage: boolean) {
      self.isStartPage = isStartPage;
    },
    setIsOldControlMode(isOldControlMode: boolean) {
      self.isOldControlMode = isOldControlMode;
    },
    setAfkMinutesLeft(afkMinutesLeft: number) {
      self.afkMinutesLeft = afkMinutesLeft;
    },
    setUeClientDomain(ueClientDomain: string) {
      self.ueClientDomain = ueClientDomain;
    },
  }));

type ConnectionModelType = Instance<typeof ConnectionModel>;
export interface ConnectionType extends ConnectionModelType {}
