import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: '6.5rem',
    marginTop: '15.375rem',
    marginBottom: 'auto',
    zIndex: 100,
    width: '12.5rem',
    position: 'absolute',
    overflow: 'hidden',
  },
  button: {
    width: '100%',
    height: '3rem',
    border: '.125rem solid #F1F1F1',
    borderRadius: '1.5rem',
    marginTop: '1.25rem',
    backgroundColor: '#262626',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '1rem',
    textAlign: 'center',
    letterSpacing: '0.045em',
    color: '#F1F1F1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
