import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  badgeClass: {
    background: '#E6E6E6',
  },
  participantTitle: {
    paddingTop: '40px',
    paddingBottom: '24px',
  },
  notificationContainer: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
}));

export default useStyles;
