import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  settings: {
    width: '20rem',
    backgroundColor: 'rgba(31, 31, 31, 0.9)',
    padding: '1.25rem',
    borderRadius: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  settingsHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '1.25rem',
  },
  settingsHeaderTitle: {
    margin: 0,
  },
  settingsHeaderIconWrap: {},
  settingsListItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  settingsListItemLabel: {
    margin: '0 0 0 1.25rem',
    fontWeight: 700,
    fontSize: '1rem',
    color: '#7E7E7E',
  },
  settingsListItemLabelActive: {
    color: '#FFFFFF',
  },
  settingsListItemSwitchWrap: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  settingsContainer: {
    top: '0rem',
    left: '1rem',
    position: 'absolute',
    height: '100%',
    width: '20rem',
  },
  moderatoSettingsContainer: {
    display: 'flex',
    position: 'relative',
    alignSelf: 'center',
  },
}));

export default useStyles;
